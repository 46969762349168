<template>
  <div>
    <section>
      <SectionHeader
        title="App-Berechtigungen"
        subtitle="Welche Funktionen der App können generell genutzt werden?"
      />

      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 0 : 0"
                color="grey lighten-3"
                style="transition: 0.4s"
                @click="app_licences.quiz = !app_licences.quiz"
              >
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="app_licences.quiz"
                        color="primary"
                        readonly
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Quiz</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ app_licences.quiz ? "Aktiviert" : "Deaktiviert" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1">mdi-lightbulb</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 0 : 0"
                color="grey lighten-3"
                style="transition: 0.4s"
                @click="app_licences.journeys = !app_licences.journeys"
              >
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="app_licences.journeys"
                        color="primary"
                        readonly
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Lernpfade</v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          app_licences.journeys ? "Aktiviert" : "Deaktiviert"
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1"
                        >mdi-map-marker-path</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 0 : 0"
                color="grey lighten-3"
                style="transition: 0.4s"
                @click="app_licences.kpi = !app_licences.kpi"
              >
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="app_licences.kpi"
                        color="primary"
                        readonly
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Analysetools</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ app_licences.kpi ? "Aktiviert" : "Deaktiviert" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1"
                        >mdi-chart-areaspline</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 0 : 0"
                color="grey lighten-3"
                style="transition: 0.4s"
                @click="app_licences.ghostwriter = !app_licences.ghostwriter"
              >
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="app_licences.ghostwriter"
                        color="primary"
                        readonly
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Ghostwriter</v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          app_licences.ghostwriter ? "Aktiviert" : "Deaktiviert"
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="grey lighten-1">mdi-robot</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-expand-transition>
      <section v-show="app_licences.quiz">
        <v-divider class="my-4" />
        <SectionHeader
          title="Quiz-Einstellungen"
          subtitle="Einstellungen zur Quiz-Nutzung"
        />

        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="settings.quiz.user_count"
                outlined
                background-color="white"
                dense
                type="number"
                min="1"
                max="2000"
                label="Max. Anzahl Nutzer"
              />
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>

    <v-expand-transition>
      <section v-show="app_licences.ghostwriter">
        <v-divider class="my-4" />
        <SectionHeader
          title="Ghostwriter-Einstellungen"
          subtitle="Einstellungen zur Ghostwriter-Nutzung"
        >
          <template #action>
            <v-btn x-small color="primary" disabled>
              Zur Lizenzverwaltung</v-btn
            >
          </template>
        </SectionHeader>

        <v-container>
          <v-row>
            <v-col cols="12" sm="6"> blubb </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>
  </div>
</template>

<script>
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "LicenceAdministration",
  components: {
    SectionHeader,
  },
  data() {
    return {
      app_licences: {
        quiz: true,
        journeys: true,
        kpi: false,
        ghostwriter: true,
      },
      settings: {
        quiz: {
          user_count: 200,
        },
        ghostwriter: {
          licence_type: "prepaid",
        },
      },
    };
  },
};
</script>

<style></style>
