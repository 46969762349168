<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO: check eslint err -->
<template>
  <div v-if="item">
    <v-form v-model="valid" :disabled="item.is_accepted || disabled">
      <v-expand-transition>
        <v-row v-if="showOptions" dense>
          <v-col cols="12">
            <SectionHeader
              :title="$t('gw.addSession.settings')"
              :subtitle="$t('gw.optionsHint')"
              class="px-0"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="item.content.category_id"
              :items="sortedCategories"
              item-text="name"
              item-value="id"
              :label="$t('resources.categories.singular')"
              outlined
              dense
              clearable
              background-color="white"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.content.time"
              :label="$t('gw.bulk.question_time_2')"
              dense
              type="number"
              listen-to-input
              outlined
              min="0"
              max="180"
              background-color="white"
              :hint="$t('gw.bulk.hints.time_question')"
              persistent-hint
            />
          </v-col>
          <v-col v-if="!hideActivate" cols="12" md="6">
            <v-switch
              v-model="item.content.activate"
              dense
              inset
              color="secondary"
              class="ml-1 mt-1"
            >
              <template #label>
                {{ $t("gw.activateContent") }}
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-row dense>
        <v-col cols="12" class="pt-2">
          <v-textarea
            v-model="item.content.body"
            :label="$t('resources.questions.singular')"
            outlined
            dense
            hide-details
            :background-color="item.is_accepted ? '' : 'white'"
            :rules="item.formRules.body"
            :readonly="item.is_accepted"
            rows="3"
          />
        </v-col>
        <v-col>
          <v-list color="transparent">
            <v-list-item
              v-for="(a, i) in item.content.answers"
              :key="i"
              class="px-0"
            >
              <v-list-item-content>
                <v-textarea
                  v-model="a.body"
                  :label="`${$t('resources.questions.answer')} ${i + 1}`"
                  outlined
                  dense
                  rows="3"
                  hide-details
                  :background-color="item.is_accepted ? '' : 'white'"
                  :rules="item.formRules.answer"
                  :readonly="item.is_accepted"
                >
                  <template #prepend>
                    <v-icon
                      small
                      :color="a.correct ? 'success' : 'error'"
                      class="mt-1"
                    >
                      {{ a.correct ? "mdi-check-circle" : "mdi-close-circle" }}
                    </v-icon>
                  </template>
                </v-textarea>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GwQuestion } from "@/models/ghostwriter/content/GwQuestion.js";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "GwQuestionForm",
  components: {
    SectionHeader,
  },
  props: {
    item: {
      type: [GwQuestion],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    idx: {
      type: Number,
      required: true,
    },
    updated: {
      type: Boolean,
      required: false,
    },
    showOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideActivate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      original: false,
      //defaultTime: true
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.checkIfChanged();
      },
    },
    valid: {
      deep: false,
      handler(val) {
        this.$emit("valid", val);
      },
    },
    idx: {
      handler() {
        this.setQuestion();
      },
    },
    updated: {
      handler() {
        this.setQuestion();
      },
    },
    "item.content.defaultTime": {
      handler(v) {
        if (v) this.item.setTime(null);
      },
    },
  },
  beforeMount() {
    this.setQuestion();
  },
  methods: {
    setQuestion() {
      this.original = JSON.parse(JSON.stringify(this.item));
      if (this.item.content.time) this.defaultTime = false;
    },
    checkIfChanged() {
      if (this.item.id !== this.original.id) return false;
      let changed = false;
      if (this.item.content.body !== this.original.content.body) changed = true;
      this.item.content.answers.forEach((a, i) => {
        if (a.body !== this.original.content.answers[i].body) changed = true;
      });
      if (Number(this.item.content.time) !== Number(this.original.content.time))
        changed = true;
      if (this.item.content.defaultTime !== this.original.content.defaultTime)
        changed = true;
      if (this.item.content.category_id !== this.original.content.category_id)
        changed = true;
      if (this.item.content.activate !== this.original.content.activate)
        changed = true;

      this.$emit("changed", changed);
    },
  },
};
</script>
