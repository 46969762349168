export const set_users = (state, value) => {
  if (value) {
    state.users = value.sort((a, b) => {
      if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) {
        return -1;
      }
      if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  } else {
    state.users = null;
  }
};

export const update_users = (state, user) => {
  let old = state.users
    .map((e) => {
      return e.id;
    })
    .indexOf(user.id);
  if (old < 0) {
    state.users.push(user);
  } else {
    state.users.splice(old, 1, user);
  }
};

export const set_user = (state, value) => {
  state.user = value;
  if (!value) {
    state.user_stats = null;
  }
};

export const set_user_stats = (state, value) => {
  state.user_stats = value;
};

export const set_delete_user = (state, user) => {
  let filteredUsers = state.users.filter(function (oldUser) {
    return oldUser.id !== user.id;
  });
  state.users = filteredUsers;
};
