<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="show_dialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card
        v-if="show_dialog"
        color="grey lighten-3"
        max-width="640"
        :loading="loading"
      >
        <template #progress>
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <v-card-title class="overline secondary--text pb-0">
          {{ $t("gw.transcriptions.delete_transcription_title") }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t("gw.transcriptions.delete_transcription_text") }}</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            small
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="error"
            small
            :disabled="loading"
            @click="deleteTranscription()"
          >
            <span>{{ $t("general.delete") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwDeleteTranscriptionDialog",
  props: {
    transcription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show_dialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("ghostwriter", [
      "delete_transcription",
      "fetch_gw_media_upload_token",
    ]),
    openDialog() {
      this.show_dialog = true;
    },
    closeDialog() {
      this.show_dialog = false;
    },
    async deleteTranscription() {
      this.loading = true;
      var token = await this.fetch_gw_media_upload_token();
      var config = {
        headers: {
          gwtoken: token,
        },
      };
      var res = await this.delete_transcription({
        id: this.transcription.id,
        config: config,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.closeDialog();
        this.$router.push({ name: "GwTranscriptions" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
