<template>
  <v-card
    :color="color"
    :dark="dark"
    outlined
    :disabled="disabled"
    :elevation="elevation"
  >
    <v-card-title class="pb-0">
      <v-avatar tile :color="avatarBackgroundColor" size="24" class="mr-2">
        <v-icon v-if="icon" left x-small class="ma-0" color="grey lighten-3">
          {{ icon }}
        </v-icon>
      </v-avatar>
      <span class="body-2 font-weight-bold" :class="`${textColor}--text`">
        {{ title }}
      </span>
    </v-card-title>

    <v-card-subtitle v-if="subtitle.length > 0" class="pt-6 pb-2">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-actions v-if="routeName">
      <LinkWidget
        :title="routeDescription"
        :route-name="routeName"
        :text="linkTypeText"
        :color="linkColor"
        :disabled="linkDisabled"
        small
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import LinkWidget from "@/components/widgets/LinkWidget";

export default {
  name: "TextWidget",
  components: {
    LinkWidget,
  },
  props: {
    title: {
      value: [String, Number],
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    routeName: {
      type: String,
      required: false,
      default: undefined,
    },
    routeDescription: {
      type: String,
      required: false,
      default: "go to",
    },
    linkColor: {
      type: String,
      required: false,
      default: "primary",
    },
    linkTypeText: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    textColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    elevation: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    avatarBackgroundColor: {
      type: String,
      required: false,
      default: "secondary",
    },
  },
  methods: {
    ...mapMutations("questions", [
      "set_questions",
      "set_reviewmode",
      "set_reportmode",
    ]),
    goTo(routeName) {
      if (routeName) {
        if (routeName == "QuestionReports") {
          this.set_reviewmode(false);
          this.set_reportmode(true);
          routeName = "QuestionOverview";
        }
        if (routeName == "QuestionApprovals") {
          this.set_reportmode(false);
          this.set_reviewmode(true);
          routeName = "QuestionOverview";
        }
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style></style>
