<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.nuggets.plural')"
      icon="mdi-school"
      route-name="NuggetAdd"
    />
    <ViewLoader v-if="!categories || !nuggets" />

    <section v-if="categories && nuggets">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="!categories">
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters">
                <v-row>
                  <v-col v-if="categories" cols="12">
                    <v-autocomplete
                      v-model="filter.categories"
                      :items="sortedCategories"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.plural')"
                      prepend-icon="mdi-animation-play"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="!categories" cols="12">
                    <v-skeleton-loader width="100%" type="chip" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.is_active"
                      :items="options.is_active"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.nuggets.is_active')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-motion-play"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.type"
                      :items="options.type"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.nuggets.type')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-motion-play"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.source"
                      :items="options.source"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.nuggets.source')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-motion-play"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="datatable pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="nuggetsFiltered"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="filter.page"
                :loading="!nuggets"
              >
                <template #item="{ item }">
                  <tr
                    @click="
                      $router.push({
                        name: 'NuggetDetail',
                        params: { id: item.id },
                      })
                    "
                  >
                    <td>
                      <v-icon
                        small
                        :color="item.is_active == 1 ? 'success' : 'error'"
                      >
                        {{ item.is_active ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </td>
                    <td>
                      <span v-if="categories">
                        <v-tooltip right>
                          <template #activator="{ on, attrs }">
                            <v-avatar size="32" tile>
                              <v-img
                                v-bind="attrs"
                                :src="item.category.image"
                                v-on="on"
                              />
                            </v-avatar>
                          </template>
                          <span>{{ item.category.name }}</span>
                        </v-tooltip>
                      </span>
                      <span v-if="!categories">
                        <v-avatar size="32" tile>
                          <v-skeleton-loader
                            height="32"
                            width="32"
                            type="image"
                          />
                        </v-avatar>
                      </span>
                    </td>
                    <td>
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            :src="item.type"
                            small
                            v-on="on"
                          >
                            {{ typeIconByText(item.type) }}
                          </v-icon>
                        </template>
                        <span>{{
                          $t("resources.nuggets.types." + item.type)
                        }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-icon v-bind="attrs" :src="item.source" v-on="on">
                            {{ sourceIconByText(item.source) }}
                          </v-icon>
                        </template>
                        <span>{{
                          $t("resources.nuggets.sources." + item.source)
                        }}</span>
                      </v-tooltip>
                    </td>
                    <td>
                      <span>{{ item.title }}</span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "NuggetOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: this.$t("resources.nuggets.is_active"),
          value: "is_active",
          width: "5%",
        },
        {
          text: this.$t("resources.nuggets.category"),
          value: "category_id",
          width: "5%",
        },
        {
          text: this.$t("resources.nuggets.type"),
          value: "type",
          width: "10%",
        },
        {
          text: this.$t("resources.nuggets.source"),
          value: "source",
          width: "10%",
        },
        {
          text: this.$t("resources.nuggets.title"),
          value: "title",
          width: "70%",
        },
      ],
      display: {
        filters: false,
      },
      filter: {
        categories: [],
        type: [],
        source: [],
        is_active: [],
        search: "",
        page: 1,
      },
      options: {
        is_active: [
          { id: false, name: this.$t("resources.nuggets.not_is_active") },
          { id: true, name: this.$t("resources.nuggets.is_active") },
        ],
        type: [
          { id: "text", name: this.$t("resources.nuggets.types.text") },
          { id: "video", name: this.$t("resources.nuggets.types.video") },
        ],
        source: [
          { id: "local", name: this.$t("resources.nuggets.sources.local") },
          {
            id: "p3lmedia",
            name: this.$t("resources.nuggets.sources.p3lmedia"),
          },
          { id: "youtube", name: this.$t("resources.nuggets.sources.youtube") },
          { id: "vimeo", name: this.$t("resources.nuggets.sources.vimeo") },
          { id: "ted", name: this.$t("resources.nuggets.sources.ted") },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("nuggets", { nuggets: "get_nuggets" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      let active =
        this.filter.is_active.length > 0 ? this.filter.is_active.length : 0;
      let type = this.filter.type.length > 0 ? this.filter.type.length : 0;
      let source =
        this.filter.source.length > 0 ? this.filter.source.length : 0;
      return this.filter.categories.length + active + search + type + source;
    },
    nuggetsFiltered() {
      if (this.nuggets) {
        let filtered = this.nuggets.filter((nugget) => {
          if (this.filter.categories.length > 0) {
            if (!this.filter.categories.includes(nugget.category.id)) {
              return false;
            }
          }
          return true;
        });
        if (this.filter.is_active.length > 0) {
          filtered = filtered.filter((nugget) => {
            if (!this.filter.is_active.includes(nugget.is_active)) {
              return false;
            }
            return true;
          });
        }
        if (this.filter.type.length > 0) {
          filtered = filtered.filter((nugget) => {
            if (!this.filter.type.includes(nugget.type)) {
              return false;
            }
            return true;
          });
        }
        if (this.filter.source.length > 0) {
          filtered = filtered.filter((nugget) => {
            if (!this.filter.source.includes(nugget.source)) {
              return false;
            }
            return true;
          });
        }
        return filtered;
      }
      return [];
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.nuggets.plural"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.getFilter();
  },
  destroyed() {
    this.set_categories(null);
    this.set_nuggets(null);
  },
  methods: {
    ...mapActions("categories", ["fetch_categories"]),
    ...mapActions("nuggets", ["fetch_nuggets"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("nuggets", ["set_nuggets"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories();
      await this.fetch_nuggets();
      this.loading = false;
    },
    flagName(lang) {
      return lang.slice(-2);
    },
    typeIconByText(type) {
      if (type == "text") {
        return "mdi-format-color-text";
      }
      if (type == "video") {
        return "mdi-video";
      }
      return "mdi-format-color-text";
    },
    sourceIconByText(source) {
      if (source == "local" || source == "p3lmedia") {
        return "mdi-upload";
      }
      if (source == "youtube") {
        return "mdi-youtube";
      }
      if (source == "vimeo") {
        return "mdi-video-image";
      }
      if (source == "ted") {
        return "mdi-video-account";
      }
      return "mdi-upload";
    },
    resetFilter() {
      this.filter = {
        categories: [],
        type: [],
        source: [],
        is_active: [],
        search: "",
        page: 1,
      };
    },
    storeFilter() {
      localStorage.setItem("fil_nug", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_nug")) {
        let filter = JSON.parse(localStorage.getItem("fil_nug"));

        this.filter.categories = filter.categories ? filter.categories : [];
        this.filter.type = filter.type ? filter.type : [];
        this.filter.source = filter.source ? filter.source : [];
        this.filter.is_active = filter.is_active ? filter.is_active : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.page = filter.page ? filter.page : 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
