<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="structures && !loading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card color="grey lighten-3" flat>
              <v-text-field
                v-model="structureSearch"
                dense
                solo
                flat
                hide-details
                background-color="grey lighten-3"
                label="Durchsuchen"
                clearable
                prepend-inner-icon="mdi-magnify"
              />
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card color="grey lighten-3" flat>
              <v-card-text>
                <v-treeview
                  ref="treeview"
                  :items="visibleStructures"
                  item-children="substructures"
                  transition
                  :open.sync="open"
                  open-all
                  open-on-click
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon :x-small="!!item.structure">
                      {{ `mdi-${!item.structure ? "file-tree" : "square"}` }}
                    </v-icon>
                  </template>
                  <template v-slot:append="{ item }">
                    <div class="d-flex">
                      <AddSubstructureDialog
                        v-if="!item.structure"
                        :structure="item"
                      >
                        <template #button="{ openDialog }">
                          <v-tooltip left color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-btn
                                  icon
                                  small
                                  class="ml-2"
                                  @click.stop.prevent="openDialog"
                                >
                                  <v-icon small> mdi-plus-circle </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>Substruktur hinzufügen</span>
                          </v-tooltip>
                        </template>
                      </AddSubstructureDialog>

                      <EditStructureDialog
                        v-if="!item.structure"
                        :structure="item"
                      >
                        <template #button="{ openDialog }">
                          <v-btn
                            icon
                            class="mx-2"
                            small
                            @click.stop.prevent="openDialog(item)"
                          >
                            <v-icon small> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                      </EditStructureDialog>

                      <DeleteStructureDialog
                        v-if="!item.structure"
                        :structure="item"
                      >
                        <template #button="{ openDialog }">
                          <v-tooltip
                            left
                            :disabled="item.substructures.length > 0"
                            color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-btn
                                  icon
                                  small
                                  color="error"
                                  :disabled="item.substructures.length > 0"
                                  @click.stop.prevent="openDialog(item)"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>Löschen</span>
                          </v-tooltip>
                        </template>
                      </DeleteStructureDialog>

                      <EditSubstructureDialog
                        v-if="item.structure"
                        :substructure="item"
                      >
                        <template #button="{ openDialog }">
                          <v-btn
                            icon
                            small
                            @click.stop.prevent="openDialog(item)"
                          >
                            <v-icon small> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                      </EditSubstructureDialog>
                    </div>
                  </template>

                  <template v-slot:label="{ item }">
                    <span>{{ item.name }}</span>
                    <v-chip v-if="!item.structure" small label class="ml-2">
                      {{ item.substructures.length }}
                    </v-chip>
                  </template>
                </v-treeview>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EditStructureDialog from "@/components/structures/EditStructureDialog";
import DeleteStructureDialog from "@/components/structures/DeleteStructureDialog";
import AddSubstructureDialog from "@/components/structures/AddSubstructureDialog";
import EditSubstructureDialog from "@/components/structures/EditSubstructureDialog";
import ViewLoader from "@/components/_layout/ViewLoader.vue";

export default {
  name: "StructureAdministration",
  components: {
    EditStructureDialog,
    DeleteStructureDialog,
    AddSubstructureDialog,
    EditSubstructureDialog,
    ViewLoader,
  },
  data() {
    return {
      loading: false,
      structureSearch: "",
      open: [],
      headers: {
        structures: [
          { text: "Struktur", value: "name" },
          { text: "# Substrukturen", value: "substructures" },
          {
            text: "Aktionen",
            value: "actions",
            sortable: false,
            width: "120px",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    visibleStructures() {
      if (!this.structures) return [];
      let structures = JSON.parse(JSON.stringify(this.structures));
      if (this.structureSearch && this.structureSearch.length > 0) {
        structures = structures.filter((s) => {
          let match = false;
          if (s.name.toLowerCase().includes(this.structureSearch.toLowerCase()))
            match = true;
          s.substructures.forEach((sub) => {
            if (
              sub.name
                .toLowerCase()
                .includes(this.structureSearch.toLowerCase())
            )
              match = true;
          });
          return match;
        });
      }
      return structures;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin"]);
  },
  methods: {
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_structures();
      this.loading = false;
    },
  },
};
</script>

<style></style>
