<template>
  <v-container :style="`height: ${height}px;`">
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col
        class="text-subtitle-1 align-center d-md-flex justify-center text-center"
        cols="10"
        md="12"
      >
        <v-avatar size="128" color="transparent">
          <v-img :src="image" max-width="96" max-height="96" />
        </v-avatar>
        <div class="ml-md-4 title primary--text">
          <div>{{ $t("general.wait") }}</div>
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            reverse
            height="4"
            max-width="420px"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ViewLoader",
  props: {
    height: {
      type: [String, Number],
      required: false,
      default: 400,
    },
  },
  data: () => ({
    image: require("@/assets/img/misc/flamingo.png"),
  }),
};
</script>

<style></style>
