<template>
  <v-navigation-drawer v-model="display" app dark @input="emitMenuStatus()">
    <template #prepend>
      <v-container class="pa-0">
        <v-card tile dark elevation="0" @click="$emit('go-to', 'Account')">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline">
                {{ auth.user_name }}
              </div>
              <v-list-item-title>
                <span class="text-caption">
                  {{ auth.first_name }} {{ auth.last_name }}</span
                ><br />
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-caption">{{ auth.email }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar size="48">
              <v-img :src="auth.avatar_img_url" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-container>
    </template>

    <v-list dense nav class="py-0">
      <v-subheader>
        {{ $t("navigation.menu.title") }}
      </v-subheader>

      <v-list-item
        v-if="['superadmin', 'admin', 'editor', 'manager'].includes(role)"
        link
        :to="{ name: 'Dashboard' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-apps</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.dashboard.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin'].includes(role)"
        link
        :to="{ name: 'StructureAdministration' }"
      >
        <v-list-item-icon>
          <v-icon small> mdi-lock </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Administration</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin', 'manager'].includes(role) && showStats"
        link
        :to="{ name: 'StatisticsUser' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-finance</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("navigation.statistics.title") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin'].includes(role) && showKPI"
        link
        :to="{ name: 'KpiOverview' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("kpi.label") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="['superadmin', 'admin'].includes(role)"
        link
        :to="{ name: 'SupportOverview' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-face-agent</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.support.support") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-group
        v-if="['superadmin', 'admin', 'manager'].includes(role)"
        prepend-icon="mdi-account-cog"
        active-class="primary white--text"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigation.management.title")
            }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="pl-3" link :to="{ name: 'UserOverview' }">
          <v-list-item-icon>
            <v-icon small> mdi-account-multiple </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.users.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="!hideRegisterOptions"
          class="pl-3"
          link
          :to="{ name: 'InvitationOverview' }"
        >
          <v-list-item-icon>
            <v-icon small> mdi-account-multiple-plus </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.invitations.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="!hideRegisterOptions"
          class="pl-3"
          link
          :to="{ name: 'CodeOverview' }"
        >
          <v-list-item-icon>
            <v-icon small> mdi-numeric </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.codes.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list-group>

      <v-list-group
        v-if="['superadmin', 'admin', 'editor'].includes(role)"
        prepend-icon="mdi-content-paste"
        active-class="primary white--text"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navigation.content.title")
            }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="pl-3" link :to="{ name: 'QuestionOverview' }">
          <v-list-item-icon>
            <v-icon small> mdi-comment-question </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("resources.questions.plural") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pl-3" link :to="{ name: 'NuggetOverview' }">
          <v-list-item-icon>
            <v-icon small> mdi-school </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("resources.nuggets.plural") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pl-3" link :to="{ name: 'CategoryOverview' }">
          <v-list-item-icon>
            <v-icon small> mdi-shape </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("resources.categories.plural") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list-group>

      <v-list-item
        v-if="['superadmin', 'admin', 'manager', 'editor'].includes(role)"
        link
        :to="{ name: 'JourneyCurrent' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-map-marker-path</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("resources.journeys.journey.plural") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="
          ['superadmin', 'admin', 'editor'].includes(role) && showGhostwriter
        "
        link
        :to="{ name: 'GwSessionList' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-typewriter</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("gw.ghostwriter") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list dense>
        <v-list-item link :href="appLink" target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-play</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("ng.to_app") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="['superadmin', 'admin'].includes(role)"
          link
          :to="{ name: 'Community' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.community.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Account' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.account.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$emit('logout')">
          <v-list-item-icon>
            <v-icon>mdi-eject</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("navigation.logout.title") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppSideBar",
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  computed: {
    ...mapGetters("auth", { auth_sources: "get_auth_sources" }),
    showStats() {
      if (
        process.env.VUE_APP_HIDE_STATS_FOR_MANAGER === "true" &&
        this.role == "manager"
      )
        return false;
      return true;
    },
    showGhostwriter() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
    showKPI() {
      return process.env.VUE_APP_SHOW_KPI === "true";
    },
    appLink() {
      return `https://${process.env.VUE_APP_INSTANCE_NAME}.p3l.app`;
    },
    hideRegisterOptions() {
      if (!this.auth_sources) return false;
      return !this.auth_sources.filter((s) => s.name === "local")[0];
    },
  },
  watch: {
    menu: function (value) {
      this.display = value;
    },
  },
  methods: {
    emitMenuStatus() {
      this.$emit("menu-status", this.display);
    },
  },
};
</script>

<style></style>
