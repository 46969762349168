import api from "@/services/api";
import { Statistics } from "@/models/Statistics";

export const fetch_statistics = async ({ commit }, payload, config = {}) => {
  try {
    let res = await api.get(
      `admin/statistics/?time_frame=${payload.timeFrame}&time_pointer=${payload.timePointer}`,
      config
    );
    if (res._status === 200) {
      commit("set_statistics", new Statistics(res.stats));
      commit("set_options", {
        timeFrame: res.stats.timeFrame,
        timePointer: res.stats.timePointer,
      });
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_statistics_details = async (
  { commit },
  payload,
  config = {}
) => {
  try {
    let today = new Date();
    let startDate = new Date(new Date().setDate(today.getDate() - 30))
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
    let endDate = today.toISOString().slice(0, 10).replace("T", " ");
    let res = await api.get(
      `admin/statistics/details?start_date=${startDate}&end_date=${endDate}`,
      config
    );
    if (res._status === 200) {
      let { duels, wins, submissions } = res;
      commit("set_statistics_details", { duels, wins, submissions });
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
