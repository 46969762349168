export const set_sources = (state, value) => {
  state.sources = value;
};

export const set_source = (state, value) => {
  state.source = value;
};

export const set_job = (state, value) => {
  state.job = value;
};
