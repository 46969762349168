export const set_codes = (state, value) => {
  if (value) {
    state.codes = value;
  } else {
    state.codes = null;
  }
};

export const set_code = (state, value) => {
  state.code = value;
};

export const set_code_proposal = (state, value) => {
  state.code_proposal = value;
};

export const update_codes = (state, code) => {
  let old = state.codes
    .map((e) => {
      return e.code;
    })
    .indexOf(code.code);

  if (old < 0) {
    state.codes.push(code);
  } else {
    state.codes.splice(old, 1, code);
  }
};
