import { GwNugget } from "@/models/ghostwriter/content/GwNugget.js";
import { GwQuestion } from "@/models/ghostwriter/content/GwQuestion.js";
export class GwPart {
  constructor(obj) {
    this.id = obj.id;
    this.session_id = obj.session_id;

    this.content = obj.content;

    this.is_deleted = obj.is_deleted;
    this.is_generated = obj.is_generated;

    // options
    this.options = obj.options ? obj.options : null;

    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);

    this.nuggets = this.generateNuggets(obj.nuggets);
    this.questions = this.generateQuestions(obj.questions);
  }

  get nugget_count() {
    return this.nuggets.filter((n) => !n.is_deleted).length;
  }

  get question_count() {
    return this.questions.filter((q) => !q.is_deleted).length;
  }

  get whole_content() {
    let content = this.nuggets.concat(this.questions);
    return content
      .filter((c) => !c.is_deleted)
      .sort((a, b) => a.created_at - b.created_at);
  }

  get content_word_count() {
    if (!this.content || this.content.length === 0) return 0;
    return this.content.split(" ").length;
  }

  get set_credits() {
    return this.content_word_count * (1 + 3);
  }
  get nugget_credits() {
    return this.content_word_count;
  }
  get question_credits() {
    return this.content_word_count;
  }

  generateNuggets(arr) {
    let nuggets = [];
    arr.map((n) => {
      let nugget = new GwNugget(n);
      nugget.part_info = {
        id: this.id,
        content: this.content,
        created_at: this.created_at,
      };
      nuggets.push(nugget);
    });
    return nuggets;
  }
  generateQuestions(arr) {
    let questions = [];
    arr.map((q) => {
      let question = new GwQuestion(q);
      question.part_info = {
        id: this.id,
        content: this.content,
        created_at: this.created_at,
      };
      questions.push(question);
    });
    return questions;
  }
  removeItem(id) {
    this.nuggets = this.nuggets.filter((n) => n.id !== id);
    this.questions = this.questions.filter((q) => q.id !== id);
  }
}
