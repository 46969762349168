<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">
          {{ $t("gw.part_info") }}
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <p>{{ $t("gw.part_info_hint") }}</p>
              <p class="caption">
                ID: {{ item.part_info.id }},
                {{ $t("resources.invitations.created_at") }}
                {{ item.part_info.created_at.toLocaleDateString() }}
              </p>
              <v-sheet color="grey lighten-2" class="pa-2">
                {{ item.part_info.content }}
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GwQuestion } from "@/models/ghostwriter/content/GwQuestion";
import { GwNugget } from "@/models/ghostwriter/content/GwNugget";

export default {
  name: "GwPartInfoDialog",
  props: {
    item: {
      type: [GwQuestion, GwNugget],
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>
