<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="5">
        <v-dialog
          ref="startDatePicker"
          v-model="startDatePicker"
          :return-value.sync="date_start"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="date_start"
              readonly
              v-bind="attrs"
              dense
              solo
              flat
              hide-details
              :background-color="validStartDate ? 'grey lighten-2' : 'error'"
              :dark="!validStartDate"
              :disabled="disabled"
              v-on="on"
              :rules="[...rules.required, validStartDate]"
            >
              <template #prepend-inner>
                <v-icon small class="mr-2"> mdi-calendar-end </v-icon>
              </template>
              <template #append>
                <v-icon> mdi-menu-down </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="date_start"
            scrollable
            color="primary"
            :locale="$i18n.locale"
            type="month"
            show-current
          >
            <v-spacer />
            <v-btn text color="primary" @click="startDatePicker = false">
              {{ $t("general.cancel") }}
            </v-btn>
            <v-tooltip
              :disabled="validStartDate && validEndDate"
              top
              color="error"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    text
                    color="primary"
                    :disabled="!validEndDate || !validStartDate"
                    @click="$refs.startDatePicker.save(date_start)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("kpi.invalid_start_date_hint") }}</span>
            </v-tooltip>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="5">
        <v-dialog
          ref="endDatePicker"
          v-model="endDatePicker"
          :return-value.sync="date_end"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="date_end"
              readonly
              v-bind="attrs"
              dense
              solo
              flat
              hide-details
              :background-color="validEndDate ? 'grey lighten-2' : 'error'"
              :dark="!validEndDate"
              v-on="on"
              :disabled="disabled"
              :rules="[...rules.required, validEndDate]"
            >
              <template #prepend-inner>
                <v-icon small class="mr-2"> mdi-calendar-end </v-icon>
              </template>
              <template #append>
                <v-icon> mdi-menu-down </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="date_end"
            scrollable
            color="primary"
            :locale="$i18n.locale"
            type="month"
            show-current
          >
            <v-spacer />
            <v-btn text color="primary" @click="endDatePicker = false">
              {{ $t("general.cancel") }}
            </v-btn>

            <v-tooltip
              :disabled="validStartDate && validEndDate"
              top
              color="error"
            >
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    text
                    color="primary"
                    :disabled="!validEndDate || !validStartDate"
                    @click="$refs.endDatePicker.save(date_end)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("kpi.invalid_end_date_hint") }}</span>
            </v-tooltip>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn
          color="primary"
          block
          depressed
          :disabled="!datesHaveChanged || disabled"
          @click="changeDates()"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "KpiDatePicker",
  props: {
    start: {
      type: String,
      required: false,
    },
    end: {
      type: String,
      required: false,
    },
    global: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      date_start: "",
      date_end: "",
      startDatePicker: false,
      endDatePicker: false,
      rules: {
        required: [(v) => !!v || this.$t("general.has_to_be_selected")],
      },
    };
  },
  computed: {
    ...mapGetters("kpi", { dates: "get_kpi_dates" }),
    validStartDate() {
      var start = new Date(this.date_start);
      var end = new Date(this.date_end);
      return start <= end;
    },
    validEndDate() {
      var start = new Date(this.date_start);
      var end = new Date(this.date_end);
      return end >= start;
    },
    datesHaveChanged() {
      var start = this.formatDate(this.date_start);
      var end = this.formatDate(this.date_end);
      return (
        (this.global &&
          (this.dates.date_start !== start || this.dates.date_end !== end)) ||
        (!this.global && (this.start !== start || this.end !== end))
      );
    },
  },
  watch: {
    date_start: {
      handler(v) {
        this.date_start = this.formatDate(v);
      },
    },
    date_end: {
      handler(v) {
        this.date_end = this.formatDate(v);
      },
    },
  },
  beforeMount() {
    this.init();
  },
  methods: {
    ...mapMutations("kpi", ["set_kpi_dates"]),
    init() {
      this.date_start = this.formatDate(
        this.global ? this.dates.date_start : this.start
      );
      this.date_end = this.formatDate(
        this.global ? this.dates.date_end : this.end
      );
    },
    changeDates() {
      var start = this.formatDate(this.date_start);
      var end = this.formatDate(this.date_end);
      if (this.global) {
        var dates = {
          date_start: start,
          date_end: end,
        };
        this.set_kpi_dates(dates);

        return false;
      }
      this.$emit("date-change", {
        date_start: start,
        date_end: end,
      });
    },
    formatDate(date) {
      return date.length === 7 ? date.concat("-01") : date;
    },
  },
};
</script>

<style></style>
