<template>
  <div>
    <section>
      <v-container>
        <v-row dense>
          <v-col cols="12" md="4">
            <KpiTypePicker :disabled="loading" />
          </v-col>
          <v-col cols="12" md="8">
            <KpiDatePicker global :disabled="loading" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <ChartCard
              chart-type="line"
              :chart-data="mainGraphData"
              :card-height="'auto'"
              :chart-height="500"
              :loading="loading"
              :chart-options="chartOptions"
              bundle-charts
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChartCard from "@/components/kpi/ChartCard";
import { COLORS } from "@/plugins/chartcolors.js";
import KpiDatePicker from "@/components/kpi/KpiDatePicker";
import KpiTypePicker from "@/components/kpi/KpiTypePicker";

export default {
  name: "KpiPerformanceCompetency",
  components: {
    ChartCard,
    KpiDatePicker,
    KpiTypePicker,
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: "center" /* position: 'right', */,
          },
        },
        scales: {
          y: {
            max: 1,
            min: 0,
            title: {
              display: true,
              text: this.$t("kpi.competency"),
              font: {
                family: "Roboto",
                size: 12,
                lineHeight: 1.2,
              },
              padding: { top: 20, bottom: 20 },
            },
          },
          x: {
            type: "time",
            time: {
              unit: "month",
              tooltipFormat: "Y-MM",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      stats: "get_kpi_timed",
      type: "get_kpi_type",
      dates: "get_kpi_dates",
      loading: "get_kpi_loading",
    }),
    mainGraphData() {
      if (!this.stats || this.stats.length === 0) return null;
      var items = [...this.stats];
      var datasets = [];

      items.forEach((item, i) => {
        var itemData = [];
        item.labels.forEach((l, idx) => {
          itemData.push({
            x: new Date(l),
            y: item.datasets.competency[idx],
          });
        });

        var item_label = "";
        if (!["substructure", "user"].includes(item.resource)) {
          item_label = item.info.name;
        } else {
          if (item.resource === "substructure")
            item_label = `${item.info.name} [${item.info.structure.name}]`;
          if (item.resource === "user")
            item_label = `${item.info.name} [${item.info.first_name} ${item.info.last_name}]`;
        }
        var dataset = {
          label: item_label,
          backgroundColor: COLORS[i],
          data: itemData,
        };
        datasets.push(dataset);
      });

      return {
        datasets: datasets,
      };
    },
  },
};
</script>

<style></style>
