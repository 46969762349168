<template>
  <v-card
    class="d-flex flex-column align-center justify-center"
    elevation="0"
    color="grey lighten-3"
    height="100"
  >
    <h3>
      {{ value }}
    </h3>
    <span class="caption text-uppercase font-weight-bold secondary--text">{{
      text
    }}</span>
  </v-card>
</template>

<script>
export default {
  name: "StatisticsCardSimple",
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "-",
    },
    text: {
      type: [String, Number],
      required: false,
      default: "-",
    },
  },
};
</script>
