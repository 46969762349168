var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("general.edit")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("gw.journey.editJourney.subtitle_2")))]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","background-color":"white","label":_vm.$t('resources.nuggets.title'),"rules":_vm.rules.title,"disabled":_vm.journey.is_imported ||
                    _vm.journey.is_deleted ||
                    _vm.journey.is_archived},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"success","loading":_vm.loading,"disabled":!_vm.titleHasChanged ||
                  !_vm.valid ||
                  _vm.journey.is_imported ||
                  _vm.journey.is_deleted ||
                  _vm.journey.is_archived},on:{"click":function($event){return _vm.updateJourney()}}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.$t("general.delete")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("gw.journey.delete.subtitle_2")))]),_c('v-card-actions',[_c('v-spacer'),_c('GwDeleteJourneyDialog',{attrs:{"journey":_vm.journey},scopedSlots:_vm._u([{key:"action",fn:function({ openDialog }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","text":"","color":"error"},on:{"click":openDialog}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.delete")))])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.journey.delete_journey")))])])]}}])})],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }