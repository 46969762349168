<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="show_dialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card
        v-if="show_dialog"
        color="grey lighten-3"
        max-width="640"
        :loading="loading"
      >
        <template #progress>
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <v-card-title class="overline secondary--text pb-0">
          {{ $t("resources.journeys.duplicate_journey") }}
        </v-card-title>
        <v-card-text>
          <p>{{ $t("resources.journeys.duplicate_1") }}</p>
          <v-sheet color="grey lighten-2" class="pa-2 mb-2">
            <h4 class="secondary--text">{{ journey.name }}</h4>
            <small>{{ journey.description }}</small>
          </v-sheet>
          <p>{{ $t("resources.journeys.duplicate_2") }}</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            small
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            small
            :disabled="loading || !!duplicate_id"
            @click="duplicateJourney()"
          >
            <span>{{ $t("general.copy") }}</span>
            <v-icon small v-if="!!duplicate_id" class="ml-2"
              >mdi-check-circle</v-icon
            >
          </v-btn>
          <v-slide-x-reverse-transition>
            <v-btn
              v-if="duplicate_id"
              depressed
              color="primary"
              small
              :disabled="loading"
              @click="goToDuplicate()"
            >
              {{ $t("resources.journeys.to_new_journey") }}
            </v-btn>
          </v-slide-x-reverse-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DuplicateJourneyDialog",
  props: {
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show_dialog: false,
      loading: false,
      duplicate_id: null,
    };
  },
  methods: {
    ...mapActions("journeys", ["duplicate_journey"]),
    openDialog() {
      this.show_dialog = true;
    },
    closeDialog() {
      this.show_dialog = false;
      this.duplicate_id = null;
    },
    async duplicateJourney() {
      this.loading = true;
      let payload = { journey_id: this.journey.id };
      let res = await this.duplicate_journey({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.duplicate_id = res.journey_id;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    goToDuplicate() {
      this.$router
        .push({
          name: "JourneySummary",
          params: { id: this.duplicate_id },
        })
        .then(() => {
          this.$router.go();
        });
    },
  },
};
</script>
