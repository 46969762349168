<template>
  <div>
    <v-container class="px-0">
      <v-row dense>
        <v-col cols="12" sm="6">
          <KpiStatsProgressCard
            :title="$t('kpi.table_chips.journeys_assigned')"
            color="grey lighten-2"
            highlight-color="info"
            icon="mdi-account-circle"
            :value="stats ? stats.assigned : 0"
            :loading="!stats"
            hoverable
            :to-fixed-value="0"
            @click="$emit('change-tab', 2)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <KpiStatsProgressCard
            :title="$t('kpi.table_chips.journeys_finished')"
            color="grey lighten-2"
            highlight-color="success"
            icon="mdi-check-circle"
            :value="stats ? stats.finished : 0"
            :loading="!stats"
            hoverable
            :to-fixed-value="0"
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <KpiStatsProgressCard
            :title="$t('kpi.table_chips.journeys_in_progress')"
            color="grey lighten-2"
            highlight-color="primary"
            icon="mdi-progress-clock"
            :value="stats ? stats.in_progress : 0"
            :loading="!stats"
            hoverable
            :to-fixed-value="0"
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <KpiStatsProgressCard
            :title="$t('kpi.table_chips.journeys_failed')"
            color="grey lighten-2"
            highlight-color="error"
            icon="mdi-close-circle"
            :value="stats ? stats.failed : 0"
            :loading="!stats"
            hoverable
            :to-fixed-value="0"
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <KpiStatsProgressCard
            :title="$t('kpi.average_quiz_value')"
            color="grey lighten-2"
            highlight-color="secondary"
            icon="mdi-percent-circle-outline"
            :value="journey ? journey.stats.criteria * 100 : 0"
            :loading="!stats"
            hoverable
            :to-fixed-value="0"
            unit="%"
            @click="$emit('change-tab', 1)"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="12">
          <ChartCard
            chart-type="bar"
            :chart-data="graphData"
            :card-height="'auto'"
            :chart-height="300"
            :chart-options="chartOptions"
            :loading="!graphData"
            card-color="grey lighten-1"
            sheet-color="grey lighten-3"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="journey">
          <v-list color="transparent">
            <v-list-item
              v-for="(substructure, i) in journey.substructures"
              :key="`substructure-${i}`"
              class="grey lighten-2 mb-2"
            >
              <v-list-item-content>
                <div class="d-flex align-center justify-end">
                  <div>{{ substructure.name }}</div>
                  <v-spacer />
                  <StatisticsTableChip
                    :value="substructure.stats.assigned"
                    no-delta
                    chip-color="grey lighten-3"
                    :description="$t('kpi.table_chips.journeys_assigned')"
                    icon="mdi-account-circle"
                    tooltip-position="left"
                    :to-fixed-value="0"
                  />
                  <StatisticsTableChip
                    :value="substructure.stats.finished"
                    no-delta
                    chip-color="grey lighten-3"
                    :description="$t('kpi.table_chips.journeys_finished')"
                    icon="mdi-check-circle"
                    tooltip-color="success"
                    icon-color="success"
                    tooltip-position="left"
                    :to-fixed-value="0"
                    class="ml-2"
                  />
                  <StatisticsTableChip
                    :value="substructure.stats.in_progress"
                    no-delta
                    chip-color="grey lighten-3"
                    :description="$t('kpi.table_chips.journeys_in_progress')"
                    icon="mdi-progress-clock"
                    tooltip-color="primary"
                    icon-color="primary"
                    tooltip-position="left"
                    :to-fixed-value="0"
                    class="mx-2"
                  />
                  <StatisticsTableChip
                    :value="substructure.stats.failed"
                    no-delta
                    chip-color="grey lighten-3"
                    :description="$t('kpi.table_chips.journeys_failed')"
                    icon="mdi-close-circle"
                    icon-color="error"
                    tooltip-color="error"
                    tooltip-position="left"
                    :to-fixed-value="0"
                    class="mx-2"
                  />
                  <StatisticsTableChip
                    :value="substructure.stats.criteria * 100"
                    no-delta
                    chip-color="grey lighten-3"
                    :description="$t('kpi.average_quiz_value')"
                    icon="mdi-percent-circle-outline"
                    tooltip-color="secondary"
                    tooltip-position="left"
                    :to-fixed-value="0"
                    unit="%"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KpiStatsProgressCard from "@/components/kpi/KpiStatsProgressCard";
import ChartCard from "@/components/kpi/ChartCard";
import StatisticsTableChip from "@/components/kpi/StatisticsTableChip";

export default {
  name: "KpiJourneyMain",
  components: {
    KpiStatsProgressCard,
    ChartCard,
    StatisticsTableChip,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: "center",
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },

            title: {
              display: true,
              text: this.$t("kpi.all_users"),
              font: {
                family: "Roboto",
                size: 12,
                lineHeight: 1.2,
              },
              padding: { top: 20, bottom: 20 },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      journey: "get_kpi_journey",
    }),
    graphData() {
      if (!this.journey) return null;
      var labels = [];

      var items = [...this.journey.substructures];
      var stats = {
        finished: [],
        in_progress: [],
        failed: [],
        assigned: [],
      };

      items.forEach((item) => {
        labels.push(`${item.structure.name} / ${item.name}`);
        stats.finished.push(item.stats.finished);
        stats.in_progress.push(item.stats.in_progress);
        stats.failed.push(item.stats.failed);
        stats.assigned.push(item.stats.assigned);
      });

      var datasets = [
        {
          label: this.$t("resources.journeys.user_statuses.finished"),
          backgroundColor: "#8AC34A",
          data: stats.finished,
        },
        {
          label: this.$t("resources.journeys.user_statuses.in_progress"),
          backgroundColor: "#00afd7",
          data: stats.in_progress,
        },
        {
          label: this.$t("resources.journeys.user_statuses.failed"),
          backgroundColor: "#F34335",
          data: stats.failed,
        },
        {
          label: this.$t("resources.journeys.user_statuses.not_started"),
          backgroundColor: "#617D8B", //F34335
          data: stats.assigned,
        },
      ];

      return {
        datasets: datasets,
        labels: labels,
      };
    },
  },
};
</script>

<style></style>
