<template>
  <div>
    <ViewLoader v-if="loading" />

    <ViewHeader
      v-if="!loading"
      :breadcrumbs="breadcrumbs"
      :title="'source-detail'"
      icon="mdi-source-repository"
    >
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'SourceOverview' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>
    </ViewHeader>

    <section v-if="!loading" class="mt-4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card flat>
              <v-card-title class="justify-center">Current Job</v-card-title>
              <v-card-text class="text-center">
                <p
                  v-if="
                    !tmpjob ||
                    (tmpjob && !tmpjob.info && tmpjob.status !== 'FAILURE')
                  "
                >
                  no job running
                </p>
                <p v-if="tmpjob && tmpjob.info">{{ tmpjob.id }}</p>
                <p
                  v-if="
                    tmpjob &&
                    tmpjob.info &&
                    Object.keys(tmpjob.info).length !== 0
                  "
                  class="caption"
                >
                  {{ tmpjob.info }}
                </p>
                <p
                  v-if="tmpjob && tmpjob.status === 'FAILURE'"
                  class="caption error--text"
                >
                  JOB FAILED!
                </p>
                <v-expand-transition>
                  <v-progress-linear
                    v-if="tmpjob && !!tmpjob.info"
                    height="16"
                    :indeterminate="tmpjob.status !== 'FAILURE'"
                    :value="100"
                    :color="tmpjob.status === 'FAILURE' ? 'error' : 'primary'"
                  >
                    <!-- :value="tmpjob.info ? tmpjob.info.progress : 0" -->
                    <!-- <span class="overline white--text">{{
                      tmpjob.info && !!tmpjob.info.step ? tmpjob.info.step : "-"
                    }}</span> -->
                  </v-progress-linear>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- <v-divider v-if="!loading" class="my-8" /> -->

    <section v-if="!loading">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h4 text-uppercase grey--text">source info</h2>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="source.id"
                  label="id"
                  outlined
                  dense
                  background-color="white"
                  readonly
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  :value="source.mimetype"
                  label="mimetype"
                  outlined
                  dense
                  background-color="white"
                  readonly
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-dialog v-model="bodyDialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="info" block v-bind="attrs" v-on="on">
                      Show body
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Body
                    </v-card-title>

                    <v-card-text>
                      {{ source.body }}
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="bodyDialog = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :value="source.path"
                  label="path"
                  outlined
                  dense
                  background-color="white"
                  readonly
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="refreshInterval"
                  label="refreshInterval (ms)"
                  outlined
                  dense
                  background-color="info lighten-3"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-btn
                  block
                  color="info"
                  :href="`https://admin.devworker.p3l.app/backend/admin/dev/sources/${source.id}`"
                  target="_blank"
                  >show src</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"> Structure source: </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  small
                  block
                  color="primary"
                  :disabled="
                    !!tmpjob ||
                    licences.ghostwriter.credits_count < source.cost.topic
                  "
                  @click="structureSource('topic')"
                >
                  <span>
                    Topic based
                    <small
                      >({{
                        source.cost.topic
                          ? source.cost.topic.toLocaleString()
                          : "-"
                      }})</small
                    ></span
                  >
                </v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  small
                  block
                  color="primary"
                  class="ml-2"
                  :disabled="
                    !!tmpjob ||
                    licences.ghostwriter.credits_count < source.cost.course
                  "
                  @click="structureSource('course')"
                >
                  <span>
                    Course based
                    <small
                      >({{
                        source.cost.course
                          ? source.cost.course.toLocaleString()
                          : "-"
                      }})</small
                    >
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  small
                  block
                  color="primary"
                  :disabled="
                    !!tmpjob ||
                    licences.ghostwriter.credits_count < source.cost.journey
                  "
                  @click="structureSource('journey')"
                  >journey
                  <small
                    >({{
                      source.cost.journey
                        ? source.cost.journey.toLocaleString()
                        : "-"
                    }})</small
                  ></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="my-8" />

    <section v-if="!loading" class="mb-8">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            v-if="!source.structures || source.structures.length === 0"
          >
            <p>Structure source first (topic, course or journey)</p>
          </v-col>
          <v-col
            cols="12"
            v-if="source.structures && source.structures.length > 0"
          >
            <v-tabs
              v-model="currentStructureTab"
              height="32"
              background-color="grey lighten-3"
              active-class="grey white--text"
              hide-slider
            >
              <v-tab
                v-for="s in source.structures"
                :key="`structure-${s.id}`"
                >{{ `${s.id}: ${s.ai_variant}` }}</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="currentStructureTab">
              <v-tab-item
                v-for="s in source.structures"
                :key="`structuretab-${s.id}`"
                class="pt-8"
              >
                <v-row>
                  <v-col cols="6" md="3">
                    <v-text-field
                      :value="s.duration ? s.duration : '-'"
                      label="Dauer Generierung (in s)"
                      outlined
                      disabled
                      dense
                      append
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      :value="getInputTokens(s)"
                      label="Kosten Strukturieren (Input Tokens)"
                      outlined
                      disabled
                      dense
                      append
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      :value="getOutputTokens(s)"
                      label="Kosten Strukturieren (Output Tokens)"
                      outlined
                      disabled
                      dense
                      append
                    />
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-text-field
                      :value="s.credits ? s.credits.toLocaleString() : '-'"
                      label="Kosten Credits (p3l)"
                      outlined
                      disabled
                      dense
                      append
                    />
                  </v-col>
                </v-row>

                <v-expansion-panels accordeon>
                  <v-expansion-panel v-for="p in s.parts" :key="`part-${p.id}`">
                    <v-expansion-panel-header>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-tooltip
                            :disabled="p.pos_end - p.pos_start >= 1000"
                            right
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs">{{
                                p.pos_end - p.pos_start < 1000
                                  ? "mdi-alert"
                                  : "mdi-check"
                              }}</v-icon>
                            </template>
                            <span
                              >Less than 1.000 characters ({{
                                p.pos_end - p.pos_start
                              }})!</span
                            >
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-content style="width: 50%">
                          <v-list-item-title>
                            {{ `${p.title}` }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Titel (id: {{ p.id }})</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ p.nuggets.length }} /
                            {{ p.questions.length }}</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >nuggets / questions</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-tooltip left>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mt-2"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-information</v-icon
                              >
                            </template>
                            <div>
                              <div>pos_start: {{ p.pos_start }}</div>
                              <div>pos_end: {{ p.pos_end }}</div>
                            </div>
                          </v-tooltip>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="p.nuggets && p.nuggets.length > 0">
                        <v-slide-group
                          v-model="nuggetSlide[p.id]"
                          class="pa-4"
                          show-arrows
                        >
                          <v-slide-item
                            v-for="n in p.nuggets"
                            :key="`nugget-${n.id}`"
                          >
                            <v-dialog v-model="contentDialog[n.id]" width="640">
                              <template v-slot:activator="{ on, attrs }">
                                <v-card
                                  outlined
                                  color="grey lighten-3"
                                  max-width="500"
                                  class="mx-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-card-subtitle class="pb-1 d-flex">
                                    <span class="overline">Nugget</span>
                                    <v-spacer />
                                    <v-icon small>mdi-arrow-expand</v-icon>
                                  </v-card-subtitle>
                                  <v-card-title class="pt-0">{{
                                    n.title
                                  }}</v-card-title>
                                  <v-card-text>
                                    <p>{{ n.description }}</p>
                                  </v-card-text>
                                </v-card>
                              </template>

                              <v-card>
                                <v-card-subtitle class="pb-1 pt-4">
                                  <span class="overline">Nugget</span>
                                </v-card-subtitle>
                                <v-card-title class="pt-0">{{
                                  n.title
                                }}</v-card-title>
                                <v-card-text>
                                  <p>{{ n.description }}</p>
                                  <p>{{ n.content }}</p>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </v-slide-item>
                        </v-slide-group>
                      </div>
                      <div v-if="p.questions && p.questions.length > 0">
                        <v-slide-group
                          v-model="questionSlide[p.id]"
                          class="pa-4"
                          show-arrows
                        >
                          <v-slide-item
                            v-for="q in p.questions"
                            :key="`question-${q.id}`"
                          >
                            <v-card
                              outlined
                              color="grey lighten-3"
                              max-width="500"
                              class="mx-2"
                            >
                              <v-card-subtitle class="overline pb-0"
                                >Question</v-card-subtitle
                              >
                              <v-card-title class="pt-0">{{
                                q.body
                              }}</v-card-title>
                              <v-card-text>
                                <v-list-item
                                  v-for="a in q.answers"
                                  :key="`answer-${a.id}`"
                                >
                                  <v-list-item-icon>
                                    <v-icon
                                      :color="
                                        a.is_correct ? 'success' : 'error'
                                      "
                                      >{{
                                        a.is_correct ? "mdi-check" : "mdi-close"
                                      }}</v-icon
                                    >
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    {{ a.body }}
                                  </v-list-item-content>
                                </v-list-item>
                              </v-card-text>
                            </v-card>
                          </v-slide-item>
                        </v-slide-group>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import ViewHeader from "@/components/_layout/ViewHeader";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";

export default {
  name: "SourceDetail",
  components: { ViewLoader, ViewHeader, GwCreditInfoMenu },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      progress: null,
      refreshInterval: 4000,
      currentStructureTab: null,
      bodyDialog: false,
      nuggetSlide: [[]],
      questionSlide: [[]],
      contentDialog: [[]],
    };
  },
  computed: {
    ...mapGetters("tmp", { source: "get_source", tmpjob: "get_job" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "source overview",
          disabled: false,
          to: { name: "SourceOverview" },
        },
      ];
    },
    displayedBody() {
      if (!this.source || !this.source.body) return "no body available";
      var body = this.source.body;
      if (!this.highlightedPart) {
        return body;
      }

      var part_body = this.highlightedPart.body;

      var span_start = "<span class='highlighted-text'>";
      var span_end = "</span>";

      var start_index = body.indexOf(part_body);
      var part_body_length = part_body.length;
      var end_index = start_index + part_body_length + span_start.length;

      var el = [
        body.slice(0, start_index),
        span_start,
        body.slice(start_index),
      ].join("");
      el = [el.slice(0, end_index), span_end, el.slice(end_index)].join("");

      return el;
    },
    /* inputTokens() {
      // 5$ / 1M input tokens
      if (!this.source || !this.source.structure_input_tokens) return "-";
      var tokens = this.source.structure_input_tokens;
      var pricePerToken = 5 / 1000000;
      var price = (tokens * pricePerToken).toFixed(3);
      return `${tokens} (${price} $)`;
    },
    outputTokens() {
      // 15$ / 1M output tokens
      if (!this.source || !this.source.structure_output_tokens) return "-";
      var tokens = this.source.structure_output_tokens;
      var pricePerToken = 15 / 1000000;
      var price = (tokens * pricePerToken).toFixed(3);
      return `${tokens} (${price} $)`;
    }, */
  },
  destroyed() {
    this.set_source(null);
    this.set_job(null);
    this.progress = clearInterval(this.progress);
  },
  watch: {
    displayedBody() {
      if (!this.highlightedPart) return false;
      this.$nextTick(() => {
        var highlightedElement =
          document.getElementsByClassName("highlighted-text")[0];
        if (!highlightedElement) return false;
        //test.scrollIntoView();
        var scrollableDiv = document.getElementById("scroll");
        scrollableDiv.scrollTo({
          top: highlightedElement.offsetTop - 40,
          behavior: "smooth",
        });
      });
    },
  },
  methods: {
    ...mapActions("tmp", [
      "fetch_source",
      "fetch_job",
      "structure_source",
      "generate_journey",
    ]),
    ...mapActions("auth", ["init_auth"]),
    ...mapMutations("tmp", ["set_source", "set_job"]),
    async fetchData() {
      this.loading = true;
      // todo: remove line, move to check job function
      this.set_job(null);

      var res = await this.fetch_source({
        id: this.id,
      });

      if (res && res._status === 200 && !!res.source.job.id) {
        this.checkJob();
      }
      this.loading = false;
    },
    async structureSource(type) {
      var payload = {
        ai_variant: type,
      };
      var res = await this.structure_source({
        id: this.id,
        payload: payload,
      });

      if (res && res._status === 200) {
        this.refreshData();
      }
    },
    async refreshData() {
      if (!this.tmpjob) {
        await this.fetch_source({
          id: this.id,
        });
      }
      await this.fetch_job({
        id: this.tmpjob ? this.tmpjob.id : this.source.job.id,
      });

      this.checkJob();
    },
    async checkJob() {
      await this.fetch_job({
        id: this.tmpjob ? this.tmpjob.id : this.source.job.id,
      });
      if (
        !this.source ||
        !this.source.job.id ||
        this.$route.name !== "SourceDetail" ||
        (this.tmpjob && this.tmpjob.status === "SUCCESS") ||
        (this.tmpjob && this.tmpjob.status === "FAILURE")
      ) {
        this.cancelJob();
        return false;
      }
      if (!this.source || !this.source.job.id) return false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (this.progress) return false;
      this.progress = setInterval(
        function () {
          this.refreshData();
        }.bind(this),
        this.refreshInterval
      );
    },
    async cancelJob() {
      if (this.tmpjob && this.tmpjob.status !== "FAILURE") {
        this.set_job(null);
        await this.init_auth({ id: this.auth.id });
      }
      this.progress = clearInterval(this.progress);
      await this.fetch_source({
        id: this.id,
      });
    },
    getInputTokens(structure) {
      // 5$ / 1M input tokens
      if (!structure || !structure.tokens_input) return "-";
      var tokens = structure.tokens_input;
      var pricePerToken = 5 / 1000000;
      var price = (tokens * pricePerToken).toFixed(3);
      return `${tokens} (${price} $)`;
    },
    getOutputTokens(structure) {
      // 15$ / 1M output tokens
      if (!structure || !structure.tokens_output) return "-";
      var tokens = structure.tokens_output;
      var pricePerToken = 15 / 1000000;
      var price = (tokens * pricePerToken).toFixed(3);
      return `${tokens} (${price} $)`;
    },
  },
};
</script>
