<template>
  <div>
    <v-btn
      icon
      :small="!xSmall"
      :x-small="xSmall"
      :color="iconColor"
      :disabled="disabled"
      @click="showDialog = true"
    >
      <v-icon>{{ icon }}</v-icon>
    </v-btn>

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      scrollable
      max-width="480"
      @keydown.esc="closeDialog"
      @click:outside="closeDialog"
    >
      <v-card color="grey lighten-3">
        <v-img
          v-if="getImageSource"
          max-height="240"
          :src="getImageSource"
          alt="tutorial image"
        />
        <div class="text-center">
          <span
            v-for="(step, i) in templates.length"
            :key="i"
            :class="i == currentTemplate ? 'primary' : 'grey lighten-2'"
            class="step d-inline-block ml-2"
            @click="currentTemplate = i"
          />
        </div>
        <v-card-text class="px-2 pb-0">
          <keep-alive>
            <component :is="showTemplate" />
          </keep-alive>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="templates.length > 1"
            icon
            color="primary"
            :disabled="currentTemplate == 0"
            @click="currentTemplate--"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn text color="primary" @click="closeDialog">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="templates.length > 1"
            icon
            color="primary"
            :disabled="currentTemplate == templates.length - 1"
            @click="currentTemplate++"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TutorialCarouselDialog",
  components: {},
  props: {
    templates: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    icon: {
      type: String,
      default: "mdi-help-circle-outline",
      required: false,
    },
    iconColor: {
      type: String,
      default: "grey",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      currentTemplate: 0,
    };
  },
  computed: {
    showTemplate() {
      let template = this.templates[this.currentTemplate].replace(
        /\b\w/g,
        (l) => l.toUpperCase()
      );
      return () =>
        import(
          "@/components/tutorials/templates/TutorialTemplate" +
            template +
            ".vue"
        );
    },
    getImageSource() {
      try {
        return require(`@/assets/img/tutorials/tutorial-template-${this.templates[
          this.currentTemplate
        ].toLowerCase()}.png`);
      } catch {
        console.log(
          "template image " +
            this.templates[this.currentTemplate].toLowerCase() +
            ".png not found"
        );
        return "";
      }
    },
  },
  methods: {
    closeDialog() {
      this.currentTemplate = 0;
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
.step {
  width: 12px;
  height: 2px;
  cursor: pointer;
}
</style>
