import api from "@/services/api";

export const login_user = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/auth/login", payload, config);
    if (res._status === 200) {
      localStorage.setItem("token", res.admin_info.apiToken);
      localStorage.setItem("auth_id", res.admin_info.id_user);
      await dispatch("init_auth", { id: res.admin_info.id_user });
      return res;
    } else {
      return res;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const logout_user = async ({ commit }, config = {}) => {
  try {
    let res = await api.post("admin/auth/logout", null, config);
    if (res._status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("auth_id");
      commit("set_auth", null);
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const init_auth = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/auth/user", config);
    if (res._status === 200) {
      let role = "player";
      if (res.user.role_id == 4) {
        role = "manager";
      }
      if (res.user.role_id == 2) {
        role = "editor";
      }
      if (res.user.role_id == 1) {
        role = "admin";
      }
      if (res.user.role_id == 5) {
        role = "superadmin";
      }
      commit("set_role", role);
      commit("set_auth", res.user);
      commit("set_licences", res.licences);
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_language = async ({ state }, { language, config = {} }) => {
  try {
    let res = await api.patch(
      `users/${state.auth.id}`,
      {
        language: language,
      },
      config
    );
    if (res._status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_dashboard_info = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/auth/dashboard", config);
    if (res._status === 200) {
      commit("set_dashboard_stats", res.stats);
      commit("set_dashboard_questions", res.questions);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const check_sso_code = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("oauth2/sso/code", payload, config);
    if (res._status === 200) {
      localStorage.setItem("token", res.apiToken);
      localStorage.setItem("auth_id", res.id);
      await dispatch("init_auth", { id: res.id });
      return res;
    } else {
      return res;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};
