export const GRADES = [
  { value: 0, label: "Sonstige" },
  { value: 1, label: "Klasse 1" },
  { value: 2, label: "Klasse 2" },
  { value: 3, label: "Klasse 3" },
  { value: 4, label: "Klasse 4" },
  { value: 5, label: "Klasse 5" },
  { value: 6, label: "Klasse 6" },
  { value: 7, label: "Klasse 7" },
  { value: 8, label: "Klasse 8" },
  { value: 9, label: "Klasse 9" },
  { value: 10, label: "Klasse 10" },
  { value: 11, label: "Klasse 11" },
  { value: 12, label: "Klasse 12" },
  { value: 13, label: "Klasse 13" },
];

export const SCHOOLS = [
  { value: 1, label: "Grundschule" },
  { value: 2, label: "Realschule" },
  { value: 3, label: "Gymnasium" },
  { value: 4, label: "Haupt-/Mittelschule" },
  { value: 5, label: "Gesamtschule" },
];
