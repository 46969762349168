<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div>{{ $t("general.errors." + $route.params.code) }}</div>
          <v-btn
            v-if="!!auth"
            depressed
            color="primary"
            class="mt-4"
            :to="{ name: 'Dashboard' }"
          >
            {{ $t("ng.go_to_dashboard") }}
          </v-btn>
          <v-btn
            v-if="!auth"
            depressed
            color="primary"
            class="mt-4"
            @click="logout()"
          >
            {{ $t("sso.back_to_login") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ErrorPage",
  methods: {
    ...mapActions("auth", ["logout_user"]),
    async logout() {
      this.menu = false;
      let success = await this.logout_user();
      if (success) {
        if (this.$route.name != "Login") {
          this.$router.push({ name: "Login" });
        }
      } else {
        this.$notify({
          type: "error",
          text: this.$t("general.errors.logout_failed"),
        });
      }
    },
  },
};
</script>
