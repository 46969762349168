<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card
        v-if="showDialog && user_licence"
        color="grey lighten-3"
        max-width="640"
      >
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="overline secondary--text">{{
            $t("gw.licences.update_licence")
          }}</span>
          <v-btn x-small depressed color="error" @click="deleteUserLicence()">{{
            $t("general.delete")
          }}</v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-form
            v-model="valid"
            :disabled="licenceExpired || !['superadmin'].includes(role)"
          >
            <v-row dense>
              <v-col cols="12">
                <v-alert
                  v-if="licenceExpired"
                  text
                  type="info"
                  color="error"
                  dense
                  >{{ $t("gw.licences.expired_user_licence_hint") }}</v-alert
                >
                <p
                  v-html="
                    $t('gw.licences.valid_from_until', {
                      user: `${user_licence.user.first_name} ${user_licence.user.last_name} (${user_licence.user.user_name})`,
                      from: user_licence.date_start
                        .toLocaleString()
                        .substr(0, 10),
                      until: user_licence.date_end
                        .toLocaleString()
                        .substr(0, 10),
                    })
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedMonthDuration"
                  :items="monthDurations"
                  item-text="label"
                  dense
                  clearable
                  :label="$t('gw.licences.month_duration')"
                  outlined
                  :disabled="formChanged || licenceExpired"
                  :hint="$t('gw.licences.month_duration')"
                  persistent-hint
                  hide-details
                  background-color="white"
                  item-value="value"
                />
                <v-expand-transition>
                  <div v-show="formChanged">
                    <small>{{ $t("general.save_first_hint") }}</small>
                  </div>
                </v-expand-transition>
              </v-col>
              <v-col cols="6">
                <v-btn
                  depressed
                  block
                  small
                  color="primary"
                  class="mt-1"
                  :loading="loading"
                  :disabled="
                    !selectedMonthDuration || formChanged || licenceExpired
                  "
                  @click="extendUserLicence('increase')"
                  >{{ $t("gw.licences.extend_licence") }}</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  depressed
                  block
                  small
                  color="error"
                  class="mt-1"
                  :loading="loading"
                  :disabled="
                    !selectedMonthDuration || formChanged || licenceExpired
                  "
                  @click="extendUserLicence('decrease')"
                  >{{ $t("gw.licences.shorten_licence") }}</v-btn
                >
              </v-col>
            </v-row>

            <v-divider class="my-4" />
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="input.credits_count"
                  :label="$t('gw.licences.credits_count')"
                  dense
                  outlined
                  :background-color="
                    isExtendingLicence ? 'grey lighten-3' : 'white'
                  "
                  :hint="
                    !isExtendingLicence
                      ? $t('gw.licences.credits_count')
                      : $t('general.save_first_hint')
                  "
                  persistent-hint
                  min="0"
                  :disabled="isExtendingLicence"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            color="error"
            :disabled="loading || !formChanged"
            @click="reset()"
            >{{ $t("general.reset") }}</v-btn
          >
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="!valid || loading || !formChanged"
            @click="updateUserLicence()"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "GhostwriterUserLicenceDialog",
  props: {
    user_licence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      valid: false,
      input: null,
      rules: {
        required: [(v) => !!v || "Muss ausgewählt werden"],
      },
      monthDurations: [
        {
          value: 1,
          label: `1 ${this.$t("general.single_month")}`,
        },
        {
          value: 2,
          label: `2 ${this.$t("general.months")}`,
        },
        {
          value: 3,
          label: `3 ${this.$t("general.months")}`,
        },
        {
          value: 6,
          label: `6 ${this.$t("general.months")}`,
        },
        {
          value: 12,
          label: `12 ${this.$t("general.months")}`,
        },
      ],
      selectedMonthDuration: null,
    };
  },
  computed: {
    ...mapGetters("users", { users: "get_users" }),
    ...mapGetters("ghostwriter", { licences: "get_licences" }),
    visibleUsers() {
      if (!this.users) return [];
      var licence = [...this.licences].filter(
        (l) => l.id === this.user_licence.licence_id
      )[0];
      var users = licence.users;
      var licence_users = users.map((u) => u.user_id);
      return this.users.filter(
        (u) =>
          !licence_users.includes(u.id) || u.id === this.user_licence.user_id
      );
    },
    licenceExpired() {
      if (!this.user_licence) return true;
      let now = new Date();
      let date = new Date(this.user_licence.date_end);
      return now > date;
    },
    formChanged() {
      if (!this.user_licence) return false;
      if (
        Number(this.user_licence.credits_count) !==
        Number(this.input.credits_count)
      )
        return true;
      return false;
    },
    isExtendingLicence() {
      return !!this.selectedMonthDuration;
    },
  },
  watch: {
    showDialog: {
      handler: function (v) {
        if (v) this.setInput();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("administration", [
      "update_gw_user_licence",
      "extend_gw_user_licence",
      "delete_gw_user_licence",
    ]),
    ...mapActions("users", ["fetch_users"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
      this.fetchUsers();
    },
    setInput() {
      var input = { ...this.user_licence };
      delete input.date_start;
      delete input.date_end;
      this.input = input;
    },
    async fetchUsers() {
      this.loading = true;
      await this.fetch_users();
      this.loading = false;
    },
    async updateUserLicence() {
      this.loading = true;
      var add_credits =
        this.input.credits_count - this.user_licence.credits_count;
      var payload = {
        id: this.user_licence.id,
        add_credits: add_credits,
      };
      var res = await this.update_gw_user_licence({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.update_user_licence_success"),
        });
        this.setInput();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.update_user_licence_error"),
        });
      }
    },
    async extendUserLicence(type) {
      this.loading = true;
      let payload = {
        id: this.user_licence.id,
        //month_duration: this.selectedMonthDuration,
        add_months:
          type === "decrease"
            ? -this.selectedMonthDuration
            : this.selectedMonthDuration,
      };

      var res = await this.update_gw_user_licence({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.update_user_licence_success"),
        });
        this.selectedMonthDuration = null;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.update_user_licence_error"),
        });
      }
    },
    async deleteUserLicence() {
      var confirm = window.confirm(
        this.$t("gw.licences.delete_licence_confirm")
      );
      if (!confirm) return false;

      this.loading = true;
      var res = await this.delete_gw_user_licence({
        payload: { id: this.user_licence.id },
      });
      this.loading = false;

      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.delete_user_licence_success"),
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.delete_user_licence_error"),
        });
      }
    },
    reset() {
      this.setInput();
    },
  },
};
</script>
