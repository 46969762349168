<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.users.page_title')"
      icon="mdi-account-multiple"
    >
      <template #titleButton>
        <div class="d-flex align-center">
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'UserOverview' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>

      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <router-view v-if="!loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import ViewNavigation from "@/components/_layout/ViewNavigation";

export default {
  name: "UserDetail",
  components: {
    ViewHeader,
    ViewLoader,
    ViewNavigation,
  },
  data() {
    return {
      loading: true,
      subloading: false,
      id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_bulk_session" }),
    title() {
      if (!this.session || !this.session.name) return "";
      return this.session.name;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("general.overview"),
          to: { name: "UserOverview" },
          disabled: false,
        },
      ];
    },
    navItems() {
      return [
        {
          title: this.$t("general.details"),
          link: { name: "UserInfo" },
          disabled: false,
        },
        {
          title: this.$t("navigation.statistics.title"),
          link: { name: "UserStatistics" },
          disabled: false,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
  },
  destroyed() {
    this.set_user(null);
    this.set_structures(null);
    this.set_substructures(null);
    this.set_categories(null);
  },
  methods: {
    ...mapActions("users", ["fetch_user"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("ghostwriter", ["set_bulk_session"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("users", ["set_user"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      this.loading = true;
      if (this.id) {
        await this.fetch_user({
          id: this.id,
        });

        await this.fetch_structures();
        await this.fetch_categories();
      }
      this.loading = false;
    },
  },
};
</script>

<style></style>
