<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="categories"
      :items-per-page="itemsPerPage"
      :loading="!categories"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td class="px-0">
            <v-list-item>
              <v-list-item-avatar class="mr-2">
                <v-progress-circular
                  :value="item.completion * 100"
                  :color="getCategoryProgressColor(item.completion * 100)"
                  size="24"
                  width="4"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.completion ? item.completion.toFixed(2) : "-" }}
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-icon small>mdi-information</v-icon>
              </v-list-item-action> -->
            </v-list-item>
          </td>
          <td class="px-0">
            <v-list-item>
              <v-list-item-avatar class="mr-2">
                <v-progress-circular
                  :value="item.competency * 100"
                  :color="getCategoryProgressColor(item.competency * 100)"
                  size="24"
                  width="4"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.competency ? item.competency.toFixed(2) : "-" }}
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-icon small>mdi-information</v-icon>
              </v-list-item-action> -->
            </v-list-item>
          </td>
          <td class="px-0">
            <v-list-item>
              <v-list-item-avatar class="mr-2">
                <v-progress-circular
                  :value="item.retention * 100"
                  :color="getCategoryProgressColor(item.retention * 100)"
                  size="24"
                  width="4"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.retention ? item.retention.toFixed(2) : "-" }}
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-icon small>mdi-information</v-icon>
              </v-list-item-action> -->
            </v-list-item>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "UserStatisticsCategories",
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        {
          text: this.$t("resources.categories.name"),
          value: "name",
        },
        {
          text: this.$t("kpi.discovery"),
          value: "completion",
          width: "20%",
          align: "start",
        },
        {
          text: this.$t("kpi.competency"),
          value: "competency",
          width: "20%",
        },
        {
          text: this.$t("kpi.retention"),
          value: "retention",
          width: "20%",
        },
        /* {
          text: this.$t("resources.journeys.propNames.name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: `${this.$t("resources.journeys.status")} (${this.$t(
            "resources.journeys.journey.singular"
          )})`,
          value: "journey_status",
          width: "25%",
        },
        {
          text: `${this.$t("resources.journeys.status")} (${this.$t(
            "resources.users.singular"
          )})`,
          value: "status",
          width: "25%",
        },
        {
          text: this.$t("general.finished"),
          value: "user_journey.updated_at",
          width: "25%",
        }, */
      ],
    };
  },
  methods: {
    getUserProgressColor(status) {
      if (!status) return "grey lighten-3";
      if (status === "finished") return "success";
      if (status === "in_progress") return "warning";
      if (status === "failed") return "error";
      return "secondary";
    },
    getCategoryProgressColor(value) {
      if (!value || (value && value < 30)) return "error";
      if (value >= 30 && value <= 70) return "warning";
      if (value > 70) return "success";
    },
    goToJourney(id) {
      this.$router.push({ name: "JourneySummary", params: { id: id } });
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
  },
};
</script>

<style></style>
