<template>
  <div>
    <section>
      <v-container v-if="source" class="pt-0">
        <v-textarea
          :value="source.body"
          outlined
          background-color="white"
          flat
          :label="$t('gw.journey.source')"
          hide-details
          height="800"
          no-resize
          readonly
          :loading="loading"
          style="border: 1px solid #ccc"
        />
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "GwJourneySource",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source: "get_source",
    }),
  },
  mounted() {
    this.fetchSource();
  },
  destroyed() {
    this.set_source(null);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_source"]),
    ...mapMutations("ghostwriter", ["set_source"]),
    async fetchSource() {
      this.loading = true;
      await this.fetch_source({ id: this.journey.source_id });
      this.loading = false;
    },
  },
};
</script>
