<template>
  <div>
    <section>
      <v-container>
        <v-row>
          <v-col
            v-if="['superadmin', 'admin', 'manager'].includes(role)"
            cols="12"
            sm="6"
          >
            <StatisticsRankingList
              :data="structure_statistics"
              :title="$t('ng.largest_structures')"
              :subtitle="$t('ng.active_user')"
              item-title="name"
              header-route-name="StatisticsStructures"
              append-value="users_count"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import StatisticsRankingList from "@/components/statistics/StatisticsRankingList";

export default {
  name: "StatisticsOverview",
  components: {
    StatisticsRankingList,
  },
  computed: {
    ...mapGetters("statistics", {
      statistics: "get_statistics",
      top_three: "get_statistics_detail",
      options: "get_options",
    }),
  },
  methods: {
    ...mapActions("statistics", [
      "fetch_statistics",
      "fetch_statistics_detail",
    ]),
    ...mapMutations("statistics", [
      "set_statistics",
      "fetch_statistics_details",
      "set_options",
    ]),
  },
  fetchData() {
    this.fetch_statistics_detail();
  },
  /* destroyed() {
    TODO: check this
    this.set_statistics(null);
    this.set_options(null);
  } */
};
</script>
