<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editStructures.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.editStructures.subtitle')
        "
      />

      <v-container v-if="journey && substructures">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="secondary--text">
                {{
                  $t("resources.journeys.addedSubstructures").slice(
                    0,
                    $t("resources.journeys.addedSubstructures").length - 1
                  )
                }}
              </v-card-title>
              <v-card-subtitle>{{
                $t("ng.journey.update_visibility")
              }}</v-card-subtitle>
              <v-card-text>
                <p v-html="$t('resources.journeys.structure_warning')" />
                <v-chip
                  v-for="substructure in journey.substructures"
                  :key="substructure"
                  class="mr-2"
                  color="primary"
                >
                  <span
                    >{{ getSubstructureById(substructure).structure.name }} /
                    {{ getSubstructureById(substructure).name }}</span
                  >
                  <v-btn
                    icon
                    x-small
                    :disabled="
                      !['unpublished'].includes(journey.status) ||
                      !['superadmin', 'admin', 'editor'].includes(role) ||
                      loading
                    "
                    class="ml-1"
                    @click="removeSubstructure(substructure)"
                  >
                    <v-icon small color="white"> mdi-close-circle </v-icon>
                  </v-btn>
                </v-chip>
                <div
                  v-if="journey.substructures.length === 0"
                  class="error--text font-weight-bold"
                >
                  {{ $t("resources.journeys.noStructuresYet") }}
                </div>
              </v-card-text>
              <v-card-text>
                <v-autocomplete
                  v-model="newSubstructures"
                  :items="availableSubstructures"
                  item-text="name"
                  item-value="id"
                  clearable
                  multiple
                  outlined
                  dense
                  hide-details
                  :placeholder="$t('ng.journey.add_substructures')"
                  :disabled="
                    ['closed', 'expired'].includes(journey.status) ||
                    !['superadmin', 'admin', 'editor'].includes(role) ||
                    loading
                  "
                >
                  <!-- :placeholder="$t('resources.substructures.plural')" -->
                  <template #item="{ item }">
                    <v-checkbox :value="isSelected(item)" />
                    <div>
                      {{ getSubstructureById(item.id).structure.name }} /
                      {{ getSubstructureById(item.id).name }}
                    </div>
                  </template>
                  <template #selection="{ item }">
                    <v-chip small color="grey lighten-2">
                      {{ getSubstructureById(item.id).structure.name }} /
                      {{ getSubstructureById(item.id).name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  small
                  text
                  color="primary"
                  :disabled="
                    !newSubstructures ||
                    newSubstructures.length === 0 ||
                    ['closed', 'expired'].includes(journey.status) ||
                    !['superadmin', 'admin', 'editor'].includes(role)
                  "
                  @click="add()"
                  >{{ $t("general.add") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <JourneyDialogStructureWarning
      v-if="showWarningDialog"
      :dialog="showWarningDialog"
      @close="closeWarningDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import JourneyDialogStructureWarning from "@/components/journeys/dialogs/JourneyDialogStructureWarning";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "JourneyStructures",
  components: {
    JourneyDialogStructureWarning,
    SectionHeader,
  },
  data() {
    return {
      newSubstructures: [],
      showWarningDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("structures", { substructures: "get_substructures" }),
    ...mapGetters("journeys", { journey: "get_journey" }),
    availableSubstructures() {
      return this.substructures.filter(
        (substructure) => !this.journey.substructures.includes(substructure.id)
      );
    },
  },
  methods: {
    ...mapActions("journeys", [
      "add_journey_substructures",
      "delete_journey_substructure",
    ]),
    getSubstructureById(id) {
      return this.substructures.filter(
        (substructure) => substructure.id === id
      )[0];
    },
    openWarningDialog() {
      this.showWarningDialog = true;
    },
    closeWarningDialog(confirm) {
      if (confirm) this.addSubstructure();
      this.showWarningDialog = false;
    },
    add() {
      if (["active", "paused"].includes(this.journey.status)) {
        this.openWarningDialog();
      } else {
        this.addSubstructure();
      }
    },
    async addSubstructure() {
      this.loading = true;
      let payload = {
        journey_id: this.journey.id,
        substructures: [...this.newSubstructures],
      };
      let res = await this.add_journey_substructures({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.newSubstructures = [];
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async removeSubstructure(id) {
      this.loading = true;
      let payload = {
        journey_id: this.journey.id,
        substructure_id: id,
      };
      let res = await this.delete_journey_substructure({
        payload: payload,
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    isSelected(substructure) {
      return (
        this.newSubstructures.filter((s) => s === substructure.id).length > 0
      );
    },
  },
};
</script>

<style>
.structure-list-item {
  border-left: 4px solid #617d8b !important;
  background: white;
}
</style>
