<template>
  <div>
    <ViewLoader v-if="loading && !statistics" height="200" />

    <section v-if="statistics">
      <v-container class="pt-0">
        <SectionHeader
          :title="$t('ng.time_settings')"
          :subtitle="$t('ng.time_settings_descr')"
          class="pl-0 mb-2"
        />

        <StatisticsTimepicker />
      </v-container>
    </section>

    <section v-if="statistics">
      <v-container>
        <v-row>
          <v-col cols="12">
            <SectionHeader
              :title="$t('ng.played_duels')"
              :subtitle="$t('ng.played_duels_descr')"
              class="pl-0 mb-4"
            />

            <ChartLine :chart-data="stats.duels" :options="chartOptions" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="statistics" class="my-4">
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="accumulated_values.duels.toLocaleString()"
              :subtitle="$t('ng.played_duels')"
              icon="mdi-account-plus"
            />
          </v-col>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="accumulated_values.trainings.toLocaleString()"
              :subtitle="$t('views.statistics.trainings')"
              icon="mdi-account-star"
              color="secondary"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="statistics">
      <v-container>
        <v-row>
          <v-col cols="12">
            <SectionHeader
              :title="$t('views.statistics.trainings')"
              :subtitle="$t('ng.trainings_descr')"
              class="pl-0 mb-4"
            />
            <ChartLine :chart-data="stats.trainings" :options="chartOptions" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import StatisticsTimepicker from "@/components/statistics/StatisticsTimepicker.vue";
import SectionHeader from "@/components/_layout/SectionHeader.vue";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import ChartLine from "@/components/charts/ChartLine.vue";

export default {
  name: "StatisticsQuizzes",
  components: {
    ViewLoader,
    StatisticsTimepicker,
    ChartLine,
    SectionHeader,
    StatisticsCardSmall,
  },
  data() {
    return {
      loading: true,
      stats: {
        trainings: {
          labels: [],
          datasets: [],
        },
        duels: {
          labels: [],
          datasets: [],
        },
      },
      accumulated_values: {
        trainings: 0,
        duels: 0,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxRotation: 90,
                minRotation: 90,
              },
              gridLines: {
                color: "rgba(0,175,215,0.1)",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                precision: 0,
                min: 0,
              },
              gridLines: {
                color: "rgba(0,175,215,0)",
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters("statistics", {
      statistics: "get_statistics",
      options: "get_options",
    }),
  },
  watch: {
    /* 'options.currentChart': function() { this.generateStatistics(); }, */
    options: {
      handler: function () {
        this.fetchStatistics();
      },
      deep: true,
    },
    statistics: function () {
      this.generateStatistics();
    },
    tab: function () {
      this.generateStatistics();
    },
  },
  destroyed() {
    this.set_statistics(null);
  },
  methods: {
    ...mapActions("statistics", ["fetch_statistics"]),
    ...mapMutations("statistics", ["set_statistics"]),
    fetchData() {
      this.fetchStatistics();
    },
    async fetchStatistics() {
      this.loading = true;
      let payload = {
        timeFrame: this.options.timeFrame,
        timePointer: this.options.timePointer,
      };
      await this.fetch_statistics(payload);
      this.loading = false;
    },
    createDataset(data, label, color) {
      return {
        label: label,
        data: data,
        backgroundColor: "transparent",
        color: "#000000",
        borderColor: color,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        sum: data.reduce((a, b) => a + b, 0),
      };
    },
    calculateAccumulatedValues() {
      this.accumulated_values.duels = this.statistics.datasets.duels.reduce(
        (a, b) => a + b,
        0
      );
      this.accumulated_values.trainings =
        this.statistics.datasets.trainings.reduce((a, b) => a + b, 0);
    },
    formatLabel(label) {
      if (
        this.statistics.timeFrame === 2 ||
        (this.statistics.timeFrame === 1 && this.statistics.timePointer === 0)
      ) {
        return this.$t("general.monthNumeric." + label);
      }
      return label.slice(5);
    },
    generateStatistics() {
      if (!this.statistics) return false;
      this.calculateAccumulatedValues();
      let labels = this.statistics.labels.map((label) => {
        return this.formatLabel(label);
      });

      this.stats.trainings.labels = labels;
      let datasets_trainings = [];
      datasets_trainings.push(
        this.createDataset(
          this.statistics.datasets.trainings,
          this.$t("views.statistics.trainings"),
          this.$vuetify.theme.themes.light.secondary
        )
      );
      this.stats.trainings.datasets = datasets_trainings;

      this.stats.duels.labels = labels;
      let datasets_duels = [];
      datasets_duels.push(
        this.createDataset(
          this.statistics.datasets.duels,
          this.$t("views.statistics.duels"),
          this.$vuetify.theme.themes.light.primary
        )
      );
      this.stats.duels.datasets = datasets_duels;
    },
  },
};
</script>
