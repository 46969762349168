<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      open-on-hover
      offset-y
      nudge-bottom="12"
      close-delay
      max-width="240"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          depressed
          :color="licences.ghostwriter ? 'primary' : 'grey lighten-2'"
          class="ml-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small class="mr-2" color="#ffd700">
            mdi-circle-multiple
          </v-icon>
          <span>{{
            $t("gw.xCredits", {
              number:
                licences && licences.ghostwriter
                  ? licences.ghostwriter.credits_count.toLocaleString()
                  : "-",
            })
          }}</span>
        </v-btn>
      </template>

      <v-card color="primary" dark max-width="240">
        <v-card-text
          v-if="licences.ghostwriter && !licenceExpired"
          class="white--text"
        >
          <h5>{{ $t("gw.yourBalance") }}</h5>
          <div class="font-weight-bold d-flex align-center">
            <v-icon small class="mr-2" color="#ffd700">
              mdi-circle-multiple
            </v-icon>
            <span>{{
              $t("gw.xCredits", {
                number:
                  licences && licences.ghostwriter
                    ? licences.ghostwriter.credits_count.toLocaleString()
                    : "-",
              })
            }}</span>
            <v-icon
              v-if="
                licences &&
                licences.ghostwriter &&
                licences.ghostwriter.credits_count <= 0
              "
              x-small
              color="white"
              class="ml-2"
              >mdi-alert-circle</v-icon
            >
          </div>
          <div v-if="licences.ghostwriter.licence.can_overload" class="mt-4">
            <h5>{{ $t("gw.licences.credit_pool") }}</h5>
            <div class="font-weight-bold d-flex align-center">
              <v-icon small class="mr-2" color="#ffd700"> mdi-bank </v-icon>
              <span>{{
                $t("gw.xCredits", {
                  number:
                    licences && licences.ghostwriter.licence
                      ? licences.ghostwriter.licence.credits_overload.toLocaleString()
                      : "-",
                })
              }}</span>
              <v-icon
                v-if="
                  licences &&
                  licences.ghostwriter &&
                  licences.ghostwriter.licence.credits_overload <= 0
                "
                x-small
                color="white"
                class="ml-2"
                >mdi-alert-circle</v-icon
              >
            </div>
            <small>{{ $t("gw.licences.credit_pool_usage_hint_2") }}</small>
          </div>
          <v-divider class="my-2" />
          <small>{{
            $t("gw.licenceValidUntil", {
              date: new Date(
                licences.ghostwriter.date_end
              ).toLocaleDateString(),
            })
          }}</small>
        </v-card-text>

        <v-card-text
          v-if="!licences.ghostwriter || licenceExpired"
          class="white--text"
        >
          <h5>{{ $t("gw.missingLicence") }}</h5>
          <p>{{ $t("gw.missingLicence_2") }}</p>
          <p>{{ $t("gw.missingLicence_3") }}</p>
          <v-divider v-if="licences.ghostwriter" class="my-4" />
          <small v-if="licences.ghostwriter">
            {{
              $t("gw.licenceExpiredOn", {
                date: new Date(
                  licences.ghostwriter.date_end
                ).toLocaleDateString(),
              })
            }}
          </small>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GwCreditInfoMenu",
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
  },
};
</script>
