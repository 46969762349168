<template>
  <v-container>
    <v-row>
      <v-col cols="12" :md="showOptions ? 9 : 12">
        <div
          class="d-flex align-center"
          :class="[{ 'justify-center': centered }, { 'justify-end': right }]"
        >
          <v-chip-group v-model="currentChip" show-arrows mandatory>
            <v-chip
              v-for="(item, i) in navItems"
              v-show="
                $route.name === item.link.name ||
                i === 0 ||
                $route.name === navItems[0].link.name ||
                alwaysExpanded
              "
              :key="item.title"
              :disabled="item.disabled || disabled"
              :to="item.link"
              :active-class="color"
              :small="smallChips"
              :large="largeChips"
              pill
              :close="
                $route.name === item.link.name && i !== 0 && !alwaysExpanded
              "
              @click:close="resetNavigation()"
            >
              <v-avatar v-if="item.icon" left>
                <!-- :color="
                  $route.name === item.link.name
                    ? `${color} darken-2`
                    : 'grey lighten-2'
                " -->
                <v-icon
                  :color="
                    $route.name === item.link.name ? 'white' : 'secondary'
                  "
                  >{{ item.icon }}</v-icon
                >
              </v-avatar>
              <span>{{ item.title }}</span>
            </v-chip>
          </v-chip-group>

          <v-chip-group v-if="subNavigation" v-model="currentSubChip" mandatory>
            <v-chip
              v-for="subitem in subNavigation"
              :key="subitem.title"
              small
              active-class="secondary"
              :disabled="subitem.disabled"
              @click="triggerSubnavigationLink(subitem.link)"
              >{{ subitem.title }}</v-chip
            >
          </v-chip-group>
        </div>
      </v-col>
      <v-col v-if="showOptions" cols="12" md="3">
        <slot name="options" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChipNavigation",
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    smallChips: {
      type: Boolean,
      required: false,
      default: false,
    },
    largeChips: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentChip: 0,
      initialized: false,
      display: this.$route.query.d,
      currentSubChip: 0,
    };
  },
  computed: {
    subNavigation() {
      if (!this.initialized) return null;
      var item = this.navItems[this.currentChip];
      if (!item.children) return null;
      return item.children;
    },
  },
  beforeMount() {
    this.setInititalChips();
  },
  methods: {
    resetNavigation() {
      this.$router.push(this.navItems[0].link);
    },
    setInititalChips() {
      var routeNames = this.navItems.map((i) => i.link.name);
      this.currentChip = !routeNames.includes(this.$route.name)
        ? 0
        : routeNames.indexOf(this.$route.name);

      if (this.$route.query.d && !!this.navItems[this.currentChip].children) {
        var children = this.navItems[this.currentChip].children.map(
          (c) => c.link.query.d
        );

        this.currentSubChip = !children.includes(this.display)
          ? 0
          : children.indexOf(this.display);

        //var subRouteNames = this.navItems.map((i) => i.link.name);
      }
      this.initialized = true;
    },
    triggerSubnavigationLink(link) {
      if (link.query.d === this.display) return false;
      this.$router.push(link);
    },
  },
};
</script>

<style></style>
