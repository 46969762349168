<template>
  <BubbleChart :key="updated" :data="chartData" :options="chartOptions" />
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale,
} from "chart.js";
import { Bubble as BubbleChart } from "vue-chartjs";

ChartJS.register(Title, Tooltip, Legend, PointElement, LinearScale);

export default {
  name: "ChartBubble",
  components: { BubbleChart },
  data() {
    return {
      updated: 0,
    };
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              usePointStyle: false,
              align: "end" /* position: 'right', */,
            },
          },
        };
      },
    },
  },
  watch: {
    chartData: {
      handler: function () {
        this.updated++;
      },
      deep: true,
    },
  },
};
</script>
