<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading" class="mt-4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              solo
              label="Suche"
              append-icon="mdi-magnify"
              clearable
            />
          </v-col>
          <v-col cols="12">
            <v-card outlined>
              <v-list class="pb-0">
                <v-list-item v-if="!sources || sources.length === 0"
                  >No sources.</v-list-item
                >
                <v-list-item
                  v-for="source in currentSources"
                  :key="`source-${source.id}`"
                  :style="'border-bottom: 1px solid #ccc'"
                >
                  <v-list-item-icon>
                    <v-icon v-if="!source.job.id">mdi-check</v-icon>
                    <v-progress-circular
                      v-if="!!source.job.id"
                      indeterminate
                      size="20"
                      width="2"
                      color="primary"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ source.path ? source.path : "-" }}
                      <span class="caption"
                        >(id: {{ source.id }})</span
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <span class="caption">mimetype </span>
                      <span class="font-weight-bold">{{
                        source.mimetype
                      }}</span>
                      <!-- <span class="caption ml-2">ai_variant </span>
                    <span class="font-weight-bold">{{
                      source.ai_variant ? source.ai_variant : "-"
                    }}</span> -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      :to="{ name: 'SourceDetail', params: { id: source.id } }"
                    >
                      <v-icon>mdi-arrow-right-thin-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-end">
            <v-btn
              small
              icon
              class="mr-4"
              :disabled="page == 1"
              @click="page--"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div>
              <v-btn
                v-for="i in Math.ceil(sortedSources.length / itemsPerPage)"
                :key="`btn-${i}`"
                icon
                @click="page = i"
                >{{ i }}</v-btn
              >
            </div>
            <v-btn
              small
              icon
              class="ml-4"
              :disabled="page == Math.ceil(sortedSources.length / itemsPerPage)"
              @click="page++"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";

export default {
  name: "SourceOverview",
  components: { ViewLoader },
  data() {
    return {
      loading: true,
      search: "",
      itemsPerPage: 10,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("tmp", { sources: "get_sources" }),
    sortedSources() {
      if (!this.sources) return [];
      var sources = [...this.sources].sort((a, b) => b.id - a.id);
      if (this.search) {
        sources = sources.filter((s) => {
          if (!s.path) return false;
          return s.path.toLowerCase().includes(this.search);
        });
      }
      return sources;
    },
    currentSources() {
      if (!this.sources) return [];
      return this.sortedSources.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },
  },
  destroyed() {
    this.set_sources([]);
  },
  methods: {
    ...mapActions("tmp", ["fetch_sources"]),
    ...mapMutations("tmp", ["set_sources"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_sources();
      this.loading = false;
    },
  },
};
</script>
