<template>
  <section
    :class="mode === 'default' ? 'secondary' : 'info'"
    style="transition: 0.2s"
  >
    <v-container>
      <v-carousel
        v-if="mode === 'default'"
        v-model="tutorial"
        hide-delimiters
        :height="$vuetify.breakpoint.smAndDown ? 'auto' : 280"
      >
        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col
              cols="10"
              md="3"
              offset="1"
              :class="{ 'text-right': $vuetify.breakpoint.xl }"
            >
              <v-avatar size="200" color="grey lighten-3" class="elevation-8">
                <v-img src="@/assets/img/misc/ghostwriter.png" />
              </v-avatar>
            </v-col>
            <v-col
              cols="10"
              md="6"
              offset="1"
              class="grey--text text--lighten-3"
            >
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorial.page1.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorial.page1.p1") }}
              </p>
              <p class="mt-4">
                {{ $t("gw.tutorial.page1.p2") }}
              </p>
              <div
                class="mt-3 mb-2 d-flex"
                :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  color="primary"
                  :block="$vuetify.breakpoint.smAndDown"
                  @click="tutorial++"
                >
                  {{ $t("gw.tutorial.page1.btn1") }}
                </v-btn>
                <GwAddSessionDialog>
                  <template #button="{ openDialog }">
                    <v-btn
                      depressed
                      color="grey lighten-3"
                      :block="$vuetify.breakpoint.smAndDown"
                      :class="[
                        { 'ml-2': $vuetify.breakpoint.mdAndUp },
                        { 'my-4': $vuetify.breakpoint.smAndDown },
                      ]"
                      @click="openDialog()"
                    >
                      {{ $t("gw.tutorial.page1.btn2") }}
                    </v-btn>
                  </template>
                </GwAddSessionDialog>
              </div>
              <div>
                <v-btn text small color="white" @click="disableTutorial()">
                  {{ $t("gw.tutorial.page1.btn3") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial.page2.title") }}
                </h2>
                <p v-html="$t('gw.tutorial.page2.p1')" />
                <p v-html="$t('gw.tutorial.page2.p2')" />
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3"
                :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
              >
                <p class="mt-6" v-html="$t('gw.tutorial.page2.p3')" />
                <p v-html="$t('gw.tutorial.page2.p4')" />
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial.page3.title") }}
                </h2>
                <p v-html="$t('gw.tutorial.page3.p1')" />
                <p v-html="$t('gw.tutorial.page3.p2')" />
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3 text-right d-flex flex-column justify-space-between align-end"
                :class="{
                  'align-center text-center': $vuetify.breakpoint.smAndDown,
                }"
              >
                <div class="mt-6">
                  <p v-html="$t('gw.tutorial.page3.p3')" />
                </div>
                <div>
                  <v-icon color="grey lighten-3"> mdi-alert </v-icon>
                  <p class="caption">
                    <span>{{ $t("gw.tutorial.page3.hint") }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial.page4.title") }}
                </h2>
                <p v-html="$t('gw.tutorial.page4.p1')" />
                <p v-html="$t('gw.tutorial.page4.p2')" />
                <p
                  class="mt-6 font-weight-bold"
                  v-html="$t('gw.tutorial.page4.p3')"
                />
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3 text-center d-flex flex-column align-center justify-center"
              >
                <v-avatar size="120" color="transparent" tile class="mb-4">
                  <v-img src="@/assets/img/misc/ghostwriter_2.png" />
                </v-avatar>
                <GwAddSessionDialog>
                  <template #button="{ openDialog }">
                    <v-btn
                      depressed
                      color="primary"
                      :block="$vuetify.breakpoint.smAndDown"
                      class="my-2"
                      @click="openDialog()"
                    >
                      <!-- :class="[{'ml-2' : $vuetify.breakpoint.smAndUp}, { 'my-4' : $vuetify.breakpoint.smAndDown}]" -->
                      {{ $t("gw.tutorial.page4.btn1") }}
                    </v-btn>
                  </template>
                </GwAddSessionDialog>
                <v-btn text small color="white" @click="disableTutorial()">
                  {{ $t("gw.tutorial.page1.btn3") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>

      <v-carousel
        v-if="mode === 'bulk'"
        v-model="tutorial"
        hide-delimiters
        :height="$vuetify.breakpoint.smAndDown ? 'auto' : 280"
      >
        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col
              cols="10"
              md="3"
              offset="1"
              :class="{ 'text-right': $vuetify.breakpoint.xl }"
            >
              <v-avatar size="200" color="grey lighten-3" class="elevation-8">
                <v-img src="@/assets/img/misc/ghostwriter_2.png" />
              </v-avatar>
            </v-col>
            <v-col
              cols="10"
              md="6"
              offset="1"
              class="grey--text text--lighten-3"
            >
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorial2.page1.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorial2.page1.p1") }}
              </p>
              <p class="mt-4">
                {{ $t("gw.tutorial2.page1.p2") }}
              </p>
              <div
                class="mt-3 mb-2 d-flex"
                :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  color="white"
                  :block="$vuetify.breakpoint.smAndDown"
                  @click="tutorial++"
                >
                  {{ $t("gw.tutorial2.page1.btn1") }}
                </v-btn>
              </div>
              <div>
                <v-btn text small color="white" @click="disableTutorial()">
                  {{ $t("gw.tutorial.page1.btn3") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial.page2.title") }}
                </h2>
                <p>{{ $t("gw.tutorial2.page2.p1") }}</p>
                <p>{{ $t("gw.tutorial2.page2.p2") }}</p>
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3"
                :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
              >
                <p>{{ $t("gw.tutorial2.page2.p3") }}</p>
                <p>{{ $t("gw.tutorial2.page2.p4") }}</p>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial2.page3.title") }}
                </h2>
                <p>{{ $t("gw.tutorial2.page3.p1") }}</p>
                <p>{{ $t("gw.tutorial2.page3.p2") }}</p>
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3 text-right d-flex flex-column justify-space-between align-end"
                :class="{
                  'align-center text-center': $vuetify.breakpoint.smAndDown,
                }"
              >
                <div class="mt-6">
                  <p>{{ $t("gw.tutorial2.page3.p3") }}</p>
                  <p class="caption">{{ $t("gw.tutorial2.page3.p4") }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>

        <v-carousel-item
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <div class="fill-height d-flex align-center">
            <v-row>
              <v-col
                cols="10"
                offset="1"
                md="5"
                class="grey--text text--lighten-3"
              >
                <h2 class="subtitle-2 font-weight-bold pb-1">
                  {{ $t("gw.tutorial.page4.title") }}
                </h2>
                <p>
                  {{ $t("gw.tutorial2.page4.p1") }}
                </p>
                <p
                  class="mt-6 font-weight-bold"
                  v-html="$t('gw.tutorial.page4.p3')"
                />
              </v-col>
              <v-col
                cols="10"
                md="4"
                offset="1"
                class="grey--text text--lighten-3 text-center d-flex flex-column align-center justify-center"
              >
                <v-btn color="white" @click="disableTutorial()">
                  {{ $t("gw.tutorial.page4.btn") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-container>
    <v-divider />
  </section>
</template>

<script>
import GwAddSessionDialog from "@/components/ghostwriter/GwAddSessionDialog";

export default {
  name: "GwTutorial",
  components: {
    GwAddSessionDialog,
  },
  props: {
    mode: {
      type: String, // 'default', 'bulk'
      required: false,
      default: "default",
    },
  },
  data() {
    return {
      tutorial: 0,
    };
  },
  watch: {
    mode: {
      handler: function () {
        this.tutorial = 0;
      },
    },
  },
  methods: {
    disableTutorial() {
      localStorage.setItem("gw_disable_tut", true);
      this.$emit("close");
    },
  },
};
</script>
