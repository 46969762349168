<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.users.plural')"
      icon="mdi-account-multiple"
    />
    <ViewLoader v-if="!users || !structures" />

    <section v-if="users && structures">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="!substructures">
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters">
                <v-row>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.structures"
                      :items="structures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.structures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="substructures" cols="6">
                    <v-autocomplete
                      v-model="filter.substructures"
                      :items="visibleSubstructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.substructures.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.structure.name }} /
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.isactive"
                      :items="options.isactive"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.users.isactive')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-check"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.roles"
                      :items="options.roles"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.users.role')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-account-details"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      class="mt-0 pt-0"
                      prepend-icon="mdi-magnify"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      :label="$t('general.search')"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="usersFiltered"
                :items-per-page="10"
                :page.sync="filter.page"
                :search="filter.search"
                :loading="!users"
              >
                <template #item="user">
                  <tr @click="rowClicked(user.item)">
                    <td>
                      <span>
                        <v-icon v-if="user.item.isactive == 1" color="success"
                          >mdi-check</v-icon
                        >
                        <v-icon v-if="user.item.isactive != 1" color="error"
                          >mdi-close</v-icon
                        >
                      </span>
                    </td>
                    <td>
                      <v-tooltip right color="primary">
                        <template #activator="{ on }">
                          <v-icon
                            small
                            :color="
                              user.item.licence ? 'secondary' : 'grey lighten-2'
                            "
                            v-on="on"
                            >{{
                              user.item.licence ? "mdi-robot" : "mdi-robot-off"
                            }}</v-icon
                          >
                        </template>
                        <div>
                          {{
                            user.item.licence
                              ? `${$t("gw.licences.singular")}: ${
                                  user.item.licence.licence.name
                                }`
                              : $t("gw.licences.no_licence")
                          }}
                        </div>
                      </v-tooltip>
                    </td>
                    <td>
                      <span
                        ><b
                          >{{ user.item.first_name }}
                          {{ user.item.last_name }}</b
                        ></span
                      ><br />
                      <span class="text-caption">{{ user.item.user_name }}</span
                      ><br />
                      <span class="text-caption">{{ user.item.email }}</span>
                    </td>
                    <td>
                      <span>
                        <v-chip x-small>
                          {{ $t("resources.users.roles." + user.item.role_id) }}
                        </v-chip>
                      </span>
                    </td>
                    <td>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-chip small v-bind="attrs" v-on="on">
                            {{ user.item.level_id.toLocaleString() }}
                          </v-chip>
                        </template>
                        <span>
                          {{ user.item.score.toLocaleString() }}
                          {{ $t("resources.users.score") }}
                        </span>
                      </v-tooltip>
                    </td>
                    <td>
                      <div v-if="substructures">
                        <div
                          v-for="(substructure, i) in user.item
                            .substructures_ids"
                          :key="i"
                        >
                          <v-chip
                            v-if="i < 2"
                            x-small
                            class="mx-1 my-1"
                            color="grey lighten-3"
                          >
                            {{ substructureById(substructure).structure.name }}
                            /
                            {{ substructureById(substructure).name }}
                          </v-chip>
                        </div>
                      </div>
                      <v-tooltip
                        v-if="user.item.substructures_ids.length > 2"
                        left
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            x-small
                            class="mx-1 my-1"
                            v-bind="attrs"
                            color="grey lighten-2"
                            v-on="on"
                          >
                            + {{ user.item.substructures_ids.length - 2 }}
                            {{ $t("resources.invitations.moreIndicator") }}
                          </v-chip>
                        </template>
                        <div>
                          <div
                            v-for="(id, i) in user.item.substructures_ids"
                            :key="i"
                          >
                            {{ substructureById(id).structure.name }} /
                            {{ substructureById(id).name }}
                          </div>
                        </div>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.smAndUp && showDownloadButton">
          <v-col class="text-right">
            <ExportDataDialog type="users" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import ExportDataDialog from "@/components/global/ExportDataDialog";

export default {
  name: "UserOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    ExportDataDialog,
  },
  data() {
    return {
      display: {
        filters: true,
      },
      filter: {
        substructures: [],
        isactive: [],
        roles: [],
        search: "",
        page: 1,
        structures: [],
      },
      options: {
        isactive: [
          { id: 0, name: this.$t("resources.users.not_isactive") },
          { id: 1, name: this.$t("resources.users.isactive") },
        ],
        roles: [
          { id: 1, name: this.$t("resources.users.roles.1") },
          { id: 2, name: this.$t("resources.users.roles.2") },
          { id: 3, name: this.$t("resources.users.roles.3") },
          { id: 4, name: this.$t("resources.users.roles.4") },
        ],
      },
      headers: [
        { text: this.$t("resources.users.isactive"), value: "isactive" },
        { text: this.$t("gw.ghostwriter"), value: "licence" },
        {
          text: this.$t("resources.users.singular"),
          value: "email",
        },
        { text: this.$t("resources.users.role"), value: "role_id" },
        { text: this.$t("resources.users.level"), value: "level_id" },
        {
          text: this.$t("resources.structures.plural"),
          value: "substructures_ids",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("users", { users: "get_users" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      let isactive = this.filter.isactive.length;
      let roles = this.filter.roles.length;
      return (
        this.filter.substructures.length +
        this.filter.structures.length +
        search +
        isactive +
        roles
      );
    },
    usersFiltered() {
      if (this.users) {
        let filtered = this.users;
        if (this.filter.isactive.length > 0) {
          filtered = filtered.filter((user) => {
            if (!this.filter.isactive.includes(user.isactive)) {
              return false;
            }
            return true;
          });
        }
        if (this.filter.roles.length > 0) {
          filtered = filtered.filter((user) => {
            if (!this.filter.roles.includes(user.role_id)) {
              return false;
            }
            return true;
          });
        }
        if (this.filter.substructures.length > 0) {
          return filtered.filter((user) => {
            return (
              user.substructures_ids.filter((substructure_id) => {
                return this.filter.substructures.includes(substructure_id);
              }).length > 0
            );
          });
        }
        if (this.filter.structures.length > 0) {
          return filtered.filter((user) => {
            let substructures = [];
            user.substructures_ids.forEach((s) =>
              substructures.push(this.substructureById(s))
            );
            return (
              substructures.filter((s) => {
                if (!s) return null;
                return this.filter.structures.includes(s.structure.id);
              }).length > 0
            );
          });
        }
        return filtered;
      }
      return [];
    },
    visibleSubstructures() {
      if (this.auth) {
        if (["superadmin", "admin"].includes(this.role)) {
          return this.substructures;
        }
        let visibleSubstructures = this.substructures.filter((substructure) => {
          return this.auth.related_substructures.includes(substructure.id);
        });
        return visibleSubstructures;
      }

      return [];
    },
    showDownloadButton() {
      return process.env.VUE_APP_HIDE_CSV_USR_DL !== "true";
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("general.overview"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.getFilter();
    if (["superadmin"].includes(this.role))
      this.options.roles.push({
        id: 5,
        name: this.$t("resources.users.roles.5"),
      });
  },
  destroyed() {
    this.set_users(null);
    this.set_structures(null);
    this.set_substructures(null);
  },
  methods: {
    ...mapActions("users", ["fetch_users", "export_users"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("users", ["set_users"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      await this.fetch_structures();
      await this.fetch_users();
    },
    rowClicked(item) {
      this.$router.push({ name: "UserInfo", params: { id: item.id } });
    },
    substructureById(id) {
      let substr = this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
      return substr
        ? substr
        : {
            id: -1,
            name: "INVALID SUBTRUCTURE",
            structure: { id: -1, name: "ERROR" },
          };
    },
    resetFilter() {
      this.filter = {
        substructures: [],
        isactive: [],
        roles: [],
        search: "",
        page: 1,
        structures: [],
      };
    },
    storeFilter() {
      localStorage.setItem("fil_usr", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_usr")) {
        let filter = JSON.parse(localStorage.getItem("fil_usr"));

        this.filter.substructures = filter.substructures
          ? filter.substructures
          : [];
        this.filter.isactive = filter.isactive ? filter.isactive : [];
        this.filter.roles = filter.roles ? filter.roles : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.page = filter.page ? filter.page : 1;
        this.filter.structures = filter.structures ? filter.structures : [];
      }
    },
  },
};
</script>

<style></style>
