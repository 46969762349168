export class Journeystep {
  id = -1;
  order = -1;
  type = "";
  nugget = null;
  quiz = null;

  /* created_at = '';
    updated_at = ''; */

  constructor(obj) {
    this.id = obj.id;
    this.order = obj.order;
    this.type = obj.type;
    this.nugget = obj.nugget;
    this.quiz = obj.quiz;
    /* this.created_at = new Date(obj.created_at);
      this.updated_at = new Date(obj.updated_at); */
  }
}
