var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('ViewLoader',{attrs:{"height":"200"}}):_vm._e(),(!_vm.loading)?_c('section',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h5',{staticClass:"overline mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.$t("views.statistics.categories_popular_title"))+" ")]),_c('span',{staticClass:"caption grey--text mt-0"},[_vm._v(_vm._s(_vm.$t("views.statistics.categories_popular_descr")))])]),_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"1"}},[_c('StatisticsChartBarHorizontal',{attrs:{"chart-data":_vm.categoriesMostPlayed,"visible-rows":_vm.categoriesMostPlayed.datasets.length > 5
                ? 5
                : _vm.categoriesMostPlayed.datasets.length,"color":"primary"}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h5',{staticClass:"overline mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.$t("views.statistics.categories_difficulty_title"))+" ")]),_c('span',{staticClass:"caption grey--text mt-0"},[_vm._v(_vm._s(_vm.$t("views.statistics.categories_difficulty_descr")))])]),_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"1"}},[_c('StatisticsChartBarHorizontal',{attrs:{"chart-data":_vm.categoriesMostCorrectAnswers,"display-min-max-only":_vm.categoriesMostCorrectAnswers.datasets.length > 4,"colored":""}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('h5',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.$t("general.details"))+" ")]),_c('span',{staticClass:"caption grey--text mt-0"},[_vm._v(_vm._s(_vm.$t("ng.display_by_category")))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8","offset-md":"1"}},[_c('v-autocomplete',{attrs:{"items":_vm.categories,"item-text":"name","clearable":"","solo":"","dense":"","flat":"","background-color":"grey lighten-3","return-object":"","placeholder":_vm.$t('general.search')},model:{value:(_vm.currentCategory),callback:function ($$v) {_vm.currentCategory=$$v},expression:"currentCategory"}})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.currentCategory)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("resources.questions.select_category"))+" ")]):_vm._e(),(_vm.currentCategory)?_c('div',[_c('SectionHeader',{staticClass:"px-0 mb-2",attrs:{"title":_vm.currentCategory.name,"subtitle":_vm.currentCategoryGroupNames},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","color":"primary","x-small":"","to":{
                    name: 'CategoryDetail',
                    params: { id: _vm.currentCategory.id },
                  }}},[_vm._v(" "+_vm._s(_vm.$t("general.details"))+" ")])]},proxy:true}],null,false,3660196561)}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"col":"6","sm":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.stats.questions.count,"text":_vm.$t('resources.questions.plural')}})],1),_c('v-col',{attrs:{"col":"6","sm":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.stats.nuggets.count,"text":_vm.$t('resources.nuggets.plural')}})],1),_c('v-col',{attrs:{"col":"6","sm":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.is_active
                      ? _vm.$t('general.yes')
                      : _vm.$t('general.no'),"text":_vm.$t('resources.categories.isactive')}})],1),_c('v-col',{attrs:{"col":"6","sm":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.is_active_nugget
                      ? _vm.$t('general.yes')
                      : _vm.$t('general.no'),"text":_vm.$t('resources.categories.isactive_nugget')}})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.stats.rounds.played,"text":_vm.$t('resources.categories.roundsPlayed')}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('StatisticsCardSimple',{attrs:{"value":_vm.currentCategory.stats.rounds.perfect,"text":_vm.$t('resources.categories.statistics_list.rounds_perfect')}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('StatisticsCardSimple',{attrs:{"value":`${_vm.currentCategory.stats.questions.percent} %`,"text":_vm.$t('resources.users.statistics_list.correctQuestionsSub')}})],1)],1)],1):_vm._e()])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }