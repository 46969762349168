<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="journeyStats"
      :items-per-page="itemsPerPage"
      :loading="!journeyStats"
    >
      <template #item="journey">
        <!-- journey.item.journey_status -->
        <!-- @click="
            ['superadmin', 'admin'].includes(role)
              ? goToJourney(journey.item.journey_id)
              : false
          " -->
        <tr>
          <td>
            <span class="caption">{{ journey.item.name }}</span>
          </td>
          <td class="caption">
            {{
              $t(`resources.journeys.statuses.${journey.item.journey_status}`)
            }}
          </td>
          <td>
            <v-chip x-small :color="getUserProgressColor(journey.item.status)">
              {{
                journey.item.status
                  ? $t(
                      `resources.journeys.user_statuses.${journey.item.status}`
                    )
                  : $t("resources.journeys.user_statuses.not_started")
              }}
            </v-chip>
          </td>
          <td class="caption">
            {{
              journey.item.status && journey.item.status === "finished"
                ? formatDate(journey.item.updated_at)
                : "-"
            }}
          </td>
          <td class="d-flex align-center justify-end">
            {{ journey.stat }}
            <UserJourneyDialog :user="user" :journey="journey.item">
              <template #action="{ openDialog }">
                <v-btn
                  x-small
                  color="info"
                  :disabled="!journey.item.status"
                  @click="openDialog()"
                  >{{ $t("general.details") }}</v-btn
                >
              </template>
            </UserJourneyDialog>
            <v-btn
              x-small
              color="primary"
              class="ml-2"
              :to="{
                name: 'JourneySummary',
                params: { id: journey.item.journey_id },
              }"
              >{{ $t("resources.journeys.journey.singular") }}</v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserJourneyDialog from "@/components/users/UserJourneyDialog.vue";

export default {
  name: "UserStatisticsJourney",
  components: {
    UserJourneyDialog,
  },
  props: {
    journeyStats: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        {
          text: this.$t("resources.journeys.propNames.name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: `${this.$t("resources.journeys.status")} (${this.$t(
            "resources.journeys.journey.singular"
          )})`,
          value: "journey_status",
          width: "25%",
        },
        {
          text: `${this.$t("resources.journeys.status")} (${this.$t(
            "resources.users.singular"
          )})`,
          value: "status",
          width: "25%",
        },
        {
          text: this.$t("general.finished"),
          value: "user_journey.updated_at",
          width: "25%",
        },
        {
          text: this.$t("ng.further_actions"),
          width: "15%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("users", { user: "get_user" }),
  },
  methods: {
    getUserProgressColor(status) {
      if (!status) return "grey lighten-3";
      if (status === "finished") return "success";
      if (status === "in_progress") return "warning";
      if (status === "failed") return "error";
      return "secondary";
    },
    goToJourney(id) {
      this.$router.push({ name: "JourneySummary", params: { id: id } });
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
  },
};
</script>

<style></style>
