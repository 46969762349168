<template>
  <div>
    <LineChart
      v-if="loaded"
      :key="updated"
      :data="chartData"
      :options="chartOptions"
      :height="height"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

export default {
  name: "ChartLine",
  components: { LineChart },
  data() {
    return {
      updated: 0,
      loaded: true,
    };
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              usePointStyle: false,
              align: "end" /* position: 'right', */,
            },
          },
        };
      },
    },
    height: {
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 400,
    },
  },
  watch: {
    chartData: {
      handler: function () {
        this.loaded = false;
        this.updated++;
        this.loaded = true;
      },
      deep: true,
    },
  },
};
</script>
