export class Categorygroup {
  id = -1;
  name = "";
  description = "";
  categories = [];

  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.description = obj.description;
    this.category_count = obj.categories ? obj.categories.length : 0;
    this.categories = obj.categories ? obj.categories : [];
  }
}
