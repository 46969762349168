<template>
  <div>
    <v-text-field
      :value="value"
      outlined
      dense
      :hide-details="hideDetails"
      readonly
      :label="label"
      background-color="white"
      :hint="hint"
      :persistent-hint="persistentHint"
      @input="updateValue($event)"
    >
      <template #append>
        <div class="d-flex align-center">
          <v-sheet height="16" width="16" rounded outlined :color="value" />
          <v-btn
            icon
            small
            :disabled="disabled"
            color="secondary"
            @click="openDialog()"
          >
            <v-icon>mdi-palette</v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>

    <v-dialog
      v-model="showDialog"
      max-width="300"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text">
          {{ $t("general.select_color") }}
        </v-card-title>
        <v-color-picker
          :value="value"
          width="300"
          mode="rgba"
          class="grey lighten-3"
          @input="updateValue($event)"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn text small @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  props: {
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    hint: {
      type: String,
      required: false,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    updateValue(v) {
      this.$emit("input", v);
    },
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>
