export const set_categories = (state, categories) =>
  (state.categories = categories);
export const set_visible_categories = (state, categories) =>
  (state.visible_categories = categories);
export const set_category = (state, category) => (state.category = category);
export const set_categorygroups = (state, categorygroups) =>
  (state.categorygroups = categorygroups);
export const set_categorygroup = (state, categorygroup) =>
  (state.categorygroup = categorygroup);
export const set_category_translations = (state, translations) =>
  (state.category_translations = translations);
