<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.journeys.title')"
      icon="mdi-map-marker-path"
      ><template #titleButton>
        <GwCreditInfoMenu />
      </template>
      <template #button>
        <AddTextSourceDialog>
          <template #action="{ openDialog }">
            <v-btn
              color="primary"
              fab
              absolute
              right
              bottom
              dark
              small
              @click="openDialog()"
            >
              <v-icon>mdi-text-box</v-icon>
            </v-btn>
          </template>
        </AddTextSourceDialog>
        <AddPdfSourceDialog>
          <template #action="{ openDialog }">
            <v-tooltip left color="primary" attach="#test">
              <template #activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    fab
                    id="test"
                    dark
                    small
                    absolute
                    right
                    bottom
                    style="margin-right: 48px"
                    @click="openDialog()"
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>PDF</span>
            </v-tooltip>
          </template>
        </AddPdfSourceDialog>
        <AddMediaSourceDialog>
          <!-- <template #action="{ openDialog }"> -->
          <template #action>
            <v-btn
              color="red"
              elevation="0"
              fab
              dark
              small
              absolute
              right
              bottom
              style="margin-right: 96px; opacity: 0.25"
            >
              <v-icon>mdi-multimedia</v-icon>
            </v-btn>
          </template>
        </AddMediaSourceDialog>
      </template>
      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <section>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import AddTextSourceDialog from "@/components/tmp/AddTextSourceDialog";
import AddMediaSourceDialog from "@/components/tmp/AddMediaSourceDialog";
import AddPdfSourceDialog from "@/components/tmp/AddPdfSourceDialog";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";

export default {
  name: "JourneyOverview",
  components: {
    ViewHeader,
    ViewNavigation,
    AddTextSourceDialog,
    AddMediaSourceDialog,
    AddPdfSourceDialog,
    GwCreditInfoMenu,
  },
  data() {
    return {
      openSpeedDial: false,
    };
  },
  computed: {
    navItems() {
      let items = [{ title: "Sources", link: { name: "SourceOverview" } }];
      return items;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "tmp",
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
  },
};
</script>
