<template>
  <div>
    <ChipNavigation
      :nav-items="navItems"
      always-expanded
      color="primary"
      :disabled="loading"
    />

    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChipNavigation from "@/components/_layout/ChipNavigation";

export default {
  name: "KpiCategory",
  components: {
    ChipNavigation,
  },
  computed: {
    ...mapGetters("kpi", { loading: "get_kpi_loading" }),
    navItems() {
      var items = [
        {
          title: this.$t("kpi.discovery"),
          link: { name: "KpiCategoryDiscovery" },
          icon: "mdi-progress-check",
        },
        {
          title: this.$t("kpi.competency"),
          link: { name: "KpiCategoryCompetency" },
          icon: "mdi-progress-star",
        },
        {
          title: this.$t("kpi.retention"),
          link: { name: "KpiCategoryRetention" },
          icon: "mdi-progress-star-four-points",
        },
      ];
      return items;
    },
  },
  beforeMount() {
    this.fetchStatistics();
  },
  destroyed() {
    this.set_kpi_categories(null);
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_categories"]),
    ...mapMutations("kpi", ["set_kpi_categories"]),
    async fetchStatistics() {
      await this.fetch_kpi_categories({
        type: "categories",
      });
    },
  },
};
</script>
