export class GwCreditPool {
  constructor(obj) {
    this.id = obj ? obj.id : -1;
    this.credits_count = obj ? obj.credits_count : 500000; // int
    this.credits_keep = obj ? obj.credits_keep : false; // 0 || 1
    this.credits_period = obj ? obj.credits_period : 2; // 1 M 2 Y
    this.date_start = obj ? new Date(obj.date_start) : null;
    this.date_end = obj ? new Date(obj.date_end) : null;
    this.month_duration = obj ? obj.month_duration : 1;
  }
}
