<template>
  <div>
    <v-container :class="onlyTabs ? 'ma-0' : 'mt-2'">
      <v-row v-if="$vuetify.breakpoint.smAndDown || onlyTabs">
        <v-col cols="12" class="text-center pa-0">
          <v-tabs show-arrows hide-slider height="32">
            <v-tab
              v-for="item in navItems"
              :key="item.title"
              :disabled="item.disabled || disabled"
              :to="item.link"
              class="caption"
              active-class="primary white--text"
            >
              <v-badge :value="!!item.alert" color="transparent">
                <template v-slot:badge>
                  <v-avatar
                    size="6"
                    color="info"
                    class="pulse mt-1 elevation-1"
                  />
                </template>
                <div>
                  <span>{{ item.title }}</span>
                  <v-chip
                    v-if="item.beta"
                    x-small
                    label
                    class="ml-2"
                    color="info"
                    >beta</v-chip
                  >
                </div>
              </v-badge>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.mdAndUp && !onlyTabs">
        <v-col v-if="false" cols="12" class="pb-0">
          <SectionHeader
            :title="`${$t('general.navigation')}`"
            title-color="secondary"
          />
        </v-col>
        <v-col cols="12">
          <v-list dense nav class="transparent pt-0">
            <v-list-item
              v-for="item in navItems"
              v-show="!item.navItems"
              :key="item.title"
              :to="item.link"
              color="secondary"
              class="my-0 nav-item"
              active-class="nav-item-active"
              :class="{ 'nav-item-disabled': item.disabled }"
              :disabled="item.disabled || disabled"
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex">
                  <span>{{ item.title }}</span>
                  <v-avatar
                    v-if="item.alert"
                    color="error"
                    size="6"
                    class="ml-1"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ViewNavigation",
  components: {
    SectionHeader,
  },
  props: {
    navItems: {
      type: Array,
      required: true,
    },
    onlyTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style>
.nav-item {
  border-left: 4px solid #617d8b;
  border-radius: 0 !important;
  background: transparent !important;
}
.nav-item-active {
  border-left: 4px solid #00afd7;
  background-color: rgba(0, 0, 0, 0) !important;
}
.nav-item-disabled {
  border-left: 4px solid rgba(0, 0, 0, 0.2) !important;
}
.nav-item-active .v-list-item__content {
  color: #00afd7 !important;
}
.nav-item.theme--light.v-list-item--active:before,
.nav-item.theme--light.v-list-item--active:hover:before,
.nav-item.theme--light.v-list-item:focus:before {
  opacity: 0;
}
</style>
