export const set_role = (state, value) => {
  state.role = value;
};

export const set_auth = (state, value) => {
  state.auth = value;
};

export const set_dashboard_stats = (state, obj) => (state.dashboardStats = obj);
export const set_dashboard_questions = (state, obj) =>
  (state.dashboardQuestions = obj);

export const set_licences = (state, obj) => (state.licences = obj);
export const set_gw_credits = (state, v) =>
  (state.licences.ghostwriter.credits_count = v);

export const set_auth_sources = (state, obj) => (state.auth_sources = obj);
