<template>
  <div>
    <div class="d-flex align-center justify-start">
      <v-avatar :size="large ? 96 : 64" color="white">
        <v-img class="avatar-border" :src="`/cdn/category/${imageInput}`" />
      </v-avatar>
      <div class="ml-1">
        <v-btn
          :small="large"
          :x-small="!large"
          color="primary"
          class="ml-2"
          :disabled="disabled"
          depressed
          @click="showDialog = true"
        >
          {{ $t("ng.change_image") }}
        </v-btn>
      </div>
    </div>

    <v-dialog
      :value="showDialog"
      width="500"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card>
        <v-card-title class="overline pb-0">
          {{ $t("ng.select_image") }}
        </v-card-title>
        <v-card-text class="px-2">
          <v-container>
            <v-row dense>
              <v-col
                v-for="(image, index) in visibleImages"
                :key="index"
                cols="4"
                sm="3"
                class="text-center"
              >
                <v-card
                  :color="
                    selectedImage.id === image.id ? 'primary' : 'grey lighten-3'
                  "
                  elevation="0"
                  @click="selectImage(image)"
                >
                  <v-card-text>
                    <v-avatar size="64" color="white">
                      <v-img :src="`/cdn/category/${image.name}`" />
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small :disabled="loading" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            small
            :disabled="loading"
            color="success"
            @click="changeImage()"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CategoryImageChangerVue",
  props: {
    imageInput: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    categoryId: {
      type: Number,
      required: false, // required if patchImage
    },
    patchImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      imageCount: 120,
      selectedImage: null,
      loading: false,
    };
  },
  computed: {
    visibleImages() {
      let images = [];
      for (let i = 1; i <= this.imageCount; i++) {
        images.push({ id: i, name: `${i}.png` });
      }
      return images;
    },
  },
  beforeMount() {
    if (!this.imageInput) {
      this.selectedImage = this.visibleImages[0];
    } else {
      this.selectedImage = this.visibleImages.filter(
        (image) => image.name === this.imageInput
      )[0];
    }
  },
  methods: {
    ...mapActions("categories", ["patch_category"]),
    closeDialog() {
      this.showDialog = false;
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    async changeImage() {
      if (!this.patchImage) {
        this.$emit("image-changed", this.selectedImage);
        this.closeDialog();
      } else {
        this.loading = true;
        let properties = [
          { name: "image_url", value: this.selectedImage.name },
        ];

        let payload = { properties: properties, language: this.$i18n.locale };

        let res = await this.patch_category({
          id: this.categoryId,
          payload: payload,
        });
        this.loading = false;

        if (!res || !res._status === 200) {
          this.$notify({
            type: "error",
            title: this.$t("general.error"),
            text: this.$t("general.error"),
          });
          return false;
        }
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      }
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss">
.avatar-border {
  border: 1px solid grey !important;
}
</style>
