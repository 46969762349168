<template>
  <div>
    <v-tooltip v-if="disabled" bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn color="grey lighten-3" elevation="0" small disabled>
            <v-avatar
              size="6"
              :color="category.is_active ? 'success' : 'error'"
            />
            <span class="mx-2 black--text">{{
              $t("views.statistics.quiz")
            }}</span>
            <v-avatar
              size="6"
              :color="category.is_active_nugget ? 'success' : 'error'"
            />
            <span class="mx-2 black--text">{{
              $t("resources.nuggets.plural")
            }}</span>
            <v-icon v-if="!loading" small> mdi-dots-vertical </v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ $t("resources.questions.activationHint_unsaved") }}</span>
    </v-tooltip>

    <v-menu v-if="!disabled" offset-x left close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey lighten-3"
          elevation="0"
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            size="6"
            :color="category.is_active ? 'success' : 'error'"
          />
          <span class="mx-2 black--text">{{
            $t("views.statistics.quiz")
          }}</span>
          <v-avatar
            size="6"
            :color="category.is_active_nugget ? 'success' : 'error'"
          />
          <span class="mx-2 black--text">{{
            $t("resources.nuggets.plural")
          }}</span>
          <v-icon v-if="!loading" small> mdi-dots-vertical </v-icon>
          <v-progress-circular
            v-if="loading"
            indeterminate
            :size="12"
            :width="2"
            color="grey"
          />
        </v-btn>
      </template>
      <v-list dense class="py-0">
        <v-subheader class="caption font-weight-bold pl-4 grey lighten-3">
          <span class="grey--text">{{
            $t("resources.journeys.changeStatus")
          }}</span>
        </v-subheader>

        <v-subheader class="pl-4 overline primary--text">
          {{ $t("views.statistics.quiz") }}
        </v-subheader>
        <v-list-item
          v-for="(status, index) in statuses"
          :key="`quiz-${index}`"
          :disabled="
            (category.is_active && status.status) ||
            (!category.is_active && !status.status) ||
            (!category.is_active && !category.can.activate)
          "
          @click="changeStatus('quiz', status.status)"
        >
          <v-list-item-title>{{ status.name }}</v-list-item-title>
        </v-list-item>

        <v-subheader class="pl-4 overline primary--text">
          {{ $t("resources.nuggets.plural") }}
        </v-subheader>
        <v-list-item
          v-for="(status, index) in statuses_nuggets"
          :key="`nuggets-${index}`"
          :disabled="
            (category.is_active_nugget && status.status) ||
            (!category.is_active_nugget && !status.status)
          "
          @click="changeStatus('nugget', status.status)"
        >
          <v-list-item-title>{{ status.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Category } from "@/models/content/categories/Category";

export default {
  name: "CategoryActivator",
  props: {
    category: {
      type: Category,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statuses: [
        { status: true, name: this.$t("resources.questions.isactive") },
        { status: false, name: this.$t("resources.questions.not_isactive") },
      ],
      statuses_nuggets: [
        { status: true, name: this.$t("resources.questions.isactive") },
        { status: false, name: this.$t("resources.questions.not_isactive") },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", { language: "get_selected_language" }),
  },
  methods: {
    ...mapActions("categories", ["patch_category"]),
    async changeStatus(type, status) {
      this.loading = true;

      let properties = [
        {
          name: "is_active",
          value: type === "quiz" ? status : this.category.is_active,
        },
        {
          name: "is_active_nugget",
          value: type === "nugget" ? status : this.category.is_active_nugget,
        },
      ];

      let payload = {
        properties: properties,
        language: this.language,
      };

      let res = await this.patch_category({
        id: this.category.id,
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateSuccess"
          )}`,
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateError"
          )}`,
        });
      }
    },
  },
};
</script>

<style></style>
