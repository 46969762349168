<template>
  <div>
    <v-container class="px-0">
      <slot name="filter" />
      <v-row>
        <v-col cols="12" md="12">
          <ChartCard
            chart-type="line"
            :chart-data="graphData"
            :card-height="'auto'"
            :chart-height="300"
            :chart-options="chartOptions"
            :loading="!graphData"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { COLORS } from "@/plugins/chartcolors.js";
import ChartCard from "@/components/kpi/ChartCard";

export default {
  name: "KpiStructureActivity",
  components: {
    ChartCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: "center" /* position: 'right', */,
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              tooltipFormat: "Y-MM-D",
            },
          },
          y: {
            title: {
              display: true,
              text: this.$t("kpi.measurement"),
              font: {
                family: "Roboto",
                size: 12,
                lineHeight: 1.2,
              },
              padding: { top: 20, bottom: 20 },
            },
          },
        },
        pointStyle: false,
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      timed: "get_kpi_timed_detail",
      resource: "get_kpi_resource_detail",
    }),
    graphData() {
      if (!this.timed) return null;
      var items = { ...this.timed };
      var datasets = [];
      var keys = Object.keys(items.datasets);
      keys.forEach((key, i) => {
        if (["retention", "competency", "completion"].includes(key))
          return false;
        if (this.role !== "superadmin" && key === "overallSpeed") return false;
        let dataset = {
          label:
            key === "currentSpeed" ? this.$t(`kpi.speed`) : "overallspeed tmp",
          data: items.datasets[key],
          fill: false,
          borderColor: COLORS[i],
          tension: 0.1,
        };
        datasets.push(dataset);
      });
      var labels = items.labels;

      return {
        datasets: datasets,
        labels: labels,
      };
    },
  },
};
</script>

<style></style>
