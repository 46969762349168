import api from "@/services/api";

export const init_community = async (
  { commit },
  { lang, domain, config = {} }
) => {
  domain = domain.replace("admin.", "");
  if (domain == "localhost:8080") {
    domain = `${process.env.VUE_APP_INSTANCE_NAME}.p3l.app`;
  }
  domain = domain.replace(/:\d\d\d\d/gm, "");
  try {
    let res = await api.get(
      `/communities/init?language=${lang}`,
      {
        url: domain, // not needed anymore?
      },
      config
    );
    if (res && res._status === 200) {
      commit("set_community", res);
      commit("auth/set_auth_sources", res.auth_sources, { root: true });
      return res;
    } else {
      return res;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetch_community = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get("admin/communities/" + id, config);
    if (res._status === 200) {
      commit("set_community", res.community);
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_community = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      "admin/communities/" + payload.id,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_community", { id: payload.id });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const fetch_community_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/communities/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_community_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/communities/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_community_translations", { payload: { id: payload.id } });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};
