<template>
  <div>
    <section class="mb-8">
      <v-expand-transition hide-on-leave>
        <v-container
          v-if="journey.is_imported && journey.journey_id && !job"
          class="pt-0"
        >
          <v-alert type="info" color="primary">
            <p>
              {{ $t("gw.journey.import.succes_msg") }}
            </p>

            <div class="d-flex justify-end">
              <v-btn
                text
                small
                :to="{
                  name: 'JourneySummary',
                  params: { id: journey.journey_id },
                }"
              >
                <span>{{ $t("gw.journey.to_journey") }}</span>
                <v-icon small class="ml-2">mdi-arrow-right-circle</v-icon>
              </v-btn>
            </div>
          </v-alert>
        </v-container>
      </v-expand-transition>

      <v-expand-transition hide-on-leave>
        <v-container v-if="job" class="pt-0">
          <v-alert type="info">
            <p>{{ $t("gw.journey.import.import_msg") }}</p>
            <p>{{ $t("gw.journey.import.import_msg_2") }}</p>
            <p class="caption mb-0">
              {{
                $te(
                  `gw.journey.import.steps.${jobStepTranslation(job.info.step)}`
                )
                  ? $t(
                      `gw.journey.import.steps.${jobStepTranslation(
                        job.info.step
                      )}`
                    )
                  : "..."
              }}
            </p>
            <v-progress-linear color="white" height="12" indeterminate />
          </v-alert>
        </v-container>
      </v-expand-transition>

      <v-expand-transition hide-on-leave>
        <v-container
          v-if="hasNoOpenContent && !journey.is_imported && !job"
          class="pt-0"
        >
          <v-alert type="success">
            <p>{{ $t("gw.journey.no_open_content") }}</p>
          </v-alert>
        </v-container>
      </v-expand-transition>

      <v-container
        v-if="journey.bundles && journey.bundles.length > 0"
        class="pt-0"
      >
        <v-row>
          <v-col cols="12">
            <v-slide-x-transition hide-on-leave>
              <GwJourneyBundles
                v-if="!source_filter.hide_bundles"
                :bundles="visibleBundles"
              />
            </v-slide-x-transition>
            <v-slide-x-transition hide-on-leave>
              <GwJourneyContentCards
                v-if="source_filter.hide_bundles"
                :bundles="visibleBundles"
              />
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GwJourneyBundles from "@/components/ghostwriter/journeys/GwJourneyBundles";
import GwJourneyContentCards from "@/components/ghostwriter/journeys/GwJourneyContentCards";

export default {
  name: "GwJourneyContent",
  components: {
    GwJourneyBundles,
    GwJourneyContentCards,
  },
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source_filter: "get_source_filter",
      job: "get_job",
    }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    visibleBundles() {
      var bundles = { ...this.journey }.bundles;
      return bundles;
    },
    hasNoOpenContent() {
      var content_count = 0;
      var bundles = { ...this.journey }.bundles;
      bundles.forEach((b) => {
        b.questions.forEach((q) => {
          if (!q.is_accepted && !q.is_deleted) content_count++;
        });
        b.nuggets.forEach((n) => {
          if (!n.is_accepted && !n.is_deleted) content_count++;
        });
      });
      return content_count === 0;
    },
  },
  methods: {
    jobStepTranslation(a) {
      if (!a) return "";
      return a.replace(/ /g, "_");
    },
  },
};
</script>
