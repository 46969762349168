<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.statistics.title')"
      icon="mdi-finance"
    />

    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <ViewNavigation :nav-items="navItems" class="flex-grow-1" />
        </v-col>
        <v-col cols="12" md="9">
          <router-view :key="$route.fullPath" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewNavigation from "@/components/_layout/ViewNavigation";

export default {
  name: "StatisticsView",
  components: {
    ViewHeader,
    ViewNavigation,
  },
  computed: {
    navItems() {
      let items = [
        //{ title: 'Übersicht', link: { name: 'StatisticsOverview' } },
      ];
      if (["superadmin", "admin", "manager"].includes(this.role)) {
        items = items.concat([
          {
            title: this.$t("resources.users.plural"),
            link: { name: "StatisticsUser" },
          },
          {
            title: this.$t("resources.structures.plural"),
            link: { name: "StatisticsStructures" },
          },
          {
            title: this.$t("ng.game_activity"),
            link: { name: "StatisticsQuizzes" },
          },
          { title: this.$t("ng.top_3"), link: { name: "StatisticsTopThree" } },
        ]);
      }
      if (["superadmin", "admin", "manager"].includes(this.role)) {
        items = items.concat([
          {
            title: this.$t("resources.categories.plural"),
            link: { name: "StatisticsCategories" },
          },
        ]);
      }
      return items;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.statistics.title"),
          disabled: true,
        },
      ];
    },
  },
};
</script>
