import i18n from "@/i18n.js";

export class GwQuestion {
  constructor(obj) {
    this.id = obj.id;
    this.part_id = obj.part_id;
    this.question_id = obj.question_id;
    this.type = "question";

    this.content = JSON.parse(JSON.stringify(obj.content));
    if (!this.content.time && this.content.time !== 0) this.content.time = null;
    this.content.category_id =
      obj.content && obj.content.category_id ? obj.content.category_id : null;
    this.content.defaultTime =
      !!this.content.time || this.content.time !== null ? false : true;
    this.content.activate =
      obj.content && obj.content.activate ? obj.content.activate : null;

    this.is_deleted = obj.is_deleted;
    this.is_accepted = obj.is_accepted;
    this.is_imported = this.question_id ? true : false;

    this.status = obj.status ? obj.status : 0;
    // 0 editing
    // 1 ready
    // 2 importing
    // 3 imported
    // 4 import error
    this.part_info = obj.part_info ? obj.part_info : null;

    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);
  }

  get formRules() {
    return {
      body: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        //(v) => !!v && v.length <= 255 || i18n.t('ng.too_many_chars_hint', { count: v.length, max: 255 })
      ],
      answer: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        //(v) => !!v && v.length <= 255 || i18n.t('ng.too_many_chars_hint', { count: v.length, max: 255 })
      ],
    };
  }

  setTime(val) {
    if (val || val === 0) {
      this.content.time = val;
    } else {
      this.content.time = null;
    }
  }
}
