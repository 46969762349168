export const get_kpi_stats = (state) => state.kpi_stats;
export const get_kpi_current = (state) => state.current;
export const get_kpi_current_stats = (state) => state.current_stats;
export const get_kpi_timed = (state) => state.timed;
export const get_kpi_dates = (state) => state.dates;
export const get_kpi_type = (state) => state.type;
export const get_kpi_current_detail = (state) => state.current_detail;
export const get_kpi_current_detail_stats = (state) =>
  state.current_detail_stats;
export const get_kpi_timed_detail = (state) => state.timed_detail;
export const get_kpi_dates_detail = (state) => state.dates_detail;
export const get_kpi_resource_detail = (state) => state.resource_detail;
export const get_kpi_journeys = (state) => state.journeys;
export const get_kpi_journey = (state) => state.journey;
export const get_kpi_categories = (state) => state.categories;
export const get_kpi_users = (state) => state.users;
export const get_kpi_general = (state) => state.general;
export const get_kpi_mappers = (state) => state.mappers;
export const get_kpi_mappers_categories = (state) => state.mappers.categories;
export const get_kpi_mappers_structures = (state) => state.mappers.structures;
export const get_kpi_mappers_substructures = (state) =>
  state.mappers.substructures;
export const get_kpi_mappers_users = (state) => state.mappers.users;
export const get_kpi_loading = (state) => state.loading;
export const get_kpi_version = (state) => state.version;
