var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('SectionHeader',{attrs:{"title":_vm.$t('resources.journeys.sectionHeaders.editStructures.title'),"subtitle":_vm.$t('resources.journeys.sectionHeaders.editStructures.subtitle')}}),(_vm.journey && _vm.substructures)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("resources.journeys.addedSubstructures").slice( 0, _vm.$t("resources.journeys.addedSubstructures").length - 1 ))+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("ng.journey.update_visibility")))]),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('resources.journeys.structure_warning'))}}),_vm._l((_vm.journey.substructures),function(substructure){return _c('v-chip',{key:substructure,staticClass:"mr-2",attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.getSubstructureById(substructure).structure.name)+" / "+_vm._s(_vm.getSubstructureById(substructure).name))]),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","x-small":"","disabled":!['unpublished'].includes(_vm.journey.status) ||
                    !['superadmin', 'admin', 'editor'].includes(_vm.role) ||
                    _vm.loading},on:{"click":function($event){return _vm.removeSubstructure(substructure)}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-close-circle ")])],1)],1)}),(_vm.journey.substructures.length === 0)?_c('div',{staticClass:"error--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("resources.journeys.noStructuresYet"))+" ")]):_vm._e()],2),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.availableSubstructures,"item-text":"name","item-value":"id","clearable":"","multiple":"","outlined":"","dense":"","hide-details":"","placeholder":_vm.$t('ng.journey.add_substructures'),"disabled":['closed', 'expired'].includes(_vm.journey.status) ||
                  !['superadmin', 'admin', 'editor'].includes(_vm.role) ||
                  _vm.loading},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-checkbox',{attrs:{"value":_vm.isSelected(item)}}),_c('div',[_vm._v(" "+_vm._s(_vm.getSubstructureById(item.id).structure.name)+" / "+_vm._s(_vm.getSubstructureById(item.id).name)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":"grey lighten-2"}},[_vm._v(" "+_vm._s(_vm.getSubstructureById(item.id).structure.name)+" / "+_vm._s(_vm.getSubstructureById(item.id).name)+" ")])]}}],null,false,2967410764),model:{value:(_vm.newSubstructures),callback:function ($$v) {_vm.newSubstructures=$$v},expression:"newSubstructures"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"primary","disabled":!_vm.newSubstructures ||
                  _vm.newSubstructures.length === 0 ||
                  ['closed', 'expired'].includes(_vm.journey.status) ||
                  !['superadmin', 'admin', 'editor'].includes(_vm.role)},on:{"click":function($event){return _vm.add()}}},[_vm._v(_vm._s(_vm.$t("general.add")))])],1)],1)],1)],1)],1):_vm._e()],1),(_vm.showWarningDialog)?_c('JourneyDialogStructureWarning',{attrs:{"dialog":_vm.showWarningDialog},on:{"close":_vm.closeWarningDialog}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }