<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.invitations.newInvitation')"
      icon="mdi-account-multiple-plus"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'InvitationOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>

    <section id="mail" class="mt-4">
      <SectionHeader
        :title="$t('resources.invitations.emailTitle')"
        :subtitle="$t('resources.invitations.emailSubtitle')"
      />

      <InvitationMail @change="setMails" />
    </section>

    <section id="settings" class="my-4">
      <SectionHeader
        :title="$t('resources.invitations.settings')"
        :subtitle="$t('resources.invitations.settingsSubtitle')"
      />

      <v-container>
        <v-row v-if="$i18n.availableLocales.length > 1" align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text>
                <span>{{ $t("general.language") }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <LanguageChooserInline
              :hidedetails="true"
              :hide-border="false"
              :initial="$i18n.locale"
              colored
              @change="changeLanguage"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text class="d-flex align-center">
                <span class="mr-2">{{
                  $t("resources.codes.assignable_substructures")
                }}</span>
                <TutorialCarouselDialog :templates="tutorialStructures" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="inputs.assigned_substructure_ids"
              :items="availableAssignableSubstructures"
              item-text="name"
              item-value="id"
              :rules="substructureRules"
              :label="$t('resources.codes.assignable_substructures')"
              multiple
              outlined
              hide-details
              dense
              background-color="white"
              @change="checkSelectableStructures"
            >
              <template #item="{ item }">
                {{ item.structure.name }} / {{ item.name }}
              </template>
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ substructureById(item.id).structure.name }} /
                  {{ item.name }}
                </span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ inputs.assigned_substructure_ids.length - 1 }}
                  {{ $t("resources.invitations.moreIndicator") }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text class="d-flex align-center">
                <span class="mr-2">{{
                  $t("resources.codes.selectable_structures")
                }}</span>
                <TutorialCarouselDialog :templates="tutorialSelectStructures" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-show="
                ['superadmin', 'admin'].includes(role) ||
                pre_select_structures != ''
              "
              v-model="inputs.select_structure_ids"
              :items="availableSelectableStructures"
              item-text="name"
              item-value="id"
              :disabled="
                !['superadmin', 'admin'].includes(role) &&
                pre_select_structures != ''
              "
              :label="$t('resources.codes.selectable_structures')"
              multiple
              :hint="
                pre_select_structures != ''
                  ? $t('resources.invitations.preSelectHint')
                  : ''
              "
              persistent-hint
              outlined
              hide-details
              dense
              background-color="white"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ item.name }}
                </span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ inputs.select_structure_ids.length - 1 }}
                  {{ $t("resources.invitations.moreIndicator") }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text>
                <div class="d-flex align-center">
                  <span class="mr-2">{{ $t("resources.users.role") }}</span>
                  <TutorialCarouselDialog :templates="tutorialUserrole" />
                </div>
                <div v-if="emails && emails.length > 1" class="caption grow-1">
                  {{ $t("resources.invitations.roles_hint") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-if="!loading"
              v-model="inputs.role_id"
              :items="roles"
              item-text="name"
              item-value="id"
              :disabled="emails.length > 1 || role == 'manager'"
              :label="$t('resources.users.role')"
              :rules="roleRules"
              outlined
              hide-details
              dense
              background-color="white"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row v-if="inputs.role_id == 4" align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text class="d-flex align-center">
                <span class="mr-2">{{
                  $t("resources.users.manager_substructures")
                }}</span>
                <TutorialCarouselDialog
                  :templates="tutorialManagerStructures"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="inputs.role_assigned_ids"
              :items="managerPossibleSubstructures"
              item-text="name"
              item-value="id"
              :label="$t('resources.users.manager_substructures')"
              multiple
              hide-details
              outlined
              dense
              background-color="white"
            >
              <template #item="{ item }">
                {{ item.structure.name }} / {{ item.name }}
              </template>
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ item.structure.name }} / {{ item.name }}
                </span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ inputs.role_assigned_ids.length - 1 }}
                  {{ $t("resources.invitations.moreIndicator") }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-if="inputs.role_id == 2" align="center">
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text class="d-flex align-center">
                <span class="mr-2">{{
                  $t("resources.users.related_categories")
                }}</span>
                <TutorialCarouselDialog
                  :templates="tutorialAssignedcategories"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-if="!loading && inputs.role_id == 2"
              v-model="inputs.role_assigned_ids"
              :items="sortedCategories"
              item-text="name"
              item-value="id"
              :label="$t('resources.users.related_categories')"
              multiple
              outlined
              hide-details
              dense
              background-color="white"
            >
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ item.name }}
                </span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ inputs.role_assigned_ids.length - 1 }}
                  {{ $t("resources.invitations.moreIndicator") }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          v-if="
            ['superadmin', 'admin'].includes(role) &&
            showGhostwriter &&
            inputs.role_id !== 3
          "
          align="center"
        >
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text class="d-flex align-center">
                <span class="mr-2">{{ $t("gw.gw_form_label") }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="inputs.gwLicence"
              :items="visibleLicences"
              item-text="name"
              item-value="id"
              clearable
              :label="$t('gw.gw_form_label')"
              outlined
              hide-details
              dense
              background-color="white"
            >
              <template #item="{ item }">
                <span
                  >{{ item.name }}
                  <small v-if="item.is_superadmin">Superadmin</small></span
                >
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row
          v-if="
            ['superadmin'].includes(role) &&
            showGhostwriter &&
            inputs.role_id !== 3
          "
          align="center"
        >
          <v-col cols="12" md="6">
            <v-card flat color="grey lighten-3">
              <v-card-text>
                <div class="d-flex align-center">
                  <span>{{ $t("resources.invitations.licence_period") }}</span>
                </div>
                <div class="caption grow-1">
                  {{ $t("resources.invitations.licence_period_hint") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="inputs.gwMonthDuration"
              :items="monthDurations"
              item-text="label"
              item-value="value"
              clearable
              outlined
              background-color="white"
              hide-details
              dense
              :disabled="!selectedLicenceIsSuperLicence"
              :label="$t('resources.invitations.licence_period')"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="summary">
      <SectionHeader :title="$t('general.confirm_input')" />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card flat color="grey lighten-3">
              <v-card-text>
                <p
                  v-html="
                    $t('resources.invitations.confirm_mail', {
                      count: emails.length,
                    })
                  "
                />
                <v-slide-y-transition>
                  <v-alert
                    v-if="!emails || emails.length === 0"
                    type="error"
                    color="error"
                    dense
                    text
                    >{{ $t("resources.invitations.no_mails") }}</v-alert
                  >
                </v-slide-y-transition>
                <v-slide-y-transition>
                  <v-alert
                    v-if="emails && !onlyValidEmails"
                    type="error"
                    color="error"
                    dense
                    >{{
                      $t("resources.invitations.delete_invalid_mails")
                    }}</v-alert
                  >
                </v-slide-y-transition>
                <v-slide-y-transition>
                  <v-alert
                    v-if="
                      emails &&
                      emails.length > 0 &&
                      onlyValidEmails &&
                      inputs.assigned_substructure_ids.length === 0
                    "
                    type="error"
                    color="error"
                    dense
                    >{{
                      $t("resources.invitations.required_substructure_hint")
                    }}</v-alert
                  >
                </v-slide-y-transition>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  depressed
                  small
                  :disabled="
                    !emails ||
                    emails.length === 0 ||
                    !onlyValidEmails ||
                    inputs.assigned_substructure_ids.length === 0
                  "
                  color="primary"
                  @click="saveForm()"
                  >{{ $t("resources.invitations.confirmation") }}</v-btn
                >
              </v-card-actions>
            </v-card>
            <p></p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader.vue";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import InvitationMail from "@/components/invitations/InvitationMail";

export default {
  name: "InvitationAdd",
  components: {
    ViewHeader,
    LanguageChooserInline,
    TutorialCarouselDialog,
    SectionHeader,
    InvitationMail,
  },
  data() {
    return {
      loading: false,
      valid: true,
      roles: [
        { id: 1, name: this.$t("resources.users.roles.1") },
        { id: 2, name: this.$t("resources.users.roles.2") },
        { id: 3, name: this.$t("resources.users.roles.3") },
        { id: 4, name: this.$t("resources.users.roles.4") },
        //{id: 4, name: this.$t('resources.users.roles.5')},
      ],
      tutorialUserrole: ["userrole"],
      tutorialAssignedcategories: ["editorcategories"],
      tutorialStructures: ["structures"],
      tutorialManagerStructures: ["managersubstructures"],
      tutorialSelectStructures: ["selectstructures"],
      inputs: {
        email: "",
        language: this.$i18n.locale,
        role_id: 3,
        role_assigned_ids: [],
        assigned_substructure_ids: [],
        select_structure_ids: [],
        gwLicence: null,
        gwMonthDuration: null,
      },
      substructureRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.select_an_item"),
      ],
      roleRules: [(v) => !!v || this.$t("general.errors.select_an_item")],
      emails: [],
      monthDurations: [
        {
          value: 1,
          label: `1 ${this.$t("general.single_month")}`,
        },
        {
          value: 2,
          label: `2 ${this.$t("general.months")}`,
        },
        {
          value: 3,
          label: `3 ${this.$t("general.months")}`,
        },
        {
          value: 6,
          label: `6 ${this.$t("general.months")}`,
        },
        {
          value: 12,
          label: `12 ${this.$t("general.months")}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_categories" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("ghostwriter", { licences: "get_licences" }),
    availableAssignableSubstructures() {
      if (this.structures) {
        return this.substructures
          .filter((substructure) => {
            return (
              this.auth.related_substructures.includes(substructure.id) ||
              ["superadmin", "admin"].includes(this.role)
            );
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      } else return [];
    },
    availableSelectableStructures() {
      if (this.structures) {
        return this.structures
          .filter((structure) => {
            if (structure.substructures.length === 0) return true;
            let check = true;
            structure.substructures.forEach((substructure) => {
              if (
                this.inputs.assigned_substructure_ids.includes(substructure.id)
              )
                check = false;
            });
            return check;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
      } else return [];
    },
    managerPossibleSubstructures() {
      if (this.structures) {
        return this.substructures
          .filter(
            (substructure) =>
              !this.pre_select_structures.includes(
                substructure.structure.id.toString()
              )
          )
          .sort((a, b) => a.name.localeCompare(b.name));
      } else return [];
    },
    pre_select_structures() {
      return process.env.VUE_APP_PRE_SELECT_STRUCTURES == ""
        ? []
        : process.env.VUE_APP_PRE_SELECT_STRUCTURES.split(",");
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    selectedLicence() {
      if (!this.inputs.gwLicence) return "-";
      return this.licences.filter((l) => l.id === this.inputs.gwLicence)[0]
        .name;
    },
    showGhostwriter() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.invitations.plural"),
          disabled: false,
          to: { name: "InvitationOverview" },
        },
      ];
    },
    visibleLicences() {
      if (!this.licences) return [];
      var licences = [...this.licences];
      return licences.filter(
        (l) => (this.role === "superadmin" || !l.is_superadmin) && !l.is_expired
      );
    },
    onlyValidEmails() {
      var val = true;
      this.emails.forEach((m) => {
        if (!m.is_valid) val = false;
      });
      return val;
    },
    selectedLicenceIsSuperLicence() {
      var licence = this.visibleLicences.filter(
        (l) => l.id === this.inputs.gwLicence
      )[0];
      return !!licence && licence.is_superadmin;
    },
    selectedRole() {
      return this.inputs.role_id;
    },
  },
  watch: {
    emails() {
      if (this.emails.length > 1) {
        this.inputs.role_id = 3;
      }
    },
    selectedRole() {
      this.inputs.role_assigned_ids = [];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
  },
  mounted() {
    if (this.pre_select_structures) {
      this.pre_select_structures.forEach((structure) =>
        this.inputs.select_structure_ids.push(parseInt(structure))
      );
    }
  },
  destroyed() {
    this.set_categories(null);
    this.set_structures(null);
  },
  methods: {
    ...mapActions("invitations", ["add_invitations"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapActions("ghostwriter", ["fetch_licences"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("structures", ["set_structures"]),
    changeLanguage(val) {
      this.inputs.language = val;
    },
    setMails(mails) {
      this.emails = mails;
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    checkSelectableStructures() {
      this.inputs.assigned_substructure_ids.forEach((substructure_id) => {
        this.inputs.select_structure_ids =
          this.inputs.select_structure_ids.filter((structure_id) => {
            return (
              structure_id !==
              this.substructureById(substructure_id).structure.id
            );
          });
      });
    },
    async saveForm() {
      let emails = [];
      this.emails.forEach((mail) =>
        emails.push({ email: mail.email.trim(), first_name: "", last_name: "" })
      );
      if (this.showGhostwriter) {
        var options = {
          gw_licence_id: this.inputs.gwLicence,
        };
        if (this.selectedLicenceIsSuperLicence)
          options.gw_month_duration = this.inputs.gwMonthDuration;
      }
      let payload = {
        invitations: emails,
        language: this.inputs.language,
        role_id: this.inputs.role_id,
        role_assigned_ids: this.inputs.role_assigned_ids,
        assigned_substructure_ids: this.inputs.assigned_substructure_ids,
        select_structure_ids: this.inputs.select_structure_ids,
        options: this.inputs.gwLicence ? options : null,
      };
      let res = await this.add_invitations({
        payload: payload,
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.invitations.createSuccess"),
        });
        this.$router.push({ name: "InvitationOverview" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.invitations.createError"),
        });
      }
    },
    async fetchData() {
      await this.fetch_structures();
      await this.fetch_categories();
      if (["superadmin", "admin"].includes(this.role))
        await this.fetch_licences();
    },
    getStructureById(id) {
      return this.structures.filter((structure) => {
        return structure.id == id;
      })[0];
    },
    getSubstructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    getStructureBySubstructureId(id) {
      let substructure = this.getSubstructureById(id);
      return substructure.structure;
    },
    showSection(id) {
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
};
</script>

<style>
#mail,
#settings {
  scroll-margin-top: 48px;
}
</style>
