<template>
  <div class="pt-2">
    <section v-if="!!session">
      <SectionHeader
        :title="$t('gw.generated_content')"
        :subtitle="'Listenansicht'"
      >
        <template #action>
          <div class="d-flex align-center">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  @click="showFilter = !showFilter"
                >
                  <v-icon small> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("ng.filter") }}</span>
            </v-tooltip>
          </div>
        </template>
      </SectionHeader>
      <v-container>
        <v-expand-transition>
          <v-alert
            v-if="session.importing_items_count > 0"
            text
            color="info"
            dense
            type="info"
          >
            {{
              $t("gw.bulk.hints.importing_content", {
                count: session.importing_items_count,
              })
            }}
          </v-alert>
        </v-expand-transition>
        <v-list color="transparent">
          <v-list-item
            v-if="!content || content.length === 0"
            class="grey lighten-3"
          >
            <v-list-item-content>
              {{ $t("ng.no_content") }}
            </v-list-item-content>
          </v-list-item>
          <GwBulkContentListItem
            v-for="(item, i) in content"
            :key="i"
            :item="item"
          />
        </v-list>
      </v-container>
    </section>

    <v-bottom-sheet v-model="showFilter" :inset="$vuetify.breakpoint.mdAndUp">
      <v-card color="primary">
        <div class="pa-4 text-center">
          <v-btn text color="white" small @click="showFilter = !showFilter">
            <span>{{ $t("general.close") }}</span>
            <v-icon small class="ml-2">mdi-chevron-up</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-card
                  color="white"
                  flat
                  @click="showQuestions = !showQuestions"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="secondary">mdi-lightbulb-multiple</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="secondary--text">
                      <v-list-item-title>{{
                        $t("ng.show_questions")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("resources.questions.plural")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="showQuestions"
                        dense
                        hide-details
                        readonly
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card color="white" flat @click="showNuggets = !showNuggets">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="secondary">mdi-school</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="secondary--text">
                      <v-list-item-title>{{
                        $t("ng.show_nuggets")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("resources.nuggets.plural")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="showNuggets"
                        dense
                        hide-details
                        readonly
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import GwBulkContentListItem from "@/components/ghostwriter/GwBulkContentListItem";

export default {
  name: "GwSessionBulkContent",
  components: {
    SectionHeader,
    GwBulkContentListItem,
  },
  data() {
    return {
      showNuggets: true,
      showQuestions: true,
      showFilter: false,
      progress: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      session: "get_bulk_session",
      job: "get_job",
    }),
    content() {
      if (!this.session) return [];
      if (this.showQuestions && !this.showNuggets)
        return this.session.questions;
      if (!this.showQuestions && this.showNuggets) return this.session.nuggets;
      if (this.showQuestions && this.showNuggets)
        return this.session.whole_content;
      return [];
    },
  },
  watch: {
    session: {
      handler: function (v) {
        if (v) this.checkStatus();
      },
      deep: true,
    },
    "session.job": {
      handler: function (v) {
        if (v && v.id) this.checkJob(v.id);
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.progress) this.cancelRefresh();
  },
  /* mounted() {
    this.checkStatus();
  }, */
  methods: {
    ...mapActions("auth", ["init_auth"]),
    ...mapActions("ghostwriter", ["fetch_bulk_session", "fetch_job"]),
    ...mapMutations("ghostwriter", ["set_job"]),
    async refreshSession() {
      if (!this.session || !this.session.whole_content) {
        this.cancelJob();
        return false;
      }
      var hasPendingContent = false;
      this.session.whole_content.forEach((item) => {
        if ([1, 2].includes(item.status)) hasPendingContent = true;
      });
      if (!hasPendingContent) return false;
      if (!hasPendingContent) {
        this.cancelRefresh();
        return false;
      }
      await this.fetch_bulk_session({
        id: this.session.id,
      });

      await this.fetch_bulk_session({ id: this.id });
      if (this.session.job && this.session.job.id) {
        this.checkJob(this.session.job.id);
      }
    },
    async checkStatus() {
      if (!this.session) return false;
      var hasPendingContent = false;
      this.session.whole_content.forEach((item) => {
        if ([1, 2].includes(item.status)) hasPendingContent = true;
      });
      if (!hasPendingContent || !!this.progress) return false;
      this.progress = setInterval(
        function () {
          this.refreshSession();
        }.bind(this),
        6000
      );
    },
    cancelRefresh() {
      this.progress = clearInterval(this.progress);
    },
  },
};
</script>

<style></style>
