import api from "@/services/api";
import { Nugget } from "@/models/Nugget";

export const fetch_nuggets = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/nuggets/", config);
    if (res._status === 200) {
      let nuggets = res.nuggets.map((nugget) => {
        return new Nugget(nugget);
      });
      commit("set_nuggets", nuggets);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_nugget = async (
  { commit },
  { id, language, config = {} }
) => {
  try {
    let res = await api.get(`admin/nuggets/${id}?language=${language}`, config);
    if (res._status === 200) {
      commit("set_nugget", new Nugget(res.nugget));
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_nugget = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/nuggets/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return { status: { success: false } };
    }
  } catch (err) {
    console.error(err);
    return { status: { success: false, msg: err } };
  }
};

export const patch_nugget_old = async (
  { dispatch },
  { type, language, payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/nuggets/${payload.id}/${type}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_nugget", { id: payload.id, language: language });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const delete_nugget = async (_, { payload, config = {} }) => {
  try {
    let res = await api.delete(`admin/nuggets/${payload.id}`, config);
    if (res._status === 200) {
      return res;
    }
    return null;
  } catch (err) {
    return false;
  }
};

// gesamtes nugget ändern
export const patch_nugget = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.patch(`admin/nuggets/${payload.id}`, payload, config);
    if (res._status === 200) {
      dispatch("fetch_nugget", { id: payload.id, language: payload.language });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_nugget_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(`admin/nuggets/${payload.id}/translations`, config);
    if (res._status === 200) {
      commit("set_nugget_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/nuggets/${payload.id}/translations`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_nugget_translations", { payload: { id: payload.id } });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};
