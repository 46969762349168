<template>
  <v-card color="grey lighten-3" tile outlined>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="">{{ value }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ title }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div class="d-flex align-center">
          <span class="font-weight-bold" :class="`${deltaColor}--text`">{{
            delta > 0 ? `+${delta}` : delta
          }}</span>
          <v-icon :color="deltaColor">
            {{ deltaIcon }}
          </v-icon>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "KpiStatsSimpleCard",
  props: {
    value: {
      type: Number,
      required: true,
    },
    delta: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    deltaColor() {
      if (this.delta < 0) return "error";
      if (this.delta > 0) return "success";
      return "secondary";
    },
    deltaIcon() {
      if (this.delta < 0) return "mdi-menu-down";
      if (this.delta > 0) return "mdi-menu-up";
      return "mdi-square-small";
    },
  },
};
</script>

<style></style>
