<template>
  <div class="fill-height d-flex align-center justify-center">
    <LanguageChooserInline
      v-if="$i18n.availableLocales.length > 1"
      hide-label
      absolute
      @change="changeLanguage"
    />

    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
          <v-card flat color="grey lighten-3">
            <v-card-text class="py-8">
              <v-row>
                <v-col
                  cols="12"
                  sm="5"
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-img
                    src="/cdn/logo_small.png"
                    max-width="120"
                    width="120"
                    height="120"
                    max-height="120"
                    contain
                  />
                  <div class="title font-weight-light text-center">
                    {{ applicationTitle() }}
                  </div>
                </v-col>
                <v-divider :vertical="$vuetify.breakpoint.smAndUp" />
                <v-col cols="12" sm="7" class="px-8">
                  <v-form v-if="localLogin" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="email"
                          type="email"
                          required
                          outlined
                          background-color="white"
                          dense
                          hide-details
                          :label="$t('general.email')"
                          :rules="[rules.emailInput, rules.notEmpty]"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="password"
                          outlined
                          background-color="white"
                          dense
                          hide-details
                          type="password"
                          :label="$t('general.password')"
                          required
                          :rules="[rules.passwordInput, rules.notEmpty]"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          depressed
                          color="primary"
                          :disabled="!valid || !email || !password || loading"
                          :loading="loading"
                          @click="login"
                        >
                          {{ $t("views.login.login") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row dense class="mt-4">
                    <v-col
                      v-for="(client, i) in authClients"
                      :key="i"
                      cols="12"
                    >
                      <v-btn
                        block
                        small
                        depressed
                        color="secondary"
                        :href="client.url"
                      >
                        {{ $t("sso.login_with", { name: client.name }) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="mt-2">
            <v-col class="text-center">
              <v-btn text small :href="imprintUrl" target="_blank">
                {{ $t("general.imprint") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "LoginView",
  components: {
    LanguageChooserInline,
  },
  data() {
    return {
      valid: false,
      email: null,
      password: null,
      loading: false,
      rules: {
        emailInput: (v) =>
          (!!v && v.match(/\S+@\S+\.\S+/) != null) ||
          this.$t("general.errors.no_valid_email"),
        passwordInput: (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.not_enough_chars"),
        notEmpty: (value) =>
          !!value ||
          this.$t("general.errors.not_enough_chars", this.$i18n.locale),
      },
      code: this.$route.query.code,
      next: this.$route.query.next,
    };
  },
  computed: {
    ...mapGetters("auth", { auth_sources: "get_auth_sources" }),
    imprintUrl: () => process.env.VUE_APP_IMPRINT_URL,
    authClients() {
      if (!this.auth_sources) return [];
      let sources = this.auth_sources.filter((s) => s.name !== "local");
      let clients = [];
      sources.forEach((s) => {
        s.clients.forEach((c) => {
          if (c.is_active) clients.push(c);
        });
      });
      return clients;
    },
    localLogin() {
      if (!this.auth_sources) return true;
      return !!this.auth_sources.filter((s) => s.name === "local")[0];
    },
    redirectLink() {
      if (!this.$route.query || !this.$route.query.next) return null;
      if (this.$route.query.next && Object.keys(this.$route.query).length === 1)
        return this.$route.query.next;
      var host = window.location.host;
      var path = `${this.$route.path}?next=`;
      var fullPath = this.$route.fullPath;
      var result = decodeURIComponent(fullPath.replace(path, ""));
      return `https://${host}${result}`;
    },
  },
  beforeMount() {
    if (this.auth) {
      this.$router.push({ name: "Dashboard" });
    }
    if (this.code) {
      this.checkCode();
    }
  },
  mounted() {
    document.onkeydown = (event) => {
      if (event.key === "Enter" && this.$route.name === "Login") {
        this.login();
      }
    };
  },
  destroyed() {
    document.onkeydown = null;
  },
  methods: {
    ...mapActions("auth", ["login_user", "check_sso_code"]),
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
    },
    async login() {
      this.loading = true;
      let payload = {
        email: this.email,
        password: this.password,
        auth_source: "local",
        language: this.$i18n.locale,
      };
      let res = await this.login_user({ payload: payload });
      this.loading = false;
      if (res._status === 200) {
        this.$router.push(
          this.redirectLink ? this.redirectLink : { name: "Dashboard" }
        );
      } else {
        this.$notify({
          type: "error",
          text: this.$t("errors.LOGINERR"),
        });
      }
    },
    applicationTitle() {
      if (this.$te(`customize.title.${process.env.VUE_APP_INSTANCE_NAME}`)) {
        return (document.title = `${this.$t(
          `customize.title.${process.env.VUE_APP_INSTANCE_NAME}`,
          {
            name: process.env.VUE_APP_CLIENT_NAME,
          }
        )}`);
      }
      return (document.title = `${this.$t("customize.title.default", {
        name: process.env.VUE_APP_CLIENT_NAME,
      })}`);
    },
    async checkCode() {
      let payload = { code: this.code };
      let res = await this.check_sso_code({ payload: payload });
      if (res._status === 200 && res.logged_in) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$notify({
          type: "error",
          text: this.$t("errors.LOGINERR"),
        });
      }
    },
  },
};
</script>
<style lang="css" scoped></style>
