var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"max-width":"640"},on:{"click:outside":function($event){return _vm.closeDialog()}}},[_c('v-card',{attrs:{"loading":_vm.isLoading,"color":"grey lighten-3"}},[_c('v-card-title',{staticClass:"text-overline pb-0"},[_c('span',[_vm._v(_vm._s(_vm.$t("resources.users.role_title")))])]),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isLoading)?_c('v-select',{attrs:{"items":_vm.roles,"item-text":"name","item-value":"id","hint":_vm.$t('resources.users.role_warning'),"persistent-hint":"","messages":!['superadmin', 'admin'].includes(_vm.role)
                ? _vm.$t('general.errors.admin_only')
                : '',"disabled":!['superadmin', 'admin'].includes(_vm.role) ||
              (['superadmin', 'admin'].includes(_vm.role) && _vm.user.id == _vm.auth.id),"label":_vm.$t('resources.users.role')},on:{"change":_vm.changeUserRole},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"append-outer",fn:function(){return [_c('TutorialCarouselDialog',{attrs:{"templates":_vm.tutorialUserrole}})]},proxy:true}],null,false,2384884015),model:{value:(_vm.inputs.role_id),callback:function ($$v) {_vm.$set(_vm.inputs, "role_id", $$v)},expression:"inputs.role_id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isLoading && _vm.inputs.role_id == 2)?_c('v-autocomplete',{attrs:{"items":_vm.sortedCategories,"item-text":"name","item-value":"id","chips":"","small-chips":"","deletable-chips":"","hint":_vm.$t('resources.users.role_warning_short'),"persistent-hint":"","messages":!['superadmin', 'admin'].includes(_vm.role)
                ? _vm.$t('general.errors.admin_only')
                : '',"disabled":!['superadmin', 'admin'].includes(_vm.role),"label":_vm.$t('resources.users.related_categories'),"multiple":""},on:{"change":_vm.changeCategories},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"append-outer",fn:function(){return [_c('TutorialCarouselDialog',{attrs:{"templates":_vm.tutorialAssignedcategories}})]},proxy:true}],null,false,964233692),model:{value:(_vm.editorCategories),callback:function ($$v) {_vm.editorCategories=$$v},expression:"editorCategories"}}):_vm._e(),(!_vm.isLoading && _vm.inputs.role_id == 4)?_c('v-autocomplete',{attrs:{"items":_vm.sortedSubstructures,"item-text":"name","item-value":"id","chips":"","small-chips":"","deletable-chips":"","hint":_vm.$t('resources.users.role_warning_short'),"persistent-hint":"","messages":!['superadmin', 'admin'].includes(_vm.role)
                ? _vm.$t('general.errors.admin_only')
                : '',"disabled":!['superadmin', 'admin'].includes(_vm.role),"label":_vm.$t('resources.users.manager_substructures'),"multiple":""},on:{"change":_vm.changeCategories},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.structure.name)+" / "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.structure.name)+" / "+_vm._s(item.name)+" ")])]}},{key:"append-outer",fn:function(){return [_c('TutorialCarouselDialog',{attrs:{"templates":_vm.tutorialManagerStructures}})]},proxy:true}],null,false,3927647688),model:{value:(_vm.managerSubstructures),callback:function ($$v) {_vm.managerSubstructures=$$v},expression:"managerSubstructures"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }