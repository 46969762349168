<template>
  <v-dialog v-model="loading" persistent width="440">
    <v-card color="white" min-height="200" loading="primary">
      <v-card-text class="text-center d-flex justify-space-between pa-0">
        <div
          class="secondary--text d-flex flex-column align-start justify-center text-left px-4"
        >
          <span class="subtitle-1 font-weight-light pb-2">{{
            $t("gw.loader.title")
          }}</span>
          <span
            class="font-weight-light caption"
            v-html="$t('gw.loader.subtitle')"
          />
        </div>
        <v-avatar size="200" tile>
          <v-img src="@/assets/img/misc/ghostwriter.png" />
        </v-avatar>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoaderPopup",
  computed: {
    ...mapGetters("app", { loading: "get_loading" }),
  },
  // TODO: text & image austauschbar machen?
};
</script>
