<template>
  <div>
    <v-card v-if="imageUrl" flat color="transparent">
      <v-img
        :aspect-ratio="1.9"
        width="100%"
        :height="height"
        :max-height="height"
        :src="preview ? preview : imageUrl"
        cover
        class="d-flex align-end text-center pa-4"
      >
        <v-sheet
          color="primary"
          style="position: absolute; top: 12px; left: 0"
          class="px-1"
        >
          <span class="white--text font-weight-bold overline">{{ label }}</span>
        </v-sheet>
        <v-btn
          fab
          absolute
          bottom
          class="mb-8"
          x-small
          left
          color="primary"
          :disabled="!imageUrl"
          @click="showPreviewDialog = true"
        >
          <v-icon small> mdi-open-in-new </v-icon>
        </v-btn>
        <v-btn
          fab
          absolute
          bottom
          class="mb-8"
          x-small
          right
          color="error"
          :disabled="disabled"
          @click="removeImage"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </v-img>
      <div class="d-flex pt-1">
        <!-- <v-btn
          color="error"
          x-small
          depressed
          :disabled="disabled"
          @click="removeImage"
        >
          <v-icon small>
            mdi-close
          </v-icon>
          <span class="ml-1">{{ $t('resources.nuggets.remove_image') }}</span>
        </v-btn> -->
        <v-spacer />
        <v-btn
          v-if="resetable"
          color="primary"
          x-small
          text
          :disabled="disabled"
          @click="confirmImage"
        >
          <v-icon small> mdi-check </v-icon>
          <span class="ml-1">{{ $t("general.apply") }}</span>
        </v-btn>
      </div>
    </v-card>
    <v-card
      v-if="!imageUrl"
      :color="cardColor"
      :elevation="elevation"
      :height="height"
      :max-height="height"
      class="d-flex align-center pa-0"
    >
      <v-sheet
        color="primary"
        style="position: absolute; top: 12px; left: 0"
        class="px-1"
      >
        <span class="white--text font-weight-bold overline">{{ label }}</span>
      </v-sheet>
      <v-card-text class="d-flex flex-column align-center">
        <v-btn
          fab
          small
          color="primary"
          :disabled="disabled"
          @click="dialog = true"
        >
          <v-icon> mdi-image-plus </v-icon>
        </v-btn>
        <span v-if="false" class="secondary--text my-4"
          >{{ $t("resources.media.no_image_selected") }}.</span
        >
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="showPreviewDialog"
      v-model="showPreviewDialog"
      max-width="80%"
    >
      <v-card>
        <v-img :src="preview ? preview : imageUrl" contain />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      persistent
      no-click-animation
      max-width="480"
    >
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-text class="pt-4">
          <h3 class="overline primary--text text-center">
            {{ $t("resources.media.image_upload") }}
          </h3>
          <v-row>
            <v-col>
              <v-alert dense text border="left" type="info">
                <span
                  class="caption"
                  v-html="$t('resources.media.upload_info.image')"
                />
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form ref="form" :disabled="media_id != false || loading">
                <v-file-input
                  ref="file"
                  v-model="file"
                  tabindex="-1"
                  prepend-icon="mdi-camera"
                  accept="image/png,image/jpeg"
                  outlined
                  :label="$t('resources.media.image_upload')"
                  dense
                  background-color="white"
                  :error-messages="error"
                />
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text small @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            small
            :loading="loading"
            :disabled="!file || loading"
            @click="uploadFile"
          >
            {{ $t("resources.media.upload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ImageUpload",
  props: {
    imageHash: {
      type: String,
      required: false,
    },
    elevation: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    cardColor: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    resetable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 200,
    },
    label: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      file: null,
      media_id: "",
      imageUrl: "",
      error: "",
      hash: "",
      preview: null,
      showPreviewDialog: false,
    };
  },
  watch: {
    dialog: function (value) {
      if (value) this.resetForm();
    },
    imageHash: function (value) {
      if (value) {
        this.preview = null;
        this.imageUrl = `${this.media_server_url}/i/${value}`;
      }
    },
  },
  mounted() {
    if (this.imageHash) {
      this.preview = null;
      this.imageUrl = `${this.media_server_url}/i/${this.imageHash}`;
    }
  },
  methods: {
    ...mapActions("media", ["fetch_media_upload_token", "upload_media"]),
    resetForm() {
      this.file = null;
      this.imageUrl = "";
      this.media_id = "";
      this.error = "";
      this.preview = null;
    },
    async uploadFile() {
      this.error = "";
      if (!["image/png", "image/jpeg"].includes(this.file.type)) {
        this.error = this.$t("general.errors.unaccepted_file_format");
        return;
      }
      this.loading = true;
      let token = await this.fetch_media_upload_token();
      this.hash = token;

      let inputs = new FormData();
      inputs.append("file", this.file);
      inputs.append("type", "image");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res = await this.upload_media({
        payload: inputs,
        token: token,
        config: config,
      });
      this.loading = false;

      // TODO: res.status instead of res?
      if (res) {
        this.media_id = res.uuid;
        this.imageUrl = URL.createObjectURL(this.file);
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.upload_success"),
        });
        this.closeDialog();

        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.preview = reader.result;
        };

        /* this.$nextTick(() => { 
          this.confirmImage();

        }); */
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.upload_error"),
        });
      }
    },
    closeDialog() {
      if (this.media_id && !this.resetable)
        this.$emit("image-changed", {
          hash: this.hash,
          filename: this.media_id,
        });
      this.dialog = false;
    },
    removeImage() {
      this.resetForm();
      this.$emit("image-changed", "");
    },
    confirmImage() {
      this.$emit("image-changed", { hash: this.hash, filename: this.media_id });
      this.resetForm();
    },
  },
};
</script>

<style></style>
