<template>
  <div>
    <div class="d-flex flex-column">
      <v-sheet
        v-for="comment in comments"
        :key="comment.id"
        :color="comment.sender_is_admin ? 'grey lighten-2' : 'primary'"
        :dark="!comment.sender_is_admin"
        class="mb-2 py-2 px-4"
        :class="comment.sender_is_admin ? 'align-self-end' : 'align-self-start'"
        max-width="80%"
        min-width="80px"
        shaped
      >
        {{ comment.message }}
        <v-divider />
        <small
          v-html="
            $t('general.comment_by', {
              username: comment.sender ? comment.sender.user_name : '?',
              date: getLocaleDateString(comment.created_at),
            })
          "
        />
      </v-sheet>
      <p v-if="!comments || comments.length == 0">
        {{ $t("general.no_comments") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentSection",
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
