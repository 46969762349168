<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="800"
      scrollable
      :persistent="loading"
      :disabled="loading"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @click:outside="loading ? null : closeDialog()"
    >
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text">{{
          $t("gw.journey.editbundle.title")
        }}</v-card-title>
        <v-card-subtitle>{{
          $t("gw.journey.editbundle.subtitle")
        }}</v-card-subtitle>

        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" class="pt-4">
                <v-text-field
                  v-model="title"
                  dense
                  outlined
                  background-color="white"
                  :label="$t('resources.nuggets.title')"
                  :rules="rules.title"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn small text :disabled="loading" @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            color="success"
            :loading="loading"
            :disabled="!titleHasChanged || !valid"
            @click="updateBundle()"
            >{{ $t("general.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwJourneyBundleEditDialog",
  props: {
    bundle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      title: "",
      valid: false,
      rules: {
        title: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 255) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
        ],
      },
    };
  },
  computed: {
    titleHasChanged() {
      return this.title !== this.bundle.title;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.title = this.bundle.title;
      } else {
        this.title = "";
      }
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["update_gw_bundle"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async updateBundle() {
      this.loading = true;
      var payload = {
        id: this.bundle.id,
        title: this.title,
        journey_id: this.bundle.journey_id,
      };
      var res = await this.update_gw_bundle({ payload: payload });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
