export class Invitation {
  constructor(obj) {
    this.id = obj.id;
    this.created_at = new Date(obj.created_at);
    this.expired_at = new Date(obj.expired_at);
    this.hash = obj.hash;
    this.hash_token = obj.hash_token;
    this.email = obj.email;
    this.first_name = obj.first_name;
    this.last_name = obj.last_name;
    this.resend_count = obj.resend_count;
    this.language = obj.language;
    this.role_id = obj.role_id;
    this.role_assigned_ids = obj.role_assigned_ids;
    this.assigned_substructure_ids = obj.assigned_substructure_ids;
    this.select_structure_ids = obj.select_structure_ids;
    this.options = obj.options ? obj.options : null;
    this.status = this.fakeStatus(obj.status, this.expired_at);
    this.fakeStatus(obj.status, this.expired_at);
  }

  fakeStatus(status, expires_at) {
    // TODO: remove
    // status should be set by API
    // temporary fix to display expired invitations actually as expired
    if (["accepted", "max_resent"].includes(status)) return status;
    var now = new Date();
    now > expires_at;
    return now > expires_at ? "expired" : status;
  }
}
