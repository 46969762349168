<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="'Administration'"
      icon="mdi-lock"
    >
      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>

      <template #button>
        <AddStructureDialog>
          <template #button="{ openDialog }">
            <v-btn
              v-show="addButtonType === 'structures'"
              fab
              small
              color="success"
              dark
              absolute
              bottom
              right
              @click="openDialog"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </AddStructureDialog>
        <AddSSOClientDialog>
          <template #button="{ openDialog }">
            <v-btn
              v-show="addButtonType === 'sso'"
              fab
              small
              color="success"
              dark
              absolute
              bottom
              right
              @click="openDialog"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </AddSSOClientDialog>
        <AddIDPClientDialog>
          <template #button="{ openDialog }">
            <v-btn
              v-show="addButtonType === 'idp'"
              fab
              small
              color="success"
              dark
              absolute
              bottom
              right
              @click="openDialog"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </AddIDPClientDialog>
        <AddGhostwriterLicenceDialog>
          <template #button="{ openDialog }">
            <v-btn
              v-show="addButtonType === 'gw'"
              fab
              small
              color="success"
              dark
              absolute
              bottom
              right
              @click="openDialog"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </AddGhostwriterLicenceDialog>
      </template>
    </ViewHeader>

    <section>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import AddStructureDialog from "@/components/structures/AddStructureDialog";
import AddSSOClientDialog from "@/components/administration/AddSSOClientDialog";
import AddIDPClientDialog from "@/components/administration/AddIDPClientDialog";
import AddGhostwriterLicenceDialog from "@/components/administration/ghostwriter/AddGhostwriterLicenceDialog";

export default {
  name: "AdministrationOverview",
  components: {
    ViewHeader,
    ViewNavigation,
    AddStructureDialog,
    AddSSOClientDialog,
    AddIDPClientDialog,
    AddGhostwriterLicenceDialog,
  },
  computed: {
    ...mapGetters("auth", { auth_sources: "get_auth_sources" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Administration",
          disabled: true,
        },
      ];
    },
    navItems() {
      return [
        {
          title: this.$t("resources.structures.plural"),
          link: { name: "StructureAdministration" },
        },
        {
          title: "SSO Clients",
          link: { name: "SSOClientAdministration" },
          disabled: !this.auth_sources,
        },
        {
          title: "IDP Clients",
          link: { name: "IDPClientAdministration" },
          disabled: !this.auth_sources,
        },
        {
          title: "Ghostwriter Lizenzen",
          link: { name: "GhostwriterLicenceAdministration" },
          disabled: process.env.VUE_APP_GHOSTWRITER !== "true",
        },
        /* {
          title: "Lizenz neu",
          link: { name: "LicenceAdministration" },
          disabled: true,
        }, */
      ];
    },
    addButtonType() {
      if (this.$route.name === "StructureAdministration") return "structures";
      if (this.auth_sources) {
        if (this.$route.name === "SSOClientAdministration") return "sso";
        if (this.$route.name === "IDPClientAdministration") return "idp";
      }
      if (process.env.VUE_APP_GHOSTWRITER === "true") {
        if (this.$route.name === "GhostwriterLicenceAdministration")
          return "gw";
      }
      return null;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin"]);
  },
};
</script>

<style></style>
