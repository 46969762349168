<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog :value="dialog" width="500" @click:outside="closeDialog()">
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-title class="overline">
          <span>{{ $t("ng.journey.edit_bundle") }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid">
            <small
              v-if="journey.status !== 'unpublished'"
              class="grey--text font-weight-bold"
              >{{ $t("resources.journeys.editWarning") }}</small
            >
            <v-row dense>
              <v-col cols="6">
                <LanguageChooserInline
                  :label="$t('ng.source_language')"
                  background-color="white"
                  colored
                  @change="changeLanguage"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="bundle.level"
                  dense
                  outlined
                  disabled
                  background-color="white"
                  :label="$t('resources.journeys.level')"
                />
              </v-col>
              <v-col cols="12">
                <InputTextfield
                  v-model="input.name"
                  :label="$t('resources.journeys.propNames.name')"
                  :rules="rules.name"
                  disabled
                  counter="64"
                  listen-to-input
                  translatable
                  @is-editing="setEditing"
                  @save="updateName"
                >
                  <template
                    #actions="{
                      editing,
                      startEditing,
                      cancelEditing,
                      saveInput,
                      inputChanged,
                    }"
                  >
                    <div v-if="!editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              :disabled="isEditing || loading"
                              @click="startEditing()"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.edit") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <JourneybundleTranslationDialog
                              prop-name="name"
                              :bundle="bundle"
                              :disabled="!input.name || loading"
                            >
                              <template #button="{ onClick }">
                                <v-btn
                                  icon
                                  :disabled="
                                    !input.name ||
                                    input.name.length === 0 ||
                                    isEditing ||
                                    loading
                                  "
                                  @click="onClick"
                                >
                                  <v-icon small> mdi-translate </v-icon>
                                </v-btn>
                              </template>
                            </JourneybundleTranslationDialog>
                          </div>
                        </template>
                        <span>{{ $t("general.translations") }}</span>
                      </v-tooltip>
                    </div>
                    <div v-if="editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn icon color="error" @click="cancelEditing()">
                              <v-icon small> mdi-close </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.cancel") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              color="success"
                              :disabled="!inputChanged"
                              @click="saveInput()"
                            >
                              <v-icon small> mdi-check </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.save") }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </InputTextfield>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <InputTextarea
                  v-model="input.description"
                  :label="$t('resources.journeys.propNames.description')"
                  :rules="rules.description"
                  disabled
                  counter="200"
                  translatable
                  @is-editing="setEditing"
                  @save="updateDescription"
                >
                  <template
                    #actions="{
                      editing,
                      startEditing,
                      cancelEditing,
                      saveInput,
                      inputChanged,
                    }"
                  >
                    <div v-if="!editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              :disabled="isEditing || loading"
                              @click="startEditing()"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.edit") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <JourneybundleTranslationDialog
                              prop-name="description"
                              :bundle="bundle"
                              :disabled="!input.description || loading"
                            >
                              <template #button="{ onClick }">
                                <v-btn
                                  icon
                                  :disabled="
                                    !input.description ||
                                    input.description.length === 0 ||
                                    isEditing ||
                                    loading
                                  "
                                  @click="onClick"
                                >
                                  <v-icon small> mdi-translate </v-icon>
                                </v-btn>
                              </template>
                            </JourneybundleTranslationDialog>
                          </div>
                        </template>
                        <span>{{ $t("general.translations") }}</span>
                      </v-tooltip>
                    </div>
                    <div v-if="editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn icon color="error" @click="cancelEditing()">
                              <v-icon small> mdi-close </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.cancel") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              color="success"
                              :disabled="!inputChanged"
                              @click="saveInput()"
                            >
                              <v-icon small> mdi-check </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.save") }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </InputTextarea>
              </v-col>
            </v-row>
            <v-divider class="mb-8" />
            <v-row>
              <v-col cols="12">
                <v-slider
                  v-model="input.quiz_criteria"
                  :readonly="!['unpublished'].includes(journey.status)"
                  :label="$t('resources.journeys.quiz_criteria')"
                  thumb-color="primary"
                  thumb-label="always"
                  track-color="secondary"
                  min="0"
                  max="100"
                ></v-slider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h5 class="secondary--text">
                  {{ $t("resources.nuggets.image") }}
                </h5>
                <ImageUpload
                  :image-hash="input.image_token"
                  card-color="grey lighten-2"
                  @image-changed="imageChange"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            color="primary"
            :disabled="!inputChanged || !valid"
            @click="save"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Journeybundle } from "@/models/journeys/Journeybundle.js";
import ImageUpload from "@/components/global/ImageUpload";
import JourneybundleTranslationDialog from "@/components/journeys/dialogs/JourneybundleTranslationDialog";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "EditBundleDialog",
  components: {
    ImageUpload,
    JourneybundleTranslationDialog,
    LanguageChooserInline,
  },
  props: {
    bundle: {
      type: Journeybundle,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      input: null,
      valid: true,
      startAtPicker: false,
      endAtPicker: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
            }),
          (v) =>
            (v && v.length <= 100) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
              max: 100,
            }),
        ],
        description: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
            }),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
              max: 200,
            }),
        ],
      },
      editModes: {
        name: false,
        description: false,
      },
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    inputChanged() {
      let changed = false;
      if (this.input.quiz_criteria !== this.bundle.quiz_criteria)
        changed = true;
      if (this.input.image !== this.bundle.image) changed = true;
      return changed;
    },
  },
  beforeMount() {
    this.input = { ...this.bundle };
  },
  watch: {
    language: {
      handler: function () {
        this.resetForm();
      },
    },
  },
  methods: {
    ...mapActions("journeys", ["patch_bundle", "patch_bundle_translation"]),
    ...mapMutations("journeys", ["set_selected_language"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.input = { ...this.bundle };
    },
    async save() {
      let properties = [
        { name: "name", value: this.input.name },
        { name: "description", value: this.input.description },
        { name: "image", value: this.input.image ? this.input.image : "" },
        {
          name: "quiz_criteria",
          value: this.input.quiz_criteria
            ? Number(this.input.quiz_criteria)
            : 0,
        },
      ];
      let payload = {
        bundle_id: this.bundle.id,
        journey_id: this.journey.id,
        properties: properties,
      };
      let res = await this.patch_bundle({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    imageChange(image) {
      this.input.image_token = image.hash;
      this.input.image = image.filename;
    },
    async updateName(value, translate) {
      let properties = [{ name: "name", value: value }];
      this.loading = true;
      let payload = {
        id: this.bundle.id,
        journey_id: this.journey.id,
        language: this.language,
        override: translate,
        properties: properties,
      };
      let res = await this.patch_bundle_translation({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateDescription(value, translate) {
      let properties = [{ name: "description", value: value }];
      this.loading = true;
      let payload = {
        id: this.bundle.id,
        journey_id: this.journey.id,
        language: this.language,
        override: translate,
        properties: properties,
      };
      let res = await this.patch_bundle_translation({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    setEditing(val) {
      this.isEditing = val;
    },
    changeLanguage(val) {
      this.set_selected_language(val);
    },
  },
};
</script>

<style></style>
