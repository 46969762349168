<template>
  <div>
    <v-card
      :elevation="cardElevation"
      :color="cardColor"
      :dark="cardDark"
      :height="cardHeight"
      :max-height="cardHeight"
      :loading="loading"
      :disabled="loading"
      outlined
      tile
    >
      <template #progress>
        <v-progress-linear height="2" color="primary" indeterminate />
      </template>

      <v-card-title class="secondary--text justify-center" :class="sheetColor">
        <span v-if="!!title">{{ title }}</span>
        <v-spacer />
        <div v-if="bundleCharts" class="d-flex align-center">
          <v-btn
            x-small
            depressed
            :color="unselectedDatasetCount > 0 ? 'warning' : 'primary'"
            class="mx-2"
            @click="showDatasetSheet = !showDatasetSheet"
          >
            <v-icon
              x-small
              class="mr-1"
              :color="unselectedDatasetCount > 0 ? 'error' : 'white'"
              >{{
                unselectedDatasetCount > 0 ? "mdi-alert" : "mdi-filter"
              }}</v-icon
            >
            <span
              >{{ $t("kpi.datasets.plural") }} ({{
                `${loading ? "-" : datasetLength}/${
                  loading ? "-" : datasets.length - unselectedDatasetCount
                }`
              }})</span
            >
          </v-btn>
          <v-btn
            small
            icon
            color="secondary"
            :disabled="loading || datasetIndex === 0"
            @click="datasetIndex--"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="caption">{{
            `${loading ? "-" : datasetIndex + 1} / ${
              loading ? "-" : Math.ceil(datasets.length / datasetLength)
            }`
          }}</span>
          <v-btn
            small
            icon
            color="secondary"
            :disabled="
              loading ||
              datasetIndex === Math.ceil(datasets.length / datasetLength) - 1
            "
            @click="datasetIndex++"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-expand-transition>
        <v-card-text class="fill-height pa-0" v-if="!!currentDatasets">
          <v-sheet class="pa-4" :color="sheetColor">
            <ChartBar
              v-if="chartType === 'bar'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartBubble
              v-if="chartType === 'bubble'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartDoughnut
              v-if="chartType === 'doughnut'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartLine
              v-if="chartType === 'line'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartPie
              v-if="chartType === 'pie'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartPolar
              v-if="chartType === 'polar'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartRadar
              v-if="chartType === 'radar'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
            <ChartScatter
              v-if="chartType === 'scatter'"
              :chart-data="currentDatasets"
              :chart-options="chartOptions"
              :height="chartHeight"
            />
          </v-sheet>
        </v-card-text>
      </v-expand-transition>
      <v-fade-transition leave-absolute>
        <v-sheet
          v-if="!chartData || loading"
          :color="sheetColor"
          class="d-flex align-center justify-center py-8"
        >
          <span v-if="loading">{{ $t("general.data_is_loading") }}</span>
          <span v-if="!chartData">{{ $t("$vuetify.noDataText") }}</span>
        </v-sheet>
      </v-fade-transition>
    </v-card>

    <v-bottom-sheet
      v-if="bundleCharts"
      v-model="showDatasetSheet"
      :inset="$vuetify.breakpoint.smAndUp"
      scrollable
    >
      <v-card color="primary">
        <v-card-title>
          <span class="overline white--text">{{
            $t("kpi.chart_options")
          }}</span>
          <v-spacer />
          <!-- <div class="pa-4 text-center"> -->
          <v-btn text color="white" small @click="showDatasetSheet = false">
            <span>{{ $t("general.close") }}</span>
            <v-icon small class="ml-2">mdi-close-circle</v-icon>
          </v-btn>
          <!-- </div> -->
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="6">
              <h5 class="white--text">{{ $t("kpi.datasets.count") }}</h5>
              <v-select
                v-model="datasetLength"
                :items="[5, 10, 15, 20]"
                dense
                hide-details
                solo
                flat
                background-color="white"
                active-color="primary"
              >
                <template #selection="{ item }">
                  <span class="secondary--text">{{ item }}</span>
                </template>
                <template #append>
                  <v-icon color="secondary">mdi-menu-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center">
                <h5 class="white--text">{{ $t("kpi.datasets.select") }}</h5>
                <v-spacer />
                <v-btn text color="white" x-small @click="deselectDatasets()">{{
                  $t("kpi.filter_deselect_all")
                }}</v-btn>
                <v-btn
                  text
                  color="white"
                  x-small
                  @click="resetSelectedDatasets()"
                  >{{ $t("general.reset") }}</v-btn
                >
              </div>
              <v-card color="white" flat>
                <v-list dense color="transparent">
                  <template v-for="(dataset, i) in datasets">
                    <v-divider v-if="i > 0" :key="i" />
                    <v-list-item :key="dataset.label">
                      <v-list-item-action>
                        <v-checkbox v-model="selectedDatasets[i]" dense />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          dataset.label
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-avatar
                        :color="dataset.backgroundColor"
                        size="16"
                        tile
                      />
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import ChartBar from "@/components/charts/ChartBar.vue";
import ChartBubble from "@/components/charts/ChartBubble.vue";
import ChartDoughnut from "@/components/charts/ChartDoughnut.vue";
import ChartLine from "@/components/charts/ChartLine.vue";
import ChartPie from "@/components/charts/ChartPie.vue";
import ChartPolar from "@/components/charts/ChartPolar.vue";
import ChartRadar from "@/components/charts/ChartRadar.vue";
import ChartScatter from "@/components/charts/ChartScatter.vue";

export default {
  name: "ChartCard",
  components: {
    ChartBar,
    ChartBubble,
    ChartDoughnut,
    ChartLine,
    ChartPie,
    ChartPolar,
    ChartRadar,
    ChartScatter,
  },
  data() {
    return {
      datasetIndex: 0,
      datasetLength: 10,
      datasets: [],
      showDatasetSheet: false,
      selectedDatasets: [],
    };
  },
  props: {
    // card options
    title: {
      type: String,
      required: false,
    },
    cardElevation: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    cardColor: {
      type: String,
      required: false,
      default: "grey lighten-1",
    },
    cardDark: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardHeight: {
      type: [String, Number],
      required: false,
      default: "auto",
    },
    // ChartOptions
    chartData: {
      type: Object,
      required: false,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    chartType: {
      type: String, // bar, bubble, doughnut, line, pie, polar, radar, scatter
      required: false,
      default: "line",
    },
    chartHeight: {
      type: Number,
      required: false,
      default: 200,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    sheetColor: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    bundleCharts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    currentDatasets() {
      if (!this.chartData || (!!this.datasets && !this.datasets.length))
        return null;
      if (!this.bundleCharts) return this.chartData;
      var datasets = [...this.datasets];
      if (this.selectedDatasets.length > 0) {
        datasets = datasets.filter((d, i) => !!d && this.selectedDatasets[i]);
      }
      var start = this.datasetLength * this.datasetIndex;
      var end = this.datasetLength + this.datasetLength * this.datasetIndex;
      return {
        datasets: datasets.slice(start, end),
      };
    },
    unselectedDatasetCount() {
      return this.selectedDatasets.filter((d) => !d).length;
    },
  },
  watch: {
    chartData: {
      handler: function () {
        this.initDatasets();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.initDatasets();
  },
  methods: {
    initDatasets() {
      if (!this.chartData) return false;
      this.datasets = [...this.chartData.datasets];
      this.datasetIndex = 0;
      this.datasetLength = 10;
      this.resetSelectedDatasets();
    },
    resetSelectedDatasets() {
      var datasetValues = [];
      this.datasets.forEach(() => datasetValues.push(true));
      this.selectedDatasets = datasetValues;
    },
    deselectDatasets() {
      this.selectedDatasets = [];
    },
  },
};
</script>

<style></style>
