import api from "@/services/api";

export const fetch_structures = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/structures/", config);
    if (res._status === 200) {
      commit("set_structures", res.structures);
      commit(
        "set_substructures",
        res.structures.flatMap((structure) => {
          return structure.substructures.map((substructure) => {
            substructure.structure = {
              id: structure.id,
              name: structure.name,
            };
            return substructure;
          });
        })
      );
    } else {
      // 500
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_structures_statistics = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/structures/statistics", config);
    if (res.success) {
      commit(
        "set_structure_statistics",
        res.structures.sort((a, b) => b.users_count - a.users_count)
      );
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_structure = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/structures/", payload, config);
    if (res._status === 200) {
      dispatch("fetch_structures");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_structure = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/structures/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_structures");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_structure = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(`admin/structures/${payload.id}`, config);
    if (res._status === 200) {
      dispatch("fetch_structures");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const add_substructure = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/structures/${payload.structure_id}/substructures`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_structures");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_substructure = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/structures/${payload.structure_id}/substructures/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_structures");
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
