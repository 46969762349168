import api from "@/services/api";

export const fetch_users = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/users/", config);
    if (res._status === 200) {
      commit("set_users", res.users);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_user = async ({ commit, dispatch }, { id, config = {} }) => {
  try {
    let res = await api.get("admin/users/" + id, config);
    if (res._status === 200) {
      commit("set_user", res.user);
      await dispatch("fetch_user_stats", {
        payload: {
          userId: id,
          timeFrame: 2,
          timePointer: 0,
        },
      });
    } else {
      // 404 wrong id / not existing user / manager fetch user from other substructures
      // 403 permission denied
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_user_stats = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    //let res = await api.get(`admin/statistics/?user_id=${payload.userId}&time_frame=${payload.timeFrame}&time_pointer=${payload.timePointer}`, payload, config);
    let res = await api.get(
      `admin/users/${payload.userId}/statistics?time_frame=${payload.timeFrame}&time_pointer=${payload.timePointer}`,
      payload,
      config
    );
    if (res._status === 200) {
      commit("set_user_stats", {
        categories: res.categories,
        journeys: res.journeys,
        nuggets: res.nuggets,
        overallStats: res.overallStats,
        stats: res.stats,
      });
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_user = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.put("admin/users/" + payload.id, payload, config);
    if (res._status === 200) {
      dispatch("fetch_user", payload);
      return true;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const update_role = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.put(`admin/users/${payload.id}/roles`, payload, config);
    if (res._status === 200) {
      dispatch("fetch_user", payload);
      return true;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_role = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.delete(`admin/users/${payload.id}/roles`, config);
    if (res._status === 200) {
      dispatch("fetch_user", payload);
      return true;
    } else {
      return false;
      // 403
    }
  } catch (err) {
    return false;
  }
};

export const delete_user = async (_, { payload, config = {} }) => {
  try {
    let res = await api.delete(`admin/users/${payload.id}`, config);
    if (res._status === 200) {
      //commit('set_delete_user', payload);
      return true;
    }
    // errors: 403
    return false;
  } catch (err) {
    return false;
  }
};

export const export_users = async (_, { payload, config = {} }) => {
  try {
    let res = await api.get(
      `admin/users/export?format=${payload.format}`,
      config
    );
    return res;
  } catch (err) {
    return null;
  }
};
