<template>
  <div>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.content.bundles
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.journeys.bundle.plural")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.content.bundles }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.journeys.bundle.plural") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.content.nuggets
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.nuggets.plural")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.content.nuggets }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.nuggets.plural") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.content.quizzes
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.journeys.stepTypes.quiz.plural")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.content.quizzes }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.journeys.stepTypes.quiz.plural") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.content.questions
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.questions.plural")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.content.questions }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.questions.plural") }}</span
            > -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JourneyStatsContent",
  computed: {
    ...mapGetters("journeys", { journey: "get_journey" }),
  },
};
</script>

<style></style>
