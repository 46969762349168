<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">
          {{ $t("ng.user_journey") }}
        </v-card-title>
        <v-card-text v-if="!user_journey" class="px-2">
          <span class="ml-4">{{ $t("general.data_is_loading") }}</span>
        </v-card-text>
        <v-expand-transition>
          <v-card-text v-if="user_journey">
            <v-row align="center" dense>
              <v-col cols="8">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        `${user.first_name} ${user.last_name}`
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        user.user_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  outlined
                  :color="
                    user_journey.status === 'finished'
                      ? 'success'
                      : user_journey.status === 'failed'
                      ? 'error'
                      : ''
                  "
                  :dark="['finished', 'failed'].includes(user_journey.status)"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        user_journey.status
                          ? $t(
                              `resources.journeys.user_statuses.${user_journey.status}`
                            )
                          : $t("resources.journeys.user_statuses.not_started")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("resources.journeys.status")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ journey.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("resources.journeys.journey.singular")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="journey.journey_id">
                      <v-btn
                        fab
                        x-small
                        color="secondary"
                        :to="{
                          name: 'JourneySummary',
                          params: { id: journey.journey_id },
                        }"
                        target="_blank"
                      >
                        <v-icon>mdi-arrow-top-right-thin</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{
                          user_journey.quiz_results === "-"
                            ? "-"
                            : `${user_journey.quiz_results.toFixed(2)}%`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("ng.current_quiz_score")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        user_journey.attempts_left
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("ng.remaining_attempts") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        formatDate(user_journey.created_at)
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("resources.support.props.created_at") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        formatDate(user_journey.updated_at)
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("general.updated_at") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h5 class="text-h6 font-weight-light">
                  {{ $t("resources.journeys.bundle.plural") }}
                </h5>
                <p
                  v-if="
                    !user_journey.bundles || user_journey.bundles.length === 0
                  "
                >
                  {{ $t("general.no_content") }}
                </p>
                <v-expansion-panels
                  flat
                  outlined
                  multiple
                  style="border: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  <v-expansion-panel
                    v-for="(bundle, i) in sortedBundles"
                    :key="i"
                  >
                    <v-expansion-panel-header class="py-0">
                      <v-list-item dense>
                        <v-list-item-icon>
                          <v-avatar size="24" color="grey lighten-3 mt-2">
                            {{ bundle.level }}
                          </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            bundle.name
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-avatar>
                          <v-icon :color="bundle.status_color" class="mr-2">
                            {{ bundle.status_icon }}
                          </v-icon>
                        </v-list-item-avatar>
                      </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="px-0">
                      <v-list dense>
                        <v-list-item
                          v-for="(step, n) in sortedSteps(bundle.steps)"
                          :key="`step-${n}`"
                          :style="
                            n > 0 ? 'border-top: 1px solid rgba(0,0,0,.12)' : ''
                          "
                        >
                          <v-list-item-icon class="pt-2">
                            <v-tooltip right>
                              <template #activator="{ on, attrs }">
                                <v-avatar size="24" v-bind="attrs" v-on="on">
                                  <v-icon small color="secondary">{{
                                    step.type === "nugget"
                                      ? "mdi-school"
                                      : "mdi-lightbulb"
                                  }}</v-icon>
                                </v-avatar>
                              </template>
                              <span>{{
                                step.type === "nugget"
                                  ? $t("resources.nuggets.singular")
                                  : $t(
                                      "resources.journeys.stepTypes.quiz.singular"
                                    )
                              }}</span>
                            </v-tooltip>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              step.type === "nugget"
                                ? step.nugget.title
                                : `${step.question_count} ${$t(
                                    "resources.questions.plural"
                                  )}`
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              step.status === "locked"
                                ? "-"
                                : step.status === "unlocked"
                                ? $t(
                                    "resources.journeys.user_statuses.in_progress"
                                  )
                                : $t(
                                    `resources.journeys.user_statuses.${step.status}`
                                  )
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                    <v-divider />
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expand-transition>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            color="secondary"
            :loading="!user_journey"
            @click="closeDialog()"
          >
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserJourneyDialog",
  props: {
    user: {
      type: Object,
      required: true,
    },
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters("journeys", { user_journey: "get_user_journey" }),
    sortedBundles() {
      if (!this.user_journey || this.user_journey.bundles.length === 0)
        return null;
      var bundles = this.user_journey.bundles;
      return bundles.sort((a, b) => a.level - b.level);
    },
  },
  methods: {
    ...mapActions("journeys", ["fetch_user_journey"]),
    ...mapMutations("journeys", ["set_user_journey"]),
    async fetchUserJourney() {
      this.loading = true;
      await this.fetch_user_journey({
        journey_id: this.journey.journey_id
          ? this.journey.journey_id
          : this.journey.id,
        user_id: this.user.id,
      });
      this.loading = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.set_user_journey(null);
    },
    openDialog() {
      this.showDialog = true;
      this.fetchUserJourney();
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
    sortedSteps(steps) {
      var sorted = [...steps].filter(
        (s) => (!!s.origin.question_id && !!s.question_count) || !!s.nugget
      );
      return sorted;
    },
  },
};
</script>
