<template>
  <div>
    <section v-if="question">
      <SectionHeader
        :title="$t('ng.question_image_title')"
        :subtitle="$t('ng.question_image_subtitle')"
      />
      <v-container>
        <v-alert
          v-if="question.is_active"
          type="info"
          text
          dense
          colored-border
          border="left"
        >
          <div class="caption">
            <span>{{ $t("ng.question_active_hint") }}</span>
          </div>
        </v-alert>
        <v-row>
          <v-col cols="12" sm="6">
            <ImageUpload
              :disabled="question.is_active || !question.can_edit"
              :image-hash="question.image_hash"
              @image-changed="imageChange"
            />
            <div class="text-right mt-2">
              <v-btn
                :disabled="!imageChanged"
                small
                depressed
                :loading="loading"
                color="primary"
                @click="changeImage()"
              >
                {{ $t("general.save") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageUpload from "@/components/global/ImageUpload";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "QuestionMedia",
  components: {
    ImageUpload,
    SectionHeader,
  },
  data() {
    return {
      imageChanged: false,
      newImage: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      language: "get_selected_language",
    }),
  },
  methods: {
    ...mapActions("questions", ["patch_question"]),
    async changeImage() {
      this.loading = true;
      let properties = [];

      properties.push({
        name: "image",
        value: this.newImage ? this.newImage : "",
      });

      let payload = {
        id: this.question.id,
        properties: properties,
        language: this.language,
      };

      let res = await this.patch_question({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.imageChanged = false;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.upload_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.upload_error"),
        });
      }
    },
    imageChange(imageObj) {
      this.newImage = imageObj.filename;
      this.imageChanged = true;
    },
  },
};
</script>

<style></style>
