export const set_statistics = (state, value) => {
  state.statistics = value;
};
export const set_statistics_details = (state, value) => {
  state.statistics_details = value;
};

export const set_options = (state, value) => {
  state.options.timeFrame = value.timeFrame;
  state.options.timePointer = value.timePointer;
};
