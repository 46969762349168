<template>
  <section>
    <v-list color="transparent">
      <v-list-item
        v-for="(item, index) in filteredData.datasets"
        :key="index"
        class="pl-0"
      >
        <v-list-item-content
          v-if="
            !displayMinMaxOnly ||
            (displayMinMaxOnly && index != 2) ||
            listToggled
          "
          class="py-0"
        >
          <span class="caption">{{ filteredData.labels[index] }}</span>
          <div class="d-flex align-center">
            <span class="mr-2 caption grey--text"
              >{{ filteredData.datasets[index] }}{{ unit }}</span
            >
            <v-progress-linear
              :value="filteredData.datasets[index]"
              height="8"
              :color="getBarColor(filteredData.datasets[index])"
              background-color="rgba(0,0,0,.1)"
            />
          </div>
        </v-list-item-content>

        <v-list-item-content
          v-if="displayMinMaxOnly && index == 2 && !listToggled"
          class="py-0"
        >
          <v-btn text small @click="toggleListLength">
            <v-icon small> mdi-menu-right </v-icon>
            <span>{{
              listToggled
                ? $t("general.display_less")
                : $t("general.display_all")
            }}</span>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-btn v-if="showToggleButton" text small @click="toggleListLength">
      <v-icon small>
        {{ listToggled ? "mdi-menu-up" : "mdi-menu-down" }}
      </v-icon>
      <span>{{
        listToggled ? $t("general.display_less") : $t("general.display_all")
      }}</span>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: "StatisticsChartBarHorizontal",
  components: {},
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    visibleRows: {
      type: Number,
      required: false,
    },
    colored: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    displayMinMaxOnly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    unit: {
      type: String,
      required: false,
      default: "%",
    },
  },
  data() {
    return {
      listToggled: false,
      filteredData: { ...this.chartData },
    };
  },
  computed: {
    showToggleButton() {
      return (
        (this.visibleRows &&
          this.visibleRows < this.chartData.datasets.length) ||
        (this.displayMinMaxOnly && this.listToggled)
      );
    },
  },
  beforeMount() {
    if (this.visibleRows > this.chartData.datasets.length) {
      console.error("Mehr visible rows als datasets");
    }
    this.filterData();
  },
  methods: {
    filterData() {
      let arr_length = this.visibleRows
        ? this.visibleRows
        : this.chartData.datasets.length;
      if (!this.displayMinMaxOnly) {
        this.filteredData.datasets = this.chartData.datasets.slice(
          0,
          arr_length
        );
        this.filteredData.labels = this.chartData.labels.slice(0, arr_length);
      }

      if (this.displayMinMaxOnly) {
        let maxData = this.chartData.datasets.slice(0, 2);
        let minData = this.chartData.datasets.slice(arr_length - 2);
        let maxLabels = this.chartData.labels.slice(0, 2);
        let minLabels = this.chartData.labels.slice(arr_length - 2);

        // empty array in between to be able to iterate over v-list-item
        this.filteredData.datasets = maxData.concat([0]).concat(minData);
        this.filteredData.labels = maxLabels.concat([""]).concat(minLabels);
      }
    },
    toggleListLength() {
      if (!this.listToggled) {
        this.filteredData = { ...this.chartData };
      }
      if (this.listToggled) {
        this.filterData();
      }

      this.listToggled = !this.listToggled;
    },
    getBarColor(value) {
      if (!this.colored) {
        return this.color;
      }

      if (value >= 60) {
        return "success";
      }
      if (value < 20) {
        return "error";
      }
      return "warning";
    },
  },
};
</script>
