<template>
  <div>
    <v-card color="transparent" flat>
      <div class="d-flex" :class="{ 'flex-column': vertical }">
        <v-html-field
          v-if="html"
          v-model="input"
          @input="handleInput"
          :height="height"
          :disabled="disabled"
        />
        <v-textarea
          v-if="!html"
          v-model="input"
          :text="type === 'text'"
          :filled="type === 'filled'"
          :outlined="type === 'outlined'"
          :solo="type === 'solo'"
          :color="color"
          :disabled="disabled && !editable"
          :readonly="readonly"
          :min-height="height"
          :hide-details="hideDetails"
          :dense="dense"
          :rows="rows"
          :rules="rules"
          :hint="bodyFormat ? `Format: ${input_format}` : hint ? hint : ''"
          persistent-hint
          :background-color="disabled && !editable ? '' : backgroundColor"
          :type="number ? 'number' : 'text'"
          :counter="!disabled && !!counter ? counter : false"
          :auto-grow="autoGrow"
          @input="handleInput"
        >
          <!-- validate-on-blur -->
          <template v-if="label" #label>
            <v-icon v-if="labelIcon" class="mx-2" :color="labelIconColor">
              {{ labelIcon }}
            </v-icon>
            <span class="label">{{ label }}</span>
          </template>
        </v-textarea>

        <div
          class="d-flex flex-row align-center ml-2"
          :class="{ 'flex-column align-start': !vertical }"
        >
          <slot
            name="actions"
            :editing="editable"
            :start-editing="startEditing"
            :cancel-editing="cancelEditing"
            :save-input="saveInput"
            :input-changed="inputHasChanged"
            :change-format="changeFormat"
            :toggle-preview="togglePreview"
            :add-gap="addGap"
            :add-image-placeholder="addImagePlaceholder"
          />
        </div>
      </div>
      <v-expand-transition>
        <div v-if="translatable && editable" class="px-4 pb-1">
          <div class="d-flex" v-if="input_format !== 'math'">
            <v-switch
              v-model="translate"
              dense
              :disabled="input_format === 'math'"
              class="mt-0"
              hide-details
            >
              <template #label>
                <span class="caption">{{ $t("ng.update_all_languages") }}</span>
              </template>
            </v-switch>
          </div>
        </div>
      </v-expand-transition>

      <v-expand-transition v-if="preview">
        <div v-show="showPreview">
          <v-card outlined class="mt-4">
            <v-card-title class="caption">{{
              $t("resources.invitations.preview")
            }}</v-card-title>
            <v-card-text>
              <div>
                <div v-if="['html'].includes(bodyFormat)" v-html="value" />
                <div v-if="['text'].includes(bodyFormat)" v-text="value" />
                <MathJaxRenderer
                  v-if="['math'].includes(bodyFormat)"
                  :formula="value"
                  :safe="false"
                />
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import MathJaxRenderer from "@/components/global/MathJaxRenderer";

export default {
  name: "InputTextarea",
  components: {
    MathJaxRenderer,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String, // text, outlined, filled, solo,
      required: false,
      default: "outlined",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: [String, Number],
      required: false,
    },
    labelIcon: {
      type: String,
      required: false,
    },
    labelIconColor: {
      type: String,
      required: false,
      default: "text",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    number: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white",
    },
    hint: {
      type: String,
      required: false,
    },
    counter: {
      type: [String, Number, Boolean],
      required: false,
      default: false,
    },
    translatable: {
      type: Boolean,
      required: false,
      default: false,
    },
    bodyFormat: {
      type: String,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    gaps: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 180,
    },
    customBodyFormats: {
      type: Array,
      required: false,
    },
    html: {
      type: Boolean,
      required: false,
      default: false,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoGrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    rows: {
      type: [String, Number],
      required: false,
      default: 5,
    },
  },
  data() {
    return {
      input: null,
      input_format: null,
      editable: false,
      translate: false,
      body_formats: ["text", "html", "math"],
      showPreview: false,
    };
  },
  computed: {
    inputHasChanged() {
      return this.input !== this.value || this.input_format !== this.bodyFormat;
    },
  },
  watch: {
    value() {
      if (!this.listenToInput) {
        this.setValue();
      }
    },
    bodyFormat(val) {
      this.input_format = val;
    },
    input_format(val) {
      if (val === "math") this.translate = false;
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.translate = false;
      this.input = this.value;
      if (this.bodyFormat) {
        this.input_format = this.bodyFormat;
      }
    },
    handleInput(e) {
      let input = this.number ? parseFloat(e) : e;
      this.$emit("input", input);
    },
    startEditing() {
      this.editable = true;
      this.$emit("is-editing", this.editable);
    },
    cancelEditing() {
      this.setValue();
      this.editable = false;
      this.$emit("cancel");
      this.$emit("is-editing", false);
    },
    saveInput() {
      this.editable = false;
      this.$emit("is-editing", false);
      this.$emit("save", this.input, this.translate, this.input_format);
    },
    changeFormat() {
      let formats = this.customBodyFormats
        ? this.customBodyFormats
        : this.body_formats;
      let idx = formats.indexOf(this.input_format);
      idx === -1 || idx === formats.length - 1 ? (idx = 0) : idx++;
      this.input_format = formats[idx];
      this.$emit("change-format", this.input_format);
    },
    togglePreview() {
      this.showPreview = !this.showPreview;
    },
    addGap() {
      this.$emit("add-gap");
    },
    addImagePlaceholder() {
      this.$emit("add-image-placeholder");
    },
  },
};
</script>

<style lang="scss">
.v-textarea.v-input--is-disabled,
.label {
  color: #303030;
}
.v-textarea textarea[disabled="disabled"] {
  color: #303030;
}
</style>
