<template>
  <div>
    <v-container class="pa-0">
      <v-row>
        <v-col v-for="(c, i) in visibleContent" :key="`content-${i}`" cols="12">
          <GwJourneyNuggetCard
            v-if="c.type === 'nugget'"
            :nugget="c"
            :journey-id="journey.id"
            :disabled="
              journey.is_imported || journey.is_deleted || journey.is_archived
            "
          />
          <GwJourneyQuestionCard
            v-if="c.type === 'question'"
            :question="c"
            :journey-id="journey.id"
            :disabled="
              journey.is_imported || journey.is_deleted || journey.is_archived
            "
          />
        </v-col>
        <v-col v-if="!visibleContent || visibleContent.length === 0">
          {{ $t("gw.journey.content_checked") }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GwJourneyNuggetCard from "@/components/ghostwriter/journeys/GwJourneyNuggetCard";
import GwJourneyQuestionCard from "@/components/ghostwriter/journeys/GwJourneyQuestionCard";

export default {
  name: "GwJourneyContentCards",
  components: {
    GwJourneyNuggetCard,
    GwJourneyQuestionCard,
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source_filter: "get_source_filter",
    }),
    visibleContent() {
      var bundles = { ...this.journey }.bundles;
      var content = [];
      bundles.forEach((b) => {
        if (this.source_filter.show_nuggets) {
          b.nuggets.forEach((n) => {
            n.type = "nugget";
            n.bundle = b.title;
            if (
              !(this.source_filter.hide_accepted && n.is_accepted) &&
              !(this.source_filter.hide_deleted && n.is_deleted)
            ) {
              content.push(n);
            }
          });
        }
        if (this.source_filter.show_questions) {
          b.questions.forEach((q) => {
            q.type = "question";
            q.bundle = b.title;
            if (
              !(this.source_filter.hide_accepted && q.is_accepted) &&
              !(this.source_filter.hide_deleted && q.is_deleted)
            ) {
              content.push(q);
            }
          });
        }
      });
      return content;
    },
  },
};
</script>
