/**
 * @typedef Statistics
 * @property {number} statistics.timeFrame
 * @property {string} statistics.timePointer
 * @property {Array} statistics.labels
 * @property {Object} statistics.datasets
 * @property {Array} statistics.datasets.duels
 * @property {Array} statistics.datasets.logins
 * @property {Array} statistics.datasets.registers
 * @property {Array} statistics.datasets.trainings
 */

/**
 * @type {Statistics}
 */

export class Statistics {
  timeFrame = 0;
  timePointer = 0;
  labels = [];
  datasets = {
    duels: [],
    logins: [],
    registers: [],
    trainings: [],
  };

  constructor(obj) {
    this.timeFrame = obj.timeFrame;
    this.timePointer = obj.timePointer;
    this.labels = obj.labels;
    this.datasets = obj.datasets;
  }
}
