<template>
  <div>
    <v-container class="px-0">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" offset-md="4">
          <KpiFilterMenu>
            <template #filterlist>
              <v-subheader
                class="caption pl-4 font-weight-bold secondary--text"
                >{{ $t("resources.journeys.status") }}</v-subheader
              >
              <v-list-item>
                <v-select
                  :items="options.status"
                  v-model="filter.status"
                  item-text="name"
                  item-value="type"
                  background-color="grey lighten-3"
                  solo
                  flat
                  dense
                  clearable
                  :disabled="!journey"
                />
              </v-list-item>
            </template>
          </KpiFilterMenu>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="search"
            :label="$t('general.search')"
            flat
            solo
            background-color="grey lighten-2"
            hide-details
            dense
            :disabled="!journey"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-1" outlined tile>
            <v-data-table
              :items="filteredUsers"
              :headers="tableHeaders"
              class="grey lighten-3"
              :items-per-page="5"
              :search="search"
            >
              <template #item="{ item }">
                <tr>
                  <td colspan="3">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${item.user.first_name} ${item.user.last_name}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.user.user_name
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                  <td>
                    <v-chip small :color="getUserProgressColor(item.status)">
                      {{
                        item.status !== "not_started"
                          ? $t(
                              `resources.journeys.user_statuses.${item.status}`
                            )
                          : $t("resources.journeys.user_statuses.not_started")
                      }}
                    </v-chip>
                  </td>
                  <td>
                    <v-progress-linear
                      :color="
                        getProgress(item)[1] > 0 ? 'success' : 'secondary'
                      "
                      :value="
                        getProgress(item)[1] > 0
                          ? (getProgress(item)[0] / getProgress(item)[1]) * 100
                          : 0
                      "
                      height="8"
                    />
                  </td>
                  <td class="caption">
                    {{
                      item &&
                      ["finished", "failed", "in_progress"].includes(
                        item.status
                      )
                        ? formatDate(item.updated_at)
                        : "-"
                    }}
                  </td>
                  <td>
                    <v-chip small color="grey lighten-3">
                      {{
                        item.criteria
                          ? `${(item.criteria * 100).toFixed(1)}%`
                          : "-"
                      }}
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KpiFilterMenu from "@/components/kpi/KpiFilterMenu";

export default {
  name: "KpiStructureMain",
  components: {
    KpiFilterMenu,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [
        { text: this.$t("resources.users.user_name"), value: "user.user_name" },
        {
          value: "user.first_name",
          text: "",
          sortable: false,
          width: "0%",
        },
        {
          value: "user.last_name",
          text: "",
          sortable: false,
          width: "0%",
        },
        { text: this.$t("resources.journeys.status"), value: "status" },
        {
          text: this.$t("resources.journeys.sectionHeaders.statsUser.subtitle"),
          sortable: false,
        },
        { text: this.$t("ng.last_interaction"), value: "updated_at" },
        { text: this.$t("resources.journeys.quiz_results"), value: "criteria" },
      ],

      filter: {
        status: "",
      },
      search: "",
      options: {
        status: [
          {
            type: "not_started",
            name: this.$t("resources.journeys.user_statuses.not_started"),
          },
          {
            type: "in_progress",
            name: this.$t("resources.journeys.user_statuses.in_progress"),
          },
          {
            type: "finished",
            name: this.$t("resources.journeys.user_statuses.finished"),
          },
          {
            type: "failed",
            name: this.$t("resources.journeys.user_statuses.failed"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      journey: "get_kpi_journey",
    }),
    filteredUsers() {
      if (!this.journey) return [];
      var users = { ...this.journey }.users;
      if (this.filter.status) {
        users = users.filter((u) => this.filter.status.includes(u.status));
      }
      return users;
    },
  },
  methods: {
    getUserProgressColor(status) {
      if (!status) return "grey lighten-3";
      if (status === "finished") return "success";
      if (status === "in_progress") return "warning";
      if (status === "failed") return "error";
      return "secondary";
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      return `${date}`;
    },
    getProgress(item) {
      if (!item.bundles) return [0, 0];
      var progress = [0, item.bundles.length];
      item.bundles.forEach((b) => {
        if (b.status === "finished") progress[0] += 1;
      });
      return progress;
    },
  },
};
</script>

<style></style>
