<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        block
        color="grey lighten-2"
        v-on="on"
        v-bind="attrs"
        :disabled="loading || disabled"
      >
        <v-icon small class="mr-2" color="secondary">mdi-filter</v-icon>
        <span class="secondary--text">{{
          customText ? customText : $t("kpi.filter")
        }}</span>
      </v-btn>
    </template>

    <v-card>
      <v-list dense>
        <slot name="filterlist" />
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KpiFilterMenu",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters("kpi", { loading: "get_kpi_loading" }),
  },
};
</script>

<style></style>
