import { render, staticRenderFns } from "./ReportCard.vue?vue&type=template&id=236cf400&scoped=true"
import script from "./ReportCard.vue?vue&type=script&lang=js"
export * from "./ReportCard.vue?vue&type=script&lang=js"
import style0 from "./ReportCard.vue?vue&type=style&index=0&id=236cf400&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236cf400",
  null
  
)

export default component.exports