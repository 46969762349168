import { Question } from "@/models/content/questions/Question";
import i18n from "@/i18n.js";

export class QuestionGap extends Question {
  constructor(obj) {
    super(obj);
    this.question_type = "gap_text";
    this.type_image = "/img/question_type_4.png";

    if (!obj) {
      this.body = "[_] ";
      this.answers = [
        {
          body: "",
          body_format: "text",
          id: this.getRandomId(),
          is_correct: true,
          options: null,
          order: 0,
        },
      ];
    }
    this.options = {
      show_chars: obj && obj.options ? obj.options.show_chars : false,
    };
  }

  get formRules() {
    return {
      body: [
        this.body.length > 0 || i18n.t("ng.add_chars_hint"),
        this.body.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.body.length,
            max: 255,
          }),
      ],
      hint: [
        this.hint.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.hint.length,
            max: 255,
          }),
      ],
      gap: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          v.length <= 20 ||
          i18n.t("ng.too_many_chars_hint", { count: v.length, max: 20 }),
      ],
      categories: [
        this.category_ids.length > 0 ||
          i18n.t("resources.questions.select_category"),
      ],
      explanation: [
        (v) => {
          if (v)
            return (
              v.length <= 512 ||
              i18n.t("ng.too_many_chars_hint", { count: v.length, max: 512 })
            );
          else return true;
        },
      ],
    };
  }

  addAnswer() {
    this.answers.push({
      body: "",
      body_format: "text",
      id: this.getRandomId(),
      is_correct: true,
      options: null,
      order: 0,
    });
  }

  add() {
    super.add();
  }

  gapCount() {
    let count = this.body.split("[_]").length - 1;
    return count;
  }

  canBeActivated() {
    //if(!this.category_ids || this.category_ids.length === 0) return false;
    if (this.status !== 1) return false;
    return this.gapCount() === this.answers.length && this.can.activate;
  }
}
