<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="show_dialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card v-if="show_dialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text pb-0">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div v-html="text" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "KpiInfoDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show_dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.show_dialog = true;
    },
    closeDialog() {
      this.show_dialog = false;
    },
  },
};
</script>
