<template>
  <div>
    <ChipNavigation :nav-items="navItems" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import ChipNavigation from "@/components/_layout/ChipNavigation";

export default {
  name: "KpiContent",
  components: {
    ChipNavigation,
  },
  computed: {
    navItems() {
      return [
        {
          title: "Übersicht",
          link: { name: "KpiContentOverview" },
        },
        {
          title: "Kategorien",
          disabled: true,
          //link: { name: "KpiActivityQuiz" },
        },
        {
          title: "Fragen",
          disabled: true,
          //link: { name: "KpiActivityQuestions" },
        },
        {
          title: "Nuggets",
          disabled: true,
          //link: { name: "KpiActivityQuestions" },
        },
      ];
    },
  },
};
</script>
