<template>
  <div>
    <!-- :class="[`${color} elevation-${elevation}`]" -->
    <v-list-item
      dense
      three-line
      class="mb-2 white"
      style="border: 1px solid rgba(0, 0, 0, 0.12) !important"
      @click="
        item.type === 'nugget'
          ? (showNuggetDialog = true)
          : (showQuestionDialog = true)
      "
    >
      <v-list-item-avatar tile size="24">
        <v-icon color="secondary">
          {{ item.type === "nugget" ? "mdi-school" : "mdi-comment-question" }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="overline">
          {{
            item.type === "nugget"
              ? item.content.title
              : $t("resources.questions.types.6")
          }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ item.content.body }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-tooltip left color="secondars">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-show="![1, 2].includes(item.status)"
              small
              v-bind="attrs"
              v-on="on"
              :color="getStatusColor"
              >{{ getStatusIcon }}</v-icon
            >

            <v-progress-circular
              v-show="[1, 2].includes(item.status)"
              indeterminate
              color="primary"
              size="16"
              width="2"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ getStatusText }}</span>
        </v-tooltip>
      </v-list-item-action>
      <v-overlay
        v-if="item.status === 3"
        absolute
        opacity=".1"
        class="justify-start align-start"
      >
        <v-sheet
          class="px-2 py-0 mt-1"
          :color="
            item.status === 3 && (!!item.question_id || !!item.nugget_id)
              ? 'success'
              : item.status === 3 && !item.question_id && !item.nugget_id
              ? 'error'
              : 'grey lighten-3'
          "
        >
          <span class="caption text-uppercase font-weight-bold">{{
            !!item.question_id || !!item.nugget_id
              ? $t("gw.imported")
              : $t("general.error")
          }}</span>
        </v-sheet>
      </v-overlay>
      <v-overlay
        v-if="item.is_deleted"
        absolute
        opacity="0"
        class="justify-start align-start"
      >
        <v-sheet class="px-2 py-0" color="error">
          <span class="caption text-uppercase font-weight-bold">{{
            $t("general.rejected")
          }}</span>
        </v-sheet>
      </v-overlay>
    </v-list-item>
    <GwBulkQuestionDialog
      v-if="item.type === 'question' && session"
      :question="item"
      :visible="showQuestionDialog"
      :session-id="session.id"
      :disabled="item.status !== 0"
      :default-category="
        session.defaults && session.defaults.category_id
          ? session.defaults.category_id
          : null
      "
      @close="showQuestionDialog = false"
    />
    <GwBulkNuggetDialog
      v-if="item.type === 'nugget' && session"
      :nugget="item"
      :visible="showNuggetDialog"
      :session-id="session.id"
      :disabled="item.status !== 0"
      :default-category="
        session.defaults && session.defaults.category_id
          ? session.defaults.category_id
          : null
      "
      @close="showNuggetDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GwBulkNuggetDialog from "@/components/ghostwriter/GwBulkNuggetDialog";
import GwBulkQuestionDialog from "@/components/ghostwriter/GwBulkQuestionDialog";
import { GwNugget } from "@/models/ghostwriter/content/GwNugget.js";
import { GwQuestion } from "@/models/ghostwriter/content/GwQuestion.js";

export default {
  name: "GwBulkContentListItem",
  components: {
    GwBulkNuggetDialog,
    GwBulkQuestionDialog,
  },
  props: {
    item: {
      type: Object,
      required: [GwNugget, GwQuestion],
    },
    color: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
    elevation: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showQuestionDialog: false,
      showNuggetDialog: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_bulk_session" }),
    getStatusIcon() {
      if (!this.item.status && this.item.status !== 0) return "mdi-minus";
      if (this.item.status === 0) return "mdi-pencil-circle";
      if (this.item.status === 2 || this.item.status === 1)
        return "mdi-circle-slice-7";
      if (
        this.item.status === 3 &&
        (!!this.item.question_id || !!this.item.nugget_id)
      )
        return "mdi-check-circle";
      if (
        this.item.status === 4 ||
        (this.item.status === 3 &&
          !this.item.question_id &&
          !this.item.nugget_id)
      )
        return "mdi-alert-circle";

      return "mdi-minus";
    },
    getStatusText() {
      if (!this.item.status && this.item.status !== 0)
        return this.$t("gw.bulk.status.unknown");
      if (this.item.status === 0) return this.$t("gw.bulk.status_content.0");
      if (this.item.status === 1) return this.$t("gw.bulk.status_content.1");
      if (this.item.status === 2) return this.$t("gw.bulk.status_content.2");
      if (this.item.status === 3) return this.$t("gw.bulk.status_content.3");
      if (this.item.status === 4) return this.$t("gw.bulk.status_content.4");
      return this.$t("gw.bulk.status.unknown");
    },
    getStatusColor() {
      if (!this.item.status && this.item.status !== 0) return "secondary";
      if ([1, 4].includes(this.item.status)) return "error";
      if ([3].includes(this.item.status)) return "white";
      return "secondary";
    },
  },
};
</script>

<style lang="scss">
.success-border {
  border: 1px solid red !important;
}
</style>
