<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="nuggetStats"
      :items-per-page="itemsPerPage"
      :loading="!nuggetStats"
    >
      <template #item="nugget">
        <tr
          @click="
            ['superadmin', 'admin'].includes(role)
              ? goToNugget(nugget.item.nugget_id)
              : false
          "
        >
          <td>
            <span class="caption">{{
              nugget.item.title.length > 30
                ? nugget.item.title.slice(0, 30) + "..."
                : nugget.item.title
            }}</span>
          </td>
          <td>
            <v-icon small>
              {{ nugget.item.is_liked ? "mdi-check" : "mdi-minus" }}
            </v-icon>
          </td>
          <td>
            <v-icon small>
              {{ nugget.item.is_favorite ? "mdi-check" : "mdi-minus" }}
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "UserStatisticsNugget",
  props: {
    nuggetStats: {
      type: Array,
      required: true,
      default: () => [0],
    },
  },
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        {
          text: this.$t("resources.nuggets.title"),
          align: "start",
          value: "title",
          width: "60%",
        },
        {
          text: this.$t("resources.nuggets.is_liked"),
          value: "is_liked",
          width: "15%",
        },
        {
          text: this.$t("resources.nuggets.is_favorite"),
          value: "is_favorite",
          width: "15%",
        },
      ],
    };
  },
  /* computed: {
    pageCount() {
      return (this.nuggetStats.length / 5) <= 1 ? 1 : Math.ceil(this.nuggetStats.length / 5);
    }
  }, */
  methods: {
    goToNugget(id) {
      this.$router.push({ name: "NuggetDetail", params: { id: id } });
    },
  },
};
</script>

<style>
.v-pagination li:nth-child(2) {
  display: none !important;
}
</style>
