<template>
  <div>
    <section v-if="question">
      <SectionHeader
        :title="$t('resources.questions.explanation')"
        :subtitle="$t('ng.explanation_subtitle')"
      >
        <template #action>
          <LanguageChooserInline
            hidedetails
            hide-border
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>
      <v-container class="mt-6">
        <v-form ref="explanation" v-model="valid">
          <v-row>
            <v-col cols="12">
              <InputTextarea
                v-model="input.body"
                :label="$t('resources.questions.explanation')"
                counter="255"
                :body-format="input.body_format"
                disabled
                :hint="'Test'"
                :translatable="input.id !== -1"
                :rules="question.formRules.explanation"
                preview
                listen-to-input
                @save="updateExplanation"
                @cancel="setExplanation"
              >
                <template
                  #actions="{
                    editing,
                    startEditing,
                    cancelEditing,
                    saveInput,
                    changeFormat,
                    togglePreview,
                  }"
                >
                  <div v-if="!editing" class="d-flex flex-column ml-2">
                    <v-btn
                      icon
                      :disabled="loading || !question.can_edit"
                      @click="startEditing()"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      :disabled="
                        input.id === -1 || loading || !question.can_edit
                      "
                      color="error"
                      @click="deleteExplanation()"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                    <ExplanationTranslationDialog
                      prop-name="body"
                      :disabled="!question.can_edit"
                      :explanation="question.explanation"
                    >
                      <template #button="{ onClick }">
                        <v-btn
                          icon
                          :disabled="
                            question.explanation.body.length === 0 ||
                            ['math'].includes(
                              question.explanation.body_format
                            ) ||
                            loading
                          "
                          @click="onClick"
                        >
                          <v-icon small> mdi-translate </v-icon>
                        </v-btn>
                      </template>
                    </ExplanationTranslationDialog>
                  </div>
                  <div v-if="editing" class="d-flex flex-column ml-2">
                    <v-btn icon color="error" @click="cancelEditing()">
                      <v-icon small> mdi-close </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="success"
                      :disabled="!input.body || input.body.length < 1"
                      @click="saveInput()"
                    >
                      <v-icon small> mdi-check </v-icon>
                    </v-btn>
                    <v-tooltip
                      class="align-self-end"
                      left
                      dense
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-btn
                            icon
                            :disabled="!editing"
                            @click="changeFormat()"
                          >
                            <v-icon small>
                              mdi-format-letter-case-upper
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t("ng.change_format") }}</span>
                    </v-tooltip>
                    <v-btn icon @click="togglePreview()">
                      <v-icon small> mdi-file-find </v-icon>
                    </v-btn>
                  </div>
                </template>
              </InputTextarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </section>

    <section v-if="question" class="mt-8">
      <SectionHeader
        :title="$t('resources.questions.explanation_image')"
        :subtitle="$t('ng.explanation_image_subtitle')"
      />
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <ImageUpload
              :image-hash="question.explanation.image_hash"
              :disabled="
                loading || question.explanation.id === -1 || !question.can_edit
              "
              @image-changed="imageChange"
            />
            <div class="text-right mt-2">
              <v-btn
                :disabled="!imageChanged || loading"
                small
                :loading="loading"
                color="primary"
                depressed
                @click="changeImage()"
              >
                {{ $t("general.save") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import ExplanationTranslationDialog from "@/components/questions/ExplanationTranslationDialog";
import ImageUpload from "@/components/global/ImageUpload";

export default {
  name: "QuestionExplanation",
  components: {
    SectionHeader,
    LanguageChooserInline,
    ExplanationTranslationDialog,
    ImageUpload,
  },
  data() {
    return {
      valid: false,
      input: null,
      isEditing: false,
      loading: false,
      newImage: "",
      imageChanged: false,
    };
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      language: "get_selected_language",
    }),
  },
  watch: {
    question() {
      this.setExplanation();
    },
  },
  created() {
    this.setExplanation();
  },
  methods: {
    ...mapActions("questions", [
      "fetch_question",
      "patch_question_explanation",
      "add_question_explanation",
      "delete_question_explanation",
    ]),
    ...mapMutations("questions", ["set_selected_language"]),
    setExplanation() {
      if (this.question) {
        this.input = JSON.parse(JSON.stringify(this.question.explanation));
      }
    },
    async updateExplanation(val, translate, format) {
      if (this.input.id === -1) {
        this.addExplanation(val, format);
      } else {
        this.patchExplanation(val, translate, format);
      }
    },
    async addExplanation(val, format) {
      this.loading = true;
      let payload = {
        question_id: this.question.id,
        language: this.language,
        body: val,
        body_format: format,
      };
      let res = await this.add_question_explanation({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async patchExplanation(val, translate, format) {
      this.loading = true;
      let properties = [
        { name: "body", value: val },
        { name: "body_format", value: format },
      ];
      let payload = {
        id: this.input.id,
        question_id: this.question.id,
        language: this.language,
        override: translate,
        properties: properties,
      };
      let res = await this.patch_question_explanation({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async deleteExplanation() {
      this.loading = true;
      let payload = {
        id: this.input.id,
        question_id: this.question.id,
        language: this.language,
      };
      let res = await this.delete_question_explanation({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.deleteSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.deleteError"),
        });
      }
    },
    async changeLanguage(lang) {
      this.set_selected_language(lang);
      this.loading = true;
      await this.fetch_question({
        id: this.question.id,
        language: this.language,
      });
      this.loading = false;
    },
    async changeImage() {
      this.loading = true;
      let properties = [];

      properties.push({
        name: "image",
        value: this.newImage ? this.newImage : "",
      });

      let payload = {
        id: this.input.id,
        properties: properties,
        question_id: this.question.id,
        language: this.language,
      };

      let res = await this.patch_question_explanation({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.imageChanged = false;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.upload_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.upload_error"),
        });
      }
    },
    imageChange(imageObj) {
      this.newImage = imageObj.filename;
      this.imageChanged = true;
    },
  },
};
</script>
