import api from "@/services/api";

export const fetch_sources = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/cnt/sources/", config);
    if (res._status === 200) {
      commit("set_sources", res.sources);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_source = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/sources/${id}`, config);
    if (res._status === 200) {
      commit("set_source", res.source);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const add_source = async (_, { payload, type, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.post(
      `admin/cnt/sources/upload/${type}`,
      payload,
      config
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const fetch_job = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/cnt/jobs/${id}`, config);
    if (res._status === 200) {
      commit("set_job", res.job);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const structure_source = async (_, { id, payload, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.post(
      `admin/cnt/sources/${id}/generate/structure`,
      payload,
      config
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const generate_journey = async (_, { id, config = {} }) => {
  try {
    // TODO: update endpoint after different types are available
    let res = await api.post(
      `admin/cnt/sources/${id}/generate/journey`,
      config
    );
    return res;
  } catch (err) {
    console.error(err);
  }
};
