export const COLORS = [
  "#00afd7",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
  "#005d5d",
  "#570408",
  "#6929c4",
  "#9f1853",
  "#198038",
  "#b28600",
  "#fa4d56",
  "#002d9c",
  "#009d9a",
  "#a56eff",
  "#ee538b",
  "#012749",
];
