<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO: check eslint err -->
<template>
  <div>
    <v-container>
      <v-form
        ref="question"
        v-model="valid"
        lazy-validation
        :disabled="disabled"
      >
        <v-row>
          <v-col cols="12">
            <InputTextarea
              v-model="question.body"
              :label="$t('resources.questions.body')"
              counter="255"
              preview
              :body-format="question.body_format"
              :rules="question.formRules.body"
            >
              <template #actions="{ togglePreview }">
                <div class="d-flex flex-column">
                  <v-tooltip class="align-self-end" left dense color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn icon @click="question.changeBodyFormat()">
                          <v-icon small> mdi-format-letter-case-upper </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("ng.change_format") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="question.body.length === 0"
                          @click="togglePreview()"
                        >
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("resources.invitations.preview") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="question.hint"
              :label="$t('ng.question_hint')"
              :rules="question.formRules.hint"
              counter="255"
              listen-to-input
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-slider
              v-model="question.time"
              thumb-label="always"
              min="0"
              max="180"
              color="secondary"
              track-color="secondary lighten-3"
              hide-details
              dense
              :rules="question.formRules.time"
            >
              <template #prepend>
                <div>
                  <v-text-field
                    v-model="question.time"
                    type="number"
                    min="0"
                    max="180"
                    outlined
                    style="width: 160px"
                    dense
                    :label="$t('ng.question_time')"
                    background-color="white"
                    hide-details
                    :rules="question.formRules.time"
                    suffix="s"
                  />
                </div>
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="question.answer.body"
              number
              :label="$t('resources.questions.answer')"
              :rules="question.formRules.answer"
              listen-to-input
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="question.options.border.min"
              number
              listen-to-input
              :label="$t('resources.questions.border_left')"
              :rules="question.formRules.borderMin"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="question.options.border.max"
              number
              listen-to-input
              :label="$t('resources.questions.border_right')"
              :rules="question.formRules.borderMax"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="question.options.tolerance.min"
              number
              listen-to-input
              :label="$t('resources.questions.tolerance_left')"
              :rules="question.formRules.toleranceMin"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="question.options.tolerance.max"
              number
              listen-to-input
              :label="$t('resources.questions.tolerance_right')"
              :rules="question.formRules.toleranceMax"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";

export default {
  name: "QuestionEstimationAdd",
  props: {
    question: {
      type: [QuestionEstimation],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  watch: {
    valid() {
      this.checkValidation();
    },
    question: {
      handler: function () {
        this.checkValidation();
      },
      deep: true,
    },
  },
  methods: {
    checkValidation() {
      this.$emit("validation", this.valid);
    },
  },
};
</script>
