var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("button",null,{"openDialog":_vm.openDialog}),(_vm.showDialog)?_c('v-dialog',{attrs:{"max-width":"640","scrollable":""},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog && _vm.user_licence)?_c('v-card',{attrs:{"color":"grey lighten-3","max-width":"640"}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"overline secondary--text"},[_vm._v(_vm._s(_vm.$t("gw.licences.update_licence")))]),_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.deleteUserLicence()}}},[_vm._v(_vm._s(_vm.$t("general.delete")))])],1),_c('v-card-text',{staticClass:"pt-2"},[_c('v-form',{attrs:{"disabled":_vm.licenceExpired || !['superadmin'].includes(_vm.role)},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.licenceExpired)?_c('v-alert',{attrs:{"text":"","type":"info","color":"error","dense":""}},[_vm._v(_vm._s(_vm.$t("gw.licences.expired_user_licence_hint")))]):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('gw.licences.valid_from_until', {
                    user: `${_vm.user_licence.user.first_name} ${_vm.user_licence.user.last_name} (${_vm.user_licence.user.user_name})`,
                    from: _vm.user_licence.date_start
                      .toLocaleString()
                      .substr(0, 10),
                    until: _vm.user_licence.date_end
                      .toLocaleString()
                      .substr(0, 10),
                  })
                )}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.monthDurations,"item-text":"label","dense":"","clearable":"","label":_vm.$t('gw.licences.month_duration'),"outlined":"","disabled":_vm.formChanged || _vm.licenceExpired,"hint":_vm.$t('gw.licences.month_duration'),"persistent-hint":"","hide-details":"","background-color":"white","item-value":"value"},model:{value:(_vm.selectedMonthDuration),callback:function ($$v) {_vm.selectedMonthDuration=$$v},expression:"selectedMonthDuration"}}),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formChanged),expression:"formChanged"}]},[_c('small',[_vm._v(_vm._s(_vm.$t("general.save_first_hint")))])])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"depressed":"","block":"","small":"","color":"primary","loading":_vm.loading,"disabled":!_vm.selectedMonthDuration || _vm.formChanged || _vm.licenceExpired},on:{"click":function($event){return _vm.extendUserLicence('increase')}}},[_vm._v(_vm._s(_vm.$t("gw.licences.extend_licence")))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"depressed":"","block":"","small":"","color":"error","loading":_vm.loading,"disabled":!_vm.selectedMonthDuration || _vm.formChanged || _vm.licenceExpired},on:{"click":function($event){return _vm.extendUserLicence('decrease')}}},[_vm._v(_vm._s(_vm.$t("gw.licences.shorten_licence")))])],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('gw.licences.credits_count'),"dense":"","outlined":"","background-color":_vm.isExtendingLicence ? 'grey lighten-3' : 'white',"hint":!_vm.isExtendingLicence
                    ? _vm.$t('gw.licences.credits_count')
                    : _vm.$t('general.save_first_hint'),"persistent-hint":"","min":"0","disabled":_vm.isExtendingLicence,"type":"number"},model:{value:(_vm.input.credits_count),callback:function ($$v) {_vm.$set(_vm.input, "credits_count", $$v)},expression:"input.credits_count"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"error","disabled":_vm.loading || !_vm.formChanged},on:{"click":function($event){return _vm.reset()}}},[_vm._v(_vm._s(_vm.$t("general.reset")))]),_c('v-btn',{attrs:{"text":"","color":"success","small":"","loading":_vm.loading,"disabled":!_vm.valid || _vm.loading || !_vm.formChanged},on:{"click":function($event){return _vm.updateUserLicence()}}},[_vm._v(" "+_vm._s(_vm.$t("general.edit"))+" ")])],1)],1):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }