export class GwLicence {
  constructor(obj) {
    this.id = obj ? obj.id : -1;
    this.name = obj ? obj.name : "";
    this.credits_count = obj ? obj.credits_count : 20000;
    this.credits_keep = obj ? obj.credits_keep : false;
    this.credits_period = obj ? obj.credits_period : 1;
    this.licences_total = obj && obj.licences_total ? obj.licences_total : 0;
    this.licences_blocked =
      obj && obj.licences_blocked ? obj.licences_blocked : 0;
    this.licences_used = obj ? obj.licences_used : 0;
    this.is_superadmin = obj ? obj.is_superadmin : false;
    this.can_overload = obj ? obj.can_overload : false;
    this.credits_overload = obj ? obj.credits_overload : 0;
    this.users = obj ? obj.users : [];
    this.date_start = obj && obj.date_start ? new Date(obj.date_start) : null;
    this.date_end = obj && obj.date_end ? new Date(obj.date_end) : null;
  }

  get available_licence_count() {
    return this.licences_total - this.licences_blocked - this.licences_used;
  }

  get is_expired() {
    return this.date_end < new Date();
  }
}
