<template>
  <div>
    <v-bottom-sheet
      v-model="showSheet"
      :inset="$vuetify.breakpoint.mdAndUp"
      @click:outside="closeSheet()"
    >
      <v-card color="primary">
        <v-form v-model="valid">
          <v-card-title>
            <span class="overline white--text">{{
              $t("gw.session_options")
            }}</span>
            <v-spacer />
            <!-- <div class="pa-4 text-center"> -->
            <v-btn text color="white" small @click="closeSheet()">
              <span>{{ $t("general.close") }}</span>
              <v-icon small class="ml-2">mdi-chevron-up</v-icon>
            </v-btn>
            <!-- </div> -->
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-if="!shortVersion" cols="12" sm="6">
                  <h5 class="white--text">
                    {{ $t("resources.journeys.propNames.name") }}
                  </h5>
                  <v-text-field
                    v-model="name"
                    :label="$t('resources.categories.name')"
                    solo
                    flat
                    background-color="white"
                    :rules="rules.notEmpty"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <h5 class="white--text">
                    {{ $t("gw.default_category") }}
                  </h5>
                  <v-autocomplete
                    v-model="defaults.category_id"
                    :items="sortedCategories"
                    item-text="name"
                    item-value="id"
                    solo
                    flat
                    small-chips
                    clearable
                    background-color="white"
                    :disabled="loading || !categories || disabled"
                    :label="$t('gw.default_category')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" v-if="!noTime">
                  <v-card flat>
                    <v-card-text class="py-3">
                      <v-slider
                        v-model="defaults.time"
                        :label="$t('ng.question_time')"
                        thumb-label="always"
                        min="0"
                        max="180"
                        color="secondary"
                        class="pt-1"
                        dense
                        track-color="secondary lighten-3"
                        hide-details
                        :disabled="disabled"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!noActivation">
                  <v-card
                    color="white"
                    flat
                    @click="defaults.activate = !defaults.activate"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon color="secondary"
                          >mdi-lightbulb-multiple</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content class="secondary--text">
                        <v-list-item-title>{{
                          $t("gw.activateContent")
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          $t("general.activation")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="defaults.activate"
                          dense
                          hide-details
                          readonly
                        />
                      </v-list-item-action>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="align-end">
            <v-btn
              v-if="!shortVersion"
              color="error"
              depressed
              small
              :disabled="disabled"
              @click="deleteSession()"
            >
              <v-icon x-small class="mr-2"> mdi-delete </v-icon>
              <span>{{ $t("gw.deleteSession") }}</span>
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              depressed
              small
              :loading="loading"
              :disabled="
                ![0, 7].includes(session.status) ||
                !changed ||
                disabled ||
                !valid
              "
              @click="patchSession()"
            >
              <v-icon small class="mr-2"> mdi-content-save </v-icon>
              <span>{{ $t("general.save") }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GwSession } from "@/models/ghostwriter/GwSession.js";

export default {
  name: "GwSessionOptions",
  props: {
    session: {
      type: GwSession,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    shortVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    noTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    noActivation: {
      type: Boolean,
      required: false,
      default: false,
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      defaults: null,
      name: "",
      showSheet: false,
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          //(v) => !!v && v.length <= 64 || this.$t('ng.too_many_chars_hint', { count: v.length, max: 64 })
        ],
      },
      valid: false,
    };
  },
  computed: {
    changed() {
      let changed = false;
      if (!this.session.defaults) return false;
      if (this.name !== this.session.name) changed = true;
      if (this.defaults.time !== this.session.defaults.time) changed = true;
      if (this.defaults.category_id !== this.session.defaults.category_id)
        changed = true;
      if (this.defaults.activate !== this.session.defaults.activate)
        changed = true;
      return changed;
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    visible(val) {
      val ? this.openSheet() : this.closeSheet();
    },
  },
  beforeMount() {
    this.defaults = JSON.parse(JSON.stringify(this.session.defaults));
    this.name = this.session.name;
  },
  methods: {
    ...mapActions("ghostwriter", ["patch_session", "delete_session"]),
    openSheet() {
      this.showSheet = true;
    },
    closeSheet() {
      this.showSheet = false;
      this.$emit("close");
    },
    async patchSession() {
      let props = [
        { name: "name", value: this.name },
        { name: "defaults", value: this.defaults },
      ];
      let payload = {
        session_id: this.session.id,
        session_type: this.session.type,
        properties: props,
      };
      let res = await this.patch_session({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async deleteSession() {
      var confirm = window.confirm(this.$t("gw.confirm_delete_session"));
      if (!confirm) return false;
      let res = await this.delete_session({
        id: this.session.id,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.$router.push(
          this.session.type === 1
            ? { name: "GwSessionList" }
            : { name: "GwSessionBulk" }
        );
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
