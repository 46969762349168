<template>
  <div :class="`mb-${marginBottom}`">
    <v-sheet color="grey lighten-3" min-height="86">
      <v-container
        fill-height
        class="align-baseline"
        style="position: relative"
      >
        <v-row>
          <v-col cols="12" class="pb-1">
            <v-breadcrumbs
              v-if="showBreadcrumbs"
              :items="breadcrumbs"
              class="pa-0"
            >
              <template #item="{ item }">
                <router-link
                  :is="item.disabled ? 'span' : 'router-link'"
                  :to="item.to"
                  class="breadcrumb-link secondary--text"
                >
                  <v-icon v-if="item.text == 'Dashboard'" small>
                    mdi-apps
                  </v-icon>
                  <span
                    v-if="
                      item.text !== 'Dashboard' ||
                      (item.text == 'Dashboard' && breadcrumbs.length < 3)
                    "
                    class="caption"
                  >
                    {{ item.text }}
                  </span>
                </router-link>
              </template>

              <template #divider>
                <span class="breadcrumb-divider secondary" />
              </template>
            </v-breadcrumbs>
          </v-col>

          <v-col cols="12" class="pt-0">
            <div class="d-sm-flex justify-space-between">
              <div class="d-flex align-center mb-2 mb-sm-0">
                <v-avatar tile size="24" color="secondary" class="mr-2">
                  <v-icon small color="grey lighten-3">
                    {{ icon }}
                  </v-icon>
                </v-avatar>
                <div class="d-flex align-center">
                  <h1
                    class="title font-weight-bold secondary--text"
                    :class="{
                      'body-2': $vuetify.breakpoint.xsOnly,
                      'mr-2': flag,
                    }"
                  >
                    {{ title }}
                  </h1>
                  <slot name="titleButtonLeft" />
                </div>
                <country-flag :country="flag" size="small" />
              </div>
              <slot name="titleButton" />
            </div>
          </v-col>

          <v-col cols="12" class="py-0">
            <slot name="extension" />
          </v-col>
        </v-row>

        <v-fab-transition v-if="routeName && !routeDisabled">
          <v-tooltip left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                fab
                dark
                small
                absolute
                bottom
                right
                :to="{ name: routeName }"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("general.add") }}</span>
          </v-tooltip>
        </v-fab-transition>
        <slot v-if="!routeName" name="button" />
      </v-container>
    </v-sheet>
    <v-divider />
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "ViewHeader",
  components: { CountryFlag },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => [],
    },
    showBreadcrumbs: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: false,
    },
    routeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    flag: {
      type: String,
      required: false,
      default: "",
    },
    marginBottom: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style>
.breadcrumb-link {
  text-decoration: none;
}
.breadcrumb-divider {
  display: block;
  height: 3px;
  width: 3px;
}
</style>
