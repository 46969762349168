<template>
  <PolarArea
    :key="updated"
    :data="chartData"
    :options="chartOptions"
    :height="height"
    :width="width"
  />
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";
import { PolarArea } from "vue-chartjs";

ChartJS.register(Title, Tooltip, Legend, ArcElement, RadialLinearScale);

export default {
  name: "ChartPolar",
  components: { PolarArea },
  data() {
    return {
      updated: 0,
    };
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              usePointStyle: false,
              align: "end" /* position: 'right', */,
            },
          },
        };
      },
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  watch: {
    chartData: {
      handler: function () {
        this.updated++;
      },
      deep: true,
    },
  },
};
</script>
