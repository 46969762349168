<template>
  <div
    :class="[
      'language-chooser-inline-wrapper',
      $attrs['hide-label'] == '' ? 'hide-label' : '',
      absolute ? 'language-chooser-absolute' : '',
    ]"
  >
    <v-select
      v-model="selected"
      placeholder="Language"
      :items="languages"
      item-text="name"
      item-value="name"
      flat
      dense
      height="36px"
      :outlined="!hideBorder"
      small-chips
      selection-height="10"
      :hide-details="hidedetails"
      :disabled="disabled"
      :background-color="
        colored
          ? disabled
            ? backgroundColor
              ? backgroundColor
              : 'grey lighten-3'
            : backgroundColor
          : 'transparent'
      "
      :label="label"
      :hint="hint"
      :persistent-hint="persistentHint"
      :prepend-icon="prependIcon"
      :dark="dark"
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          small
          :color="chipColor"
        >
          <country-flag
            :country="flagName(data.item)"
            size="small"
            :class="$attrs['hide-label'] != '' ? 'mr-1' : ''"
          />
          <span v-if="$attrs['hide-label'] != ''">
            {{ $t("languages." + data.item + ".display") }}
          </span>
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template>
          <country-flag :country="flagName(data.item)" size="small" />
          <v-list-item-content class="ml-4">
            <v-list-item-title>
              {{ $t("languages." + data.item + ".display") }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>

      <template v-if="appendIcon" #append-outer>
        <v-icon color="grey">
          {{ appendIcon }}
        </v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "LanguageChooserInline",
  components: {
    CountryFlag,
  },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hidedetails: {
      type: Boolean,
      default() {
        return false;
      },
    },
    initial: {
      type: String,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: "",
    },
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    filled: {
      type: Boolean,
      required: false,
      default: false,
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
      default: "",
    },
    hint: {
      type: String,
      required: false,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipColor: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
  },
  data() {
    return {
      selected: this.$i18n.locale,
      languages: this.$i18n.availableLocales,
    };
  },
  watch: {
    selected(lang) {
      this.$emit("change", lang);
    },
    initial(val) {
      if (val) this.selected = val;
    },
  },
  beforeMount() {
    if (this.initial) this.selected = this.initial;
  },
  methods: {
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
  },
};
</script>

<style lang="scss">
.language-chooser-inline-wrapper {
  .v-input__prepend-inner {
    margin-top: 0 !important;
  }
}
.language-chooser-inline-wrapper.hide-label {
  .v-select__selections {
    max-width: 62px;
  }
  .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0;
  }
}
.language-chooser-absolute {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
