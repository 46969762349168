<template>
  <div>
    <SectionHeader :title="title" :subtitle="subtitle" class="px-0">
      <template v-if="headerRouteName" #action>
        <v-btn icon tile :to="{ name: headerRouteName }">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
    </SectionHeader>

    <v-list dense color="transparent">
      <v-list-item
        v-if="!visibleData || visibleData.length === 0"
        class="mb-2 grey lighten-3"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-light">
            {{ $t("$vuetify.noDataText") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(item, i) in visibleData"
        :key="i"
        class="mb-2 grey lighten-3"
        :to="
          itemRouteName
            ? { name: itemRouteName, params: { id: item[itemRouteProp] } }
            : null
        "
      >
        <v-list-item-avatar v-if="!hideIndex" color="transparent" tile>
          <span class="font-weight-bold caption secondary--text">{{
            i + 1
          }}</span>
          <slot name="custom" :item="item" />
          <!-- <span class="font-weight-bold caption secondary--text">{{ Math.round((category.played_count / categoryPlayedSum) * 100) }}%</span> -->
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="!anotherProp" class="font-weight-light">
            {{ item[itemTitle] }}
          </v-list-item-title>
          <v-list-item-title v-if="anotherProp" class="font-weight-light">
            {{ item[itemTitle][anotherProp] }}
          </v-list-item-title>
          <!-- <v-progress-linear
                    :value="(category.played_count / categoryPlayedSum) * 100"
                    height="8"
                    background-color="transparent"
                  /> -->
        </v-list-item-content>
        <v-list-item-avatar v-if="itemImage" size="32">
          <!-- <span class="font-weight-bold secondary--text">{{ category.played_count }}</span> -->
          <v-img :src="item[itemImage]" />
        </v-list-item-avatar>
        <div
          v-if="!itemImage && appendValue"
          class="font-weight-bold caption secondary--text"
        >
          {{ item[appendValue] }}
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "StatisticsRankingList",
  components: {
    SectionHeader,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    itemTitle: {
      type: String,
      required: false,
      default: "name",
    },
    itemImage: {
      type: String,
      required: false,
    },
    headerRouteName: {
      type: String,
      required: false,
    },
    itemRouteName: {
      type: String,
      required: false,
    },
    itemRouteProp: {
      type: String,
      required: false,
      default: "id",
    },
    rowCount: {
      type: Number,
      required: false,
      default: 3,
    },
    hideIndex: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendValue: {
      type: [Number, String],
      required: false,
      default: "",
    },
    anotherProp: {
      type: String,
      required: false,
    },
  },
  computed: {
    visibleData() {
      return this.data.slice(0, this.rowCount);
    },
  },
};
</script>
