<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.invitations.page_title')"
      icon="mdi-account-multiple-plus"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'InvitationOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!invitation" />

    <section v-if="invitation">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12" sm="4">
            <v-card outlined :loading="loading">
              <v-card-title class="caption pb-0">
                {{ $t("general.information") }}
              </v-card-title>
              <v-card-text v-if="invitation" class="pa-0">
                <v-list dense class="pt-0">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("resources.invitations.email")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        invitation.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("resources.invitations.created_at")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        invitation.created_at.toLocaleDateString($i18n.locale, {
                          day: "numeric",
                          year: "numeric",
                          month: "long",
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("resources.invitations.expired_at")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        invitation.expired_at.toLocaleDateString($i18n.locale, {
                          day: "numeric",
                          year: "numeric",
                          month: "long",
                        })
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("resources.invitations.resend_count")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        :class="{ 'red--text': invitation.resend_count > 2 }"
                      >
                        {{ invitation.resend_count }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("resources.invitations.status.general")
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-badge
                          bordered
                          dot
                          inline
                          tile
                          left
                          :color="getStatusColor(invitation)"
                          offset-x="10"
                          offset-y="10"
                        >
                          {{
                            $t(
                              "resources.invitations.status." +
                                invitation.status
                            )
                          }}
                        </v-badge>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card outlined class="mt-4">
              <v-card-title class="caption" :class="{ 'pb-0': !loading }">
                {{ $t("resources.users.actions") }}
              </v-card-title>
              <v-card-text v-if="invitation">
                <div class="d-flex flex-column align-start mt-2">
                  <v-btn
                    small
                    text
                    color="primary"
                    :disabled="
                      !['pending', 'max_resent'].includes(invitation.status) ||
                      loading
                    "
                    @click="copyInvitationLink(invitation)"
                  >
                    {{ $t("resources.invitations.copyLink") }}
                  </v-btn>
                  <v-btn
                    small
                    text
                    color="primary"
                    :disabled="
                      invitation.resend_count > 2 ||
                      !['pending', 'expired'].includes(invitation.status) ||
                      loading
                    "
                    @click="resendInvitation(invitation)"
                  >
                    {{ $t("resources.invitations.resendInvitation") }}
                  </v-btn>
                  <v-btn
                    small
                    text
                    color="error"
                    :disabled="
                      !['pending', 'expired', 'max_resent'].includes(
                        invitation.status
                      ) || loading
                    "
                    @click="deleteInvitation(invitation)"
                  >
                    {{ $t("resources.invitations.deleteInvitation") }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="8">
            <v-card outlined :loading="loading">
              <v-card-title class="caption pb-0">
                {{ $t("general.details") }}
              </v-card-title>
              <v-card-text v-if="!loading" class="px-1">
                <v-form
                  v-model="valid"
                  :disabled="invitation.status !== 'pending' || loading"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="inputs.assigned_substructure_ids"
                          :items="availableAssignableSubstructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          :rules="substructureRules"
                          :label="
                            $t('resources.codes.assignable_substructures')
                          "
                          multiple
                          @change="checkSelectableStructures"
                        >
                          <template #item="{ item }">
                            {{ item.structure.name }} / {{ item.name }}
                          </template>
                          <template #selection="{ item, index }">
                            <span v-if="index < 1">
                              {{ substructureById(item.id).structure.name }} /
                              {{ item.name }}
                            </span>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption ml-1"
                            >
                              (+{{
                                inputs.assigned_substructure_ids.length - 1
                              }}
                              {{ $t("resources.invitations.moreIndicator") }})
                            </span>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-show="
                            ['superadmin', 'admin'].includes(role) ||
                            inputs.select_structure_ids
                          "
                          v-model="inputs.select_structure_ids"
                          :items="availableSelectableStructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          :label="$t('resources.codes.selectable_structures')"
                          multiple
                        >
                          <template #item="{ item }">
                            {{ item.name }}
                          </template>
                          <template #selection="{ item, index }">
                            <span v-if="index < 1">
                              {{ item.name }}
                            </span>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption ml-1"
                            >
                              (+{{ inputs.select_structure_ids.length - 1 }}
                              {{ $t("resources.invitations.moreIndicator") }})
                            </span>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialSelectStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-if="!loading"
                          v-model="inputs.role_id"
                          :items="roles"
                          item-text="name"
                          item-value="id"
                          :disabled="role == 'manager'"
                          :label="$t('resources.users.role')"
                          @change="changeRole"
                        >
                          <template #item="{ item }">
                            {{ item.name }}
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialUserrole"
                            />
                          </template>
                        </v-select>
                      </v-col>

                      <v-col v-if="inputs.role_id == 4" cols="12" sm="6">
                        <v-select
                          v-model="inputs.role_assigned_ids"
                          :items="managerPossibleSubstructures"
                          item-text="name"
                          item-value="id"
                          attach
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          :label="$t('resources.users.manager_substructures')"
                          multiple
                          :rules="assignedRules"
                        >
                          <template #item="{ item }">
                            {{ item.structure.name }} / {{ item.name }}
                          </template>
                          <template #selection="{ item, index }">
                            <span v-if="index < 1">
                              {{ item.structure.name }} / {{ item.name }}
                            </span>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption ml-1"
                            >
                              (+{{ inputs.role_assigned_ids.length - 1 }}
                              {{ $t("resources.invitations.moreIndicator") }})
                            </span>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialManagerStructures"
                            />
                          </template>
                        </v-select>
                      </v-col>

                      <v-col v-if="inputs.role_id == 2" cols="12" sm="6">
                        <v-select
                          v-if="!loading && inputs.role_id == 2"
                          v-model="inputs.role_assigned_ids"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          attach
                          :label="$t('resources.users.related_categories')"
                          multiple
                          :rules="assignedRules"
                        >
                          <template #selection="{ item, index }">
                            <span v-if="index < 1">
                              {{ item.name }}
                            </span>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption ml-1"
                            >
                              (+{{ inputs.role_assigned_ids.length - 1 }}
                              {{ $t("resources.invitations.moreIndicator") }})
                            </span>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialAssignedcategories"
                            />
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        ['superadmin', 'admin'].includes(role) &&
                        showGhostwriter
                      "
                    >
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="gwLicence.gw_licence_id"
                          :items="licences"
                          item-text="name"
                          item-value="id"
                          clearable
                          :label="$t('gw.gw_form_label')"
                        >
                          <template #append-outer>
                            <v-icon color="grey"> mdi-typewriter </v-icon>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="gwLicence.gw_month_duration"
                          :items="monthDurations"
                          item-text="label"
                          item-value="value"
                          clearable
                          :label="'Lizenz-Zeitraum'"
                          :disabled="!selectedLicenceIsSuperLicence"
                        >
                          <template #append-outer>
                            <v-icon color="grey"> mdi-calendar-month </v-icon>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!loading"
                  color="error"
                  text
                  small
                  :disabled="!formChanged"
                  @click="resetForm"
                >
                  {{ $t("general.clear") }}
                </v-btn>
                <v-btn
                  v-if="!loading"
                  color="success"
                  text
                  small
                  :disabled="!valid || !formChanged"
                  @click="saveForm()"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog.vue";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "InvitationDetail",
  components: {
    TutorialCarouselDialog,
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      id: 0,
      loading: true,
      valid: true,
      inputs: null,
      roles: [
        { id: 1, name: this.$t("resources.users.roles.1") },
        { id: 2, name: this.$t("resources.users.roles.2") },
        { id: 3, name: this.$t("resources.users.roles.3") },
        { id: 4, name: this.$t("resources.users.roles.4") },
      ],
      tutorialUserrole: ["userrole"],
      tutorialAssignedcategories: ["editorcategories"],
      tutorialStructures: ["structures"],
      tutorialSelectStructures: ["selectstructures"],
      tutorialManagerStructures: ["managersubstructures"],
      substructureRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.select_an_item"),
      ],
      assignedRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.select_an_item"),
      ],
      gwLicence: null,
      monthDurations: [
        {
          value: 1,
          label: `1 ${this.$t("general.single_month")}`,
        },
        {
          value: 2,
          label: `2 ${this.$t("general.months")}`,
        },
        {
          value: 3,
          label: `3 ${this.$t("general.months")}`,
        },
        {
          value: 6,
          label: `6 ${this.$t("general.months")}`,
        },
        {
          value: 12,
          label: `12 ${this.$t("general.months")}`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_categories" }),
    ...mapGetters("invitations", { invitation: "get_invitation" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("ghostwriter", { licences: "get_licences" }),
    availableAssignableSubstructures() {
      if (!this.loading) {
        return this.substructures.filter((substructure) => {
          return (
            this.auth.related_substructures.includes(substructure.id) ||
            ["superadmin", "admin"].includes(this.role)
          );
        });
      } else return [];
    },
    availableSelectableStructures() {
      if (!this.loading) {
        return this.structures.filter((structure) => {
          if (structure.substructures.length === 0) return true;
          let check = true;
          structure.substructures.forEach((substructure) => {
            if (this.inputs.assigned_substructure_ids.includes(substructure.id))
              check = false;
          });
          return check;
        });
      } else return [];
    },
    managerPossibleSubstructures() {
      if (!this.loading) {
        return this.substructures.filter(
          (substructure) =>
            !this.pre_select_structures.includes(
              substructure.structure.id.toString()
            )
        );
      } else return [];
    },
    formChanged() {
      if (!this.loading) {
        return (
          this.invitation.assigned_substructure_ids !==
            this.inputs.assigned_substructure_ids ||
          this.invitation.role_id !== this.inputs.role_id ||
          (this.invitation.select_structure_ids !== null &&
            !(
              JSON.stringify(this.invitation.select_structure_ids) ==
              JSON.stringify(this.inputs.select_structure_ids)
            )) ||
          (!this.invitation.select_structure_ids &&
            this.inputs.select_structure_ids) ||
          this.invitation.role_assigned_ids !== this.inputs.role_assigned_ids ||
          this.gwLicenceChanged()
        );
      } else return false;
    },
    pre_select_structures() {
      let stringArray = process.env.VUE_APP_PRE_SELECT_STRUCTURES.split(",");
      let intArray = stringArray.map((structure) => {
        return parseInt(structure);
      });
      return intArray;
    },
    showGhostwriter() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.invitations.plural"),
          disabled: false,
          to: { name: "InvitationOverview" },
        },
      ];
    },
    selectedLicenceIsSuperLicence() {
      if (!this.licences || !this.gwLicence) return false;
      var licence = this.licences.filter(
        (l) => l.id === this.gwLicence.gw_licence_id
      )[0];
      return !!licence && licence.is_superadmin;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.id = Number(this.$route.params.id);
  },
  destroyed() {
    this.set_categories(null);
    this.set_structures(null);
    this.set_invitation(null);
  },
  watch: {
    selectedLicenceIsSuperLicence(v) {
      if (!v) this.gwLicence.gw_month_duration = null;
    },
  },
  methods: {
    ...mapActions("categories", ["fetch_categories"]),
    ...mapActions("invitations", [
      "fetch_invitation",
      "update_invitation",
      "delete_invitation",
      "resend_invitation",
    ]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapActions("ghostwriter", ["fetch_licences"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("invitations", ["set_invitation"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_structures();
      await this.fetch_categories();
      if (["superadmin", "admin"].includes(this.role) && this.showGhostwriter) {
        await this.fetch_licences();
      }

      if (this.id) {
        await this.fetch_invitation({
          id: this.id,
        });
      }

      this.inputs = { ...this.invitation };
      if (this.inputs.select_structure_ids == "null")
        this.inputs.select_structure_ids = [];
      this.gwLicence = {
        gw_licence_id:
          this.invitation.options && this.invitation.options.gw_licence_id
            ? this.invitation.options.gw_licence_id
            : null,
        gw_month_duration:
          this.invitation.options && this.invitation.options.gw_month_duration
            ? this.invitation.options.gw_month_duration
            : null,
      };
      this.loading = false;
    },
    getStatusColor(invitation) {
      if (invitation.status == "pending") return "warning";
      if (invitation.status == "accepted") return "success";
      if (invitation.status == "rejected") return "error";
      //max_resent?
      return "grey lighten-1";
    },
    checkSelectableStructures() {
      if (!this.loading) {
        this.inputs.assigned_substructure_ids.forEach((substructure_id) => {
          if (this.inputs.select_structure_ids) {
            this.inputs.select_structure_ids =
              this.inputs.select_structure_ids.filter((structure_id) => {
                return (
                  structure_id !==
                  this.substructureById(substructure_id).structure.id
                );
              });
          } else return true;
        });
      } else return [];
    },
    resetForm() {
      this.inputs = { ...this.invitation };
    },
    async saveForm() {
      if (this.showGhostwriter) {
        var options = this.gwLicence;
      }
      if (options.gw_month_duration === null) delete options.gw_month_duration;
      let payload = {
        invitation_id: this.inputs.id,
        role_id: this.inputs.role_id,
        role_assigned_ids: [2, 4].includes(this.inputs.role_id)
          ? this.inputs.role_assigned_ids
          : [],
        assigned_substructure_ids: this.inputs.assigned_substructure_ids
          ? this.inputs.assigned_substructure_ids
          : [],
        select_structure_ids:
          this.inputs.select_structure_ids ||
          this.inputs.select_structure_ids?.length > 0
            ? this.inputs.select_structure_ids
            : [],
        options: this.gwLicence ? options : null,
      };
      let res = await this.update_invitation({
        payload: payload,
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.invitations.updateSuccess"),
        });
        this.fetchData();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.invitations.updateError"),
        });
      }
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    changeRole() {
      this.inputs.role_assigned_ids = "";
    },
    async resendInvitation(invitation) {
      let payload = {
        invitation_id: invitation.id,
      };
      let res = await this.resend_invitation({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.invitations.resendSuccess"),
        });
        this.fetchData();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.invitations.resendError"),
        });
      }
    },
    async deleteInvitation(invitation) {
      let payload = {
        invitation_id: invitation.id,
      };
      let res = await this.delete_invitation({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.invitations.deleteSuccess"),
        });
        this.$router.push({ name: "InvitationOverview" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.invitations.deleteError"),
        });
      }
    },
    copyInvitationLink(invitation) {
      let route = `https://${process.env.VUE_APP_INSTANCE_NAME}.p3l.app/register?invitation=${invitation.hash_token}`;
      navigator.clipboard.writeText(route);
      this.$notify({
        type: "success",
        title: this.$t("general.success"),
        text: this.$t("resources.invitations.linkCopied"),
      });
    },
    gwLicenceChanged() {
      if (!this.showGhostwriter || !["superadmin"].includes(this.role))
        return false;
      if (!this.invitation.options && !!this.gwLicence) return true;
      if (
        this.invitation.options &&
        this.invitation.options.gw_licence_id &&
        this.invitation.options.gw_licence_id !== this.gwLicence.gw_licence_id
      )
        return true;
      if (
        this.invitation.options &&
        this.invitation.options.gw_month_duration &&
        this.invitation.options.gw_month_duration !==
          this.gwLicence.gw_month_duration
      )
        return true;
      return false;
    },
  },
};
</script>

<style></style>
