export class GwTranscription {
  constructor(obj) {
    this.id = obj.id;
    this.file_name = obj.file_name;
    this.file_id = obj.file_id; // YT ID
    this.type = this.file_id ? "youtube" : "upload";
    this.description = obj.description;
    this.status = obj.status;
    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);
    this.language = obj.language;
    this.transcription = obj.transcription;
    this.credits = Number(obj.credits);
  }

  get status_icon() {
    const icon = [
      "mdi-playlist-edit",
      "mdi-database-import",
      "mdi-database-import",
      "mdi-check-circle",
      "mdi-close-circle",
      "mdi-close-circle",
      "mdi-checkbox-marked-circle-auto-outline",
      "mdi-checkbox-multiple-marked-circle",
    ];
    return icon[this.status];
  }
  get status_color() {
    if ([3].includes(this.status)) return "primary";
    if ([4, 5].includes(this.status)) return "error"; // no lang temp fix
    if ([7].includes(this.status)) return "success";
    return "secondary";
  }
}
