var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`mb-${_vm.marginBottom}`},[_c('v-sheet',{attrs:{"color":"grey lighten-3","min-height":"86"}},[_c('v-container',{staticClass:"align-baseline",staticStyle:{"position":"relative"},attrs:{"fill-height":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[(_vm.showBreadcrumbs)?_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(item.disabled ? 'span' : 'router-link',{tag:"router-link",staticClass:"breadcrumb-link secondary--text",attrs:{"to":item.to}},[(item.text == 'Dashboard')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-apps ")]):_vm._e(),(
                    item.text !== 'Dashboard' ||
                    (item.text == 'Dashboard' && _vm.breadcrumbs.length < 3)
                  )?_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1)]}},{key:"divider",fn:function(){return [_c('span',{staticClass:"breadcrumb-divider secondary"})]},proxy:true}],null,false,4172715632)}):_vm._e()],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center mb-2 mb-sm-0"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"tile":"","size":"24","color":"secondary"}},[_c('v-icon',{attrs:{"small":"","color":"grey lighten-3"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('h1',{staticClass:"title font-weight-bold secondary--text",class:{
                    'body-2': _vm.$vuetify.breakpoint.xsOnly,
                    'mr-2': _vm.flag,
                  }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("titleButtonLeft")],2),_c('country-flag',{attrs:{"country":_vm.flag,"size":"small"}})],1),_vm._t("titleButton")],2)]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._t("extension")],2)],1),(_vm.routeName && !_vm.routeDisabled)?_c('v-fab-transition',[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","fab":"","dark":"","small":"","absolute":"","bottom":"","right":"","to":{ name: _vm.routeName }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1301797500)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.add")))])])],1):_vm._e(),(!_vm.routeName)?_vm._t("button"):_vm._e()],2)],1),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }