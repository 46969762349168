<template>
  <div>
    <v-btn
      :width="width"
      :color="color"
      class="justify-start"
      :text="text"
      :disabled="disabled || !routeName"
      :outlined="outlined"
      :dark="dark"
      :small="small"
      :x-small="xSmall"
      :block="block"
      :depressed="depressed"
      @click="goTo(routeName)"
    >
      <v-icon v-if="icon" left class="mr-3" :small="small" :x-small="xSmall">
        {{ icon }}
      </v-icon>
      <span class="">{{ title }}</span>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "LinkWidget",
  props: {
    title: {
      value: [String, Number],
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    routeName: {
      type: String,
      required: false,
      default: undefined,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    depressed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ...mapMutations("questions", [
      "set_questions",
      "set_reviewmode",
      "set_reportmode",
    ]),
    goTo(routeName) {
      if (routeName) {
        if (routeName == "QuestionReports") {
          this.set_reviewmode(false);
          this.set_reportmode(true);
          routeName = "QuestionOverview";
        }
        if (routeName == "QuestionApprovals") {
          this.set_reportmode(false);
          this.set_reviewmode(true);
          routeName = "QuestionOverview";
        }
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style></style>
