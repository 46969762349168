<template>
  <v-dialog
    v-model="dialog"
    max-width="640"
    @click:outside="loading ? false : closeDialog()"
  >
    <v-form v-model="valid" :disabled="loading">
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text"
          >Credit Pool hinzufügen</v-card-title
        >
        <v-card-text v-if="input">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="input.credits_count"
                type="number"
                label="Anzahl Credits"
                hint="Anzahl an Credits, auf welche alle User mit berechtigten Lizenzen zugreifen können."
                persistent-hint
                dense
                background-color="white"
                outlined
                min="0"
                :rules="rules.credits_count"
              />
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="startDatePicker"
                v-model="startDatePicker"
                :return-value.sync="input.date_start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="input.date_start"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    hint="Start der Lizenz"
                    persistent-hint
                    background-color="white"
                    v-on="on"
                    :rules="rules.required"
                  >
                    <template #append>
                      <v-btn icon small @click="input.date_start = ''">
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="input.date_start"
                  scrollable
                  type="month"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="startDatePicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDatePicker.save(input.date_start)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endDatePicker"
                v-model="endDatePicker"
                :return-value.sync="input.date_end"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="input.date_end"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    hint="Ende der Lizenz"
                    persistent-hint
                    background-color="white"
                    v-on="on"
                    :rules="rules.required"
                  >
                    <template #append>
                      <v-btn icon small @click="input.date_end = ''">
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="input.date_end" scrollable type="month">
                  <v-spacer />
                  <v-btn text color="primary" @click="endDatePicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endDatePicker.save(input.date_end)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            text
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
            >{{ $t("general.close") }}</v-btn
          >
          <v-btn
            small
            text
            color="success"
            :loading="loading"
            :disabled="
              !valid ||
              loading ||
              !input.date_start ||
              !input.date_end ||
              !input.credits_count
            "
            @click="addCreditPool()"
            >{{ $t("general.add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { GwCreditPool } from "@/models/ghostwriter/GwCreditPool";

export default {
  name: "GwAddCreditPoolDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      input: null,
      loading: false,
      valid: false,
      startDatePicker: false,
      endDatePicker: false,
      rules: {
        credits_count: [
          (v) => !!v || this.$t("ng.add_chars_hint"),
          //(v) => typeof v === "number" || this.$t("ng.numeric_needed"),
        ],
      },
    };
  },
  watch: {
    visible: {
      handler: function (v) {
        if (v) this.openDialog();
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["add_credit_pool"]),
    openDialog() {
      this.dialog = true;
      this.input = new GwCreditPool();
    },
    closeDialog() {
      this.dialog = false;
      //this.input = null;
      this.$emit("close");
    },
    async addCreditPool() {
      this.loading = true;
      var pool = { ...this.input };
      delete pool.id;

      if (pool.date_start.length === 7)
        pool.date_start = pool.date_start.concat("-01");
      if (pool.date_end.length === 7)
        pool.date_end = pool.date_end.concat("-01");

      var res = await this.add_credit_pool({
        payload: pool,
      });

      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: "Credit-Pool erfolgreich hinzugefügt.",
          type: "success",
        });
        this.closeDialog();
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: "Fehler beim Hinzufügen des Credit-Pools",
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>
