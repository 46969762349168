<template>
  <div>
    <v-card color="transparent" flat>
      <div class="d-flex">
        <v-expand-x-transition>
          <v-text-field
            v-model="input"
            :text="type === 'text'"
            :filled="type === 'filled'"
            :outlined="type === 'outlined'"
            :solo="type === 'solo'"
            :color="color"
            :disabled="disabled && !editable"
            :readonly="readonly"
            :hide-details="hideDetails"
            :dense="dense"
            :rules="rules"
            :hint="bodyFormat ? `Format: ${input_format}` : hint ? hint : ''"
            persistent-hint
            :background-color="disabled && !editable ? '' : backgroundColor"
            :type="number ? 'number' : 'text'"
            :counter="!disabled && !!counter ? counter : false"
            :prefix="prefix"
            :suffix="suffix"
            @input="handleInput"
          >
            <!-- validate-on-blur -->
            <template v-if="label" #label>
              <v-icon v-if="labelIcon" class="mx-2" :color="labelIconColor">
                {{ labelIcon }}
              </v-icon>
              <span class="label">{{ label }}</span>
            </template>
            <template v-if="correctButton" #prepend>
              <v-tooltip right color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      small
                      class="mb-2"
                      :disabled="disabled && !editable"
                      :color="correct ? 'success' : 'secondary'"
                      @click="correct = !correct"
                    >
                      <v-icon small>
                        {{ correct ? "mdi-check-circle" : "mdi-close-circle" }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{
                  correct
                    ? $t("resources.questions.correct")
                    : $t("resources.questions.wrong")
                }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-expand-x-transition>

        <slot
          name="actions"
          :editing="editable"
          :start-editing="startEditing"
          :cancel-editing="cancelEditing"
          :save-input="saveInput"
          :input-changed="inputHasChanged"
          :change-format="changeFormat"
          :toggle-preview="togglePreview"
        />
      </div>

      <v-expand-transition>
        <div v-if="translatable && editable" class="px-4 pb-1">
          <div class="d-flex">
            <v-switch
              v-model="translate"
              dense
              :disabled="input_format === 'math'"
              class="mt-0"
              hide-details
            >
              <template #label>
                <span class="caption">{{ $t("ng.update_all_languages") }}</span>
              </template>
            </v-switch>
          </div>
        </div>
      </v-expand-transition>

      <v-expand-transition v-if="preview">
        <v-card v-show="showPreview" outlined class="text-center my-2">
          <div class="py-4">
            <div v-if="['html'].includes(bodyFormat)" v-html="value" />
            <div v-if="['text'].includes(bodyFormat)" v-text="value" />
            <MathJaxRenderer
              v-if="['math'].includes(bodyFormat)"
              :formula="value"
              :safe="false"
            />
          </div>
        </v-card>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import MathJaxRenderer from "@/components/global/MathJaxRenderer";

export default {
  name: "InputTextfield",
  components: {
    MathJaxRenderer,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    /* editable: {
      type: Boolean,
      required: false,
      default: true
    }, */
    type: {
      type: String, // text, outlined, filled, solo,
      required: false,
      default: "outlined",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: [String, Number],
      required: false,
    },
    labelIcon: {
      type: String,
      required: false,
    },
    labelIconColor: {
      type: String,
      required: false,
      default: "text",
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    number: {
      type: Boolean,
      required: false,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "white",
    },
    hint: {
      type: String,
      required: false,
    },
    counter: {
      type: [String, Number, Boolean],
      required: false,
      default: false,
    },
    translatable: {
      type: Boolean,
      required: false,
      default: false,
    },
    bodyFormat: {
      type: String,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    originalId: {
      type: [String, Number],
      required: false,
    },
    correctButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    correctValue: {
      type: Boolean,
      required: false,
    },
    listenToInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    answerIndex: {
      type: Number,
      required: false,
    },
    prefix: {
      type: String,
      required: false,
      default: undefined,
    },
    suffix: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      input: "",
      input_format: "text",
      editable: false,
      translate: false,
      body_formats: ["text", "html", "math"],
      showPreview: false,
      correct: false,
    };
  },
  computed: {
    inputHasChanged() {
      let hasChanged =
        this.input !== this.value ||
        this.input_format !== this.bodyFormat ||
        this.correct !== this.correctValue;
      if (this.listenToInput) {
        this.$emit(
          "change",
          this.input,
          this.input_format,
          this.correct,
          this.answerIndex
        );
      }
      return hasChanged;
    },
  },
  watch: {
    value() {
      if (!this.listenToInput) {
        this.setValue();
      }
    },
    bodyFormat(val) {
      this.input_format = val;
    },
    input_format(val) {
      if (val === "math") this.translate = false;
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.translate = this.value ? false : true;
      this.input = this.value;
      if (this.correctButton) this.correct = this.correctValue;
      if (this.bodyFormat) this.input_format = this.bodyFormat;
    },
    handleInput(e) {
      if (this.listenToInput) {
        let input = this.number ? parseFloat(e) : e;
        this.$emit("input", input, this.input_format, this.correct);
      }
    },
    startEditing() {
      this.editable = true;
      this.$emit("is-editing", this.editable);
    },
    cancelEditing() {
      this.input = this.value;
      this.editable = false;
      this.setValue();
      this.$emit("is-editing", false);
    },
    saveInput() {
      this.editable = false;
      this.$emit("is-editing", false);
      this.$emit(
        "save",
        this.input,
        this.translate,
        this.input_format,
        this.originalId,
        this.correct
      );
    },
    changeFormat() {
      let idx = this.body_formats.indexOf(this.input_format);
      idx === -1 || idx === 2 ? (idx = 0) : idx++;
      this.input_format = this.body_formats[idx];
    },
    togglePreview() {
      this.showPreview = !this.showPreview;
    },
  },
};
</script>

<style lang="scss">
.v-text-field.v-input--is-disabled,
.label {
  color: #303030;
}
.v-text-field input[disabled="disabled"] {
  color: rgba(30, 30, 30, 0.3) !important;
}
</style>
