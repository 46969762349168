<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO: check eslint err -->
<template>
  <div>
    <v-container>
      <v-form
        ref="question"
        v-model="valid"
        lazy-validation
        :disabled="disabled"
      >
        <v-row>
          <v-col cols="12">
            <InputTextarea
              v-model="question.body"
              :label="$t('resources.questions.body')"
              counter="255"
              preview
              gaps
              :body-format="question.body_format"
              :rules="question.formRules.body"
              @add-gap="addGap"
            >
              <template #actions="{ togglePreview, addGap }">
                <div class="d-flex flex-column">
                  <v-tooltip class="align-self-end" left dense color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn icon @click="question.changeBodyFormat()">
                          <v-icon small> mdi-format-letter-case-upper </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("ng.change_format") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="question.body.length === 0"
                          @click="togglePreview()"
                        >
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("resources.invitations.preview") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon @click="addGap()"> [_] </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("ng.add_gap") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="question.options.show_chars"
              :label="$t('ng.gap_option')"
              @change="updateOptions()"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="question.hint"
              :rules="question.formRules.hint"
              :label="$t('ng.question_hint')"
              counter="255"
              listen-to-input
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-expand-transition>
              <v-alert
                v-if="question.gapCount() !== question.answers.length"
                type="error"
                text
                dense
                colored-border
                border="left"
              >
                <div class="caption">
                  <span v-html="$t('ng.question_gap_hint')" />
                </div>
              </v-alert>
            </v-expand-transition>
            <v-list class="transparent">
              <v-list-item
                v-for="(answer, i) in question.answers"
                :key="i"
                class="px-0"
              >
                <v-list-item-content>
                  <InputTextfield
                    v-model="question.answers[i].body"
                    :label="`${$t('resources.questions.gap')} ${i + 1}`"
                    :rules="question.formRules.gap"
                    listen-to-input
                    :body-format="question.answers[i].body_format"
                    preview
                    counter="20"
                    :answer-index="i"
                    @change="setAnswer"
                  >
                    <template #actions="{ togglePreview }">
                      <div class="ml-2 d-flex">
                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                @click="question.changeAnswerBodyFormat(i)"
                              >
                                <v-icon small>
                                  mdi-format-letter-case-upper
                                </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("ng.change_format") }}</span>
                        </v-tooltip>
                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="question.answers[i].body.length < 1"
                                @click="togglePreview()"
                              >
                                <v-icon small> mdi-file-find </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("resources.invitations.preview") }}</span>
                        </v-tooltip>
                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="question.answers.length <= 1"
                                @click="question.removeAnswer(answer)"
                              >
                                <v-icon small color="error">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("general.delete") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </InputTextfield>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="px-0">
                <v-list-item-content class="d-flex justify-center">
                  <v-btn
                    small
                    depressed
                    :disabled="disabled"
                    color="primary"
                    @click="addGap()"
                  >
                    <v-icon small class="mr-2"> mdi-plus </v-icon>
                    <span>{{ $t("ng.add_gap") }}</span>
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";

export default {
  name: "QuestionGapAdd",
  props: {
    question: {
      type: [QuestionGap],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  watch: {
    valid() {
      this.checkValidation();
    },
    question: {
      handler: function () {
        this.checkValidation();
      },
      deep: true,
    },
  },
  methods: {
    setAnswer(input, format, correct, i) {
      //TODO: check eslint err
      // eslint-disable-next-line vue/no-mutating-props
      this.question.answers[i].body = input;
      // eslint-disable-next-line vue/no-mutating-props
      this.question.answers[i].body_format = format;
      //this.question.answers[i].is_correct = correct;
    },
    checkValidation() {
      this.$emit(
        "validation",
        this.question.gapCount() !== this.question.answers.length
          ? false
          : this.valid
      );
    },
    addGap() {
      this.question.addAnswer();
      // TODO: check eslint err
      // eslint-disable-next-line vue/no-mutating-props
      this.question.body += " [_] ";
    },
  },
};
</script>
