var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"open-on-hover":"","offset-y":"","nudge-bottom":"12","close-delay":"","max-width":"240"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","depressed":"","color":_vm.licences.ghostwriter ? 'primary' : 'grey lighten-2'}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#ffd700"}},[_vm._v(" mdi-circle-multiple ")]),_c('span',[_vm._v(_vm._s(_vm.$t("gw.xCredits", { number: _vm.licences && _vm.licences.ghostwriter ? _vm.licences.ghostwriter.credits_count.toLocaleString() : "-", })))])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"color":"primary","dark":"","max-width":"240"}},[(_vm.licences.ghostwriter && !_vm.licenceExpired)?_c('v-card-text',{staticClass:"white--text"},[_c('h5',[_vm._v(_vm._s(_vm.$t("gw.yourBalance")))]),_c('div',{staticClass:"font-weight-bold d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#ffd700"}},[_vm._v(" mdi-circle-multiple ")]),_c('span',[_vm._v(_vm._s(_vm.$t("gw.xCredits", { number: _vm.licences && _vm.licences.ghostwriter ? _vm.licences.ghostwriter.credits_count.toLocaleString() : "-", })))]),(
              _vm.licences &&
              _vm.licences.ghostwriter &&
              _vm.licences.ghostwriter.credits_count <= 0
            )?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-alert-circle")]):_vm._e()],1),(_vm.licences.ghostwriter.licence.can_overload)?_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v(_vm._s(_vm.$t("gw.licences.credit_pool")))]),_c('div',{staticClass:"font-weight-bold d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"#ffd700"}},[_vm._v(" mdi-bank ")]),_c('span',[_vm._v(_vm._s(_vm.$t("gw.xCredits", { number: _vm.licences && _vm.licences.ghostwriter.licence ? _vm.licences.ghostwriter.licence.credits_overload.toLocaleString() : "-", })))]),(
                _vm.licences &&
                _vm.licences.ghostwriter &&
                _vm.licences.ghostwriter.licence.credits_overload <= 0
              )?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-alert-circle")]):_vm._e()],1),_c('small',[_vm._v(_vm._s(_vm.$t("gw.licences.credit_pool_usage_hint_2")))])]):_vm._e(),_c('v-divider',{staticClass:"my-2"}),_c('small',[_vm._v(_vm._s(_vm.$t("gw.licenceValidUntil", { date: new Date( _vm.licences.ghostwriter.date_end ).toLocaleDateString(), })))])],1):_vm._e(),(!_vm.licences.ghostwriter || _vm.licenceExpired)?_c('v-card-text',{staticClass:"white--text"},[_c('h5',[_vm._v(_vm._s(_vm.$t("gw.missingLicence")))]),_c('p',[_vm._v(_vm._s(_vm.$t("gw.missingLicence_2")))]),_c('p',[_vm._v(_vm._s(_vm.$t("gw.missingLicence_3")))]),(_vm.licences.ghostwriter)?_c('v-divider',{staticClass:"my-4"}):_vm._e(),(_vm.licences.ghostwriter)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("gw.licenceExpiredOn", { date: new Date( _vm.licences.ghostwriter.date_end ).toLocaleDateString(), }))+" ")]):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }