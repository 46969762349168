var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"px-0"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('KpiStatsProgressCard',{attrs:{"title":_vm.$t('kpi.discovery'),"color":"grey lighten-2","highlight-color":"accent","icon":"mdi-progress-check","value":_vm.displayedStats ? _vm.displayedStats.completion[0] : 0,"delta":_vm.displayedStats
              ? _vm.displayedStats.completion[0] - _vm.displayedStats.completion[1]
              : 0,"loading":!_vm.displayedStats,"hoverable":""},on:{"click":function($event){return _vm.$emit('change-tab', 1)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('KpiStatsProgressCard',{attrs:{"title":_vm.$t('kpi.competency'),"color":"grey lighten-2","highlight-color":"accent","icon":"mdi-progress-star","value":_vm.displayedStats ? _vm.displayedStats.competency[0] : 0,"delta":_vm.displayedStats
              ? _vm.displayedStats.competency[0] - _vm.displayedStats.competency[1]
              : 0,"loading":!_vm.displayedStats,"hoverable":""},on:{"click":function($event){return _vm.$emit('change-tab', 1)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('KpiStatsProgressCard',{attrs:{"title":_vm.$t('kpi.retention'),"color":"grey lighten-2","highlight-color":"accent","icon":"mdi-progress-star-four-points","value":_vm.displayedStats ? _vm.displayedStats.retention[0] : 0,"delta":_vm.displayedStats
              ? _vm.displayedStats.retention[0] - _vm.displayedStats.retention[1]
              : 0,"loading":!_vm.displayedStats,"hoverable":""},on:{"click":function($event){return _vm.$emit('change-tab', 1)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('KpiStatsProgressCard',{attrs:{"title":_vm.$t('kpi.speed'),"color":"grey lighten-2","highlight-color":"info","icon":"mdi-play-speed","value":_vm.displayedStats ? _vm.displayedStats.currentSpeed[0] : 0,"delta":_vm.displayedStats
              ? _vm.displayedStats.currentSpeed[0] -
                _vm.displayedStats.currentSpeed[1]
              : 0,"loading":!_vm.displayedStats,"hoverable":""},on:{"click":function($event){return _vm.$emit('change-tab', 2)}}})],1),(_vm.role === 'superadmin')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"info"},on:{"click":function($event){_vm.showKpiStats = !_vm.showKpiStats}}},[_vm._v(" superadmin toggle data (aktuell: "+_vm._s(_vm.showKpiStats ? "current" : "prop")+") ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }