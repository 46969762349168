var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ViewHeader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.$t('certificate.singular'),"icon":"mdi-certificate"},scopedSlots:_vm._u([{key:"titleButton",fn:function(){return [_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"grey lighten-2","exact":"","to":{ name: 'JourneyCertificates' }}},[_vm._v(" "+_vm._s(_vm.$t("general.toOverview"))+" ")])]},proxy:true}])}),(_vm.loading)?_c('ViewLoader'):_vm._e(),(!_vm.loading && !!_vm.certificate)?_c('section',[_c('SectionHeader',{attrs:{"title":_vm.$t('resources.journeys.sectionHeaders.generalInfo.title'),"subtitle":_vm.$t('resources.journeys.sectionHeaders.generalInfo.subtitle')},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","depressed":"","small":"","disabled":!_vm.canBeActivated,"loading":_vm.sub_loading.download},on:{"click":function($event){return _vm.showPreview()}}},[_vm._v(" "+_vm._s(_vm.$t("certificate.preview"))+" ")]),_c('CertificateActivator',{attrs:{"certificate":_vm.certificate},scopedSlots:_vm._u([{key:"button",fn:function({ openDialog }){return [_c('v-tooltip',{attrs:{"disabled":!_vm.certificate.is_active,"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"grey lighten-3","elevation":"0","small":"","loading":_vm.sub_loading.activating,"disabled":_vm.certificate.is_locked ||
                        !_vm.canBeActivated ||
                        _vm.loading ||
                        _vm.certificate.is_active},on:{"click":function($event){return openDialog()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.certificate.is_active ? 'success' : 'error'}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]),_c('span',{staticClass:"mx-2 black--text"},[_vm._v(_vm._s(_vm.certificate.is_active ? _vm.$t("certificate.activation.activated") : _vm.$t("certificate.activation.deactivated")))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("certificate.activation.cannot_deactivate")))])])]}}],null,false,1314839277)})],1)]},proxy:true}],null,false,3752747678)}),_c('v-container',[_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.certificate.is_locked),expression:"certificate.is_locked"}],attrs:{"type":"info","color":"info","dense":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.locked_hint")))])])],1),_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canBeActivated),expression:"!canBeActivated"}],attrs:{"type":"error","color":"error","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.missing_prop_hint")))])])],1),_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasChanged.general ||
            _vm.hasChanged.text ||
            _vm.hasChanged.signature ||
            _vm.hasChanged.background
          ),expression:"\n            hasChanged.general ||\n            hasChanged.text ||\n            hasChanged.signature ||\n            hasChanged.background\n          "}],attrs:{"type":"error","color":"error","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.unsaved_changes")))])])],1),_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasChanged.text || _vm.hasChanged.signature || _vm.hasChanged.background
          ),expression:"\n            hasChanged.text || hasChanged.signature || hasChanged.background\n          "}],attrs:{"type":"info","color":"secondary","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.save_first"))+".")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","order":_vm.$vuetify.breakpoint.smAndUp ? 'first' : 'last'}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',[_c('InputTextfield',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.name'),"hint":_vm.$t('certificate.form.hints.name'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.name,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateName},scopedSlots:_vm._u([{key:"actions",fn:function({
                    editing,
                    startEditing,
                    cancelEditing,
                    saveInput,
                    inputChanged,
                  }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing || _vm.loading || _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"name","certificate":_vm.certificate,"disabled":!_vm.input.name || _vm.loading || _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.name ||
                                  _vm.input.name.length === 0 ||
                                  _vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,2260805628),model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})],1)],1)],1),_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","sm":"6","order":_vm.$vuetify.breakpoint.smAndUp ? 'last' : 'first'}},[_c('LanguageChooserInline',{staticClass:"pb-0",attrs:{"colored":"","dense":"","label":_vm.$t('certificate.form.labels.language'),"hint":_vm.$t('certificate.form.hints.language'),"initial":_vm.language,"disabled":_vm.hasChanged.general ||
              _vm.hasChanged.text ||
              _vm.hasChanged.signature ||
              _vm.hasChanged.background},on:{"change":_vm.changeLanguage}}),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.general_info")))])],1)],1)],1)],1):_vm._e(),(!_vm.loading)?_c('v-divider',{staticClass:"my-6"}):_vm._e(),(!_vm.loading && !!_vm.certificate)?_c('section',{staticClass:"mt-4"},[_c('SectionHeader',{attrs:{"title":_vm.$t('certificate.text.title'),"subtitle":_vm.$t('certificate.text.subtitle')}}),_c('v-container',[_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasChanged.general ||
            _vm.hasChanged.signature ||
            _vm.hasChanged.background
          ),expression:"\n            hasChanged.general ||\n            hasChanged.signature ||\n            hasChanged.background\n          "}],attrs:{"type":"info","color":"secondary","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.save_first"))+".")])])],1),_c('v-card',{staticClass:"pt-4",attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3","md":"4","offset-md":"0"}},[_c('v-img',{attrs:{"src":require("@/assets/img/misc/cert_prev.png"),"max-height":"400","contain":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-1 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextfield',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.title'),"hint":_vm.$t('certificate.form.hints.title'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.title,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateTitle},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"title","certificate":_vm.certificate,"disabled":!_vm.input.title ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.title ||
                                      _vm.input.title.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,1474872540),model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-2 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextfield',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.subtitle'),"hint":_vm.$t('certificate.form.hints.subtitle'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.subtitle,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateSubtitle},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"subtitle","certificate":_vm.certificate,"disabled":!_vm.input.subtitle ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.subtitle ||
                                      _vm.input.subtitle.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,2354302332),model:{value:(_vm.input.subtitle),callback:function ($$v) {_vm.$set(_vm.input, "subtitle", $$v)},expression:"input.subtitle"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-3 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextarea',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.intro'),"hint":_vm.$t('certificate.form.hints.intro'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.intro,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateIntro},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"intro","certificate":_vm.certificate,"disabled":!_vm.input.intro ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.intro ||
                                      _vm.input.intro.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,1099960156),model:{value:(_vm.input.intro),callback:function ($$v) {_vm.$set(_vm.input, "intro", $$v)},expression:"input.intro"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-4 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextarea',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.outro'),"hint":_vm.$t('certificate.form.hints.outro'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.outro,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateOutro},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"outro","certificate":_vm.certificate,"disabled":!_vm.input.outro ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.outro ||
                                      _vm.input.outro.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,1186212188),model:{value:(_vm.input.outro),callback:function ($$v) {_vm.$set(_vm.input, "outro", $$v)},expression:"input.outro"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-5 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextfield',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.sign'),"hint":_vm.$t('certificate.form.hints.sign'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.sign,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateSign},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"sign","certificate":_vm.certificate,"disabled":!_vm.input.sign ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.sign ||
                                      _vm.input.sign.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,3598470844),model:{value:(_vm.input.sign),callback:function ($$v) {_vm.$set(_vm.input, "sign", $$v)},expression:"input.sign"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"error lighten-1","size":"24"}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" mdi-numeric-6 ")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('InputTextarea',{attrs:{"outlined":"","dense":"","label":_vm.$t('certificate.form.labels.appendix'),"hint":_vm.$t('certificate.form.hints.appendix'),"background-color":"white","counter":"64","disabled":"","rules":_vm.rules.appendix,"translatable":""},on:{"is-editing":_vm.setEditing,"save":_vm.updateAppendix},scopedSlots:_vm._u([{key:"actions",fn:function({
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                      }){return [(!editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isEditing ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"click":function($event){return startEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('CertificateTranslationDialog',{attrs:{"prop-name":"appendix","certificate":_vm.certificate,"disabled":!_vm.input.appendix ||
                                  _vm.loading ||
                                  _vm.certificate.is_locked},on:{"close":function($event){return _vm.fetchData()}},scopedSlots:_vm._u([{key:"button",fn:function({ onClick }){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.input.appendix ||
                                      _vm.input.appendix.length === 0 ||
                                      _vm.isEditing ||
                                      _vm.loading ||
                                      _vm.certificate.is_locked},on:{"click":onClick}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-translate ")])],1)]}}],null,true)})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.translations")))])])],1):_vm._e(),(editing)?_c('div',{staticClass:"d-flex ml-2"},[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return cancelEditing()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.cancel")))])]),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!inputChanged},on:{"click":function($event){return saveInput()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save")))])])],1):_vm._e()]}}],null,false,1944103868),model:{value:(_vm.input.appendix),callback:function ($$v) {_vm.$set(_vm.input, "appendix", $$v)},expression:"input.appendix"}})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),(!_vm.loading && !!_vm.certificate)?_c('v-divider',{staticClass:"my-6"}):_vm._e(),(!_vm.loading && !!_vm.certificate)?_c('section',[_c('SectionHeader',{attrs:{"title":_vm.$t('certificate.form.labels.color_text'),"subtitle":_vm.$t('certificate.form.hints.color_text_hint')}}),_c('v-container',[_c('v-card',{attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ColorPicker',{attrs:{"label":_vm.$t('certificate.form.labels.color_text'),"hint":_vm.$t('certificate.form.hints.color_text'),"disabled":_vm.hasChanged.general ||
                  _vm.hasChanged.signature ||
                  _vm.hasChanged.background ||
                  _vm.certificate.is_locked},model:{value:(_vm.input.color_text),callback:function ($$v) {_vm.$set(_vm.input, "color_text", $$v)},expression:"input.color_text"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-spacer'),_c('ColorPicker',{attrs:{"label":_vm.$t('certificate.form.labels.color_accent'),"hint":_vm.$t('certificate.form.hints.color_accent'),"disabled":_vm.hasChanged.general ||
                  _vm.hasChanged.signature ||
                  _vm.hasChanged.background ||
                  _vm.certificate.is_locked},model:{value:(_vm.input.color_accent),callback:function ($$v) {_vm.$set(_vm.input, "color_accent", $$v)},expression:"input.color_accent"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"error","disabled":_vm.loading || !_vm.hasChanged.text},on:{"click":function($event){return _vm.setInput()}}},[_vm._v(" "+_vm._s(_vm.$t("general.reset"))+" ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"primary","text":"","loading":_vm.sub_loading.updating,"disabled":_vm.loading || !_vm.hasChanged.text},on:{"click":function($event){return _vm.updateCertificate()}}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1):_vm._e(),(!_vm.loading && !!_vm.certificate)?_c('v-divider',{staticClass:"my-6"}):_vm._e(),(!_vm.loading)?_c('section',{staticClass:"my-4"},[_c('SectionHeader',{attrs:{"title":_vm.$t('certificate.media.title'),"subtitle":_vm.$t('certificate.media.subtitle')}}),_c('v-container',[_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasChanged.general || _vm.hasChanged.text || _vm.hasChanged.signature
          ),expression:"\n            hasChanged.general || hasChanged.text || hasChanged.signature\n          "}],attrs:{"type":"info","color":"secondary","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.save_first"))+".")])])],1),_c('v-card',{attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","order-sm":"last"}},[_c('div',[_c('SectionHeader',{staticClass:"px-0",attrs:{"title":_vm.$t('certificate.background.title')}}),_c('span',[_vm._v(_vm._s(_vm.$t("certificate.background.hint")))])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ImageUpload',{attrs:{"image-hash":_vm.input.img_bg_hash,"disabled":_vm.hasChanged.general ||
                  _vm.hasChanged.text ||
                  _vm.hasChanged.signature ||
                  _vm.certificate.is_locked,"card-color":"white"},on:{"image-changed":_vm.backgroundChange}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"error","disabled":_vm.loading || !_vm.hasChanged.background},on:{"click":function($event){return _vm.setInput()}}},[_vm._v(" "+_vm._s(_vm.$t("general.reset"))+" ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"primary","text":"","loading":_vm.sub_loading.updating,"disabled":_vm.loading || !_vm.hasChanged.background || !_vm.input.img_bg},on:{"click":function($event){return _vm.updateCertificate()}}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1),_c('v-container',[_c('v-expand-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.hasChanged.general || _vm.hasChanged.text || _vm.hasChanged.background
          ),expression:"\n            hasChanged.general || hasChanged.text || hasChanged.background\n          "}],attrs:{"type":"info","color":"secondary","dense":"","text":""}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("certificate.save_first"))+".")])])],1),_c('v-card',{attrs:{"flat":"","color":"grey lighten-3"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('SectionHeader',{staticClass:"px-0",attrs:{"title":_vm.$t('certificate.signature.image')}}),_c('span',[_vm._v(_vm._s(_vm.$t("certificate.signature.hint")))])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ImageUpload',{attrs:{"image-hash":_vm.input.img_sign_hash,"disabled":_vm.hasChanged.general ||
                  _vm.hasChanged.text ||
                  _vm.hasChanged.background ||
                  _vm.certificate.is_locked,"card-color":"white"},on:{"image-changed":_vm.signatureChange}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":"","color":"error","disabled":_vm.loading || !_vm.hasChanged.signature},on:{"click":function($event){return _vm.setInput()}}},[_vm._v(" "+_vm._s(_vm.$t("general.reset"))+" ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"primary","text":"","loading":_vm.sub_loading.updating,"disabled":_vm.loading || !_vm.hasChanged.signature || !_vm.input.img_sign},on:{"click":function($event){return _vm.updateCertificate()}}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }