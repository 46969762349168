<template>
  <section class="secondary">
    <v-container>
      <v-carousel
        v-model="tutorial"
        hide-delimiters
        :height="$vuetify.breakpoint.smAndDown ? 'auto' : 280"
      >
        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col cols="10" offset="1" class="grey--text text--lighten-3">
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorialImport.page1.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorialImport.page1.p1") }}
              </p>
              <p class="mt-4">
                {{ $t("gw.tutorialImport.page1.p2") }}
              </p>
              <div
                class="mt-3 mb-2 d-flex align-center"
                :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
              >
                <v-btn
                  depressed
                  color="primary"
                  :block="$vuetify.breakpoint.smAndDown"
                  @click="tutorial++"
                >
                  {{ $t("gw.tutorialImport.page1.btn") }}
                </v-btn>
                <v-btn text small color="white" @click="disableTutorial()">
                  {{ $t("gw.tutorial.page1.btn3") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col
              cols="10"
              offset="1"
              md="5"
              class="grey--text text--lighten-3"
            >
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorialImport.page2.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorialImport.page2.p1") }}
              </p>
              <p>
                {{ $t("gw.tutorialImport.page2.p2") }}
              </p>
            </v-col>
            <v-col
              cols="10"
              md="4"
              offset="1"
              class="grey--text text--lighten-3"
              :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
            >
              <p class="mt-6">
                {{ $t("gw.tutorialImport.page3.p1") }}
              </p>
              <p>{{ $t("gw.tutorialImport.page3.p2") }}</p>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col
              cols="10"
              offset="1"
              md="5"
              class="grey--text text--lighten-3"
            >
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorialImport.page4.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorialImport.page4.p1") }}
              </p>
            </v-col>
            <v-col
              cols="10"
              md="4"
              offset="1"
              class="grey--text text--lighten-3"
              :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
            >
              <p class="mt-6">
                {{ $t("gw.tutorialImport.page4.p2") }}
              </p>
            </v-col>
          </v-row>
        </v-carousel-item>

        <v-carousel-item
          class="pa-8"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          <v-row>
            <v-col
              cols="10"
              offset="1"
              md="5"
              class="grey--text text--lighten-3"
            >
              <h2 class="subtitle-2 font-weight-bold pb-1">
                {{ $t("gw.tutorialImport.page5.title") }}
              </h2>
              <p>
                {{ $t("gw.tutorialImport.page5.p1") }}
              </p>
              <p>
                {{ $t("gw.tutorialImport.page5.p2") }}
              </p>
            </v-col>
            <v-col
              cols="10"
              md="4"
              offset="1"
              class="grey--text text--lighten-3"
              :class="{ 'text-right': $vuetify.breakpoint.mdAndUp }"
            >
              <p class="mt-6">
                {{ $t("gw.tutorialImport.page6.p1") }}
              </p>
              <v-btn depressed small color="white" @click="disableTutorial()">
                {{ $t("gw.tutorialImport.page6.btn") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-container>
    <v-divider />
  </section>
</template>

<script>
export default {
  name: "GwImportTutorial",
  data() {
    return {
      tutorial: 0,
    };
  },
  methods: {
    disableTutorial() {
      localStorage.setItem("gw_disable_tut_import", true);
      this.$emit("close");
    },
  },
};
</script>
