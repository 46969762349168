export const get_session = (state) => state.session;
export const get_sessions = (state) => state.sessions;
export const get_bulk_session = (state) => state.bulk_session;
export const get_bulk_sessions = (state) => state.bulk_sessions;
export const get_licences = (state) => state.licences;
export const get_transcriptions = (state) => state.transcriptions;
export const get_transcription = (state) => state.transcription;
export const get_credit_pool = (state) => state.credit_pool;

export const get_sources = (state) => state.sources;
export const get_sources_generating = (state) => state.sources_generating;
export const get_source = (state) => state.source;
export const get_job = (state) => state.source_job;
export const get_source_filter = (state) => state.source_filter; // TODO: rename

export const get_gw_journeys = (state) => state.gw_journeys;
export const get_gw_journey = (state) => state.gw_journey;
