<template>
  <v-container :class="{ 'px-0': noPadding, 'py-0': noPaddingY }">
    <v-row class="align-center">
      <v-col cols="12" class="d-flex justify-space-between align-start">
        <div>
          <h3 :class="`font-weight-light ${titleColor}--text py-0`">
            {{ title }}
          </h3>
          <p
            v-if="subtitle"
            class="caption mb-0"
            :class="`${subtitleColor}--text`"
          >
            {{ subtitle }}
          </p>
        </div>
        <slot name="action" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    titleColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    subtitleColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPaddingY: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style></style>
