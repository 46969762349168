<template>
  <div>
    <v-card
      :elevation="elevation"
      tile
      :color="color"
      :disabled="disabled"
      :ripple="link ? true : false"
      :to="link ? link : null"
      :style="
        !link && !clickType && !href
          ? 'cursor: default !important'
          : 'cursor: pointer !important'
      "
      style="transition: 0.4s"
      :href="href"
      :target="hrefTarget"
      @click="click"
    >
      <v-list-item class="my-0 py-0">
        <v-list-item-avatar tile class="my-0">
          <country-flag
            v-if="languageIcon"
            :country="languageIcon.slice(-2)"
            :class="$attrs['hide-label'] != '' ? 'mr-1' : ''"
            size="small"
          />
          <v-icon
            v-if="!languageIcon && !numberIcon && !imageIcon"
            :color="iconColor"
          >
            {{ icon }}
          </v-icon>
          <span v-if="numberIcon" :class="`overline ${textColor}--text`">{{
            numberIcon
          }}</span>
          <v-avatar v-if="imageIcon" size="24">
            <v-img :src="imageIcon" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content class="my-0 py-0">
          <v-list-item-title
            :class="`caption font-weight-bold ${textColor}--text`"
          >
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle :class="`caption ${textColor}--text`">
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "StatisticsCardSmall",
  components: {
    CountryFlag,
  },
  props: {
    title: {
      type: [Number, String],
      required: true,
    },
    subtitle: {
      type: [Number, String],
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: "white",
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-help",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    iconColor: {
      type: String,
      required: false,
      default: "white",
    },
    link: {
      type: Object,
      required: false,
      default: null,
    },
    elevation: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickType: {
      type: String, // approvals || reports || dialog -> TODO: needed? rename clickable?
      required: false,
      default: null,
    },
    languageIcon: {
      type: String,
      required: false,
    },
    numberIcon: {
      type: [String, Number],
      required: false,
    },
    imageIcon: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    hrefTarget: {
      type: String,
      required: false,
    },
  },
  methods: {
    click() {
      this.clickType ? this.$emit("clicked", this.clickType) : null;
    },
  },
};
</script>
