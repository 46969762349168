<template>
  <div>
    <SectionHeader
      :title="`${$t('gw.licences.assigned_licences')} (${userLicences.length})`"
      class="mb-2"
    />

    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            background-color="white"
            outlined
            hide-details
            :placeholder="$t('gw.licences.search_user_licence')"
            dense
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :items="filteredUserLicences"
      :items-per-page="10"
      class="transparent"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-if="items.length === 0 || !items">
            <td>{{ $t("gw.licences.no_user_licences") }}</td>
          </tr>
          <tr v-for="user_licence in items" :key="`user-${user_licence.id}`">
            <td>
              <v-list-item dense three-line class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${user_licence.user.first_name} ${user_licence.user.last_name}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user_licence.user.email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ user_licence.user.user_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td>
              <v-list-item dense three-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                    user_licence.credits_count.toLocaleString()
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("gw.licences.credits_count") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t("gw.licences.date_end") }}:
                    {{ new Date(user_licence.date_end).toLocaleDateString() }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </td>
            <td>
              <div class="d-flex">
                <GhostwriterUserLicenceDialog
                  v-if="['superadmin'].includes(role)"
                  :user_licence="user_licence"
                >
                  <template #button="{ openDialog }">
                    <v-btn
                      icon
                      small
                      :disabled="!licence.is_superadmin"
                      @click.stop="openDialog()"
                    >
                      <v-icon small>mdi-pencil-circle</v-icon>
                    </v-btn>
                  </template>
                </GhostwriterUserLicenceDialog>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import GhostwriterUserLicenceDialog from "@/components/administration/ghostwriter/GhostwriterUserLicenceDialog";
import SectionHeader from "@/components/_layout/SectionHeader";
import { GwLicence } from "@/models/ghostwriter/GwLicence";

export default {
  name: "GwUserLicenceTable",
  components: {
    GhostwriterUserLicenceDialog,
    SectionHeader,
  },
  props: {
    licence: {
      type: GwLicence,
      required: true,
    },
    userLicences: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredUserLicences() {
      var user_licences = [...this.userLicences];
      if (this.search) {
        var query = this.search.toLowerCase();
        user_licences = user_licences.filter((l) => {
          return (
            l.user.email.toLowerCase().includes(query) ||
            l.user.user_name.toLowerCase().includes(query) ||
            l.user.first_name.toLowerCase().includes(query) ||
            l.user.last_name.toLowerCase().includes(query)
          );
        });
      }
      return user_licences;
    },
  },
};
</script>

<style></style>
