<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-form v-model="valid">
        <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
          <v-card-title class="overline secondary--text">
            {{ $t("gw.licences.options") }}
          </v-card-title>
          <v-card-text>
            <v-row v-if="!user.licence" dense>
              <v-col cols="12">
                <div
                  class="mb-2"
                  v-html="
                    $t('gw.licences.no_user_licence', {
                      user: `${user.first_name} ${user.last_name} (${user.user_name})`,
                    })
                  "
                />
                <v-autocomplete
                  v-model="selectedLicence"
                  label="Lizenz wählen"
                  :items="visibleLicences"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  clearable
                  hide-details
                  background-color="white"
                >
                  <template #item="{ item, on, attrs }">
                    <v-list-item two-line v-on="on" v-bind="attrs">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          `${$t("gw.xCredits", {
                            number: item.credits_count,
                          })}`
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip left color="primary">
                          <template #activator="{ on }">
                            <v-icon
                              small
                              :color="
                                item.can_overload
                                  ? 'secondary'
                                  : 'grey lighten-3'
                              "
                              v-on="on"
                              >{{
                                item.can_overload ? "mdi-bank" : "mdi-bank-off"
                              }}</v-icon
                            >
                          </template>
                          <span>{{
                            item.can_overload
                              ? $t("gw.licences.credits_overload_access")
                              : $t("gw.licences.credits_overload_no_access")
                          }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  depressed
                  color="primary"
                  :loading="loading"
                  :disabled="!selectedLicence || loading"
                  @click="addUserLicence()"
                  >{{ $t("gw.licences.add_user_licence") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="user.licence" dense>
              <v-col cols="12" sm="6">
                <StatisticsCardSmall
                  :title="user.licence.licence.name"
                  :subtitle="$t('resources.categories.name')"
                  icon="mdi-file-document"
                  color="grey lighten-2"
                  text-color="secondary"
                  icon-color="secondary"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <StatisticsCardSmall
                  :title="new Date(user.licence.date_end).toLocaleDateString()"
                  :subtitle="$t('gw.licences.date_end')"
                  icon="mdi-calendar"
                  color="grey lighten-2"
                  text-color="secondary"
                  icon-color="secondary"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <StatisticsCardSmall
                  :title="user.licence.licence.credits_count"
                  :subtitle="$t('gw.licences.credits')"
                  icon="mdi-circle-multiple"
                  color="grey lighten-2"
                  text-color="secondary"
                  icon-color="secondary"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <StatisticsCardSmall
                  :title="
                    user.licence.licence.can_overload
                      ? $t('general.yes')
                      : $t('general.no')
                  "
                  :subtitle="$t('gw.licences.can_overload')"
                  :icon="
                    user.licence.licence.can_overload
                      ? 'mdi-bank'
                      : 'mdi-bank-off'
                  "
                  color="grey lighten-2"
                  text-color="secondary"
                  icon-color="secondary"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text small color="secondary" @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-spacer />
            <v-btn
              text
              small
              color="secondary"
              :to="
                role === 'superadmin'
                  ? { name: 'GhostwriterLicenceAdministration' }
                  : { name: 'GwManagement' }
              "
            >
              {{ $t("gw.licences.licence_administration_goto") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";

export default {
  name: "GwUserLicenceDialog",
  components: {
    StatisticsCardSmall,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      loading: false,
      selectedLicence: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      licences: "get_licences",
    }),
    visibleLicences() {
      if (!this.licences) return [];
      var licences = [...this.licences];
      return licences.filter((l) => !l.is_superadmin && !l.is_expired);
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_licences"]),
    ...mapActions("administration", ["add_gw_user_licence"]),
    async fetchLicences() {
      this.loading = true;
      await this.fetch_licences();
      this.loading = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedLicence = null;
    },
    openDialog() {
      this.showDialog = true;
      this.fetchLicences();
    },
    async addUserLicence() {
      this.loading = true;
      var payload = {
        licence_id: this.selectedLicence,
        user_id: this.user.id,
        reload: true,
      };
      var res = await this.add_gw_user_licence({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.add_user_licence_success"),
        });
        this.new_user_id = null;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.add_user_licence_error"),
        });
      }
    },
  },
};
</script>
