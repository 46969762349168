<template>
  <span ref="mathJaxEl" />
</template>

<script>
export default {
  name: "MathJaxRenderer",
  props: {
    formula: {
      type: String,
    },
    safe: {
      type: Boolean,
      default: true,
    },
    changeGaps: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewImages: {
      type: Array,
      required: false,
      default: () => [],
    },
    /* options: {
			type: Object,
			default: function () {
				return { enableMenu: false }
			}
		} */
  },
  data() {
    return {};
  },
  watch: {
    formula() {
      this.renderMathJax();
    },
  },
  mounted() {
    this.renderMathJax();
  },
  methods: {
    renderContent() {
      if (this.safe) {
        this.$refs.mathJaxEl.textContent = this.formula;
      } else {
        let formula = "";
        if (this.changeGaps) {
          formula = this.checkForGaps(this.formula);
        } else if (this.previewImages) {
          formula = this.checkForImages(this.formula);
        } else formula = this.formula;
        this.$refs.mathJaxEl.innerHTML = formula;
      }
    },
    renderMathJax() {
      this.renderContent();

      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [["[$]", "[$]"]],
            displayMath: [["[$$]", "[$$]"]],
            processEscapes: true,
            processEnvironments: true,
          },
          // Center justify equations in code and markdown cells. Elsewhere
          // we use CSS to left justify single line equations in code cells.
          displayAlign: "left",
          "HTML-CSS": {
            styles: { ".MathJax_Display": { margin: 0 } },
            linebreaks: { automatic: true },
          },
          //...this.options
          options: {
            enableMenu: false,
          },
        });
        window.MathJax.Hub.Queue([
          "Typeset",
          window.MathJax.Hub,
          this.$refs.mathJaxEl,
        ]);
      }
    },
    checkForGaps(formula) {
      let splittedFormula = formula.split("[_]");
      let newString = "";
      for (let i = 0; i < splittedFormula.length - 1; i++) {
        newString += ` ${
          splittedFormula[i]
        } <span class="primary py-1 px-2 white--text">Lücke ${i + 1}</span>`;
      }
      newString += splittedFormula[splittedFormula.length - 1];
      return newString;
    },
    checkForImages(formula) {
      let splittedFormula = formula.split("[_IMG_]");
      let newString = "";
      for (let i = 0; i < splittedFormula.length - 1; i++) {
        newString += ` ${splittedFormula[i]} <img style="max-width: 100%" src="${this.media_server_url}/i/${this.previewImages[i]}"/>`;
      }
      newString += splittedFormula[splittedFormula.length - 1];
      return newString;
    },
  },
};
</script>

<style></style>
