<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.generalInfo.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <template v-if="journey" #action>
          <LanguageChooserInline
            hidedetails
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>

      <v-expand-transition>
        <v-container v-if="journey && substructures">
          <v-row>
            <v-col cols="12" md="4">
              <v-row>
                <v-col cols="12">
                  <v-card outlined>
                    <v-img :src="journey.getImage()" max-height="240" cover>
                      <v-overlay v-if="!journey.image" absolute>
                        <div class="d-flex flex-column">
                          <span>{{ $t("ng.journey.placeholder") }}</span>
                          <v-btn
                            small
                            outlined
                            class="mt-4"
                            :to="{ name: 'JourneySettings' }"
                            >{{ $t("ng.change_image") }}</v-btn
                          >
                        </div>
                      </v-overlay>
                    </v-img>
                    <v-card-title class="secondary--text">{{
                      journey.name
                    }}</v-card-title>
                    <v-card-subtitle>{{ journey.description }}</v-card-subtitle>
                    <v-card-text>
                      <v-row dense class="mt-4">
                        <v-col cols="12" sm="6">
                          <h5 class="secondary--text">
                            {{ $t("resources.journeys.propNames.start_at") }}
                          </h5>
                          <p>{{ startDate ? startDate : "-" }}</p>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <h5 class="secondary--text">
                            {{ $t("resources.journeys.propNames.end_at") }}
                          </h5>
                          <p>{{ endDate ? endDate : "-" }}</p>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <h5 class="secondary--text">
                            {{ $t("resources.journeys.propNames.coins") }}
                          </h5>
                          <p>
                            {{
                              journey.coins.toLocaleString(`${$i18n.locale}`)
                            }}
                          </p>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <h5 class="secondary--text">
                            {{ $t("resources.journeys.propNames.attempts") }}
                          </h5>
                          <p>{{ journey.attempts }}</p>
                        </v-col>
                      </v-row>
                      <!-- <v-row dense>
                  <v-col cols="12">
                    <h5 class="secondary--text">
                      {{ $t("resources.substructures.plural") }}
                    </h5>
                    <div>
                      <span
                        v-for="(substructure, index) in journey.substructures"
                        :key="index"
                      >
                        <v-chip
                          v-if="index < 3"
                          small
                          color="primary"
                          class="mr-2 mt-1"
                        >
                          {{ getSubstructureById(substructure).structure.name }}
                          / {{ getSubstructureById(substructure).name }}
                        </v-chip>
                        <v-chip
                          v-if="index == 3"
                          small
                          color="primary"
                          class="mr-2 mt-1"
                          :to="{
                            name: 'JourneyStructures',
                            params: { id: journey.id },
                          }"
                          >+ {{ journey.substructures.length - 3 }}
                          {{ $t("general.moreIndicator") }}</v-chip
                        >
                      </span>
                      <small v-if="journey.substructures.length == 0">{{
                        $t("resources.journeys.noStructuresYet")
                      }}</small>
                    </div>
                  </v-col>
                </v-row> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        small
                        color="primary"
                        text
                        :to="{ name: 'JourneySettings' }"
                        >{{ $t("general.edit") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-title class="secondary--text">{{
                      $t("resources.substructures.plural")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("ng.journey.substructure_subtitle")
                    }}</v-card-subtitle>
                    <v-card-text>
                      <v-chip-group column>
                        <span
                          v-for="(substructure, index) in journey.substructures"
                          :key="index"
                        >
                          <v-chip
                            v-if="index < 3"
                            color="primary"
                            class="mr-2 mt-1"
                          >
                            {{
                              getSubstructureById(substructure).structure.name
                            }}
                            / {{ getSubstructureById(substructure).name }}
                          </v-chip>
                          <v-chip
                            v-if="index == 3"
                            small
                            color="primary"
                            class="mr-2 mt-1"
                            :to="{
                              name: 'JourneyStructures',
                              params: { id: journey.id },
                            }"
                            >+ {{ journey.substructures.length - 3 }}
                            {{ $t("general.moreIndicator") }}</v-chip
                          >
                        </span>
                      </v-chip-group>
                      <v-alert
                        v-if="journey.substructures.length == 0"
                        dense
                        type="info"
                        text
                        >{{
                          $t("resources.journeys.noStructuresYet")
                        }}.</v-alert
                      >
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        small
                        text
                        color="primary"
                        :to="{ name: 'JourneyStructures' }"
                        >{{ $t("general.edit") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12">
                  <JourneyStatusChanger :journey="journey" />
                </v-col>
                <v-col cols="12">
                  <v-card outlined>
                    <v-card-title class="secondary--text">{{
                      $t("resources.journeys.bundle.plural")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("ng.journey.bundle_subtitle")
                    }}</v-card-subtitle>
                    <v-card-text>
                      <v-alert
                        v-if="journey.isSkippingLevels"
                        type="error"
                        color="error"
                      >
                        {{ $t("ng.journey.level_error_hint") }}
                      </v-alert>
                      <v-list>
                        <v-list-item
                          v-if="
                            !journey.bundles || journey.bundles.length === 0
                          "
                          class="grey lighten-3 mb-2 rounded"
                          style="
                            border: 1px solid rgba(0, 0, 0, 0.12) !important;
                          "
                          >{{ $t("ng.journey.no_content_yet") }}</v-list-item
                        >
                        <v-list-item
                          v-for="b in sortedBundles"
                          :key="`bundle-${b.id}`"
                          class="grey lighten-3 mb-2 rounded"
                          style="
                            border: 1px solid rgba(0, 0, 0, 0.12) !important;
                          "
                        >
                          <v-list-item-icon>
                            <v-avatar color="grey lighten-2" size="24" rounded>
                              <span class="caption">{{ b.level }}</span>
                            </v-avatar>
                          </v-list-item-icon>

                          <v-list-item-avatar rounded class="mr-4">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-img
                                  :src="b.getImage()"
                                  v-on="on"
                                  v-bind="attrs"
                                />
                              </template>
                              <div>
                                <h3 class="mb-2">{{ b.name }}</h3>
                                <p>
                                  {{
                                    $t(
                                      "resources.journeys.propNames.description"
                                    )
                                  }}:
                                  <br />
                                  {{ b.description }}
                                </p>
                              </div>
                            </v-tooltip>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ b.name }}</v-list-item-title>
                          </v-list-item-content>
                          <div class="mr-4">
                            <v-tooltip left>
                              <template #activator="{ on, attrs }">
                                <v-chip
                                  label
                                  small
                                  outlined
                                  class="justify-center"
                                  style="min-width: 90px"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon left small color="secondary"
                                    >mdi-school</v-icon
                                  >
                                  <span>{{ getBundleNuggetCount(b) }}</span>
                                </v-chip>
                              </template>
                              <span>{{
                                $t("ng.journey.bundle_nugget_count", {
                                  count: getBundleNuggetCount(b),
                                })
                              }}</span>
                            </v-tooltip>
                            <v-tooltip left>
                              <template #activator="{ on, attrs }">
                                <v-chip
                                  label
                                  small
                                  outlined
                                  class="justify-center ml-2"
                                  style="min-width: 90px"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon left small color="secondary"
                                    >mdi-lightbulb</v-icon
                                  >
                                  <span
                                    >{{ getBundleQuizCount(b) }} ({{
                                      b.quiz_criteria
                                    }}%)</span
                                  >
                                </v-chip>
                              </template>
                              <span
                                >{{
                                  $t("ng.journey.bundle_quiz_count", {
                                    count: getBundleQuizCount(b),
                                    criteria: b.quiz_criteria,
                                  })
                                }}
                              </span>
                            </v-tooltip>
                          </div>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        text
                        small
                        color="primary"
                        :to="{ name: 'JourneyBundles' }"
                        >{{ $t("ng.journey.to_bundles") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </section>

    <section v-if="journey && substructures" class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.statsContent.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.statsContent.subtitle')
        "
      />

      <JourneyStatsActivity />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyStatusChanger from "@/components/journeys/JourneyStatusChanger";
import JourneyStatsActivity from "@/components/journeys/stats/JourneyStatsActivity";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "JourneySummary",
  components: {
    SectionHeader,
    JourneyStatusChanger,
    JourneyStatsActivity,
    LanguageChooserInline,
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    ...mapGetters("structures", { substructures: "get_substructures" }),
    startDate() {
      if (this.journey.start_at) {
        return new Date(this.journey.start_at).toLocaleDateString();
      }
      return "";
    },
    endDate() {
      if (this.journey.end_at) {
        return new Date(this.journey.end_at).toLocaleDateString();
      }
      return "";
    },
    sortedBundles() {
      if (
        !this.journey ||
        !this.journey.bundles ||
        this.journey.bundles.length === 0
      )
        return [];
      return { ...this.journey }.bundles.sort((a, b) => a.level - b.level);
    },
  },
  methods: {
    ...mapMutations("journeys", ["set_selected_language"]),
    getSubstructureById(id) {
      return this.substructures.filter(
        (substructure) => substructure.id === id
      )[0];
    },
    changeLanguage(lang) {
      this.set_selected_language(lang);
    },
    getBundleNuggetCount(bundle) {
      var steps = [...bundle.steps];
      return steps.filter((s) => s.type === "nugget").length;
    },
    getBundleQuizCount(bundle) {
      var steps = [...bundle.steps];
      return steps.filter((s) => s.type === "quiz").length;
    },
  },
};
</script>

<style></style>
