<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.categories.plural')"
      icon="mdi-shape"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'CategoryOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!substructures" />

    <section v-if="substructures">
      <SectionHeader
        :title="$t('general.add')"
        :subtitle="$t('ng.category_add_subtitle')"
      />

      <v-form v-model="valid" :disabled="loading">
        <v-container>
          <v-row>
            <v-col cols="12" sm="9" class="pt-8">
              <InputTextfield
                v-model="input.name"
                listen-to-input
                :rules="rules.name"
                :label="$t('resources.categories.name')"
                translatable
              />
            </v-col>
            <v-col cols="12" sm="3">
              <CategoryImageChanger
                :image-input="input.image_url"
                @image-changed="changeImage"
              />
            </v-col>
            <v-col cols="12">
              <InputTextarea
                v-model="input.description"
                listen-to-input
                :rules="rules.description"
                :label="$t('resources.nuggets.description')"
                height="120"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="input.substructure_ids"
                :items="sortedSubstructures"
                item-text="name"
                item-value="id"
                attach
                chips
                small-chips
                deletable-chips
                :messages="
                  !['superadmin', 'admin'].includes(role)
                    ? $t('general.errors.admin_only')
                    : ''
                "
                :disabled="!['superadmin', 'admin'].includes(role)"
                multiple
                :hint="$t('ng.category_substructure_hint')"
                persistent-hint
                outlined
                background-color="white"
                :label="$t('resources.categories.substructure_ids')"
              >
                <template #item="{ item }">
                  {{ item.structure.name }} / {{ item.name }}
                </template>
                <template #selection="{ item }">
                  <v-chip small color="primary" label>
                    {{ substructureById(item.id).structure.name }} /
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <CategorygroupSelector @changed-groups="setGroups" />
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-spacer />
            <v-btn color="error" text small @click="resetForm">
              {{ $t("general.clear") }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              small
              class="ml-2"
              :loading="loading"
              :disabled="!valid"
              @click="saveForm"
            >
              {{ $t("general.save") }}
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import CategoryImageChanger from "@/components/categories/CategoryImageChanger";
import CategorygroupSelector from "@/components/categories/CategorygroupSelector";

export default {
  name: "CategoryAdd",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    CategoryImageChanger,
    CategorygroupSelector,
  },
  data() {
    return {
      loading: false,
      valid: false,
      input: {
        name: "",
        description: "",
        image_url: "1.png",
        substructure_ids: [],
        group_ids: [],
      },
      rules: {
        name: [
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("general.errors.not_enough_chars"),
          (v) =>
            (!!v && v.length <= 32) ||
            this.$t("resources.categories.errors.name_too_long"),
        ],
        description: [
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("general.errors.not_enough_chars"),
          (v) =>
            (!!v && v.length <= 128) ||
            this.$t("resources.categories.errors.name_too_long"), // TODO max length?
        ],
      },
    };
  },
  computed: {
    ...mapGetters("structures", { substructures: "get_substructures" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.categories.plural"),
          disabled: false,
          to: { name: "CategoryOverview" },
        },
      ];
    },
    sortedSubstructures() {
      var substructures = [...this.substructures];
      return substructures.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  destroyed() {
    this.set_structures(null);
  },
  methods: {
    ...mapActions("categories", ["add_category"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      await this.fetch_structures();
    },
    substructureById(id) {
      if (!this.substructures) return [];

      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    resetForm() {
      this.input = {
        name: "",
        description: "",
        image_url: "1.png",
        substructure_ids: [],
        group_ids: [],
      };
    },
    async saveForm() {
      let payload = this.input;
      (payload.language = this.$i18n.locale), (this.loading = true);
      let res = await this.add_category({
        payload: payload,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text:
            this.$t("resources.categories.singular") +
            " " +
            this.$t("general.createSuccess"),
        });
        this.$router.push({ name: "CategoryOverview" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    changeImage(image) {
      this.input.image_url = image.name;
    },
    setGroups(val) {
      this.input.group_ids = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
