import i18n from "@/i18n.js";
export class Nugget {
  id = -1;
  title = "";
  //text = '';
  description = "";
  content = "";
  body_format = "html";
  created_at = "";
  category = null;
  category_id = -1;
  image = "";
  image_hash = "";
  images = [];
  images_hash = [];
  media_uid = "";
  source = "";
  is_active = false;
  language = "";
  level = -1;
  links = "";
  //used_in_journey = false;
  like_count = -1;
  proposer_id = -1;

  constructor(obj) {
    this.id = obj ? obj.id : null;
    this.type = obj ? obj.type || obj.ng_format : "text";
    this.title = obj ? obj.title : "";
    //this.text = obj ? obj.text : '';
    this.description = obj ? obj.description : "";
    this.content = obj ? obj.content : "";
    this.body_format = obj ? obj.body_format : "html";
    this.created_at = obj ? new Date(obj.created_at) : null;
    this.category = obj ? obj.category : null;
    this.category_id = obj ? obj.category.id : -1;
    this.image = obj ? obj.image : "";
    this.image_hash = obj ? obj.image_hash : "";
    this.images = obj ? obj.images : [];
    this.images_hash = obj ? obj.images_hash : [];
    this.media_uid = obj ? obj.media_uid : "";
    this.source = obj ? obj.ng_source || obj.source : "";
    this.is_active = obj ? obj.is_active : false;
    this.language = obj && obj.language ? obj.language : i18n.locale;
    this.level = obj && obj.level ? obj.level : 1;
    this.links = obj && obj.links ? obj.links.toString() : "";
    //this.used_in_journey = obj ? obj.used_in_journey : false;
    this.like_count = obj ? obj.like_count : 0;
    this.proposer_id = obj ? obj.proposer_id : 0;
  }

  bodyImgCount() {
    let count = this.content.split("[_IMG_]").length - 1;
    return count;
  }

  get formRules() {
    return {
      title: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          (!!v && v.length <= 255) ||
          i18n.t("ng.too_many_chars_hint", { count: v.length, max: 255 }),
      ],
      description: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          (!!v && v.length <= 255) ||
          i18n.t("ng.too_many_chars_hint", { count: v && v.length, max: 255 }),
      ],
      content: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        //(v) => !!v && v.length <= 64 || i18n.t('ng.too_many_chars_hint', { count: v.length, max: 64 })
      ],
      category: [
        this.category_id !== -1 ||
          i18n.t("resources.questions.select_category"),
      ],
    };
  }
}
