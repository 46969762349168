<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="showDialog" max-width="640" persistent>
      <v-form v-model="valid">
        <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
          <v-card-title class="overline secondary--text">
            <span>add pdf source</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-file-input
                  ref="gwdocument"
                  accept=".pdf"
                  :label="$t('resources.invitations.fileInput')"
                  outlined
                  :background-color="loading ? 'grey lighten-3' : 'white'"
                  :disabled="loading"
                  small-chips
                  dense
                  :rules="rules.file"
                  clear-icon="mdi-close-circle"
                >
                  <!-- <template #append-outer>
                    <v-slide-group>
                      <v-slide-x-reverse-transition leave-absolute>
                        <v-btn
                          v-if="!uploaded"
                          small
                          depressed
                          :disabled="!selected || uploaded"
                          :color="'primary'"
                          :loading="loading"
                          @click="uploadFile()"
                        >
                          <v-icon class="mr-2">mdi-upload</v-icon>
                          <span>Analysieren</span>
                        </v-btn>
                      </v-slide-x-reverse-transition>
                      <v-slide-x-transition>
                        <v-icon v-if="uploaded" color="success"
                          >mdi-check-circle</v-icon
                        >
                      </v-slide-x-transition>
                    </v-slide-group>
                  </template> -->
                </v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="$t('general.language')"
                  :value="lang"
                  background-color="white"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-btn
              text
              color="success"
              small
              :loading="loading"
              :disabled="loading || !valid"
              @click="uploadFile()"
            >
              {{ $t("general.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddPdfSourceDialog",
  data() {
    return {
      showDialog: false,
      valid: false,
      lang: "de-DE",
      loading: false,
      selected: false,
      rules: {
        file: [
          (value) =>
            !value ||
            value.size < 500000000 ||
            this.$t("resources.media.validation", { size: "500" }),
        ],
      },
    };
  },
  methods: {
    ...mapActions("tmp", ["add_source"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    selectFile(file) {
      this.selected = !!file;
    },
    async uploadFile() {
      this.loading = true;
      let inputs = new FormData();
      inputs.append("file", this.$refs.gwdocument.initialValue);

      var res = await this.add_source({
        payload: inputs,
        type: "pdf",
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.$router.push({
          name: "SourceDetail",
          params: { id: res.source.id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
      /* let res = await this.upload_document({
        payload: inputs,
        config: {
          cancelToken: this.source.token,
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
      this.loading = false;
      if (res) {
        this.text = res.text;
        this.uploaded = true;
        this.$notify({
          title: res ? this.$t("general.success") : this.$t("general.error"),
          type: res ? "success" : "warning",
          text: res ? this.$t("general.success") : this.$t("general.error"),
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: this.$t("resources.invitations.fileError"),
        });
        this.fileUploaded = false;
      } */
    },
    /* async addSource() {
      this.loading = true;
      var payload = {
        txt: this.txt,
      };
      var res = await this.add_source({ payload: payload, type: "txt" });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.$router.push({
          name: "SourceDetail",
          params: { id: res.source.id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    }, */
    reset() {
      this.txt = "";
    },
    openDialog(text) {
      if (text) this.txt = text;
      this.showDialog = true;
    },
  },
};
</script>
