var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Line")]),_c('v-card-text',[_c('ChartLine',{attrs:{"chart-data":{
              labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
              ],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: '#f87979',
                  data: [40, 39, 10, 40, 39, 80, 40],
                },
                {
                  label: 'Data Tow',
                  backgroundColor: '#00adf7',
                  data: [10, 12, 30, 80, 60, 70, 20],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Pie")]),_c('v-card-text',[_c('ChartPie',{attrs:{"chart-data":{
              labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
              datasets: [
                {
                  backgroundColor: [
                    '#41B883',
                    '#E46651',
                    '#00D8FF',
                    '#DD1B16',
                  ],
                  data: [40, 20, 80, 10],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Bubble")]),_c('v-card-text',[_c('ChartBubble',{attrs:{"chart-data":{
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: '#f87979',
                  data: [
                    {
                      x: 20,
                      y: 25,
                      r: 5,
                    },
                    {
                      x: 40,
                      y: 10,
                      r: 10,
                    },
                    {
                      x: 30,
                      y: 22,
                      r: 30,
                    },
                  ],
                },
                {
                  label: 'Data Two',
                  backgroundColor: '#7C8CF8',
                  data: [
                    {
                      x: 10,
                      y: 30,
                      r: 15,
                    },
                    {
                      x: 20,
                      y: 20,
                      r: 10,
                    },
                    {
                      x: 15,
                      y: 8,
                      r: 30,
                    },
                  ],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Scatter")]),_c('v-card-text',[_c('ChartScatter',{attrs:{"chart-data":{
              datasets: [
                {
                  label: 'Scatter Dataset 1',
                  fill: false,
                  borderColor: '#f87979',
                  backgroundColor: '#f87979',
                  data: [
                    {
                      x: -2,
                      y: 4,
                    },
                    {
                      x: -1,
                      y: 1,
                    },
                    {
                      x: 0,
                      y: 0,
                    },
                    {
                      x: 1,
                      y: 1,
                    },
                    {
                      x: 2,
                      y: 4,
                    },
                  ],
                },
                {
                  label: 'Scatter Dataset 2',
                  fill: false,
                  borderColor: '#7acbf9',
                  backgroundColor: '#7acbf9',
                  data: [
                    {
                      x: -2,
                      y: -4,
                    },
                    {
                      x: -1,
                      y: -1,
                    },
                    {
                      x: 0,
                      y: 1,
                    },
                    {
                      x: 1,
                      y: -1,
                    },
                    {
                      x: 2,
                      y: -4,
                    },
                  ],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Doughnut")]),_c('v-card-text',[_c('ChartDoughnut',{attrs:{"chart-data":{
              labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
              datasets: [
                {
                  backgroundColor: [
                    '#41B883',
                    '#E46651',
                    '#00D8FF',
                    '#DD1B16',
                  ],
                  data: [40, 20, 80, 10],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Polar")]),_c('v-card-text',[_c('ChartPolar',{attrs:{"chart-data":{
              labels: [
                'Eating',
                'Drinking',
                'Sleeping',
                'Designing',
                'Coding',
                'Cycling',
                'Running',
              ],
              datasets: [
                {
                  label: 'My First dataset',
                  backgroundColor: 'rgba(179,181,198,0.2)',
                  pointBackgroundColor: 'rgba(179,181,198,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(179,181,198,1)',
                  data: [65, 59, 90, 81, 56, 55, 40],
                },
                {
                  label: 'My Second dataset',
                  backgroundColor: 'rgba(255,99,132,.20)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  data: [28, 48, 40, 19, 96, 27, 100],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Radar")]),_c('v-card-text',[_c('ChartRadar',{attrs:{"chart-data":{
              labels: [
                'Eating',
                'Drinking',
                'Sleeping',
                'Designing',
                'Coding',
                'Cycling',
                'Running',
              ],
              datasets: [
                {
                  label: 'My First dataset',
                  backgroundColor: 'rgba(179,181,198,0.2)',
                  borderColor: 'rgba(179,181,198,1)',
                  pointBackgroundColor: 'rgba(179,181,198,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(179,181,198,1)',
                  data: [65, 59, 90, 81, 56, 55, 40],
                },
                {
                  label: 'My Second dataset',
                  backgroundColor: 'rgba(255,99,132,0.2)',
                  borderColor: 'rgba(255,99,132,1)',
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointBorderColor: '#fff',
                  pointHoverBackgroundColor: '#fff',
                  pointHoverBorderColor: 'rgba(255,99,132,1)',
                  data: [28, 48, 40, 19, 96, 27, 100],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Bar")]),_c('v-card-text',[_c('ChartBar',{attrs:{"chart-data":{
              labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: '#f87979',
                  data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                },
                {
                  label: 'Data Two',
                  backgroundColor: '#00afd7',
                  data: [60, 30, 7, 44, 23, 50, 39, 70, 60, 12, 24, 38],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height",attrs:{"color":"grey lighten-3","flat":""}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v("Bar stacked")]),_c('v-card-text',[_c('ChartBar',{attrs:{"chart-data":{
              labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              datasets: [
                {
                  label: 'Data One',
                  backgroundColor: '#f87979',
                  data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                },
                {
                  label: 'Data Two',
                  backgroundColor: '#00afd7',
                  data: [60, 30, 7, 44, 23, 50, 39, 70, 60, 12, 24, 38],
                },
              ],
            },"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }