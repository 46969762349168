import axios from "axios";

let GWAPI_PREFIX = process.env.VUE_APP_GW_SERVER_URL + "/";

/* if (process.env.NODE_ENV == "development") {
  GWAPI_PREFIX = "/";
} */

export default {
  async get(url, config = {}) {
    try {
      let res = await axios.get(GWAPI_PREFIX + url, config);
      var data = res.data instanceof Array ? { items: res.data } : res.data;
      data._status = res.status;
      return res.data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.get()", e);
      return null;
    }
  },
  async post(url, payload, config = {}) {
    try {
      let res = await axios.post(GWAPI_PREFIX + url, payload, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.post()", e);
      return null;
    }
  },
  /* async put(url, payload, config = {}) {
    try {
      let res = await axios.put(GWAPI_PREFIX + url, payload, config);
      return res.data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.put()", e);
      return null;
    }
  }, */
  async patch(url, payload, config = {}) {
    try {
      let res = await axios.patch(GWAPI_PREFIX + url, payload, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.patch()", e);
      return null;
    }
  },
  async delete(url, config = {}) {
    try {
      let res = await axios.delete(GWAPI_PREFIX + url, config);
      var data = res.data;
      data._status = res.status;
      return data;
    } catch (e) {
      console.error("Error in @/services/gwapi.js -> default.delete()", e);
      return null;
    }
  },
};
