<template>
  <div>
    <slot name="activator" :show-dialog="showDialog" />

    <v-dialog
      v-model="dialog"
      :width="width"
      :fullscreen="fullscreen"
      @click:outside="closeDialog"
    >
      <v-card color="grey lighten-3">
        <v-img :src="image" max-height="200" />
        <v-card-text>
          <div>
            {{ nugget }}
          </div>
          <v-text-field
            :value="width"
            hint="px oder %"
            label="Anzeigebreite"
            persistent-hint
            outlined
            dense
            background-color="white"
            @change="changeWidth"
          >
            <template #append-outer>
              <v-btn @click="fullscreen = !fullscreen"> fullscreen </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-overlay color="primary" opacity="0.9" absolute>
          <div class="overline text-center mb-2">Kommt bald</div>
          <v-btn
            depressed
            small
            color="white"
            class="secondary--text"
            @click="closeDialog()"
          >
            {{ $t("general.close") }}
          </v-btn>
        </v-overlay>
      </v-card>

      <!-- <v-speed-dial
        v-model="options"
        left
        bottom
        :direction="'top'"
        fixed
      >
        <template v-slot:activator>
          <v-btn
            v-model="options"
            fab
          >
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-speed-dial> -->
    </v-dialog>
  </div>
</template>

<script>
import { Nugget } from "@/models/Nugget.js";

export default {
  name: "NuggetPreview",
  props: {
    nugget: {
      type: Nugget,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      width: 600,
      fullscreen: false,
      /* options: false */
    };
  },
  computed: {
    image() {
      return require("@/assets/img/misc/default_small.jpg");
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    changeWidth(e) {
      this.width = e;
    },
  },
};
</script>

<style></style>
