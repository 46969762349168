<template>
  <div>
    <section class="mt-2" v-if="categories">
      <v-container>
        <v-row dense align="center">
          <v-col cols="12" sm="12" md="8">
            <v-autocomplete
              :items="categories"
              v-model="filter.categories"
              item-text="name"
              item-value="id"
              background-color="grey lighten-2"
              solo
              flat
              dense
              clearable
              multiple
              small-chips
              :hint="
                $t('ng.selected_categories', {
                  selected_count: filter.categories.length,
                  category_count: categories.length,
                })
              "
              persistent-hint
              deletable-chips
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 4" color="white" small class="pr-0">
                  <span>{{ item.name }}</span>
                  <v-btn
                    icon
                    x-small
                    class="mx-1"
                    @click.stop="removeCategory(item)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-chip>
                <span v-if="index === 4" class="grey--text text-caption">
                  (+{{ categories.length - 4 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            :order="$vuetify.breakpoint.smAndDown ? 'first' : ''"
          >
            <v-text-field
              v-model="search"
              :label="$t('general.search')"
              flat
              solo
              background-color="grey lighten-2"
              dense
              :hint="$t('ng.search_in_users')"
              persistent-hint
              :disabled="loading"
            />
          </v-col>
          <v-col cols="12">
            <v-card outlined color="grey lighten-1" tile>
              <v-data-table
                :headers="tableHeaders"
                :items="filteredUsers"
                :search="search"
                class="grey lighten-3"
                hide-default-header
                :loading="!users || (users && users.length === 0) || loading"
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img :src="item.info.avatar.img_url" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.first_name }}
                            {{ item.last_name }}</v-list-item-title
                          >
                          <v-list-item-subtitle>{{
                            item.user_name
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </td>
                    <td>
                      <div class="d-flex justify-end">
                        <div
                          v-for="(c, i) in item.categories"
                          :key="`cat-${i}`"
                        >
                          <StatisticsTableChip
                            v-if="filter.categories.includes(c.id)"
                            :value="
                              c.values.retention ? c.values.retention : '-'
                            "
                            :string-value="!c.values.retention"
                            no-delta
                            :description="`${c.name}: ${$t('kpi.retention')}`"
                            :image-icon="c.image"
                            tooltip-color="secondary"
                            :to-fixed-value="2"
                            class="ml-2"
                            fixed-width="80px"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatisticsTableChip from "@/components/kpi/StatisticsTableChip";

export default {
  name: "KpiCategoryRetention",
  components: {
    StatisticsTableChip,
  },
  data() {
    return {
      tableHeaders: [
        { text: "Username", value: "user_name" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
      ],
      search: "",
      options: {
        categories: [],
      },
      filter: {
        categories: [],
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      categories: "get_kpi_categories",
      users: "get_kpi_users",
      loading: "get_kpi_loading",
    }),
    filteredUsers() {
      if (!this.users) return [];
      var users = [...this.users];
      return users.sort((a, b) => a.last_name.localeCompare(b.status));
    },
  },
  watch: {
    categories: {
      handler: function () {
        this.setFilterCategories();
      },
      "filter.categories": {
        handler: function () {
          this.saveCategories();
        },
      },
    },
  },
  mounted() {
    this.setFilterCategories();
  },
  methods: {
    setFilterCategories() {
      if (!this.categories) return false;
      var categories = [];
      this.categories.slice(0, 6).forEach((c) => categories.push(c.id));
      this.filter.categories = localStorage.getItem("kpi-cat-sel")
        ? JSON.parse(localStorage.getItem("kpi-cat-sel"))
        : categories;
    },
    removeCategory(category) {
      this.filter.categories = this.filter.categories.filter(
        (c) => c !== category.id
      );
    },
    saveCategories() {
      localStorage.setItem(
        "kpi-cat-sel",
        JSON.stringify(this.filter.categories)
      );
    },
  },
};
</script>
