<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog :value="dialog" width="500" @click:outside="closeDialog()">
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-title class="overline">
          <span>{{ $t("resources.journeys.deleteBundle") }}</span>
        </v-card-title>

        <v-card-text>
          <span v-html="$t('resources.journeys.deleteBundleDescription')" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            small
            text
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            color="error"
            small
            text
            :disabled="loading"
            @click="deleteJourneybundle"
          >
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Journeybundle } from "@/models/journeys/Journeybundle.js";
import { Journey } from "@/models/journeys/Journey";

export default {
  name: "JourneybundleDialogDelete",
  props: {
    bundle: {
      type: Journeybundle,
    },
    journey: {
      type: Journey,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  methods: {
    ...mapActions("journeys", ["delete_bundle"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleteJourneybundle() {
      this.loading = true;
      let payload = {
        bundle_id: this.bundle.id,
        journey_id: this.journey.id,
      };
      let res = await this.delete_bundle({
        payload: payload,
      });
      this.loading = false;
      this.dialog = false;
      if (res) {
        this.$notify({
          title: this.$t("general.success"),
          type: "success",
          text: this.$t("resources.journeys.delete_success"),
        });
        this.closeDialog(true);
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: res.msg
            ? this.$t("errors." + res.msg)
            : this.$t("resources.journeys.delete_error"),
        });
      }
    },
  },
};
</script>

<style></style>
