<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-card flat color="grey lighten-3" class="d-flex align-center">
        <v-btn
          text
          :disabled="options.timeFrame == 1"
          @click="timeFrameChange('dec')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <span class="body-1">{{ timeFrameArr[options.timeFrame - 1] }}</span>
        <v-spacer />
        <v-btn
          text
          :disabled="options.timeFrame == 2"
          @click="timeFrameChange('inc')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card flat color="grey lighten-3" class="d-flex align-center">
        <v-btn
          text
          :disabled="timePointerArr.indexOf(options.timePointer - 1) === -1"
          @click="timePointerChange('dec')"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-spacer />
        <span>{{ timePointerString }}</span>
        <v-spacer />
        <v-btn
          text
          :disabled="timePointerArr.indexOf(options.timePointer + 1) === -1"
          @click="timePointerChange('inc')"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "StatisticsTimepicker",
  props: {},
  data() {
    return {
      timePointerArr: [],
      timePointerArrMonths: [],
      timePointerArrYears: [],
      timeFrameArr: [
        this.$t("views.statistics.month_view"),
        this.$t("views.statistics.annual_view"),
      ],
    };
  },
  computed: {
    ...mapGetters("statistics", { options: "get_options" }),
    timePointerString() {
      var string = "";
      var currentYear = new Date().getFullYear();

      if (this.options.timeFrame === 1) {
        string = this.$t("general.monthNumeric." + this.options.timePointer);
        if (this.options.timePointer !== 0) {
          string = string.concat(" " + currentYear);
        }
      } else {
        string = this.options.timePointer;
      }

      return string;
    },
  },
  watch: {
    "options.currentChart": function () {
      this.setTimePointerArr();
    },
  },
  beforeMount() {
    this.setTimePointerArr();
  },
  methods: {
    ...mapMutations("statistics", ["set_options"]),
    setTimePointerArr() {
      let date = new Date();
      if (this.options.timeFrame === 1) {
        this.options.timePointer.toString().length > 2
          ? (this.options.timePointer = date.getMonth() + 1)
          : null;
        this.timePointerArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].slice(
          0,
          date.getMonth() + 2
        );
      } else {
        this.options.timePointer.toString().length <= 2
          ? (this.options.timePointer = date.getFullYear())
          : null;
        let year = date.getFullYear();
        this.timePointerArr = [year - 2, year - 1, year];
      }
    },
    timeFrameChange(operator) {
      operator === "dec" ? this.options.timeFrame-- : this.options.timeFrame++;
      this.setTimePointerArr();
      this.changeEmitter();
    },
    timePointerChange(operator) {
      operator === "dec"
        ? this.options.timePointer--
        : this.options.timePointer++;
      this.setTimePointerArr();
      this.changeEmitter();
    },
    changeEmitter() {
      //this.$emit('changeTimeSettings', { timeFrame : this.options.timeFrame, timePointer: this.options.timePointer })
      this.set_options({
        timeFrame: this.options.timeFrame,
        timePointer: this.options.timePointer,
      });
    },
  },
};
</script>
