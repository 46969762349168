export const get_role = (state) => state.role;

export const get_auth = (state) => state.auth;

export const get_token = (state) => state.token;

export const get_dashboard_stats = (state) => state.dashboardStats;
export const get_dashboard_questions = (state) => state.dashboardQuestions;

export const get_licences = (state) => state.licences;

export const get_auth_sources = (state) => state.auth_sources;
