<!-- eslint-disable vue/no-mutating-props -->
<!-- TODO: check eslint err -->
<template>
  <div v-if="item">
    <v-form v-model="valid" :disabled="item.is_accepted || disabled">
      <v-expand-transition>
        <v-row v-if="showOptions" dense>
          <v-col cols="12">
            <SectionHeader
              :title="$t('gw.addSession.settings')"
              :subtitle="$t('gw.optionsHint')"
              class="px-0"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="item.content.category_id"
              :items="sortedCategories"
              item-text="name"
              item-value="id"
              :label="$t('resources.categories.singular')"
              outlined
              dense
              clearable
              background-color="white"
            />
          </v-col>
          <v-col v-if="!hideActivate" cols="12" md="6">
            <v-switch
              v-model="item.content.activate"
              dense
              inset
              hide-details
              color="secondary"
              class="ml-1 mt-1"
            >
              <template #label>
                {{ $t("gw.activateContent") }}
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row dense>
        <v-col cols="12" class="pt-2">
          <v-text-field
            v-model="item.content.title"
            :label="$t('resources.nuggets.title')"
            outlined
            dense
            :counter="255"
            :background-color="item.is_accepted ? '' : 'white'"
            :rules="item.formRules.title"
            :readonly="item.is_accepted"
          />
        </v-col>
        <v-col cols="12">
          <InputTextarea
            v-model="item.content.description"
            :label="$t('resources.nuggets.description')"
            outlined
            :background-color="item.is_accepted ? '' : 'white'"
            dense
            :counter="255"
            listen-to-input
            rows="3"
            :readonly="item.is_accepted"
            :rules="item.formRules.description"
          />
        </v-col>
        <v-col cols="12">
          <InputTextarea
            v-model="item.content.body"
            :label="$t('resources.nuggets.content')"
            listen-to-input
            auto-grow
            rows="9"
            :rules="item.formRules.content"
            :background-color="item.is_accepted ? '' : 'white'"
            :readonly="item.is_accepted"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GwNugget } from "@/models/ghostwriter/content/GwNugget.js";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "GwNuggetForm",
  components: {
    SectionHeader,
  },
  props: {
    item: {
      type: [GwNugget],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    idx: {
      type: Number,
      required: true,
    },
    updated: {
      type: Boolean,
      required: false,
    },
    showOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideActivate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      original: null,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    item: {
      deep: true,
      handler() {
        this.checkIfChanged();
      },
    },
    valid: {
      deep: false,
      handler(val) {
        this.$emit("valid", val);
      },
    },
    idx: {
      handler() {
        this.setNugget();
      },
    },
    updated: {
      handler() {
        this.setNugget();
      },
    },
  },
  beforeMount() {
    this.setNugget();
  },
  methods: {
    setNugget() {
      this.original = JSON.parse(JSON.stringify(this.item));
    },
    checkIfChanged() {
      if (this.item.id !== this.original.id) return false;
      let changed = false;
      if (this.item.content.title !== this.original.content.title)
        changed = true;
      if (this.item.content.description !== this.original.content.description)
        changed = true;
      if (this.item.content.body !== this.original.content.body) changed = true;
      if (this.item.content.category_id !== this.original.content.category_id)
        changed = true;
      if (this.item.content.activate !== this.original.content.activate)
        changed = true;
      this.$emit("changed", changed);
    },
  },
};
</script>
