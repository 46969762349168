export const state = {
  session: null,
  bulk_session: null,
  sessions: [],
  bulk_sessions: [],
  licences: [],
  transcriptions: [],
  transcription: null,
  credit_pool: null,
  sources: [],
  sources_generating: [],
  source: null,
  source_job: null,
  source_filter: {
    visible_bundles: [],
    hide_bundles: false,
    expand_all_bundles: false,
    show_nuggets: true,
    show_questions: true,
    hide_accepted: true,
    hide_deleted: true,
  },
  gw_journeys: [],
  gw_journey: null,
};
