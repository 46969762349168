export const set_structures = (state, value) => {
  if (value) {
    state.structures = value.filter((structure) => {
      return structure.name != "robots";
    });
  } else {
    state.structures = value;
  }
};

export const set_structure = (state, value) => {
  state.structure = value;
};

export const set_substructures = (state, value) => {
  if (value) {
    state.substructures = value.filter((substructure) => {
      return substructure.name != "robots";
    });
  } else {
    state.substructures = value;
  }
};

export const set_structure_statistics = (state, value) =>
  (state.structure_statistics = value);
