<template>
  <div class="d-flex">
    <v-icon
      v-if="!noDelta"
      :color="delta > 0 ? 'success' : delta < 0 ? 'error' : 'secondary'"
      >{{
        delta > 0
          ? "mdi-menu-up"
          : delta < 0
          ? "mdi-menu-down"
          : "mdi-square-small"
      }}</v-icon
    >
    <v-tooltip
      :top="tooltipPosition === 'top'"
      :bottom="tooltipPosition === 'bottom'"
      :left="tooltipPosition === 'left'"
      :right="tooltipPosition === 'right'"
      :color="tooltipColor"
      max-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip small :color="chipColor" v-bind="attrs" v-on="on" pill>
          <span
            class="text-center"
            style="width: 25px"
            :style="value == '0.00' ? 'opacity: 0.25' : ''"
            >{{ stringValue ? value : value.toFixed(toFixedValue) }}</span
          >
          <span v-if="unit">{{ unit }}</span>
          <v-avatar v-if="icon || imageIcon" right>
            <v-icon v-if="!imageIcon" :color="iconColor">{{ icon }}</v-icon>
            <v-img v-if="imageIcon" :src="imageIcon" />
          </v-avatar>
        </v-chip>
      </template>
      <div>
        <p
          v-if="description"
          :class="{ 'pb-0 mb-0 text-center': noDelta }"
          class="font-weight-bold"
        >
          {{ description }}
        </p>
        <p v-if="!noDelta">
          {{ $t("kpi.monthly_changes") }}:
          <br />
          <b>{{
            delta > 0
              ? `+${delta.toFixed(toFixedValue)}`
              : delta.toFixed(toFixedValue)
          }}</b>
        </p>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "StatisticsTableChip",
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    delta: {
      type: Number,
      required: false,
      default: 0,
    },
    description: {
      type: String,
      required: false,
    },
    noDelta: {
      type: Boolean,
      required: false,
      default: false,
    },
    chipColor: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
    iconColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    tooltipColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    icon: {
      type: String,
      required: false,
    },
    toFixedValue: {
      type: Number,
      required: false,
      default: 2,
    },
    stringValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: "top",
    },
    unit: {
      type: String,
      required: false,
    },
    imageIcon: {
      type: String,
      required: false,
    },
  },
};
</script>

<style></style>
