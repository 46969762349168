import api from "@/services/api";

export const fetch_codes = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/codes/", config);
    if (res._status === 200) {
      commit("set_codes", res.codes);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_code = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get("admin/codes/" + id, config);
    if (res._status === 200) {
      commit("set_code", res.code);
      commit("update_codes", res.code);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_code_proposal = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/codes/propose", config);
    if (res._status === 200) {
      commit("set_code_proposal", res.code_proposal);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const add_code = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/codes/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_code = async (_, { payload, config = {} }) => {
  try {
    let res = await api.put("admin/codes/" + payload.code, payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};
