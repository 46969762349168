var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"value":_vm.part.content,"readonly":"","outlined":"","hide-details":"","background-color":"grey lighten-3"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.part.whole_content && _vm.part.whole_content.length > 0)?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.part.whole_content),function(obj,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6"}},[_c('GwContentListItem',{attrs:{"item":obj,"session-id":_vm.part.session_id,"color":obj.is_accepted ? 'grey lighten-2' : 'grey lighten-2'},on:{"click":function($event){return _vm.openContent(i)}}})],1)}),1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary","disabled":(!_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.credits_count >= _vm.part.set_credits) ||
          (_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.licence.credits_overload +
              _vm.licences.ghostwriter.credits_count >=
              _vm.part.set_credits &&
            _vm.part.whole_content.length == 0)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ListButton',{attrs:{"title":_vm.$t('gw.set'),"subtitle":_vm.$t('gw.xCredits', { number: _vm.part.set_credits }),"icon":"mdi-plus","color":_vm.part.whole_content.length > 0 ||
                (!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.set_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.set_credits)
                  ? 'grey lighten-2'
                  : 'grey lighten-2',"text-color":_vm.part.whole_content.length > 0 ||
                (!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.set_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.set_credits)
                  ? 'grey'
                  : 'primary',"text-options":_vm.part.whole_content.length > 0 ||
                (!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.set_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.set_credits)
                  ? 'text--lighten-1'
                  : '',"icon-color":_vm.part.whole_content.length > 0 ||
                (!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.set_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.set_credits)
                  ? 'grey lighten-1'
                  : 'primary',"click-type":"link","elevation":0,"disabled":_vm.part.whole_content.length > 0 ||
                (!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.set_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.set_credits)},on:{"clicked":function($event){return _vm.generateSet()}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.part.whole_content.length === 0 ? _vm.$t("gw.not_enough_credits") : _vm.$t("gw.content_already_exist")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary","disabled":(!_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.credits_count >= _vm.part.nugget_credits) ||
          (_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.licence.credits_overload +
              _vm.licences.ghostwriter.credits_count >=
              _vm.part.nugget_credits)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ListButton',{attrs:{"title":_vm.$t('resources.nuggets.singular'),"subtitle":_vm.$t('gw.xCredits', { number: _vm.part.nugget_credits }),"icon":"mdi-plus","color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.nugget_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.nugget_credits)
                  ? 'grey lighten-2'
                  : 'grey lighten-2',"text-color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.nugget_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.nugget_credits)
                  ? 'grey'
                  : 'primary',"text-options":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.nugget_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.nugget_credits)
                  ? 'text--lighten-1'
                  : '',"icon-color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.nugget_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.nugget_credits)
                  ? 'grey lighten-1'
                  : 'primary',"click-type":"link","elevation":0,"disabled":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count < _vm.part.nugget_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.nugget_credits)},on:{"clicked":function($event){return _vm.generate('nuggets')}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.not_enough_credits")))])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary","disabled":(!_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.credits_count >= _vm.part.question_credits) ||
          (_vm.licences.ghostwriter.licence.can_overload &&
            _vm.licences.ghostwriter.licence.credits_overload +
              _vm.licences.ghostwriter.credits_count >=
              _vm.part.question_credits)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('ListButton',{attrs:{"title":_vm.$t('resources.questions.singular'),"subtitle":_vm.$t('gw.xCredits', { number: _vm.part.question_credits }),"icon":"mdi-plus","color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits)
                  ? 'grey lighten-2'
                  : 'grey lighten-2',"text-color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits)
                  ? 'grey'
                  : 'primary',"text-options":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits)
                  ? 'text--lighten-1'
                  : '',"icon-color":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits)
                  ? 'grey lighten-1'
                  : 'primary',"click-type":"link","elevation":0,"disabled":(!_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits) ||
                (_vm.licences.ghostwriter.licence.can_overload &&
                  _vm.licences.ghostwriter.licence.credits_overload +
                    _vm.licences.ghostwriter.credits_count <
                    _vm.part.question_credits)},on:{"clicked":function($event){return _vm.generate('questions')}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.not_enough_credits")))])])],1)],1),_c('GwContentDialog',{attrs:{"content":_vm.part.whole_content,"index":_vm.contentIndex,"visible":_vm.showContent,"session-id":_vm.part.session_id},on:{"close":function($event){_vm.showContent = false},"delete":_vm.removeItem,"refresh":_vm.refresh,"index-change":_vm.setIndex}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }