<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="800"
      scrollable
      :persistent="loading"
      :disabled="loading"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @click:outside="loading ? null : closeDialog()"
    >
      <v-card color="grey lighten-3">
        <v-card-title>{{ $t("gw.journey.import.title") }}</v-card-title>
        <v-card-subtitle>{{
          $t("gw.journey.import.subtitle")
        }}</v-card-subtitle>

        <v-card-text v-if="!generating">
          <v-expand-transition>
            <v-alert
              v-if="!journey.category_id"
              color="error"
              type="error"
              dense
            >
              {{ $t("gw.journey.import.category_error") }}
            </v-alert>
          </v-expand-transition>
          <v-expand-transition>
            <v-alert
              v-if="hasUnacceptedContent"
              color="info"
              type="info"
              dense
              text
            >
              {{ $t("gw.journey.import.content_warning") }}
            </v-alert>
          </v-expand-transition>
          <v-list>
            <v-list-item
              v-for="bundle in visibleBundles"
              :key="`bundle-${bundle.id}`"
            >
              <v-list-item-icon>
                <v-tooltip :disabled="getContentCount(bundle) > 0" right>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="getContentCount(bundle) === 0 ? 'error' : ''"
                      >{{
                        getContentCount(bundle) === 0
                          ? "mdi-alert"
                          : "mdi-check"
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    $t("gw.journey.import.bundles_with_no_content")
                  }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ bundle.title }}
                </v-list-item-title>
              </v-list-item-content>
              <div>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      label
                      :color="
                        getBundleNuggets(bundle).length === 0 ? 'error' : ''
                      "
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon x-small class="mr-2"> mdi-school </v-icon>
                      <span>{{ getBundleNuggets(bundle).length }}</span>
                    </v-chip>
                  </template>
                  <span
                    >{{ getBundleNuggets(bundle).length }}
                    {{
                      getBundleNuggets(bundle).length === 1
                        ? $t("resources.nuggets.singular")
                        : $t("resources.nuggets.plural")
                    }}</span
                  >
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      small
                      label
                      :color="
                        getBundleQuestions(bundle).length === 0 ? 'error' : ''
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon x-small class="mr-2">
                        mdi-comment-question
                      </v-icon>
                      <span>{{ getBundleQuestions(bundle).length }}</span>
                    </v-chip>
                  </template>
                  <span
                    >{{ getBundleQuestions(bundle).length }}
                    {{
                      getBundleQuestions(bundle).length === 1
                        ? $t("resources.questions.singular")
                        : $t("resources.questions.plural")
                    }}</span
                  >
                </v-tooltip>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-if="generating">
          {{ $t("gw.journey.import.importing_msg") }}
        </v-card-text>

        <v-card-actions>
          <v-btn small text :disabled="loading" @click="closeDialog()">{{
            $t("general.close")
          }}</v-btn>
          <v-spacer />
          <v-btn
            small
            text
            color="success"
            :disabled="
              !journey.category_id || journey.bundles.length === 0 || generating
            "
            :loading="loading"
            @click="importJourney()"
            >{{ $t("gw.sessionDetail.importContent") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwJourneyImportDialog",
  props: {
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      generating: false,
    };
  },
  computed: {
    visibleBundles() {
      var bundles = { ...this.journey }.bundles;
      return bundles;
    },
    hasUnacceptedContent() {
      var unaccepted = false;
      var bundles = [...this.visibleBundles];
      bundles.forEach((bundle) => {
        var questions = this.uncheckedContentLength(bundle.questions);
        var nuggets = this.uncheckedContentLength(bundle.nuggets);
        if (questions > 0 || nuggets > 0) {
          unaccepted = true;
        }
        if (unaccepted) return false;
      });
      return unaccepted;
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["import_gw_journey"]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.generating = false;
    },
    getBundleQuestions(bundle) {
      return bundle.questions.filter((q) => q.is_accepted);
    },
    getBundleNuggets(bundle) {
      return bundle.nuggets.filter((q) => q.is_accepted);
    },
    getContentCount(bundle) {
      var content_count = 0;
      bundle.questions.forEach((q) => {
        if (q.is_accepted) content_count++;
      });
      bundle.nuggets.forEach((n) => {
        if (n.is_accepted) content_count++;
      });
      return content_count;
    },
    async importJourney() {
      this.generating = true;
      var res = await this.import_gw_journey({ id: this.journey.id });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
    uncheckedContentLength(arr) {
      if (!arr || arr.length === 0) return 0;
      var unchecked_content = arr.filter(
        (c) => !c.is_deleted && !c.is_accepted
      );
      return unchecked_content.length;
    },
  },
};
</script>
