<template>
  <v-dialog
    :value="dialogVisible"
    max-width="640"
    @click:outside="closeDialog()"
  >
    <v-card color="grey lighten-3">
      <v-card-title class="overline secondary--text">
        {{ $t("resources.invitations.upload") }}
      </v-card-title>
      <v-card-text class="py-0 px-3">
        <v-container v-if="!fileUploaded">
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-sm-center flex-column flex-sm-row"
            >
              <v-file-input
                ref="mail_list"
                accept=".xls,.xlsx"
                :label="$t('resources.invitations.fileInput')"
                prepend-icon=""
                class="py-0 my-0"
                width="100%"
                background-color="white"
                outlined
                dense
                @change="fileSelected = true"
              />
              <v-btn
                color="primary"
                small
                class="ml-4 mb-6"
                :disabled="
                  !fileSelected ||
                  !this.$refs.mail_list ||
                  !this.$refs.mail_list.initialValue
                "
                @click="uploadList"
              >
                {{ $t("resources.invitations.uploadBtn") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <InvitationMailList
          v-if="emails"
          :emails="emails"
          :error-counter="errorCounter"
          @remove-mail="removeMail"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="closeDialog()">
          {{ $t("general.close") }}
        </v-btn>
        <v-btn
          color="success"
          text
          :disabled="!emailsValid || emails.length == 0"
          @click="closeDialog(true)"
        >
          {{ emailsValid && emails.length !== 0 ? emails.length : "" }}
          {{ $t("general.apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import InvitationMailList from "@/components/invitations/InvitationMailList";

export default {
  name: "InvitationDialogFile",
  components: {
    InvitationMailList,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    fileSelected: false,
    fileUploaded: false,
    emails: [],
    errorCounter: null,
  }),
  computed: {
    emailsValid() {
      if (!this.emails) return false;
      let check = true;
      this.emails.forEach((email) => {
        if (!email.is_valid) check = false;
      });
      return check;
    },
  },
  methods: {
    ...mapActions("invitations", ["upload_list"]),
    closeDialog(val) {
      val ? this.$emit("close", this.emails) : this.$emit("close", null);
    },
    async uploadList() {
      let inputs = new FormData();
      inputs.append("file", this.$refs.mail_list.initialValue);
      let res = await this.upload_list({
        payload: inputs,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
      if (res) {
        this.fileUploaded = true;
        this.emails = res.invitations;
        this.errorCounter = res.counter;
        this.$notify({
          title: res ? this.$t("general.success") : this.$t("general.error"),
          type: res ? "success" : "warning",
          text: res
            ? this.$t("resources.invitations.uploadSuccess")
            : this.$t("resources.invitations.uploadError"),
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: this.$t("resources.invitations.fileError"),
        });
        this.fileUploaded = false;
      }
    },
    removeMail(mail) {
      mail.is_valid ? this.errorCounter.valid-- : this.errorCounter.invalid--;
      this.emails = this.emails.filter((email) => email.email !== mail.email);
      if (this.emails.length === 0) this.fileUploaded = false;
    },
  },
};
</script>

<style></style>
