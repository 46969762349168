export const set_journeys = (state, value) => {
  state.journeys = value;
};

export const set_journey = (state, value) => {
  state.journey = value;
};

export const set_user_journey = (state, value) => {
  state.user_journey = value;
};

export const set_journey_bundles = (state, value) => {
  state.journeyBundles = value;
};

export const set_journey_statistics = (state, value) => {
  state.journeyStats = value;
};

export const set_journey_nuggets = (state, value) => {
  state.journeyNuggets = value;
};

export const set_journey_questions = (state, value) => {
  state.journeyQuestions = value;
};

export const set_bundle = (state, bundle) => {
  let nuggets = [];
  let quizzes = [];
  bundle.steps.forEach((step) => {
    if (step.type == "nugget") {
      nuggets.push(step);
    } else {
      quizzes.push(step);
    }
  });
  state.bundle = bundle;
};

export const set_certificate = (state, certificate) =>
  (state.certificate = certificate);
export const set_certificates = (state, certificates) =>
  (state.certificates = certificates);

export const set_journey_translations = (state, translations) =>
  (state.journey_translations = translations);
export const set_journey_bundle_translations = (state, translations) =>
  (state.journey_bundle_translations = translations);
export const set_selected_language = (state, language) =>
  (state.selected_language = language);

export const set_certificate_translations = (state, translations) =>
  (state.journey_translations = translations);
