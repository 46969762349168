export class Certificate {
  constructor(obj) {
    this.id = obj.id ? obj.id : null;
    this.name = obj.name ? obj.name : "";

    this.title = obj.title ? obj.title : "";
    this.subtitle = obj.subtitle ? obj.subtitle : "";
    this.intro = obj.intro ? obj.intro : "";
    this.outro = obj.outro ? obj.outro : "";
    this.appendix = obj.appendix ? obj.appendix : "";

    this.sign = obj.sign ? obj.sign : "";

    this.img_bg = obj.img_bg ? obj.img_bg : "";
    this.img_bg_hash = obj.img_bg_hash ? obj.img_bg_hash : "";
    this.img_sign = obj.img_sign ? obj.img_sign : "";
    this.img_sign_hash = obj.img_sign_hash ? obj.img_sign_hash : "";

    this.color_text = obj.color_text ? obj.color_text : "#303030";
    //this.color_accent = obj.color_accent ? obj.color_accent : '';
    this.color_accent = obj.color_accent ? obj.color_accent : "#000000";

    this.is_active = obj.is_active ? obj.is_active : false;
    this.is_deleted = obj.is_deleted ? obj.is_deleted : false;
    this.is_locked = obj.is_locked ? obj.is_locked : false;
    this.is_default = obj.is_default ? obj.is_default : false;

    this.language = obj.language ? obj.language : null;
  }
}
