export class Supportrequest {
  id = -1;
  email = "";
  name = "";
  phone = "";
  user_agent = "";
  message = "";
  images = [];
  status = "";
  admin = null;
  created_at = "";
  updated_at = "";

  constructor(obj) {
    this.id = obj.id;
    this.email = obj.email;
    this.name = obj.name;
    this.phone = obj.phone;
    this.user_agent = obj.user_agent;
    this.message = obj.message;
    this.images = this.setImages(obj.images);
    this.status = obj.status;
    this.admin = obj.admin;
    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);
  }

  setImages(image_tokens) {
    let images = [];
    let url_prefix = `${process.env.VUE_APP_MEDIA_SERVER_URL}/i/`;
    if (image_tokens) {
      image_tokens.forEach((token) => images.push(url_prefix + token));
    }
    return images;
  }
}
