<template>
  <div>
    <div v-show="showPlayer" style="position: relative">
      <iframe
        v-if="type !== 'p3lmedia'"
        width="100%"
        :height="`${height}px !important`"
        frameBorder="0"
        :src="externalLink"
      />
      <NuggetVideoPlayer
        v-show="type == 'p3lmedia' && !previewVideo"
        :nugget="nugget"
        :lang="initialLang"
      />
      <video
        v-show="previewVideo && !initialLink"
        id="video-preview-uploaded"
        controls
        style="max-width: 100%"
      >
        {{ $t("resources.media.no_video_support") }}
      </video>
      <div v-if="type && !initialLink" class="d-flex justify-space-between">
        <v-btn
          v-show="!disabled"
          color="error"
          text
          x-small
          :disabled="disabled"
          @click="reset()"
        >
          <v-icon small> mdi-close </v-icon>
          <span class="ml-1">{{ $t("resources.nuggets.remove_video") }}</span>
        </v-btn>
        <v-spacer />
        <span class="caption secondary--text">{{
          $t(`resources.nuggets.sources.${type}`)
        }}</span>
      </div>
    </div>

    <v-card
      v-if="!showPlayer"
      :color="cardColor"
      :elevation="elevation"
      class="d-flex flex-column"
    >
      <v-card-title v-if="type && !initialType">
        <v-btn icon small @click="reset()">
          <v-icon small> mdi-arrow-left </v-icon>
        </v-btn>
        <span class="overline font-weight-bold secondary--text">{{
          $t(`resources.nuggets.${type}_video`)
        }}</span>
      </v-card-title>
      <v-card-text :class="!type ? 'py-8' : 'py-2'">
        <div v-if="type">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-if="type == 'youtube'"
                v-model="externalLink"
                :hint="$t('resources.nuggets.video_youtube_hint')"
                :error-messages="mediaLinkYoutubeError"
                dense
                outlined
                background-color="white"
                :append-icon="
                  externalLink && mediaLinkYoutubeError.length === 0
                    ? 'mdi-check'
                    : 'mdi-pencil'
                "
                @change="youtubeLinkChanged"
              />
              <v-text-field
                v-if="type == 'vimeo'"
                v-model="externalLink"
                filled
                dense
                :hint="$t('resources.nuggets.video_vimeo_hint')"
                :error-messages="mediaLinkVimeoError"
                @change="vimeoLinkChanged"
              />
              <v-text-field
                v-if="type == 'ted'"
                v-model="externalLink"
                filled
                dense
                :hint="$t('resources.nuggets.video_ted_hint')"
                :error-messages="mediaLinkTedError"
                @change="tedLinkChanged"
              />
              <div v-if="type == 'p3lmedia'">
                <v-list-item v-if="!reinitializing">
                  <v-list-item-avatar>
                    <v-icon>mdi-video</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ externalLink }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      $t("general.filename")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon small color="error" @click="resetP3lmedia()"
                      >mdi-delete</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
                <div
                  v-if="reinitializing"
                  class="d-flex justify-center align-center"
                >
                  <v-btn icon tile @click="uploadDialog = true">
                    <v-icon color="primary" large> mdi-upload </v-icon>
                  </v-btn>
                  <span class="caption ml-2">{{
                    $t("resources.media.upload")
                  }}</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!type">
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-column align-center">
                <v-btn icon tile @click="type = 'youtube'">
                  <v-icon color="red" large> mdi-youtube </v-icon>
                </v-btn>
                <span class="caption">{{
                  $t("resources.nuggets.sources.youtube")
                }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-column align-center">
                <v-btn icon tile @click="type = 'vimeo'">
                  <v-icon color="secondary" large> mdi-vimeo </v-icon>
                </v-btn>
                <span class="caption">{{
                  $t("resources.nuggets.sources.vimeo")
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-column align-center">
                <v-btn icon tile @click="type = 'ted'">
                  <v-icon color="secondary" large> mdi-alpha-t-box </v-icon>
                </v-btn>
                <span class="caption">{{
                  $t("resources.nuggets.sources.ted")
                }}</span>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-column align-center">
                <v-btn icon tile @click="uploadDialog = true">
                  <v-icon color="primary" large> mdi-upload </v-icon>
                </v-btn>
                <span class="caption">{{ $t("resources.media.upload") }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-spacer />
      <v-card-actions v-if="type && !initialLink">
        <v-spacer />
        <v-btn
          text
          small
          color="success"
          :disabled="
            !type ||
            !externalLink ||
            (type == 'youtube' && mediaLinkYoutubeError.length > 0) ||
            (type == 'vimeo' && mediaLinkVimeoError.length > 0) ||
            (type == 'ted' && mediaLinkTedError.length > 0)
          "
          @click="setExternalLink"
        >
          {{ $t("general.apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="uploadDialog"
      persistent
      no-click-animation
      max-width="480"
    >
      <v-card color="grey lighten-3">
        <v-card-text>
          <h3 class="overline primary--text text-center">
            {{ $t("resources.media.video_upload") }}
          </h3>
          <v-row v-show="!uploading && !uploaded">
            <v-col>
              <v-alert dense text border="left" type="info" color="primary">
                <span
                  class="caption"
                  v-html="$t('resources.media.upload_info.video')"
                />
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-show="!uploading && !uploaded">
            <v-col>
              <v-file-input
                v-model="file"
                accept="video/mp4"
                prepend-icon="mdi-video"
                background-color="white"
                dense
                show-size
                outlined
                :rules="rules"
                :label="$t('resources.media.video_upload')"
              />
              <!-- @change="preview('video-preview')" -->
            </v-col>
          </v-row>
          <v-row v-if="uploading">
            <v-col cols="12" class="text-center">
              <span class="secondary--text">{{
                $t("resources.media.loading_text")
              }}</span>
            </v-col>
          </v-row>
          <v-row v-show="file">
            <!-- <v-col
              :cols="
                (!uploading && !uploaded) || $vuetify.breakpoint.xsOnly ? 12 : 6
              "
              class="text-center"
            >
              <video
                id="video-preview"
                :controls="!uploading && !uploaded"
                style="max-width: 100%"
                :style="{ height: '100px' }"
              >
                {{ $t("resources.media.no_video_support") }}
              </video>
            </v-col> -->
            <v-col
              v-if="uploading || uploaded"
              cols="12"
              class="d-flex align-center justify-center"
            >
              <v-progress-circular
                :value="currentFileProgress"
                :color="uploaded ? 'success' : 'primary'"
                :size="96"
                :width="10"
                :rotate="-90"
              >
                <span v-if="!uploaded" class="font-weight-bold"
                  >{{ currentFileProgress }} %</span
                >
                <v-icon
                  v-if="uploaded"
                  large
                  :color="uploadError ? 'error' : 'success'"
                >
                  {{ uploadError ? "mdi-close " : "mdi-check" }}
                </v-icon>
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            small
            :disabled="uploading"
            @click="closeUploadDialog(true)"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            v-show="!uploaded"
            color="success"
            text
            small
            :loading="uploading"
            :disabled="!file || uploading"
            @click="upload"
          >
            {{ $t("resources.media.upload") }}
          </v-btn>
          <v-btn v-show="uploaded" text small color="success" @click="setVideo">
            {{ $t("general.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NuggetVideoPlayer from "@/components/nuggets/NuggetVideoPlayer.vue";
import { Nugget } from "@/models/Nugget";

export default {
  name: "VideoUpload",
  components: {
    NuggetVideoPlayer,
  },
  props: {
    media: {
      type: Object,
      required: false,
    },
    elevation: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    cardColor: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    nugget: {
      type: Nugget,
      required: false,
    },
    height: {
      type: [String, Number],
      required: false,
      default: 200,
    },
    label: {
      type: [String, Number],
      required: false,
      default: "Neu",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialType: {
      type: String,
      required: false,
    },
    initialLink: {
      type: String,
      required: false,
    },
    initialLang: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      uploadDialog: false,
      file: null,
      uploading: false,
      uploadProgress: null,
      uploaded: false,
      uploadError: false,
      previewImage: null,
      previewVideo: null,
      progressIdToken: "",
      type: "",
      externalLink: "",
      mediaLinkYoutubeError: "",
      mediaLinkVimeoError: "",
      mediaLinkTedError: "",
      showPlayer: false,
      uuid: "",
      rules: [
        (value) =>
          !value ||
          value.size < 750000000 ||
          this.$t("resources.media.validation", { size: "750" }),
      ],
      reinitializing: false,
    };
  },
  computed: {
    fileSize() {
      if (!this.file) return 0.0;
      return (this.file.size / 1024 / 1024).toFixed(2);
    },
    currentFileProgress() {
      if (!this.uploadProgress) return 0;
      if (this.uploaded) return 100;
      let progress = { ...this.uploadProgress };
      return Math.round((progress.received / progress.size) * 100);
    },
  },
  watch: {
    initialLink() {
      this.externalLink = this.initialLink;
    },
  },
  beforeMount() {
    if (this.initialType && this.initialLink) {
      this.type = this.initialType;
      this.externalLink = this.initialLink;
      this.showPlayer = false;
    }
    if (this.nugget) {
      this.externalLink = this.nugget.media_uid;
      this.type = this.nugget.source;
      this.showPlayer = true;
    }
  },
  methods: {
    closeUploadDialog(withReset) {
      this.uploadDialog = false;
      if (withReset)
        this.type && this.type === "p3lmedia"
          ? this.resetP3lmedia()
          : this.reset();
    },
    ...mapActions("media", [
      "fetch_media_upload_token",
      "upload_media",
      "fetch_upload_progress",
    ]),
    createProgressToken() {
      let randomString = (Math.random() + 1).toString(36).substring(7);
      let time = new Date().getTime().toString();
      return `video-${randomString}-${time}`;
    },
    async upload() {
      this.uploading = true;
      this.uploadError = false;
      this.progressIdToken = this.createProgressToken();
      let token = await this.fetch_media_upload_token();
      let upload_interval = setInterval(this.check_progress, 1000);

      let inputs = new FormData();
      inputs.append("file", this.file);
      inputs.append("type", "video");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Progress-ID": this.progressIdToken,
        },
      };

      let res = await this.upload_media({
        payload: inputs,
        token: token,
        config: config,
      });
      this.uploading = false;
      clearInterval(upload_interval);
      this.check_progress();
      this.progressIdToken = "";

      if (!res) {
        this.uploadError = true;
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.upload_error"),
        });
      } else {
        this.uuid = res.uuid;
        this.uploaded = true;
      }
    },
    async check_progress() {
      let config = {
        headers: {
          "Content-Type": "application/json",
          "X-Progress-ID": this.progressIdToken,
        },
      };
      let res = await this.fetch_upload_progress({ config: config });

      this.uploadProgress = res;
    },
    reset() {
      this.file = null;
      this.uploading = false;
      this.uploadProgress = null;
      this.uploaded = false;
      this.previewImage = null;
      this.progressIdToken = "";
      this.type = "";
      this.externalLink = "";
      this.mediaLinkYoutubeError = "";
      this.mediaLinkVimeoError = "";
      this.mediaLinkTedError = "";
      this.uuid = "";
      this.showPlayer = false;
      this.reinitializing = false;
    },
    resetP3lmedia() {
      this.file = null;
      this.uploading = false;
      this.uploadProgress = null;
      this.uploaded = false;
      this.previewImage = null;
      this.progressIdToken = "";
      this.type = "p3lmedia";
      this.externalLink = "";
      this.mediaLinkYoutubeError = "";
      this.mediaLinkVimeoError = "";
      this.mediaLinkTedError = "";
      this.uuid = "";
      this.showPlayer = false;
      this.reinitializing = true;
    },
    preview(elementId) {
      let video = document.getElementById(elementId);
      let reader = new FileReader();

      reader.readAsDataURL(
        elementId === "video-preview-uploaded" ? this.previewVideo : this.file
      );
      reader.addEventListener("load", function () {
        video.src = reader.result;
      });
    },
    youtubeLinkChanged(value) {
      if (value.includes("www")) {
        this.externalLink = value.replace("www.", "");
      }
      if (value.match(/https:\/\/www.youtube.com\/watch\?v=/g)) {
        this.externalLink = value.replace(
          /https:\/\/www.youtube.com\/watch\?v=/g,
          "https://youtube.com/embed/"
        );
      }
      if (value.match(/https:\/\/youtu.be\//g)) {
        this.externalLink = value.replace(
          /https:\/\/youtu.be\//g,
          "https://youtube.com/embed/"
        );
      }
      if (!this.externalLink.match(/https:\/\/youtube.com\/embed\//g)) {
        this.mediaLinkYoutubeError = this.$t(
          "resources.nuggets.video_youtube_hint"
        );
      }
      if (this.externalLink.match(/https:\/\/youtube.com\/embed\//g))
        this.mediaLinkYoutubeError = "";
      this.$emit("video-changed-dialog", this.type, this.externalLink);
      this.$emit("video-changed-single", [this.type, this.externalLink]);
    },
    vimeoLinkChanged(value) {
      if (value.match(/https:\/\/vimeo.com\//g)) {
        this.externalLink = value.replace(
          /https:\/\/vimeo.com\//g,
          "https://player.vimeo.com/video/"
        );
      }
      if (!this.externalLink.match(/https:\/\/player.vimeo.com\/video\//g)) {
        this.mediaLinkVimeoError = this.$t(
          "resources.nuggets.video_vimeo_hint"
        );
      }
      this.$emit("video-changed-dialog", this.type, this.externalLink);
      this.$emit("video-changed-single", [this.type, this.externalLink]);
    },
    tedLinkChanged(value) {
      if (value.match(/https:\/\/www.ted.com\/talks\//g)) {
        this.externalLink = value.replace(
          /https:\/\/www.ted.com\/talks\//g,
          "https://embed.ted.com/talks/"
        );
      }
      if (!this.externalLink.match(/https:\/\/embed.ted.com\/talks\//g)) {
        this.mediaLinkTedError = this.$t("resources.nuggets.video_ted_hint");
      }
      this.$emit("video-changed-dialog", this.type, this.externalLink);
      this.$emit("video-changed-single", [this.type, this.externalLink]);
    },
    setExternalLink() {
      this.showPlayer = true;
      this.$emit("video-changed", this.type, this.externalLink);
    },
    setVideo() {
      this.showPlayer = this.reinitializing ? false : true;
      this.previewVideo = this.file;
      this.preview("video-preview-uploaded");
      this.$emit("video-changed", "p3lmedia", null, this.uuid);
      this.$emit("video-changed-dialog", "p3lmedia", this.uuid);
      this.$emit("video-changed-single", ["p3lmedia", this.uuid]);
      this.file = null;
      this.uploading = false;
      this.uploadProgress = null;
      this.uploaded = false;
      this.previewImage = null;
      this.uploadError = false;
      this.progressIdToken = "";
      this.type = "p3lmedia";
      //this.externalLink = "";
      this.mediaLinkYoutubeError = "";
      this.mediaLinkVimeoError = "";
      this.mediaLinkTedError = "";
      this.reinitializing = false;
      this.closeUploadDialog(false);
    },
  },
};
</script>

<style></style>
