<template>
  <div>
    <ChipNavigation
      :nav-items="navItems"
      always-expanded
      color="info"
      :disabled="loading"
    />

    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChipNavigation from "@/components/_layout/ChipNavigation";

export default {
  name: "KpiActivity",
  components: {
    ChipNavigation,
  },
  data() {
    return {
      typeOptions: [
        { value: "structures", label: this.$t("kpi.type_options.structures") },
        {
          value: "substructures",
          label: this.$t("kpi.type_options.substructures"),
        },
        { value: "categories", label: this.$t("kpi.type_options.categories") },
        { value: "users", label: this.$t("kpi.type_options.users") },
      ],
    };
  },
  computed: {
    ...mapGetters("kpi", {
      dates: "get_kpi_dates",
      type: "get_kpi_type",
      current: "get_kpi_current",
      loading: "get_kpi_loading",
      version: "get_kpi_version",
    }),
    navItems() {
      var items = [
        {
          title: this.$t("kpi.current_chart_title"),
          link: { name: "KpiActivityOverview" },
        },
        {
          title: this.$t("kpi.speed"),
          link: { name: "KpiActivitySpeed" },
          icon: "mdi-play-speed",
        },
      ];
      if (this.auth.role_id === 5)
        items.push({
          title: "overallspeed tmp",
          link: { name: "KpiActivityOverallSpeed" },
          icon: "mdi-play-speed",
        });
      return items;
    },
  },
  watch: {
    dates: {
      handler: function () {
        this.fetchStatistics();
      },
    },
    type: {
      handler: function () {
        this.fetchStatistics();
      },
    },
    version: {
      handler: function () {
        this.fetchStatistics();
      },
    },
  },
  beforeMount() {
    if (!this.type) this.set_kpi_type(this.typeOptions[0]);
    if (!this.current) this.fetchStatistics();
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_statistics", "fetch_kpi_mappers"]),
    ...mapMutations("kpi", [
      "set_kpi_current",
      "set_kpi_current_stats",
      "set_kpi_type",
    ]),
    async fetchStatistics() {
      await this.fetch_kpi_mappers();
      var payload = {
        date_start: this.dates.date_start,
        date_end: this.dates.date_end,
      };
      await this.fetch_kpi_statistics({
        payload: payload,
        type: this.type ? this.type.value : "structures",
      });
    },
  },
};
</script>
