<template>
  <v-dialog
    v-if="showDialog"
    :value="showDialog"
    width="500"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title class="text-overline pb-0">
        <v-icon class="mr-2" small color="error"> mdi-alert </v-icon>
        <span>{{ $t("general.attention") }}!</span>
      </v-card-title>

      <v-card-text class="pt-2">
        {{ $t("resources.journeys.addStructureWarning") }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text small @click="closeDialog()">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn color="success" text small @click="closeDialog(true)">
          {{ $t("general.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "JourneyDialogStructureWarning",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
  },
  methods: {
    closeDialog(wasDeleted) {
      this.$emit("close", wasDeleted);
    },
  },
};
</script>

<style></style>
