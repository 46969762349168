<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />
    <v-dialog :value="dialog" width="500" @click:outside="closeDialog()">
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-title class="text-overline pb-0">
          <span>{{ $t("ng.journey.add_bundle") }}</span>
        </v-card-title>

        <v-card-text class="pt-2">
          <v-form v-model="valid" :disabled="loading">
            <v-row dense>
              <v-col cols="6">
                <LanguageChooserInline
                  :label="$t('ng.source_language')"
                  background-color="white"
                  colored
                  :initial="input.language"
                  @change="changeLanguage"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="input.level"
                  :items="bundleLevels"
                  dense
                  outlined
                  background-color="white"
                  :label="$t('resources.journeys.level')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.name"
                  :counter="100"
                  :rules="rules.name"
                  required
                  dense
                  background-color="white"
                  outlined
                  :label="$t('resources.journeys.propNames.name')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.description"
                  :counter="200"
                  :rules="rules.description"
                  required
                  dense
                  outlined
                  background-color="white"
                  :label="$t('resources.journeys.propNames.description')"
                />
              </v-col>
              <v-col cols="12">
                <v-slider
                  v-model="input.quiz_criteria"
                  :label="$t('resources.journeys.quiz_criteria')"
                  thumb-color="primary"
                  thumb-label="always"
                  track-color="secondary"
                  min="0"
                  max="100"
                ></v-slider>
              </v-col>
              <v-col cols="12">
                <ImageUpload
                  :image-hash="input.image_token"
                  :disabled="
                    !['superadmin', 'admin', 'editor'].includes(role) || loading
                  "
                  card-color="grey lighten-2"
                  @image-changed="imageChange"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="secondary" small text @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!valid"
            small
            :loading="loading"
            text
            @click="addBundle"
          >
            {{ $t("general.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageUpload from "@/components/global/ImageUpload";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "AddBundleDialog",
  components: { ImageUpload, LanguageChooserInline },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      input: {
        name: "",
        description: "",
        image: "",
        image_token: "",
        level: 0,
        language: this.$i18n.locale,
        quiz_criteria: 0,
      },
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
            }),
          (v) =>
            (v && v.length <= 100) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
              max: 100,
            }),
        ],
        description: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
            }),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
              max: 200,
            }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("journeys", {
      language: "get_selected_language",
      bundles: "get_journey_bundles",
      journey: "get_journey",
    }),
    bundleLevels() {
      let levels = [1];
      if (this.bundles && this.bundles.length > 0) {
        this.bundles.forEach((bundle) => {
          if (!levels.includes(bundle.level)) levels.push(bundle.level);
        });
        levels.push(levels[levels.length - 1] + 1);
        return levels;
      }
      return levels;
    },
  },
  methods: {
    ...mapActions("journeys", ["add_bundle"]),
    openDialog() {
      this.input.language = this.language;
      this.dialog = true;
      this.reset();
    },
    closeDialog() {
      this.dialog = false;
    },
    reset() {
      this.input.image_token = null;
      this.input.image = null;
      this.input.name = "";
      this.input.description = "";
      this.input.level = this.bundleLevels[this.bundleLevels.length - 1];
      this.input.quiz_criteria = 0;
    },
    async addBundle() {
      if (!this.valid) return false;
      this.loading = true;

      let input = { ...this.input };
      let payload = {
        journey_id: this.journey.id,
        name: input.name,
        description: input.description,
        level: input.level ? input.level : 0,
        image: input.image,
        language: input.language,
        quiz_criteria: input.quiz_criteria,
      };

      let res = await this.add_bundle({
        payload: payload,
      });
      this.loading = false;

      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("general.createError"),
        });
      }
    },
    imageChange(image) {
      this.input.image_token = image.hash;
      this.input.image = image.filename;
    },
    changeLanguage(lang) {
      this.input.language = lang;
    },
  },
};
</script>

<style></style>
