export const set_questions = (state, questions) =>
  (state.questions = questions);
export const set_question = (state, question) => (state.question = question);
export const set_question_reports = (state, reports) =>
  (state.question_reports = reports);
export const set_question_translations = (state, translations) =>
  (state.question_translations = translations);
export const set_answer_translations = (state, translations) =>
  (state.answer_translations = translations);
export const set_selected_language = (state, lang) =>
  (state.selected_language = lang);
export const set_reviewmode = (state, mode) => (state.reviewmode = mode);
export const set_reportmode = (state, mode) => (state.reportmode = mode);
export const set_explanation_translations = (state, translations) =>
  (state.explanation_translations = translations);
