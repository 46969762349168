export const state = {
  statistics: null,
  statistics_details: null,
  options: {
    timeFrame: 1,
    timePointer: parseInt(new Date().getMonth()) + 1,
    currentChart: "",
  },
  isLoading: false, // todo: remove
};
