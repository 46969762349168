<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="tableHeaders"
                :items="sortedCertificates"
                :items-per-page="10"
                :page.sync="filter.page"
                :loading="!certificates"
              >
                <template #item="{ item }">
                  <tr @click="goToCertificate(item)">
                    <td>
                      <v-tooltip v-show="item.is_default" right color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            :color="item.is_active ? 'success' : 'error'"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                        </template>
                        <span>{{
                          !item.is_active
                            ? $t("certificate.activation.deactivated")
                            : $t("certificate.activation.activated")
                        }}</span>
                      </v-tooltip>
                    </td>
                    <td>{{ item.name }}</td>
                    <td class="d-flex align-center justify-end">
                      <v-tooltip v-if="item.is_locked" left color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            :color="
                              item.is_locked ? 'secondary' : 'grey lighten-3'
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.is_locked ? "mdi-lock" : "mdi-lock-off" }}
                          </v-icon>
                        </template>
                        <span>{{ $t("certificate.locked_hint") }}</span>
                      </v-tooltip>
                      <v-tooltip left color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-btn
                              icon
                              small
                              @click.stop="
                                item.is_default || !item.is_active
                                  ? false
                                  : setDefault(item.id)
                              "
                            >
                              <v-icon
                                small
                                :color="
                                  item.is_default
                                    ? 'primary'
                                    : 'secondary lighten-3'
                                "
                              >
                                {{
                                  item.is_default
                                    ? "mdi-star"
                                    : "mdi-star-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <div>
                          <div>
                            {{
                              item.is_default
                                ? $t("certificate.is_default")
                                : $t("certificate.set_default")
                            }}
                          </div>
                          <small
                            v-if="!item.is_active"
                            class="font-weight-bold"
                            >{{
                              $t("certificate.default_needs_activation")
                            }}</small
                          >
                        </div>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";

export default {
  name: "JourneyCertificates",
  components: {
    ViewLoader,
  },
  data() {
    return {
      loading: true,
      filter: {
        page: 1,
      },
      tableHeaders: [
        {
          text: this.$t("resources.journeys.status"),
          value: "is_active",
          width: "10%",
        },
        { text: this.$t("certificate.form.labels.name"), value: "name" },
        {
          text: this.$t("certificate.default"),
          width: "10%",
          value: "is_default",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("journeys", { certificates: "get_certificates" }),
    sortedCertificates() {
      if (!this.certificates) return [];
      var certificates = [...this.certificates];
      return certificates.sort((a, b) => b.id - a.id);
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
    //this.getFilter();
  },
  destroyed() {
    this.set_certificates([]);
  },
  methods: {
    ...mapActions("journeys", [
      "fetch_certificates",
      "update_certificate",
      "update_certificate_default",
    ]),
    ...mapMutations("journeys", ["set_certificates"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_certificates();
      this.loading = false;
    },
    goToCertificate(c) {
      this.$router.push({ name: "CertificateDetail", params: { id: c.id } });
    },
    async setDefault(id) {
      //this.loading = true;
      var res = await this.update_certificate_default({
        id: id,
      });
      //this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: this.$t("certificate.activation.change_success"),
          type: "success",
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: this.$t("certificate.activation.change_error"),
          type: "error",
        });
      }
    },
  },
};
</script>
