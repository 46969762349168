<template>
  <div>
    <v-tooltip top color="primary" :disabled="!disabled">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            color="primary"
            x-small
            text
            :disabled="disabled"
            @click="showDialog = true"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </div>
      </template>
      <span>{{
        !disabled
          ? $t("resources.questions.activationHint_cannotBeEdited")
          : $t("resources.questions.activationHint_unsaved")
      }}</span>
    </v-tooltip>

    <v-dialog v-if="showDialog" v-model="showDialog" max-width="640">
      <v-card color="grey lighten-4" :loading="loading">
        <v-card-title class="secondary--text overline">
          {{ $t("resources.categories.singular") }}
        </v-card-title>
        <v-card-text class="py-2">
          <v-autocomplete
            v-model="selectedCategory"
            :items="sortedCategories"
            item-value="id"
            item-text="name"
            filled
            dense
            chips
            outlined
            background-color="white"
            small-chips
            :disabled="loading"
            :loading="loading"
            deletable-chips
          >
            <template #item="{ item }">
              <v-list-item-action>
                <v-icon>{{
                  selectedCategory === item.id
                    ? "mdi-checkbox-marked-circle-outline"
                    : "mdi-checkbox-blank-circle-outline"
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip v-if="item.groups.length === 0" x-small label>
                    Ohne Gruppe
                  </v-chip>
                  <v-chip
                    v-for="group in item.groups"
                    :key="group.id"
                    x-small
                    label
                    class="mr-2"
                  >
                    {{ group.name }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text color="secondary" @click="showDialog = false">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            small
            text
            color="success"
            :disabled="!inputChanged || !selectedCategory"
            :loading="loading"
            @click="changeCategories()"
          >
            {{ $t("general.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "NuggetCategoryChanger",
  props: {
    nugget: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      selectedCategory: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    inputChanged() {
      let changed = false;
      if (this.selectedCategory !== this.nugget.category.id) {
        changed = true;
      }
      return changed;
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
  },
  watch: {
    showDialog(val) {
      if (val) this.fetchCategories();
      if (!val) this.set_visible_categories(null);
    },
  },
  methods: {
    ...mapActions("nuggets", ["patch_nugget_old"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("categories", ["set_visible_categories"]),
    resetCategories() {
      this.selectedCategory = this.nugget.category
        ? this.nugget.category.id
        : 0;
    },
    async fetchCategories() {
      this.resetCategories();
      this.loading = true;
      await this.fetch_categories();
      this.loading = false;
    },
    async changeCategories() {
      this.loading = true;

      let payload = {
        id: this.nugget.id,
        category_id: this.selectedCategory,
        language: this.$i18n.locale,
      };

      let res = await this.patch_nugget_old({
        payload: payload,
        type: "category",
        language: this.$i18n.locale,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.showDialog = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
