<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.community.title')"
      icon="mdi-earth"
    />
    <CommunityForm :model="community" />
    <!-- <v-tabs
      v-model="tab"
      centered
    >
      <v-tab :href="'#tab-overview'">
        {{ $t("general.overview") }}
      </v-tab>
      <v-tab
        :href="'#tab-templates'"
        disabled
      >
        {{
          $t("views.community.templates")
        }}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-overview'">
          <CommunityForm :model="community" />
        </v-tab-item>

        <v-tab-item :value="'tab-templates'">
          under construction
        </v-tab-item>
      </v-tabs-items>
    </v-tabs> -->
  </div>
</template>

<script>
import CommunityForm from "@/components/community/CommunityForm.vue";
import ViewHeader from "@/components/_layout/ViewHeader.vue";

export default {
  name: "CommunityView",
  components: {
    CommunityForm,
    ViewHeader,
  },
  data() {
    return {
      tab: "tab-overview",
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.account.title"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
  },
};
</script>
