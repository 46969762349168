<template>
  <div v-if="[5].includes(auth.role_id)">
    <v-select
      v-model="selectedVersion"
      :items="versions"
      style="max-width: 120px"
      solo
      flat
      :background-color="color"
      dense
      hide-details
      :dark="dark"
      @change="changeVersion()"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "KpiVersionSelector",
  props: {
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
  },
  data() {
    return {
      versions: ["v1", "v2"],
      selectedVersion: "",
    };
  },
  computed: {
    ...mapGetters("kpi", { version: "get_kpi_version" }),
  },
  mounted() {
    this.setCurrentVersion();
  },
  methods: {
    ...mapMutations("kpi", ["set_kpi_version"]),
    setCurrentVersion() {
      this.selectedVersion = this.version;
    },
    changeVersion() {
      this.set_kpi_version(this.selectedVersion);
    },
  },
};
</script>

<style></style>
