var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("button",null,{"onClick":_vm.openDialog}),_c('v-dialog',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdAndUp ? 800 : '100%'},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"grey lighten-4"}},[_c('v-card-title',{staticClass:"secondary--text overline"},[_vm._v(" "+_vm._s(_vm.$t("ng.edit_translations"))+" ")]),(_vm.translations && !_vm.loading)?_c('v-card-text',{staticClass:"px-0"},[_c('v-expansion-panels',{attrs:{"value":_vm.activePanels,"multiple":"","flat":"","dense":"","disabled":!_vm.explanation}},_vm._l((_vm.languages),function(language,i){return _c('v-expansion-panel',{key:i,staticClass:"transparent my-0",attrs:{"dense":""}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32","tile":""}},[_c('country-flag',{attrs:{"country":_vm.flagName(language)}})],1),(_vm.translations[language])?_c('div',[_vm._v(" "+_vm._s(_vm.translations[language][_vm.propName])+" ")]):_vm._e(),(!_vm.translations[language])?_c('div',[_vm._v(" "+_vm._s(_vm.$t("ng.no_translations"))+" ")]):_vm._e()],1)]),_c('v-expansion-panel-content',[_c('InputTextfield',{attrs:{"value":_vm.inputs[language].value,"counter":"255","rules":_vm.rules.input,"listen-to-input":"","disabled":_vm.disabled},on:{"input":function($event){return _vm.setValue(language, $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.disabled ||
                        _vm.translations[language][_vm.propName] ===
                          _vm.inputs[language].value},on:{"click":function($event){return _vm.resetInput(language)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1),_c('v-btn',{attrs:{"disabled":_vm.disabled ||
                        _vm.translations[language][_vm.propName] ===
                          _vm.inputs[language].value ||
                        _vm.inputs[language].value.length === 0 ||
                        _vm.inputs[language].value.length > 255,"icon":"","color":"success"},on:{"click":function($event){return _vm.saveTranslation(language)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-check ")])],1)],1)]},proxy:true}],null,true)})],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","disabled":_vm.activePanels.length === 0},on:{"click":function($event){return _vm.hideAllPanels()}}},[_vm._v(" "+_vm._s(_vm.$t("ng.close_all"))+" ")]),_c('v-btn',{attrs:{"text":"","small":"","disabled":_vm.activePanels.length === _vm.languages.length - 1},on:{"click":function($event){return _vm.showAllPanels()}}},[_vm._v(" "+_vm._s(_vm.$t("ng.show_all"))+" ")]),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }