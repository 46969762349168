import { Journeystep } from "./Journeystep";

export class Journeybundle {
  id = -1;
  name = "";
  image = "";
  image_token = "";
  description = "";

  level = -1;

  steps = [];
  quiz_criteria = -1;

  journey = null;

  constructor(obj, journey) {
    this.id = obj.id;
    this.name = obj.name;
    this.image = obj.image;
    this.image_token = obj.image_token;
    this.description = obj.description;
    this.level = obj.level;
    this.steps = obj.steps ? this.generateSteps(obj.steps) : [];
    this.quiz_criteria = this.getQuizCriteria(obj);
    this.journey = journey;
  }

  generateSteps(stepInput) {
    let steps = [];
    stepInput.forEach((obj) => steps.push(new Journeystep(obj)));
    return steps;
  }

  getImage() {
    if (!this.image_token)
      return require("@/assets/img/misc/default_small.jpg");
    return `${process.env.VUE_APP_MEDIA_SERVER_URL}/i/${this.image_token}`;
  }

  getQuizCriteria(bundle) {
    if (!bundle.criterias) return null;
    var criteria = bundle.criterias.filter(
      (c) => c.type.name === "quiz_question_percent"
    )[0];
    if (!criteria) {
      return null;
    } else {
      return criteria.value / 100;
    }
  }
}
