<template>
  <div>
    <v-container class="px-0">
      <v-row dense>
        <v-col cols="6">
          <KpiStatsProgressCard
            :title="$t('kpi.discovery')"
            color="grey lighten-2"
            highlight-color="accent"
            icon="mdi-progress-check"
            :value="displayedStats ? displayedStats.completion[0] : 0"
            :delta="
              displayedStats
                ? displayedStats.completion[0] - displayedStats.completion[1]
                : 0
            "
            :loading="!displayedStats"
            hoverable
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="6">
          <KpiStatsProgressCard
            :title="$t('kpi.competency')"
            color="grey lighten-2"
            highlight-color="accent"
            icon="mdi-progress-star"
            :value="displayedStats ? displayedStats.competency[0] : 0"
            :delta="
              displayedStats
                ? displayedStats.competency[0] - displayedStats.competency[1]
                : 0
            "
            :loading="!displayedStats"
            hoverable
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="6">
          <KpiStatsProgressCard
            :title="$t('kpi.retention')"
            color="grey lighten-2"
            highlight-color="accent"
            icon="mdi-progress-star-four-points"
            :value="displayedStats ? displayedStats.retention[0] : 0"
            :delta="
              displayedStats
                ? displayedStats.retention[0] - displayedStats.retention[1]
                : 0
            "
            :loading="!displayedStats"
            hoverable
            @click="$emit('change-tab', 1)"
          />
        </v-col>
        <v-col cols="6">
          <KpiStatsProgressCard
            :title="$t('kpi.speed')"
            color="grey lighten-2"
            highlight-color="info"
            icon="mdi-play-speed"
            :value="displayedStats ? displayedStats.currentSpeed[0] : 0"
            :delta="
              displayedStats
                ? displayedStats.currentSpeed[0] -
                  displayedStats.currentSpeed[1]
                : 0
            "
            :loading="!displayedStats"
            hoverable
            @click="$emit('change-tab', 2)"
          />
        </v-col>
        <v-col v-if="role === 'superadmin'" cols="12">
          <v-btn
            @click="showKpiStats = !showKpiStats"
            small
            depressed
            color="info"
          >
            superadmin toggle data (aktuell:
            {{ showKpiStats ? "current" : "prop" }})
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KpiStatsProgressCard from "@/components/kpi/KpiStatsProgressCard";

export default {
  name: "KpiSubstructureMain",
  components: {
    KpiStatsProgressCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showKpiStats: false,
    };
  },
  computed: {
    ...mapGetters("kpi", {
      current: "get_kpi_current_detail",
      kpi_stats: "get_kpi_current_detail_stats",
      resource: "get_kpi_resource_detail",
    }),
    displayedStats() {
      if (!this.stats) return null;
      return this.showKpiStats ? { ...this.kpi_stats } : { ...this.stats };
    },
  },
};
</script>

<style></style>
