<template>
  <div>
    <section
      v-if="user && user_stats && structures && community && categories"
      class="mt-4"
    >
      <v-container>
        <v-row dense>
          <v-col cols="12" md="3">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="user.avatar_img_url" alt="user avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${user.first_name} ${user.last_name}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    user.user_name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="6" md="3">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>mdi-clock-time-four</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    formatDate(user.last_activity)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("resources.users.last_activity")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>mdi-circle-multiple</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.score }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("resources.users.score")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>mdi-podium</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.level_id }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("resources.users.level")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="showKPI" cols="12">
            <SectionHeader
              :title="$t('resources.categories.plural')"
              no-padding
            >
              <template #action>
                <v-tooltip left max-width="400" color="info">
                  <template #activator="{ on, attrs }">
                    <v-icon color="info" v-bind="attrs" v-on="on"
                      >mdi-information</v-icon
                    >
                  </template>
                  <div>
                    <h5 class="text-subtitle-1">{{ $t("kpi.discovery") }}</h5>
                    <p v-html="$t('kpi.discovery_description_long')" />
                    <h5 class="text-subtitle-1">{{ $t("kpi.competency") }}</h5>
                    <p v-html="$t('kpi.competency_description_long')" />
                    <h5 class="text-subtitle-1">{{ $t("kpi.retention") }}</h5>
                    <p v-html="$t('kpi.retention_description_long')" />
                  </div>
                </v-tooltip>
              </template>
            </SectionHeader>
            <v-card v-if="user_stats.categories" outlined>
              <v-card-text>
                <UserStatisticsCategories :categories="user_stats.categories" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <SectionHeader
              :title="$t('resources.journeys.journey.plural')"
              no-padding
            />
            <v-card v-if="user_stats && user_stats.journeys" outlined>
              <v-card-text>
                <UserStatisticsJourney :journey-stats="user_stats.journeys" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <SectionHeader :title="$t('views.statistics.overall')" no-padding />
            <v-card outlined>
              <StatisticsListUser v-if="user_stats" :stats="user_stats" />
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <SectionHeader :title="$t('resources.nuggets.plural')" no-padding />
            <v-card v-if="user_stats && user_stats.nuggets" outlined>
              <v-card-text>
                <UserStatisticsNugget :nugget-stats="user_stats.nuggets" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatisticsListUser from "@/components/statistics/StatisticsListUser.vue";
import UserStatisticsNugget from "@/components/users/UserStatisticsNugget";
import UserStatisticsJourney from "@/components/users/UserStatisticsJourney";
import UserStatisticsCategories from "@/components/users/UserStatisticsCategories";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "UserStatistics",
  components: {
    StatisticsListUser,
    UserStatisticsNugget,
    UserStatisticsJourney,
    UserStatisticsCategories,
    SectionHeader,
  },
  computed: {
    ...mapGetters("users", { user: "get_user", user_stats: "get_user_stats" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("community", { community: "get_community" }),
    ...mapGetters("categories", { categories: "get_categories" }),
    showKPI() {
      return process.env.VUE_APP_SHOW_KPI === "true";
    },
  },
  methods: {
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
  },
};
</script>
