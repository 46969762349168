<template>
  <div>
    <v-tooltip bottom :color="highlightColor" max-width="200">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-hover v-slot="{ hover }">
            <v-card
              :color="color"
              :dark="dark"
              :elevation="hover && (!!to || hoverable) ? 0 : 0"
              class="fill-height"
              :class="[{ 'rounded-xl': roundedXl }]"
              :to="to"
              outlined
              style="transition: 0.25s"
              :loading="loading"
              :disabled="loading"
              @click="$emit('click')"
            >
              <template #progress>
                <v-progress-linear
                  indeterminate
                  :color="highlightColor"
                  height="2"
                />
              </template>

              <v-list-item dense two-line>
                <v-list-item-icon v-show="!!icon">
                  <v-progress-circular
                    :value="value * 100"
                    size="48"
                    :color="highlightColor"
                  >
                    <v-icon size="36">{{ icon }}</v-icon>
                  </v-progress-circular>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
                  <v-expand-transition>
                    <v-list-item-subtitle v-if="!!delta || delta === 0">
                      <v-sheet color="transparent" class="d-inline" rounded>
                        <span class="caption font-weight-bold">{{
                          delta.toFixed(toFixedValue)
                        }}</span>
                        <v-avatar
                          color="transparent"
                          size="12"
                          class="ml-1"
                          rounded
                        >
                          <v-icon
                            :color="
                              delta > 0
                                ? 'success'
                                : delta < 0
                                ? 'error'
                                : 'secondary'
                            "
                          >
                            {{
                              delta > 0
                                ? "mdi-menu-up"
                                : delta < 0
                                ? "mdi-menu-down"
                                : "mdi-square-small"
                            }}
                          </v-icon>
                        </v-avatar>
                      </v-sheet>
                    </v-list-item-subtitle>
                  </v-expand-transition>
                </v-list-item-content>
                <v-list-item-action>
                  <div
                    class="font-weight-bold"
                    :class="`${highlightColor}--text`"
                  >
                    <span>{{ value.toFixed(toFixedValue) }}</span>
                    <span v-if="unit">{{ unit }}</span>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-hover>
        </div>
      </template>
      <div>
        <p
          v-if="title"
          class="font-weight-bold"
          :class="{ 'pb-0 mb-0 text-center': !delta && delta !== 0 }"
        >
          {{ title }}
        </p>
        <p v-if="delta || delta === 0">
          {{ $t("kpi.monthly_changes") }}:
          <br />
          <b>{{ delta.toFixed(toFixedValue) }}</b>
        </p>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "KpiStatsProgressCard",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: [String, Number],
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    to: {
      type: Object,
      required: false,
      default: () => null,
    },
    color: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    delta: {
      type: Number,
      required: false,
    },
    highlightColor: {
      type: String,
      required: false,
      default: "white",
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: false,
    },
    roundedXl: {
      type: Boolean,
      required: false,
      default: false,
    },
    toFixedValue: {
      type: Number,
      required: false,
      default: 2,
    },
    unit: {
      type: String,
      required: false,
    },
  },
};
</script>

<style></style>
