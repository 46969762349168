<template>
  <div>
    <v-card :color="getStatusColor()" flat dark>
      <v-card-title class="d-flex">
        <div>
          <v-icon class="mr-2">{{ getStatusIcon() }}</v-icon>
          <span>{{ $t(`resources.journeys.statuses.${journey.status}`) }}</span>
        </div>

        <v-spacer />

        <TutorialCarouselDialog
          :templates="tutorialStatus"
          class="ml-2"
          icon="mdi-help-circle"
          icon-color="white"
          small
        />
      </v-card-title>
      <v-card-subtitle>{{ $t("ng.journey.status_journey") }}</v-card-subtitle>
      <v-card-text>{{ $t("ng.journey.status_journey_subtitle") }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-tooltip bottom :disabled="!journey.isSkippingLevels">
          <template #activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-menu offset-x left close-on-content-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="grey lighten-3"
                    elevation="0"
                    small
                    text
                    v-bind="attrs"
                    v-on="on"
                    :disabled="
                      ['closed', 'expired'].includes(journey.status) ||
                      !['superadmin', 'admin', 'editor'].includes(role) ||
                      journey.isSkippingLevels
                    "
                  >
                    <span>{{ $t("resources.journeys.changeStatus") }}</span>
                  </v-btn>
                </template>
                <v-list dense class="py-0">
                  <v-subheader
                    class="caption font-weight-bold pl-4 grey lighten-3"
                  >
                    <span class="grey--text">{{
                      $t("resources.journeys.changeStatus")
                    }}</span>
                  </v-subheader>
                  <v-list-item
                    v-for="(status, index) in statuses"
                    :key="index"
                    :disabled="optionIsDisabled(status)"
                    @click="changeStatus(status)"
                  >
                    <v-list-item-title>{{ status.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>{{ $t("ng.journey.level_error_hint") }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>

    <div class="d-flex align-center">
      <JourneyDialogStatus
        v-if="showStatusDialog"
        :dialog="showStatusDialog"
        :journey="journey"
        :new-status="newStatus"
        @close="closeStatusDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Journey } from "@/models/journeys/Journey.js";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog";
import JourneyDialogStatus from "@/components/journeys/dialogs/JourneyDialogStatus";

export default {
  name: "JourneyStatusChanger",
  components: {
    TutorialCarouselDialog,
    JourneyDialogStatus,
  },
  props: {
    journey: {
      type: Journey,
      required: true,
    },
  },
  data() {
    return {
      statuses: [
        {
          type: "unpublished",
          name: this.$t("resources.journeys.statuses.unpublished"),
        },
        {
          type: "published",
          name: this.$t("resources.journeys.statuses.published"),
        },
        { type: "active", name: this.$t("resources.journeys.statuses.active") },
        /* { type: 'paused', name: this.$t('resources.journeys.statuses.paused') }, */
        { type: "closed", name: this.$t("resources.journeys.statuses.closed") },
        /* { type: 'expired', name: this.$t('resources.journeys.statuses.expired') } */
      ],
      tutorialStatus: ["journeystatus"],
      showStatusDialog: false,
      newStatus: "",
    };
  },
  methods: {
    ...mapActions("journeys", ["patch_journey_status"]),
    changeStatus(newStatus) {
      this.newStatus = newStatus.type;
      this.showStatusDialog = true;
    },
    closeStatusDialog() {
      this.showStatusDialog = false;
    },
    optionIsDisabled(status) {
      let isDisabled = false;
      let journeyStatus = this.journey.status;
      if (
        ["active", "paused", "closed", "expired"].includes(status.type) &&
        journeyStatus == "unpublished"
      )
        isDisabled = true;
      if (
        ["paused", "closed", "expired"].includes(status.type) &&
        journeyStatus == "published"
      )
        isDisabled = true;
      if (
        ["unpublished", "published", "expired"].includes(status.type) &&
        journeyStatus == "active"
      )
        isDisabled = true;
      if (
        ["unpublished", "published", "expired"].includes(status.type) &&
        journeyStatus == "paused"
      )
        isDisabled = true;
      if (
        ["unpublished", "published", "active", "paused", "expired"].includes(
          status.type
        ) &&
        journeyStatus == "closed"
      )
        isDisabled = true;
      if (
        ["unpublished", "published", "active", "paused", "closed"].includes(
          status.type
        ) &&
        journeyStatus == "expired"
      )
        isDisabled = true;
      if (status.type == journeyStatus) isDisabled = true;
      return isDisabled;
    },
    getStatusIcon() {
      if (this.journey.status == "unpublished")
        return "mdi-circle-edit-outline";
      if (this.journey.status == "published")
        return "mdi-checkbox-marked-circle-outline";
      if (this.journey.status == "active") return "mdi-play-circle-outline";
      if (this.journey.status == "paused") return "mdi-pause-circle-outline";
      if (this.journey.status == "closed") return "mdi-close-circle-outline";
      if (this.journey.status == "expired") return "mdi-timer-off-outline";
      // deleted mdi-close-circle-outline
      return "mdi-circle";
    },
    getStatusColor() {
      /* if(status == 'unpublished') return 'secondary'; */
      if (this.journey.status == "published") return "secondary";
      if (this.journey.status == "active") return "success";
      if (this.journey.status == "paused") return "warning";
      if (this.journey.status == "expired" || this.journey.status == "closed")
        return "error";
      return "primary";
    },
  },
};
</script>

<style></style>
