export class Category {
  id = -1;
  name = "";
  description = "";
  is_active = false;
  is_active_nugget = false;
  img_url = "";
  substructure_ids = [];
  stats = null;
  groups = [];

  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.description = obj.description ? obj.description : "";
    this.is_active = obj.isactive;
    this.is_active_nugget = obj.isactive_nugget;
    this.img_url = obj.image;
    this.substructures = obj.substructures;
    this.substructure_ids = this.getSubstructureIds();
    this.stats = obj.stats;
    this.groups = obj.groups;
    this.can = {
      activate: obj && obj.can ? obj.can.activate : false,
      deactivate: obj && obj.can ? obj.can.deactivate : false,
      delete: obj && obj.can ? obj.can.delete : false,
    };
  }

  getSubstructureIds() {
    let ids = [];
    this.substructures.forEach((s) => ids.push(s.id));
    return ids;
  }
}
