<template>
  <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
    <v-card :loading="isLoading" color="grey lighten-3">
      <v-card-title class="text-overline">
        <span>{{ $t("resources.journeys.deleteJourney") }}</span>
      </v-card-title>

      <v-card-text>
        <span v-html="$t('resources.journeys.deleteJourneyDescription')" />
      </v-card-text>

      <v-card-actions>
        <v-btn small text color="secondary" @click="closeDialog()">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="error" small text @click="deleteJourney">
          {{ $t("general.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "JourneyDialogDelete",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    journey: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
  },
  methods: {
    ...mapActions("journeys", ["delete_journey"]),
    closeDialog(wasDeleted) {
      this.$emit("close", wasDeleted);
    },
    async deleteJourney() {
      this.isLoading = true;
      let res = await this.delete_journey({
        id: this.journey.id,
      });
      this.isLoading = false;
      if (res) {
        this.$notify({
          title: this.$t("general.success"),
          type: "success",
          text: this.$t("resources.journeys.delete_success"),
        });
        this.closeDialog(true);
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: res.msg
            ? this.$t("errors." + res.msg)
            : this.$t("resources.journeys.delete_error"),
        });
      }
    },
  },
};
</script>

<style></style>
