<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('general.details')"
      icon="mdi-numeric"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'CodeOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!code" />

    <section v-if="code">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col>
            <v-card :loading="loading" flat color="transparent">
              <v-card-text class="px-0">
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-if="!loading"
                          v-model="inputs.code"
                          :label="$t('resources.codes.code')"
                          readonly
                          outlined
                          background-color="white"
                          dense
                          required
                        >
                          <template #prepend>
                            <v-btn
                              icon
                              small
                              class="ml-1"
                              @click.stop="copyCode()"
                            >
                              <v-icon small> mdi-content-copy </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-skeleton-loader
                          v-if="loading"
                          type="text"
                          width="100%"
                          height="32"
                        />
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-if="!loading"
                          v-model="usage_count"
                          :disabled="unlimitedUsageCount"
                          :rules="usageCountRules"
                          type="number"
                          outlined
                          hide-details
                          dense
                          background-color="white"
                          :label="$t('resources.codes.usage_count.title')"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          v-model="unlimitedUsageCount"
                          dense
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          :label="$t('resources.codes.unlimited_registrations')"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="assignableSubstructures"
                          :items="substructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          small-chips
                          deletable-chips
                          outlined
                          dense
                          background-color="white"
                          :rules="substructureRules"
                          :label="
                            $t('resources.codes.assignable_substructures')
                          "
                          multiple
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          @change="checkSelectableStructures"
                        >
                          <template #item="{ item }">
                            {{ item.structure.name }} / {{ item.name }}
                          </template>
                          <template #selection="{ item }">
                            <v-chip small>
                              {{ substructureById(item.id).structure.name }} /
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="selectableStructures"
                          :items="availableSelectableStructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          outlined
                          dense
                          background-color="white"
                          small-chips
                          deletable-chips
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          :label="
                            $t('resources.codes.selectable_structures_long')
                          "
                          multiple
                        >
                          <template #item="{ item }">
                            {{ item.name }}
                          </template>
                          <template #selection="{ item }">
                            <v-chip small>
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialSelectStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!loading"
                  color="error"
                  text
                  :disabled="!isEdited"
                  small
                  @click="resetForm"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                  v-if="!loading"
                  color="success"
                  text
                  :disabled="!valid || !isEdited"
                  small
                  @click="saveForm"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "CodeDetail",
  components: {
    ViewHeader,
    ViewLoader,
    TutorialCarouselDialog,
    SectionHeader,
  },
  data() {
    return {
      id: "", // id is a string here!
      inputs: { ...this.selected },
      valid: true,
      loading: false,
      unlimitedUsageCount: false,
      assignableSubstructures: [],
      selectableStructures: [],
      tutorialSelectStructures: ["selectstructures"],
      usage_count: -1,
      codeRules: [
        (v) =>
          (!!v && v.length >= 10) || this.$t("general.errors.not_enough_chars"),
      ],
      usageCountRules: [
        (v) => v >= -1 || this.$t("general.errors.invalid_value"),
      ],
      substructureRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.select_an_item"),
      ],
      tutorialStructures: ["structures"],
    };
  },
  computed: {
    ...mapGetters("codes", { code: "get_code" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    isEdited: function () {
      if (this.code) {
        let changed = false;
        if (this.unlimitedUsageCount) {
          if (this.inputs.usage_count != -1) {
            changed = true;
          }
        } else {
          if (this.usage_count != this.inputs.usage_count) {
            changed = true;
          }
        }
        if (this.inputs.assign_substructure_ids) {
          if (
            this.assignableSubstructures != this.inputs.assign_substructure_ids
          ) {
            changed = true;
          }
        }
        if (this.inputs.select_structure_ids) {
          if (this.selectableStructures != this.inputs.select_structure_ids) {
            changed = true;
          }
        }
        return changed;
      } else return false;
    },
    availableSelectableStructures() {
      if (!this.structures) return [];
      return this.structures.filter((structure) => {
        if (structure.substructures.length === 0) return true;
        let check = true;
        structure.substructures.forEach((substructure) => {
          if (this.assignableSubstructures.includes(substructure.id))
            check = false;
        });
        return check;
      });
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.codes.plural"),
          disabled: false,
          to: { name: "CodeOverview" },
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.id = this.$route.params.id;
    this.fetchData();
  },
  destroyed() {
    this.set_code(null);
    this.set_structures(null);
  },
  methods: {
    ...mapActions("codes", ["fetch_code", "update_code"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("codes", ["set_code"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      await this.fetch_structures(), await this.loadCode();
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    async loadCode() {
      this.loading = true;
      await this.fetch_code({
        id: this.id,
      });
      this.inputs = { ...this.code };

      this.usage_count = this.inputs.usage_count;
      this.unlimitedUsageCount = this.inputs.usage_count === -1 ? true : false;
      this.inputs.assign_substructure_ids =
        this.inputs.assign_substructure_ids.map(function (item) {
          return Number(item);
        });
      this.assignableSubstructures = this.inputs.assign_substructure_ids;
      this.inputs.select_structure_ids = this.inputs.select_structure_ids.map(
        function (item) {
          return Number(item);
        }
      );
      this.selectableStructures = this.inputs.select_structure_ids;
      if (this.pre_select_structures) {
        let select_structures = [];
        this.pre_select_structures.forEach((structure) =>
          select_structures.push(parseInt(structure))
        );
        this.inputs.select_structure_ids = select_structures;
      }
      this.loading = false;
    },
    resetForm() {
      this.inputs = { ...this.code };
      this.inputs.assign_substructure_ids =
        this.inputs.assign_substructure_ids.map(function (item) {
          return Number(item);
        });
      this.assignableSubstructures = this.inputs.assign_substructure_ids;
      this.inputs.select_structure_ids = this.inputs.select_structure_ids.map(
        function (item) {
          return Number(item);
        }
      );
      this.selectableStructures = this.inputs.select_structure_ids;
      this.usage_count = this.inputs.usage_count;
      this.unlimitedUsageCount = this.inputs.usage_count === -1 ? true : false;
    },
    async saveForm() {
      this.loading = true;
      let inputs = {};
      inputs.code = this.code.code;
      inputs.usage_count = this.unlimitedUsageCount
        ? -1
        : Number(this.usage_count);
      inputs.assign_substructure_ids = this.assignableSubstructures;
      inputs.select_structure_ids = this.selectableStructures;
      inputs.community_id = this.community.id;

      let res = await this.update_code({
        payload: inputs,
      });

      if (res) {
        await this.loadCode();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text:
            this.$t("resources.codes.singular") +
            " " +
            this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error_msg"),
        });
      }
      this.loading = false;
    },
    checkSelectableStructures() {
      this.assignableSubstructures.forEach((substructure_id) => {
        this.selectableStructures = this.selectableStructures.filter(
          (structure_id) => {
            return (
              structure_id !==
              this.substructureById(substructure_id).structure.id
            );
          }
        );
      });
    },
    copyCode() {
      navigator.clipboard.writeText(this.code.code);
      this.$notify({
        type: "success",
        title: this.$t("general.success"),
        text: this.$t("resources.codes.code_copied"),
      });
    },
  },
};
</script>

<style></style>
