var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'language-chooser-inline-wrapper',
    _vm.$attrs['hide-label'] == '' ? 'hide-label' : '',
    _vm.absolute ? 'language-chooser-absolute' : '',
  ]},[_c('v-select',{attrs:{"placeholder":"Language","items":_vm.languages,"item-text":"name","item-value":"name","flat":"","dense":"","height":"36px","outlined":!_vm.hideBorder,"small-chips":"","selection-height":"10","hide-details":_vm.hidedetails,"disabled":_vm.disabled,"background-color":_vm.colored
        ? _vm.disabled
          ? _vm.backgroundColor
            ? _vm.backgroundColor
            : 'grey lighten-3'
          : _vm.backgroundColor
        : 'transparent',"label":_vm.label,"hint":_vm.hint,"persistent-hint":_vm.persistentHint,"prepend-icon":_vm.prependIcon,"dark":_vm.dark},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item),attrs:{"input-value":data.selected,"disabled":data.disabled,"small":"","color":_vm.chipColor}},'v-chip',data.attrs,false),[_c('country-flag',{class:_vm.$attrs['hide-label'] != '' ? 'mr-1' : '',attrs:{"country":_vm.flagName(data.item),"size":"small"}}),(_vm.$attrs['hide-label'] != '')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("languages." + data.item + ".display"))+" ")]):_vm._e()],1)]}},{key:"item",fn:function(data){return [[_c('country-flag',{attrs:{"country":_vm.flagName(data.item),"size":"small"}}),_c('v-list-item-content',{staticClass:"ml-4"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("languages." + data.item + ".display"))+" ")])],1)]]}},(_vm.appendIcon)?{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")])]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }