<template>
  <div>
    <ChipNavigation v-if="false" :nav-items="navItems" :disabled="loading" />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChipNavigation from "@/components/_layout/ChipNavigation";

export default {
  name: "KpiJourneys",
  components: {
    ChipNavigation,
  },
  computed: {
    ...mapGetters("kpi", { loading: "get_kpi_loading" }),
    navItems() {
      return [
        {
          title: this.$t("kpi.overview"),
          link: { name: "KpiJourneyOverview" },
        },
        /* {
          title: "Weiterer Link",
          disabled: true,
          link: { name: "KpiJourneyLink" },
        }, */
      ];
    },
  },
};
</script>
