import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import i18n from "../i18n";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties: true,
    },
    light: true,
    themes: {
      light: {
        primary: "#00afd7",
        secondary: "#617D8B",
        accent: "#9F2B68",
        error: "#F34335",
        info: "#009688",
        success: "#8AC34A",
        warning: "#FFC106",
        text: "#303030",
      },
    },
  },
});
