<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">
          IDP Client bearbeiten
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="input.name"
                  outlined
                  :label="$t('resources.categories.name')"
                  dense
                  listen-to-input
                  disabled
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.client_id"
                  background-color="white"
                  outlined
                  :label="'client_id'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.client_secret"
                  background-color="white"
                  outlined
                  :label="'client_secret'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.client_uri"
                  background-color="white"
                  outlined
                  :label="'client_uri'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.grant_types"
                  background-color="white"
                  outlined
                  :label="'grant_types'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.redirect_uris"
                  background-color="white"
                  outlined
                  :label="'redirect_uris'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.response_types"
                  background-color="white"
                  outlined
                  :label="'response_types'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.scope"
                  background-color="white"
                  outlined
                  :label="'scope'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="input.token_endpoint_auth_method"
                  :items="token_endpoint_auth_methods"
                  label="token_endpoint_auth_method"
                  dense
                  outlined
                  background-color="white"
                  :rules="rules.notEmpty"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn text small color="error" @click="reset()">
            {{ $t("general.reset") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="!valid || loading || !hasChanged"
            @click="updateClient()"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "IDPClientDialog",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      valid: false,
      input: null,
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          //(v) => !!v && v.length <= 64 || this.$t('ng.too_many_chars_hint', { count: v.length, max: 64 })
        ],
      },
      token_endpoint_auth_methods: [
        "client_secret_basic",
        "client_secret_post",
      ],
    };
  },
  computed: {
    hasChanged() {
      if (!this.client || !this.input) return false;
      return (
        this.client.client_id !== this.input.client_id ||
        this.client.client_secret !== this.input.client_secret ||
        this.client.client_uri !== this.input.client_uri ||
        this.client.grant_types !== this.input.grant_types ||
        this.client.redirect_uris !== this.input.redirect_uris ||
        this.client.response_types !== this.input.response_types ||
        this.client.scope !== this.input.scope ||
        this.client.token_endpoint_auth_method !==
          this.input.token_endpoint_auth_method
      );
    },
  },
  methods: {
    ...mapActions("administration", ["update_idp_client"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.reset();
      this.showDialog = true;
    },
    reset() {
      this.input = { ...this.client };
    },
    async updateClient() {
      this.loading = true;
      let payload = { ...this.input };
      let res = await this.update_idp_client({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Client erfolgreich bearbeitet.",
        });
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Bearbeiten des Clients.",
        });
      }
    },
  },
};
</script>
