<template>
  <div class="v-csv-field">
    <div>
      <v-text-field
        v-model="addEntryInput"
        :hint="addEntryInputUnsaved ? $t('resources.nuggets.linksUnsaved') : ''"
        persistent-hint
        outlined
        dense
        :disabled="disabled"
        :background-color="disabled ? 'grey lighten-3' : 'white'"
        :label="label"
        :error-messages="
          !addEntryInputValid ? $t('general.errors.csv_input_invalid') : ''
        "
        @change="addEntryInputChanged"
      >
        <template #append-outer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  color="success"
                  v-bind="attrs"
                  text
                  small
                  :disabled="!addEntryInputValid || !addEntryInput || disabled"
                  @click="addEntryToEntries"
                >
                  <v-icon small> mdi-plus </v-icon>
                  <span>{{ $t("general.add") }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("resources.nuggets.addLink") }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </div>
    <v-list color="transparent" dense>
      <v-list-item
        v-if="!entries || entries.length === 0"
        class="grey lighten-2"
      >
        <v-list-item-content class="secondary--text">
          {{ $t("general.no_content") }}
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-for="(entry, i) in entries"
        :key="i"
        class="grey lighten-2 mb-2"
      >
        <v-list-item-avatar size="24">
          <v-avatar>
            <v-icon> mdi-link </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="d-flex align-center">
            <span>{{ entry }}</span>
            <v-btn
              :href="entry"
              icon
              x-small
              color="info"
              class="ml-2"
              target="_blank"
            >
              <v-icon x-small>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  color="error"
                  class="mr-2"
                  icon
                  small
                  :disabled="disabled"
                  @click="removeEntry(i)"
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("resources.nuggets.removeLink") }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <!-- <div v-if="entries.length === 0">
      {{ $t('resources.nuggets.no_link') }}
    </div> -->
    <v-textarea class="d-none" :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>
export default {
  name: "CommaSeperatedField",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      addEntryInput: "",
      addEntryInputUnsaved: false,
    };
  },
  computed: {
    addEntryInputValid() {
      if (!this.addEntryInput) {
        return true;
      }
      return (
        !this.addEntryInput.includes(",") &&
        (this.addEntryInput.startsWith("https://") ||
          this.addEntryInput.startsWith("http://"))
      );
    },
    entries() {
      if (this.value && this.value != "") {
        return this.value.split(",");
      } else {
        return [];
      }
    },
  },
  methods: {
    removeEntry(index) {
      let entries = this.entries;
      entries.splice(index, 1);
      this.$emit("input", entries.join(","));
    },
    addEntryToEntries() {
      if (!this.addEntryInput) {
        return;
      }
      this.$emit("input", [...this.entries, this.addEntryInput].join(","));
      this.addEntryInput = "";
      this.addEntryInputUnsaved = false;
    },
    addEntryInputChanged(value) {
      if (value) {
        this.addEntryInputUnsaved = true;
      } else {
        this.addEntryInputUnsaved = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
