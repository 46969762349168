export const CITIES = [
  "Aach",
  "Aachen",
  "Aalen",
  "Abenberg",
  "Abensberg",
  "Achern",
  "Achim",
  "Adelsheim",
  "Adenau",
  "Adorf/Vogtl.",
  "Ahaus",
  "Ahlen",
  "Ahrensburg",
  "Aichach",
  "Aichtal",
  "Aken (Elbe)",
  "Albstadt",
  "Alfeld (Leine)",
  "Allendorf (Lumda)",
  "Allstedt",
  "Alpirsbach",
  "Alsdorf",
  "Alsfeld",
  "Alsleben (Saale)",
  "Altdorf bei Nürnberg",
  "Altena",
  "Altenberg",
  "Altenburg",
  "Altenkirchen (Westerwald)",
  "Altensteig",
  "Altentreptow",
  "Altlandsberg",
  "Altötting",
  "Alzenau",
  "Alzey",
  "Amberg",
  "Amöneburg",
  "Amorbach",
  "Andernach",
  "Angermünde",
  "Anklam",
  "Annaberg-Buchholz",
  "Annaburg",
  "Annweiler am Trifels",
  "Ansbach",
  "Apolda",
  "Arendsee (Altmark)",
  "Arneburg",
  "Arnis",
  "Arnsberg",
  "Arnstadt",
  "Arnstein",
  "Arnstein",
  "Artern/Unstrut",
  "Arzberg",
  "Aschaffenburg",
  "Aschersleben",
  "Asperg",
  "Aßlar",
  "Attendorn",
  "Aub",
  "Aue",
  "Auerbach in der Oberpfalz",
  "Auerbach/Vogtl.",
  "Augsburg",
  "Augustusburg",
  "Aulendorf",
  "Auma-Weidatal",
  "Aurich",
  "Babenhausen",
  "Bacharach",
  "Backnang",
  "Bad Aibling",
  "Bad Arolsen",
  "Bad Belzig",
  "Bad Bentheim",
  "Bad Bergzabern",
  "Bad Berka",
  "Bad Berleburg",
  "Bad Berneck im Fichtelgebirge",
  "Bad Bevensen",
  "Bad Bibra",
  "Bad Blankenburg",
  "Bad Bramstedt",
  "Bad Breisig",
  "Bad Brückenau",
  "Bad Buchau",
  "Bad Camberg",
  "Bad Colberg-Heldburg",
  "Bad Doberan",
  "Bad Driburg",
  "Bad Düben",
  "Bad Dürkheim",
  "Bad Dürrenberg",
  "Bad Dürrheim",
  "Bad Elster",
  "Bad Ems",
  "Baden-Baden",
  "Bad Fallingbostel",
  "Bad Frankenhausen/Kyffhäuser",
  "Bad Freienwalde (Oder)",
  "Bad Friedrichshall",
  "Bad Gandersheim",
  "Bad Gottleuba-Berggießhübel",
  "Bad Griesbach im Rottal",
  "Bad Harzburg",
  "Bad Herrenalb",
  "Bad Hersfeld",
  "Bad Homburg vor der Höhe",
  "Bad Honnef",
  "Bad Hönningen",
  "Bad Iburg",
  "Bad Karlshafen",
  "Bad Kissingen",
  "Bad Kleinen",
  "Bad König",
  "Bad Königshofen im Grabfeld",
  "Bad Köstritz",
  "Bad Kötzting",
  "Bad Kreuznach",
  "Bad Krozingen",
  "Bad Laasphe",
  "Bad Langensalza",
  "Bad Lauchstädt",
  "Bad Lausick",
  "Bad Lauterberg im Harz",
  "Bad Liebenstein",
  "Bad Liebenwerda",
  "Bad Liebenzell",
  "Bad Lippspringe",
  "Bad Lobenstein",
  "Bad Marienberg (Westerwald)",
  "Bad Mergentheim",
  "Bad Münder am Deister",
  "Bad Münstereifel",
  "Bad Muskau",
  "Bad Nauheim",
  "Bad Nenndorf",
  "Bad Neuenahr-Ahrweiler",
  "Bad Neustadt an der Saale",
  "Bad Oeynhausen",
  "Bad Oldesloe",
  "Bad Orb",
  "Bad Pyrmont",
  "Bad Rappenau",
  "Bad Reichenhall",
  "Bad Rodach",
  "Bad Sachsa",
  "Bad Säckingen",
  "Bad Salzdetfurth",
  "Bad Salzuflen",
  "Bad Salzungen",
  "Bad Saulgau",
  "Bad Schandau",
  "Bad Schmiedeberg",
  "Bad Schussenried",
  "Bad Schwalbach",
  "Bad Schwartau",
  "Bad Segeberg",
  "Bad Sobernheim",
  "Bad Soden am Taunus",
  "Bad Soden-Salmünster",
  "Bad Sooden-Allendorf",
  "Bad Staffelstein",
  "Bad Sulza",
  "Bad Sülze",
  "Bad Teinach-Zavelstein",
  "Bad Tennstedt",
  "Bad Tölz",
  "Bad Urach",
  "Bad Vilbel",
  "Bad Waldsee",
  "Bad Wildbad",
  "Bad Wildungen",
  "Bad Wilsnack",
  "Bad Wimpfen",
  "Bad Windsheim",
  "Bad Wörishofen",
  "Bad Wünnenberg",
  "Bad Wurzach",
  "Baesweiler",
  "Baiersdorf",
  "Balingen",
  "Ballenstedt",
  "Balve",
  "Bamberg",
  "Barby",
  "Bargteheide",
  "Barleben",
  "Barmstedt",
  "Bärnau",
  "Barntrup",
  "Barsinghausen",
  "Barth",
  "Baruth/Mark",
  "Basel",
  "Bassum",
  "Battenberg (Eder)",
  "Baumholder",
  "Baunach",
  "Baunatal",
  "Bautzen",
  "Bayreuth",
  "Bebra",
  "Beckum",
  "Bedburg",
  "Beelitz",
  "Beerfelden",
  "Beeskow",
  "Beilngries",
  "Beilstein",
  "Belgern-Schildau",
  "Bendorf",
  "Bensheim",
  "Berching",
  "Berga/Elster",
  "Bergen",
  "Bergen auf Rügen",
  "Bergheim",
  "Bergisch Gladbach",
  "Bergkamen",
  "Bergneustadt",
  "Berka/Werra",
  "Berlin",
  "Bernau bei Berlin",
  "Bernburg (Saale)",
  "Bernkastel-Kues",
  "Bernsdorf",
  "Bernstadt a. d. Eigen",
  "Bersenbrück",
  "Besigheim",
  "Bestwig",
  "Betzdorf",
  "Betzenstein",
  "Beverungen",
  "Bexbach",
  "Biberach an der Riß",
  "Biedenkopf",
  "Biederitz",
  "Bielefeld",
  "Biesenthal",
  "Bietigheim",
  "Bietigheim-Bissingen",
  "Billerbeck",
  "Bingen am Rhein",
  "Birkenfeld",
  "Bischofsheim an der Rhön",
  "Bischofswerda",
  "Bismark (Altmark)",
  "Bitburg",
  "Bitterfeld-Wolfen",
  "Blankenburg (Harz)",
  "Blankenfelde-Mahlow",
  "Blankenhain",
  "Blaubeuren",
  "Blaustein",
  "Bleckede",
  "Bleicherode",
  "Blieskastel",
  "Blomberg",
  "Blumberg",
  "Bobingen",
  "Böblingen",
  "Bocholt",
  "Bochum",
  "Bockenem",
  "Bodenwerder",
  "Bogen",
  "Böhlen",
  "Boizenburg/Elbe",
  "Bonn",
  "Bonndorf im Schwarzwald",
  "Bönnigheim",
  "Bopfingen",
  "Boppard",
  "Borgentreich",
  "Borgholzhausen",
  "Borken",
  "Borken (Hessen)",
  "Borkum",
  "Borna",
  "Bornheim",
  "Bottrop",
  "Boxberg",
  "Brackenheim",
  "Brake (Unterweser)",
  "Brakel",
  "Bramsche",
  "Brandenburg an der Havel",
  "Brand-Erbisdorf",
  "Brandis",
  "Braubach",
  "Braunfels",
  "Braunlage",
  "Bräunlingen",
  "Braunsbedra",
  "Braunschweig",
  "Breckerfeld",
  "Bredstedt",
  "Breisach am Rhein",
  "Bremen",
  "Bremerhaven",
  "Bremervörde",
  "Bretten",
  "Breuberg",
  "Brilon",
  "Brotterode-Trusetal",
  "Bruchköbel",
  "Bruchsal",
  "Brück",
  "Brüel",
  "Brühl",
  "Brunsbüttel",
  "Brüssow",
  "Buchen (Odenwald)",
  "Buchholz in der Nordheide",
  "Buchloe",
  "Bückeburg",
  "Buckow (Märkische Schweiz)",
  "Büdelsdorf",
  "Büdingen",
  "Bühl",
  "Bünde",
  "Büren",
  "Burg",
  "Burgau",
  "Burgbernheim",
  "Burgdorf",
  "Bürgel",
  "Burghausen",
  "Burgkunstadt",
  "Burglengenfeld",
  "Burgstädt",
  "Burg Stargard",
  "Burgwedel",
  "Burladingen",
  "Burscheid",
  "Bürstadt",
  "Buttelstedt",
  "Buttstädt",
  "Butzbach",
  "Bützow",
  "Buxtehude",
  "Calau",
  "Calbe (Saale)",
  "Calw",
  "Castrop-Rauxel",
  "Celle",
  "Cham",
  "Chemnitz",
  "Clausthal-Zellerfeld",
  "Clingen",
  "Cloppenburg",
  "Coburg",
  "Cochem",
  "Coesfeld",
  "Colditz",
  "Coswig",
  "Coswig (Anhalt)",
  "Cottbus",
  "Crailsheim",
  "Creglingen",
  "Creußen",
  "Creuzburg",
  "Crimmitschau",
  "Crivitz",
  "Cuxhaven",
  "Dachau",
  "Dahlen",
  "Dahme/Mark",
  "Dahn",
  "Damme",
  "Dannenberg (Elbe)",
  "Dargun",
  "Darmstadt",
  "Dassel",
  "Dassow",
  "Datteln",
  "Daun",
  "Deggendorf",
  "Deidesheim",
  "Delbrück",
  "Delitzsch",
  "Delmenhorst",
  "Demmin",
  "Dessau",
  "Dessau-Roßlau",
  "Detmold",
  "Dettelbach",
  "Dieburg",
  "Diemelstadt",
  "Diepholz",
  "Dierdorf",
  "Dietenheim",
  "Dietfurt an der Altmühl",
  "Dietzenbach",
  "Diez",
  "Dillenburg",
  "Dillingen an der Donau",
  "Dillingen/Saar",
  "Dingelstädt",
  "Dingolfing",
  "Dinkelsbühl",
  "Dinklage",
  "Dinslaken",
  "Dippoldiswalde",
  "Dissen am Teutoburger Wald",
  "Ditzingen",
  "Döbeln",
  "Doberlug-Kirchhain",
  "Döbern",
  "Dohna",
  "Dömitz",
  "Dommitzsch",
  "Donaueschingen",
  "Donauwörth",
  "Donzdorf",
  "Dorfen",
  "Dormagen",
  "Dornburg-Camburg",
  "Dornhan",
  "Dornstadt",
  "Dornstetten",
  "Dorsten",
  "Dortmund",
  "Dransfeld",
  "Drebkau",
  "Dreieich",
  "Drensteinfurt",
  "Dresden",
  "Drolshagen",
  "Duderstadt",
  "Duisburg",
  "Dülmen",
  "Düren",
  "Düsseldorf",
  "Ebeleben",
  "Eberbach",
  "Ebermannstadt",
  "Ebern",
  "Ebersbach an der Fils",
  "Ebersbach-Neugersdorf",
  "Ebersberg",
  "Eberswalde",
  "Eckartsberga",
  "Eckernförde",
  "Edenkoben",
  "Egeln",
  "Eggenfelden",
  "Eggesin",
  "Ehingen (Donau)",
  "Ehrenfriedersdorf",
  "Eibelstadt",
  "Eibenstock",
  "Eichstätt",
  "Eilenburg",
  "Einbeck",
  "Eisenach",
  "Eisenberg",
  "Eisenberg (Pfalz)",
  "Eisenhüttenstadt",
  "Eisfeld",
  "Eisleben",
  "Eislingen/Fils",
  "Ellingen",
  "Ellrich",
  "Ellwangen (Jagst)",
  "Elmshorn",
  "Elsdorf",
  "Elsfleth",
  "Elsterberg",
  "Elsterwerda",
  "Elstra",
  "Elterlein",
  "Eltmann",
  "Eltville am Rhein",
  "Elzach",
  "Elze",
  "Emden",
  "Emmelshausen",
  "Emmendingen",
  "Emmerich am Rhein",
  "Emsdetten",
  "Endingen am Kaiserstuhl",
  "Engen",
  "Enger",
  "Ennepetal",
  "Ennigerloh",
  "Eppelheim",
  "Eppingen",
  "Eppstein",
  "Erbach",
  "Erbach (Odenwald)",
  "Erbendorf",
  "Erding",
  "Erftstadt",
  "Erfurt",
  "Erkelenz",
  "Erkner",
  "Erkrath",
  "Erlangen",
  "Erlenbach am Main",
  "Erlensee",
  "Erwitte",
  "Eschborn",
  "Eschenbach in der Oberpfalz",
  "Eschershausen",
  "Eschwege",
  "Eschweiler",
  "Esens",
  "Espelkamp",
  "Essen",
  "Esslingen am Neckar",
  "Ettenheim",
  "Ettlingen",
  "Euskirchen",
  "Eutin",
  "Falkenberg/Elster",
  "Falkensee",
  "Falkenstein/Harz",
  "Falkenstein/Vogtl.",
  "Fehmarn",
  "Fellbach",
  "Felsberg",
  "Feuchtwangen",
  "Filderstadt",
  "Finsterwalde",
  "Fladungen",
  "Flensburg",
  "Flöha",
  "Flörsheim am Main",
  "Florstadt",
  "Forchheim",
  "Forchtenberg",
  "Forst (Lausitz)",
  "Frankenau",
  "Frankenberg (Eder)",
  "Frankenberg/Sa.",
  "Frankenthal (Pfalz)",
  "Frankfurt am Main",
  "Frankfurt (Oder)",
  "Franzburg",
  "Frauenstein",
  "Frechen",
  "Freiberg am Neckar",
  "Freiberg",
  "Freiburg",
  "Freiburg im Breisgau",
  "Freilassing",
  "Freinsheim",
  "Freising",
  "Freital",
  "Freren",
  "Freudenberg",
  "Freudenberg",
  "Freudenstadt",
  "Freyburg (Unstrut)",
  "Freystadt",
  "Freyung",
  "Fridingen an der Donau",
  "Friedberg",
  "Friedberg",
  "Friedland",
  "Friedland",
  "Friedrichroda",
  "Friedrichsdorf",
  "Friedrichshafen",
  "Friedrichstadt",
  "Friedrichsthal",
  "Friesack",
  "Friesoythe",
  "Fritzlar",
  "Frohburg",
  "Fröndenberg/Ruhr",
  "Fulda",
  "Fürstenau",
  "Fürstenberg/Havel",
  "Fürstenfeldbruck",
  "Fürstenwalde/Spree",
  "Fürth",
  "Furth im Wald",
  "Furtwangen im Schwarzwald",
  "Füssen",
  "Gadebusch",
  "Gaggenau",
  "Gaildorf",
  "Gammertingen",
  "Garbsen",
  "Garching bei München",
  "Gardelegen",
  "Garding",
  "Gartz (Oder)",
  "Garz/Rügen",
  "Gau-Algesheim",
  "Gebesee",
  "Gedern",
  "Geesthacht",
  "Geestland",
  "Gefell",
  "Gefrees",
  "Gehrden",
  "Gehren",
  "Geilenkirchen",
  "Geisa",
  "Geiselhöring",
  "Geisenfeld",
  "Geisenheim",
  "Geisingen",
  "Geislingen",
  "Geislingen an der Steige",
  "Geithain",
  "Geldern",
  "Gelnhausen",
  "Gelsenkirchen",
  "Gemünden am Main",
  "Gemünden (Wohra)",
  "Gengenbach",
  "Genthin",
  "Georgsmarienhütte",
  "Gera",
  "Gerabronn",
  "Gerbstedt",
  "Geretsried",
  "Geringswalde",
  "Gerlingen",
  "Germering",
  "Germersheim",
  "Gernsbach",
  "Gernsheim",
  "Gernsheim (Schöfferstadt)",
  "Gerolstein",
  "Gerolzhofen",
  "Gersfeld (Rhön)",
  "Gersthofen",
  "Gescher",
  "Geseke",
  "Gevelsberg",
  "Geyer",
  "Giengen an der Brenz",
  "Gießen",
  "Gifhorn",
  "Ginsheim-Gustavsburg",
  "Gladbeck",
  "Gladenbach",
  "Glashütte",
  "Glauchau",
  "Glinde",
  "Glücksburg (Ostsee)",
  "Glückstadt",
  "Gnoien",
  "Goch",
  "Goldberg",
  "Goldkronach",
  "Golßen",
  "Gommern",
  "Göppingen",
  "Görlitz",
  "Goslar",
  "Gößnitz",
  "Gotha",
  "Göttingen",
  "Grabow",
  "Grafenau",
  "Gräfenberg",
  "Gräfenhainichen",
  "Gräfenthal",
  "Grafenwöhr",
  "Grafing bei München",
  "Gransee",
  "Grebenau",
  "Grebenstein",
  "Greding",
  "Greifswald",
  "Greiz",
  "Greußen",
  "Greven",
  "Grevenbroich",
  "Grevesmühlen",
  "Griesheim",
  "Grimma",
  "Grimmen",
  "Gröditz",
  "Groitzsch",
  "Gronau (Leine)",
  "Gronau (Westf.)",
  "Gröningen",
  "Großalmerode",
  "Groß-Bieberau",
  "Großbottwar",
  "Großbreitenbach",
  "Großenehrich",
  "Großenhain",
  "Großheringen",
  "Großkorbetha",
  "Groß-Gerau",
  "Großräschen",
  "Großröhrsdorf",
  "Großschirma",
  "Groß-Umstadt",
  "Grünberg",
  "Grünhain-Beierfeld",
  "Grünsfeld",
  "Grünstadt",
  "Guben",
  "Gudensberg",
  "Güglingen",
  "Gummersbach",
  "Gundelfingen an der Donau",
  "Gundelsheim",
  "Günzburg",
  "Gunzenhausen",
  "Güsten",
  "Güstrow",
  "Gütersloh",
  "Gützkow",
  "Haan",
  "Hachenburg",
  "Hadamar",
  "Hagen",
  "Hagen (Westf.)",
  "Hagenbach",
  "Hagenow",
  "Haiger",
  "Haigerloch",
  "Hainichen",
  "Haiterbach",
  "Halberstadt",
  "Haldensleben",
  "Halle (Saale)",
  "Halle (Westf.)",
  "Hallenberg",
  "Hallstadt",
  "Haltern am See",
  "Halver",
  "Hamburg",
  "Hameln",
  "Hamm",
  "Hamm (Westf.)",
  "Hammelburg",
  "Hamminkeln",
  "Hanau",
  "Hannover",
  "Hann. Münden",
  "Harburg (Schwaben)",
  "Hardegsen",
  "Haren (Ems)",
  "Harsewinkel",
  "Hartenstein",
  "Hartha",
  "Harzgerode",
  "Haselünne",
  "Haslach im Kinzigtal",
  "Haßfurt",
  "Hattersheim am Main",
  "Hattingen",
  "Hatzfeld (Eder)",
  "Hausach",
  "Hauzenberg",
  "Havelberg",
  "Havelsee",
  "Hayingen",
  "Hechingen",
  "Hecklingen",
  "Heide",
  "Heideck",
  "Heidelberg",
  "Heidenau",
  "Heidenheim an der Brenz",
  "Heilbad Heiligenstadt",
  "Heilbronn",
  "Heiligenhafen",
  "Heiligenhaus",
  "Heilsbronn",
  "Heimbach",
  "Heimsheim",
  "Heinsberg",
  "Heitersheim",
  "Heldrungen",
  "Helmbrechts",
  "Helmstedt",
  "Hemau",
  "Hemer",
  "Hemmingen",
  "Hemmoor",
  "Hemsbach",
  "Hennef (Sieg)",
  "Hennigsdorf",
  "Heppenheim (Bergstraße)",
  "Herbolzheim",
  "Herborn",
  "Herbrechtingen",
  "Herbstein",
  "Herdecke",
  "Herdorf",
  "Herford",
  "Heringen/Helme",
  "Heringen (Werra)",
  "Hermeskeil",
  "Hermsdorf",
  "Herne",
  "Herrenberg",
  "Herrieden",
  "Herrnhut",
  "Hersbruck",
  "Herten",
  "Herzberg am Harz",
  "Herzberg (Elster)",
  "Herzogenaurach",
  "Herzogenrath",
  "Hessisch Lichtenau",
  "Hessisch Oldendorf",
  "Hettingen",
  "Hettstedt",
  "Heubach",
  "Heusenstamm",
  "Hilchenbach",
  "Hildburghausen",
  "Hilden",
  "Hildesheim",
  "Hillesheim",
  "Hilpoltstein",
  "Hirschau",
  "Hirschberg",
  "Hirschhorn (Neckar)",
  "Hitzacker (Elbe)",
  "Hochheim am Main",
  "Höchstadt an der Aisch",
  "Höchstädt an der Donau",
  "Hockenheim",
  "Hof",
  "Hofgeismar",
  "Hofheim am Taunus",
  "Hofheim in Unterfranken",
  "Hohenberg an der Eger",
  "Hohenleuben",
  "Hohenmölsen",
  "Hohen Neuendorf",
  "Hohenstein-Ernstthal",
  "Hohnstein",
  "Höhr-Grenzhausen",
  "Hollfeld",
  "Holzgerlingen",
  "Holzminden",
  "Homberg (Efze)",
  "Homberg (Ohm)",
  "Homburg",
  "Horb am Neckar",
  "Hornbach",
  "Horn-Bad Meinberg",
  "Hornberg",
  "Hörstel",
  "Horstmar",
  "Höxter",
  "Hoya",
  "Hoyerswerda",
  "Hückelhoven",
  "Hückeswagen",
  "Hüfingen",
  "Hünfeld",
  "Hungen",
  "Hürth",
  "Husum",
  "Ibbenbüren",
  "Ichenhausen",
  "Idar-Oberstein",
  "Idstein",
  "Ilberstedt",
  "Illertissen",
  "Ilmenau",
  "Ilsenburg (Harz)",
  "Ilshofen",
  "Immenhausen",
  "Immenstadt im Allgäu",
  "Ingelfingen",
  "Ingelheim am Rhein",
  "Ingolstadt",
  "Iphofen",
  "Iserlohn",
  "Isny im Allgäu",
  "Isselburg",
  "Itzehoe",
  "Jarmen",
  "Jena",
  "Jerichow",
  "Jessen (Elster)",
  "Jever",
  "Joachimsthal",
  "Johanngeorgenstadt",
  "Jöhstadt",
  "Jülich",
  "Jüterbog",
  "Kaarst",
  "Kahla",
  "Kaisersesch",
  "Kaiserslautern",
  "Kalbe (Milde)",
  "Kalkar",
  "Kaltenkirchen",
  "Kaltennordheim",
  "Kamen",
  "Kamenz",
  "Kamp-Lintfort",
  "Kandel",
  "Kandern",
  "Kappeln",
  "Karben",
  "Karlsruhe",
  "Karlstadt",
  "Kassel",
  "Kastellaun",
  "Katzenelnbogen",
  "Kaub",
  "Kaufbeuren",
  "Kehl",
  "Kelbra (Kyffhäuser)",
  "Kelheim",
  "Kelkheim (Taunus)",
  "Kellinghusen",
  "Kelsterbach",
  "Kemberg",
  "Kemnath",
  "Kempen",
  "Kempten",
  "Kempten (Allgäu)",
  "Kenzingen",
  "Kerpen",
  "Ketzin/Havel",
  "Kevelaer",
  "Kiel",
  "Kierspe",
  "Kindelbrück",
  "Kirchberg",
  "Kirchberg an der Jagst",
  "Kirchberg (Hunsrück)",
  "Kirchen (Sieg)",
  "Kirchenlamitz",
  "Kirchhain",
  "Kirchheimbolanden",
  "Kirchheim",
  "Kirchheim unter Teck",
  "Kirn",
  "Kirtorf",
  "Kitzingen",
  "Kitzscher",
  "Kleve",
  "Klingenberg am Main",
  "Klingenthal",
  "Klötze",
  "Klütz",
  "Knittlingen",
  "Koblenz",
  "Kohren-Sahlis",
  "Kolbermoor",
  "Kölleda",
  "Köln",
  "Königsberg in Bayern",
  "Königsborn",
  "Königsbrück",
  "Königsbrunn",
  "Königsee-Rottenbach",
  "Königslutter am Elm",
  "Königstein im Taunus",
  "Königstein (Sächsische Schweiz)",
  "Königswinter",
  "Königs Wusterhausen",
  "Könnern",
  "Konstanz",
  "Konz",
  "Korbach",
  "Korntal-Münchingen",
  "Kornwestheim",
  "Korschenbroich",
  "Köthen (Anhalt)",
  "Kraichtal",
  "Krakow am See",
  "Kranichfeld",
  "Krautheim",
  "Krefeld",
  "Kremmen",
  "Krempe",
  "Kreuztal",
  "Kronach",
  "Kronberg im Taunus",
  "Kröpelin",
  "Kroppenstedt",
  "Krumbach (Schwaben)",
  "Kühlungsborn",
  "Kulmbach",
  "Külsheim",
  "Künzelsau",
  "Kupferberg",
  "Kuppenheim",
  "Kusel",
  "Kyllburg",
  "Kyritz",
  "Laage",
  "Laatzen",
  "Ladenburg",
  "Lage",
  "Lahnstein",
  "Lahr/Schwarzwald",
  "Laichingen",
  "Lambrecht (Pfalz)",
  "Lampertheim",
  "Landau an der Isar",
  "Landau in der Pfalz",
  "Landsberg am Lech",
  "Landsberg",
  "Landshut",
  "Landstuhl",
  "Langelsheim",
  "Langen",
  "Langenau",
  "Langenburg",
  "Langenfeld (Rheinland)",
  "Langenhagen",
  "Langenselbold",
  "Langenzenn",
  "Langewiesen",
  "Lassan",
  "Laubach",
  "Laucha an der Unstrut",
  "Lauchhammer",
  "Lauchheim",
  "Lauda-Königshofen",
  "Lauenburg/Elbe",
  "Lauf an der Pegnitz",
  "Laufen",
  "Laufenburg (Baden)",
  "Lauffen am Neckar",
  "Lauingen (Donau)",
  "Laupheim",
  "Lauscha",
  "Lauta",
  "Lauter-Bernsbach",
  "Lauterbach",
  "Lauterecken",
  "Lauterstein",
  "Lebach",
  "Lebus",
  "Leer (Ostfriesland)",
  "Lehesten",
  "Lehrte",
  "Leichlingen (Rheinland)",
  "Leimen",
  "Leinefelde-Worbis",
  "Leinfelden-Echterdingen",
  "Leipheim",
  "Leipzig",
  "Leisnig",
  "Lemgo",
  "Lengenfeld",
  "Lengerich",
  "Lennestadt",
  "Lenzen",
  "Leonberg",
  "Leun",
  "Leuna",
  "Leutenberg",
  "Leutershausen",
  "Leutkirch im Allgäu",
  "Leverkusen",
  "Lich",
  "Lichtenau",
  "Lichtenau",
  "Lichtenberg",
  "Lichtenfels",
  "Lichtenfels",
  "Lichtenstein/Sa.",
  "Liebenau",
  "Liebenwalde",
  "Lieberose",
  "Liebstadt",
  "Limbach-Oberfrohna",
  "Limburg an der Lahn",
  "Lindau (Bodensee)",
  "Linden",
  "Lindenberg im Allgäu",
  "Lindenfels",
  "Lindow (Mark)",
  "Lingen (Ems)",
  "Linnich",
  "Linz am Rhein",
  "Lippstadt",
  "Löbau",
  "Löffingen",
  "Lohmar",
  "Lohne (Oldenburg)",
  "Löhne",
  "Lohr am Main",
  "Loitz",
  "Lollar",
  "Lommatzsch",
  "Löningen",
  "Lorch",
  "Lorch",
  "Lörrach",
  "Lorsch",
  "Lößnitz",
  "Löwenstein",
  "Lübbecke",
  "Lübben (Spreewald)",
  "Lübbenau/Spreewald",
  "Lübeck",
  "Lübtheen",
  "Lübz",
  "Lüchow (Wendland)",
  "Lucka",
  "Luckau",
  "Luckenwalde",
  "Lüdenscheid",
  "Lüdinghausen",
  "Ludwigsburg",
  "Ludwigsfelde",
  "Ludwigshafen am Rhein",
  "Ludwigslust",
  "Ludwigsstadt",
  "Lugau",
  "Lügde",
  "Lüneburg",
  "Lünen",
  "Lunzenau",
  "Lütjenburg",
  "Lützen",
  "Lychen",
  "Magdala",
  "Magdeburg",
  "Mahlberg",
  "Mainbernheim",
  "Mainburg",
  "Maintal",
  "Mainz",
  "Malchin",
  "Malchow",
  "Mannheim",
  "Manderscheid",
  "Mansfeld",
  "Marbach am Neckar",
  "Marburg",
  "Marienberg",
  "Marienmünster",
  "Markdorf",
  "Markgröningen",
  "Märkisch Buchholz",
  "Markkleeberg",
  "Markneukirchen",
  "Markranstädt",
  "Marktbreit",
  "Marktheidenfeld",
  "Marktleuthen",
  "Marktoberdorf",
  "Marktredwitz",
  "Marktsteft",
  "Marl",
  "Marlow",
  "Marne",
  "Marsberg",
  "Maulbronn",
  "Maxhütte-Haidhof",
  "Mayen",
  "Mechernich",
  "Meckenheim",
  "Medebach",
  "Meerane",
  "Meerbusch",
  "Meersburg",
  "Meinerzhagen",
  "Meiningen",
  "Meisenheim",
  "Meißen",
  "Meldorf",
  "Melle",
  "Mellrichstadt",
  "Melsungen",
  "Memmingen",
  "Menden (Sauerland)",
  "Mendig",
  "Mengen",
  "Meppen",
  "Merkendorf",
  "Merseburg",
  "Merzig",
  "Meschede",
  "Meßkirch",
  "Meßstetten",
  "Mettmann",
  "Metzingen",
  "Meuselwitz",
  "Meyenburg",
  "Michelstadt",
  "Miesbach",
  "Miltenberg",
  "Mindelheim",
  "Minden",
  "Mirow",
  "Mittenwalde",
  "Mitterteich",
  "Mittweida",
  "Möckern",
  "Möckmühl",
  "Moers",
  "Mölln",
  "Mönchengladbach",
  "Monheim am Rhein",
  "Monheim",
  "Monschau",
  "Montabaur",
  "Moosburg an der Isar",
  "Mörfelden-Walldorf",
  "Moringen",
  "Mosbach",
  "Mössingen",
  "Mücheln (Geiseltal)",
  "Mügeln",
  "Mühlacker",
  "Mühlberg/Elbe",
  "Mühldorf am Inn",
  "Mühlhausen/Thüringen",
  "Mühlheim am Main",
  "Mühlheim an der Donau",
  "Mülheim an der Ruhr",
  "Mülheim-Kärlich",
  "Müllheim",
  "Müllrose",
  "Münchberg",
  "Müncheberg",
  "München",
  "Münchenbernsdorf",
  "Munderkingen",
  "Murnau (Staffelsee)",
  "Münnerstadt",
  "Münsingen",
  "Munster",
  "Münster",
  "Münstermaifeld",
  "Münzenberg",
  "Murrhardt",
  "Nabburg",
  "Nagold",
  "Naila",
  "Nassau",
  "Nastätten",
  "Nauen",
  "Naumburg",
  "Naumburg (Saale)",
  "Naunhof",
  "Nebra (Unstrut)",
  "Neckarbischofsheim",
  "Neckargemünd",
  "Neckarsteinach",
  "Neckarsulm",
  "Neresheim",
  "Netphen",
  "Nettetal",
  "Netzschkau",
  "Neu-Anspach",
  "Neubrandenburg",
  "Neubukow",
  "Neubulach",
  "Neuburg an der Donau",
  "Neudenau",
  "Neuenbürg",
  "Neuenburg am Rhein",
  "Neuenhaus",
  "Neuenrade",
  "Neuenstadt am Kocher",
  "Neuenstein",
  "Neuerburg",
  "Neuffen",
  "Neuhaus am Rennweg",
  "Neuhof",
  "Neu-Isenburg",
  "Neukalen",
  "Neukirchen",
  "Neukirchen-Vluyn",
  "Neukloster",
  "Neumark",
  "Neumarkt in der Oberpfalz",
  "Neumarkt-Sankt Veit",
  "Neumünster",
  "Neunburg vorm Wald",
  "Neunkirchen",
  "Neuötting",
  "Neuruppin",
  "Neusalza-Spremberg",
  "Neusäß",
  "Neuss",
  "Neustadt an der Aisch",
  "Neustadt an der Donau",
  "Neustadt an der Waldnaab",
  "Neustadt am Kulm",
  "Neustadt am Rübenberge",
  "Neustadt an der Orla",
  "Neustadt an der Weinstraße",
  "Neustadt bei Coburg",
  "Neustadt (Dosse)",
  "Neustadt-Glewe",
  "Neustadt",
  "Neustadt in Holstein",
  "Neustadt in Sachsen",
  "Neustrelitz",
  "Neutraubling",
  "Neu-Ulm",
  "Neuwied",
  "Nidda",
  "Niddatal",
  "Nidderau",
  "Nideggen",
  "Niebüll",
  "Niedenstein",
  "Niederkassel",
  "Niedernhall",
  "Nieder-Olm",
  "Niederstetten",
  "Niederstotzingen",
  "Nieheim",
  "Niemegk",
  "Nienburg (Saale)",
  "Nienburg/Weser",
  "Nierstein",
  "Niesky",
  "Nittenau",
  "Norden",
  "Nordenham",
  "Norderney",
  "Norderstedt",
  "Nordhausen",
  "Nordhorn",
  "Nördlingen",
  "Northeim",
  "Nortorf",
  "Nossen",
  "Nürnberg",
  "Nürtingen",
  "Oberasbach",
  "Oberharz am Brocken",
  "Oberhausen",
  "Oberhof",
  "Oberkirch",
  "Oberkochen",
  "Oberlungwitz",
  "Obermoschel",
  "Obernburg am Main",
  "Oberndorf am Neckar",
  "Obernkirchen",
  "Ober-Ramstadt",
  "Oberriexingen",
  "Obertshausen",
  "Oberthulba",
  "Oberursel (Taunus)",
  "Oberviechtach",
  "Oberweißbach/Thür. Wald",
  "Oberwesel",
  "Oberwiesenthal",
  "Ochsenfurt",
  "Ochsenhausen",
  "Ochtrup",
  "Oderberg",
  "Oebisfelde-Weferlingen",
  "Oederan",
  "Oelde",
  "Oelsnitz/Erzgeb.",
  "Oelsnitz/Vogtl.",
  "Oer-Erkenschwick",
  "Oerlinghausen",
  "Oestrich-Winkel",
  "Oettingen in Bayern",
  "Offenbach am Main",
  "Offenburg",
  "Ohrdruf",
  "Öhringen",
  "Olbernhau",
  "Olching",
  "Oldenburg",
  "Oldenburg (Oldb)",
  "Oldenburg in Holstein",
  "Olfen",
  "Olpe",
  "Olsberg",
  "Oppenau",
  "Oppenheim",
  "Oranienbaum-Wörlitz",
  "Oranienburg",
  "Orlamünde",
  "Ornbau",
  "Ortenberg",
  "Ortrand",
  "Oschatz",
  "Oschersleben (Bode)",
  "Osnabrück",
  "Osterburg (Altmark)",
  "Osterburken",
  "Osterfeld",
  "Osterhofen",
  "Osterholz-Scharmbeck",
  "Osterode am Harz",
  "Osterwieck",
  "Ostfildern",
  "Ostheim vor der Rhön",
  "Osthofen",
  "Östringen",
  "Ostritz",
  "Otterberg",
  "Otterndorf",
  "Ottweiler",
  "Overath",
  "Owen",
  "Paderborn",
  "Papenburg",
  "Pappenheim",
  "Parchim",
  "Parsberg",
  "Pasewalk",
  "Passau",
  "Pattensen",
  "Pausa-Mühltroff",
  "Pegau",
  "Pegnitz",
  "Peine",
  "Peitz",
  "Penig",
  "Penkun",
  "Penzberg",
  "Penzlin",
  "Perleberg",
  "Petershagen",
  "Pfaffenhofen an der Ilm",
  "Pfarrkirchen",
  "Pforzheim",
  "Pfreimd",
  "Pfullendorf",
  "Pfullingen",
  "Pfungstadt",
  "Philippsburg",
  "Pinneberg",
  "Pirmasens",
  "Pirna",
  "Plattling",
  "Plau am See",
  "Plaue",
  "Plauen",
  "Plettenberg",
  "Pleystein",
  "Plochingen",
  "Plön",
  "Pockau-Lengefeld",
  "Pocking",
  "Pohlheim",
  "Polch",
  "Porta Westfalica",
  "Pößneck",
  "Potsdam",
  "Pottenstein",
  "Preetz",
  "Premnitz",
  "Prenzlau",
  "Pressath",
  "Preußisch Oldendorf",
  "Prichsenstadt",
  "Pritzwalk",
  "Prüm",
  "Puchheim",
  "Pulheim",
  "Pulsnitz",
  "Putbus",
  "Putlitz",
  "Püttlingen",
  "Quakenbrück",
  "Quedlinburg",
  "Querfurt",
  "Quickborn",
  "Rabenau",
  "Radeberg",
  "Radebeul",
  "Radeburg",
  "Radevormwald",
  "Radolfzell am Bodensee",
  "Raguhn-Jeßnitz",
  "Rahden",
  "Rain",
  "Ramstein-Miesenbach",
  "Ranis",
  "Ransbach-Baumbach",
  "Rastatt",
  "Rastenberg",
  "Rathenow",
  "Ratingen",
  "Ratzeburg",
  "Rauenberg",
  "Raunheim",
  "Rauschenberg",
  "Ravensburg",
  "Ravenstein",
  "Recklinghausen",
  "Rees",
  "Regen",
  "Regensburg",
  "Regis-Breitingen",
  "Rehau",
  "Rehburg-Loccum",
  "Rehna",
  "Reichelsheim (Wetterau)",
  "Reichenbach im Vogtland",
  "Reichenbach/O.L.",
  "Reinbek",
  "Reinfeld (Holstein)",
  "Reinheim",
  "Remagen",
  "Remda-Teichel",
  "Remscheid",
  "Remseck am Neckar",
  "Renchen",
  "Rendsburg",
  "Rennerod",
  "Renningen",
  "Rerik",
  "Rethem (Aller)",
  "Reutlingen",
  "Rheda-Wiedenbrück",
  "Rhede",
  "Rheinau",
  "Rheinbach",
  "Rheinberg",
  "Rheinböllen",
  "Rheine",
  "Rheinfelden (Baden)",
  "Rheinsberg",
  "Rheinstetten",
  "Rhens",
  "Rhinow",
  "Ribnitz-Damgarten",
  "Richtenberg",
  "Riedenburg",
  "Riedlingen",
  "Riedstadt",
  "Rieneck",
  "Riesa",
  "Rietberg",
  "Rinteln",
  "Röbel/Müritz",
  "Rochlitz",
  "Rockenhausen",
  "Rodalben",
  "Rodenberg",
  "Rödental",
  "Rödermark",
  "Rodewisch",
  "Rodgau",
  "Roding",
  "Römhild",
  "Romrod",
  "Ronneburg",
  "Ronnenberg",
  "Rosbach vor der Höhe",
  "Rosenfeld",
  "Rosenheim",
  "Rosenthal",
  "Rösrath",
  "Roßleben",
  "Roßwein",
  "Rostock",
  "Rotenburg an der Fulda",
  "Rotenburg (Wümme)",
  "Roth",
  "Rötha",
  "Röthenbach an der Pegnitz",
  "Rothenburg/O.L.",
  "Rothenburg ob der Tauber",
  "Rothenfels",
  "Rottenburg am Neckar",
  "Rottenburg a.d.Laaber",
  "Röttingen",
  "Rottweil",
  "Rötz",
  "Rüdesheim am Rhein",
  "Rudolstadt",
  "Ruhla",
  "Ruhland",
  "Runkel",
  "Rüsselsheim am Main",
  "Rutesheim",
  "Rüthen",
  "Saalburg-Ebersdorf",
  "Saalfeld/Saale",
  "Saarbrücken",
  "Saarburg",
  "Saarlouis",
  "Sachsenhagen",
  "Sachsenheim",
  "Salzgitter",
  "Salzkotten",
  "Salzwedel",
  "Sandau (Elbe)",
  "Sandersdorf-Brehna",
  "Sangerhausen",
  "Sankt Augustin",
  "Sankt Goar",
  "Sankt Goarshausen",
  "Sarstedt",
  "Sassenberg",
  "Sassnitz",
  "Sayda",
  "Schalkau",
  "Schauenstein",
  "Scheer",
  "Scheibenberg",
  "Scheinfeld",
  "Schelklingen",
  "Schenefeld",
  "Scheßlitz",
  "Schieder-Schwalenberg",
  "Schifferstadt",
  "Schillingsfürst",
  "Schiltach",
  "Schirgiswalde-Kirschau",
  "Schkeuditz",
  "Schkölen",
  "Schleiden",
  "Schleiz",
  "Schleswig",
  "Schlettau",
  "Schleusingen",
  "Schlieben",
  "Schlitz",
  "Schloß Holte-Stukenbrock",
  "Schlotheim",
  "Schlüchtern",
  "Schlüsselfeld",
  "Schmalkalden",
  "Schmallenberg",
  "Schmölln",
  "Schnackenburg",
  "Schnaittenbach",
  "Schneeberg",
  "Schneverdingen",
  "Schöllkrippen",
  "Schömberg",
  "Schönau",
  "Schönau im Schwarzwald",
  "Schönberg",
  "Schönebeck (Elbe)",
  "Schöneck/Vogtl.",
  "Schönewalde",
  "Schongau",
  "Schöningen",
  "Schönsee",
  "Schönwald",
  "Schopfheim",
  "Schöppenstedt",
  "Schorndorf",
  "Schortens",
  "Schotten",
  "Schramberg",
  "Schraplau",
  "Schriesheim",
  "Schrobenhausen",
  "Schrozberg",
  "Schüttorf",
  "Schwaan",
  "Schwabach",
  "Schwäbisch Gmünd",
  "Schwäbisch Hall",
  "Schwabmünchen",
  "Schwaigern",
  "Schwalbach am Taunus",
  "Schwalmstadt",
  "Schwandorf",
  "Schwanebeck",
  "Schwarzenbach am Wald",
  "Schwarzenbach an der Saale",
  "Schwarzenbek",
  "Schwarzenberg/Erzgeb.",
  "Schwarzenborn",
  "Schwarzheide",
  "Schwedt/Oder",
  "Schweich",
  "Schweinfurt",
  "Schwelm",
  "Schwentinental",
  "Schwerin",
  "Schwerte",
  "Schwetzingen",
  "Sebnitz",
  "Seddin",
  "Seehausen (Altmark)",
  "Seeland",
  "Seelow",
  "Seelze",
  "Seesen",
  "Seevetal",
  "Sehnde",
  "Seifhennersdorf",
  "Selb",
  "Selbitz",
  "Seligenstadt",
  "Selm",
  "Selters (Westerwald)",
  "Senden",
  "Sendenhorst",
  "Senftenberg",
  "Seßlach",
  "Siegburg",
  "Siegen",
  "Sigmaringen",
  "Simbach am Inn",
  "Simmern/Hunsrück",
  "Sindelfingen",
  "Singen (Hohentwiel)",
  "Sinsheim",
  "Sinzig",
  "Soest",
  "Solingen",
  "Solms",
  "Soltau",
  "Sömmerda",
  "Sondershausen",
  "Sonneberg",
  "Sonnewalde",
  "Sonthofen",
  "Sontra",
  "Spaichingen",
  "Spalt",
  "Spangenberg",
  "Speicher (Eifel)",
  "Speichersdorf",
  "Spenge",
  "Speyer",
  "Spremberg",
  "Springe",
  "Sprockhövel",
  "Stade",
  "Stadtallendorf",
  "Stadtbergen",
  "Stadthagen",
  "Stadtilm",
  "Stadtlengsfeld",
  "Stadtlohn",
  "Stadtoldendorf",
  "Stadtprozelten",
  "Stadtroda",
  "Stadtsteinach",
  "Stadt Wehlen",
  "Starnberg",
  "Staßfurt",
  "Staufen im Breisgau",
  "Staufenberg",
  "Stavenhagen",
  "St. Blasien",
  "Stein",
  "Steinach",
  "Steinau an der Straße",
  "Steinbach-Hallenberg",
  "Steinbach (Taunus)",
  "Steinfurt",
  "Steinheim an der Murr",
  "Steinheim",
  "Stendal",
  "Stendal (Hansestadt)",
  "Sternberg",
  "St. Ingbert",
  "St. Georgen im Schwarzwald",
  "Stockach",
  "Stolberg (Rheinland)",
  "Stollberg/Erzgeb.",
  "Stolpen",
  "Storkow (Mark)",
  "Stößen",
  "Straelen",
  "Stralsund",
  "Strasburg (Uckermark)",
  "Straubing",
  "Strausberg",
  "Strehla",
  "Stromberg",
  "Stühlingen",
  "Stutensee",
  "Stuttgart",
  "St. Wendel",
  "Suhl",
  "Sulingen",
  "Sulz am Neckar",
  "Sulzbach/Saar",
  "Sulzbach-Rosenberg",
  "Sulzburg",
  "Sundern (Sauerland)",
  "Südliches Anhalt",
  "Süßen",
  "Syke",
  "Sylt",
  "Tambach-Dietharz",
  "Tangerhütte",
  "Tangermünde",
  "Tann (Rhön)",
  "Tanna",
  "Tauberbischofsheim",
  "Taucha",
  "Taunusstein",
  "Tecklenburg",
  "Tegernsee",
  "Telgte",
  "Teltow",
  "Templin",
  "Tengen",
  "Tessin",
  "Teterow",
  "Tettnang",
  "Teublitz",
  "Teuchern",
  "Teupitz",
  "Teuschnitz",
  "Thale",
  "Thalheim/Erzgeb.",
  "Thannhausen",
  "Tharandt",
  "Themar",
  "Thum",
  "Tirschenreuth",
  "Titisee-Neustadt",
  "Tittmoning",
  "Todtnau",
  "Töging am Inn",
  "Tönisvorst",
  "Tönning",
  "Torgau",
  "Torgelow",
  "Tornesch",
  "Traben-Trarbach",
  "Traunreut",
  "Traunstein",
  "Trebbin",
  "Trebsen/Mulde",
  "Treffurt",
  "Trendelburg",
  "Treuchtlingen",
  "Treuen",
  "Treuenbrietzen",
  "Triberg im Schwarzwald",
  "Tribsees",
  "Trier",
  "Triptis",
  "Trochtelfingen",
  "Troisdorf",
  "Trossingen",
  "Trostberg",
  "Tübingen",
  "Tuttlingen",
  "Twistringen",
  "Übach-Palenberg",
  "Überlingen",
  "Uebigau-Wahrenbrück",
  "Ueckermünde",
  "Uelzen",
  "Uetersen",
  "Uffenheim",
  "Uhingen",
  "Ulm",
  "Ulmen",
  "Ulrichstein",
  "Ummerstadt",
  "Unkel",
  "Unna",
  "Unterschleißheim",
  "Usedom",
  "Usingen",
  "Uslar",
  "Vacha",
  "Vaihingen an der Enz",
  "Vallendar",
  "Varel",
  "Vechta",
  "Velbert",
  "Velburg",
  "Velden",
  "Vellberg",
  "Velen",
  "Vellmar",
  "Velten",
  "Verden (Aller)",
  "Veringenstadt",
  "Verl",
  "Versmold",
  "Vetschau/Spreewald",
  "Viechtach",
  "Viernheim",
  "Viersen",
  "Villingen-Schwenningen",
  "Vilsbiburg",
  "Vilseck",
  "Vilshofen an der Donau",
  "Visselhövede",
  "Vlotho",
  "Voerde (Niederrhein)",
  "Vogtsburg im Kaiserstuhl",
  "Vohburg an der Donau",
  "Vohenstrauß",
  "Vöhrenbach",
  "Vöhringen",
  "Volkach",
  "Völklingen",
  "Volkmarsen",
  "Vreden",
  "Wachenheim an der Weinstraße",
  "Wächtersbach",
  "Wadern",
  "Waghäusel",
  "Wahlstedt",
  "Waiblingen",
  "Waibstadt",
  "Waischenfeld",
  "Waldbröl",
  "Waldeck",
  "Waldenbuch",
  "Waldenburg",
  "Waldenburg",
  "Waldershof",
  "Waldheim",
  "Waldkappel",
  "Waldkirch",
  "Waldkirchen",
  "Waldkraiburg",
  "Waldmünchen",
  "Waldsassen",
  "Waldshut-Tiengen",
  "Walldorf",
  "Walldürn",
  "Wallenfels",
  "Walsrode",
  "Waltershausen",
  "Waltrop",
  "Wanfried",
  "Wangen im Allgäu",
  "Wanzleben-Börde",
  "Warburg",
  "Waren (Müritz)",
  "Warendorf",
  "Warin",
  "Warstein",
  "Wassenberg",
  "Wasserburg am Inn",
  "Wassertrüdingen",
  "Wasungen",
  "Wedel",
  "Weener",
  "Wegberg",
  "Wegeleben",
  "Wehr",
  "Weida",
  "Weiden in der Oberpfalz",
  "Weikersheim",
  "Weil am Rhein",
  "Weilburg",
  "Weil der Stadt",
  "Weilheim an der Teck",
  "Weilheim in Oberbayern",
  "Weimar",
  "Weingarten",
  "Weinheim",
  "Weinsberg",
  "Weinstadt",
  "Weismain",
  "Weißenberg",
  "Weißenburg in Bayern",
  "Weißenfels",
  "Weißenhorn",
  "Weißensee",
  "Weißenstadt",
  "Weißenthurm",
  "Weißwasser/O.L.",
  "Weiterstadt",
  "Welzheim",
  "Welzow",
  "Wemding",
  "Wendlingen am Neckar",
  "Werben (Elbe)",
  "Werdau",
  "Werder (Havel)",
  "Werdohl",
  "Werl",
  "Werlte",
  "Wermelskirchen",
  "Wernau (Neckar)",
  "Werne",
  "Werneuchen",
  "Wernigerode",
  "Wertheim",
  "Werther (Westf.)",
  "Wertingen",
  "Wesel",
  "Wesenberg",
  "Wesselburen",
  "Wesseling",
  "Westerburg",
  "Westerstede",
  "Wetter (Ruhr)",
  "Wetter",
  "Wettin-Löbejün",
  "Wetzlar",
  "Widdern",
  "Wiehe",
  "Wiehl",
  "Wiesbaden",
  "Wiesmoor",
  "Wiesensteig",
  "Wiesloch",
  "Wildau",
  "Wildberg",
  "Wildenfels",
  "Wildeshausen",
  "Wilhelmshaven",
  "Wilkau-Haßlau",
  "Willebadessen",
  "Willich",
  "Wilsdruff",
  "Wilster",
  "Wilthen",
  "Windischeschenbach",
  "Windsbach",
  "Winnenden",
  "Winsen (Luhe)",
  "Winterberg",
  "Wipperfürth",
  "Wirges",
  "Wismar",
  "Wissen",
  "Witten",
  "Wittenberg",
  "Wittenberge",
  "Wittenburg",
  "Wittichenau",
  "Wittlich",
  "Wittingen",
  "Wittmund",
  "Wittstock/Dosse",
  "Witzenhausen",
  "Woldegk",
  "Wolfach",
  "Wolfenbüttel",
  "Wolfhagen",
  "Wolframs-Eschenbach",
  "Wolfratshausen",
  "Wolfsburg",
  "Wolfstein",
  "Wolgast",
  "Wolkenstein",
  "Wolmirstedt",
  "Worms",
  "Wörrstadt",
  "Wörth am Rhein",
  "Wörth an der Donau",
  "Wörth am Main",
  "Wriezen",
  "Wülfrath",
  "Wunsiedel",
  "Wunstorf",
  "Wuppertal",
  "Würselen",
  "Wurzbach",
  "Würzburg",
  "Wurzen",
  "Wustrow (Wendland)",
  "Wyk auf Föhr",
  "Xanten",
  "Zahna-Elster",
  "Zarrentin am Schaalsee",
  "Zehdenick",
  "Zeil am Main",
  "Zeitz",
  "Zell am Harmersbach",
  "Zell im Wiesental",
  "Zell (Mosel)",
  "Zella-Mehlis",
  "Zerbst/Anhalt",
  "Zeulenroda-Triebes",
  "Zeven",
  "Ziegenrück",
  "Zierenberg",
  "Ziesar",
  "Zirndorf",
  "Zittau",
  "Zörbig",
  "Zossen",
  "Zschopau",
  "Zülpich",
  "Zweibrücken",
  "Zwenkau",
  "Zwickau",
  "Zwiesel",
  "Zwingenberg",
  "Zwönitz",
];

export const COUNTRIES = [
  { value: 1, name: "Schleswig-Holstein" },
  { value: 2, name: "Hamburg" },
  { value: 3, name: "Niedersachsen" },
  { value: 4, name: "Bremen" },
  { value: 5, name: "Nordrhein-Westfalen" },
  { value: 6, name: "Hessen" },
  { value: 7, name: "Rheinland-Pfalz" },
  { value: 8, name: "Baden-Württemberg" },
  { value: 9, name: "Bayern" },
  { value: 10, name: "Saarland" },
  { value: 11, name: "Berlin" },
  { value: 12, name: "Brandenburg" },
  { value: 13, name: "Mecklenburg-Vorpommern" },
  { value: 14, name: "Sachsen" },
  { value: 15, name: "Sachsen-Anhalt" },
  { value: 16, name: "Thüringen" },
];
