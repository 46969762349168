export class Kpitimed {
  constructor(obj, mappers) {
    this.resource = obj.resource;
    this.id = obj.id;
    this.mappers = mappers;

    this.datasets = obj.datasets;
    this.labels = obj.labels;

    this.info = this.generateInfo(this.resource, this.id);
    this.generateChildren(obj.children);
  }

  generateInfo(resource, id) {
    if (resource === "structure") {
      let name = this.mappers.structures.filter((s) => s.id === id)[0].name;
      return { name: name };
    }
    if (resource === "substructure") {
      let substructure = this.mappers.substructures.filter(
        (s) => s.id === id
      )[0];
      return { name: substructure.name, structure: substructure.structure };
    }
    if (resource === "category") {
      let name = this.mappers.categories.filter((c) => c.id === id)[0].name;
      return { name: name };
    }
    if (resource === "user") {
      let user = this.mappers.users.filter((u) => u.id === id)[0];
      return {
        name: user.user_name,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      };
    }
  }

  generateChildren(children) {
    var arr = children.map((child) => {
      child.info = this.generateInfo(child.resource, child.id);
      return child;
    });
    this.children = arr;
  }
}
