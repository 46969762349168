<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="960"
      scrollable
      :persistent="loading"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @click:outside="closeDialog()"
    >
      <v-card
        v-if="showDialog"
        color="grey lighten-3"
        max-width="960"
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="4"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="overline secondary--text">
          {{ $t("gw.modes.bulk") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" class="pt-4">
              <v-row>
                <v-col cols="12">
                  <LanguageChooserInline
                    colored
                    dense
                    class="pb-0"
                    hidedetails
                    :disabled="loading"
                    :label="$t('general.language')"
                    :initial="inputLanguage ? inputLanguage : $i18n.locale"
                    @change="changeLanguage"
                  />
                  <v-alert
                    text
                    type="info"
                    color="error"
                    class="mt-2 mb-0 caption"
                    dense
                    >{{ $t("gw.bulk.hints.language") }}</v-alert
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    :label="$t('resources.categories.name')"
                    dense
                    :disabled="loading"
                    :rules="rules.name"
                    listen-to-input
                    outlined
                    background-color="white"
                    :hint="$t('gw.addSession.hint')"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="defaults.category_id"
                    :label="$t('gw.default_category')"
                    outlined
                    :items="sortedCategories"
                    :loading="!categories"
                    :disabled="!categories || loading"
                    item-text="name"
                    item-value="id"
                    clearable
                    :background-color="loading ? 'grey lighten-3' : 'white'"
                    dense
                    :hint="$t('gw.bulk.hints.default_category')"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="defaults.time"
                    :label="$t('gw.bulk.question_time_2')"
                    dense
                    :disabled="loading"
                    type="number"
                    listen-to-input
                    outlined
                    background-color="white"
                    :hint="$t('gw.bulk.hints.time')"
                    persistent-hint
                    min="0"
                    max="180"
                  />
                </v-col>
                <v-col cols="12">
                  <GwTextExtractor :initial="text" @apply="setText">
                    <template #button="{ openDialog }">
                      <v-btn
                        depressed
                        small
                        block
                        color="primary"
                        @click="openDialog()"
                      >
                        <v-icon class="mr-2">mdi-file-import</v-icon>
                        <span>{{ $t("general.text_from_file") }}</span>
                      </v-btn>
                    </template>
                  </GwTextExtractor>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="8" class="pt-4">
              <v-textarea
                v-model="text"
                dense
                hide-details
                outlined
                :label="$t('general.text_input')"
                :height="600"
                :rules="rules.input"
                :disabled="loading"
                :background-color="loading ? 'grey lighten-3' : 'white'"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            small
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn
            outlined
            color="secondary"
            small
            class="mr-2"
            :disabled="loading"
            @click="confirmLanguage = !confirmLanguage"
          >
            <span class="mr-2">{{ $t("gw.confirm_language_check") }}</span>
            <v-icon
              small
              :color="confirmLanguage ? 'secondary' : 'secondary'"
              >{{
                confirmLanguage
                  ? "mdi-square-rounded"
                  : "mdi-square-rounded-outline"
              }}</v-icon
            >
          </v-btn>
          <v-tooltip left color="primary" :disabled="confirmLanguage">
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  text
                  color="primary"
                  small
                  :loading="loading"
                  :disabled="
                    name.length === 0 ||
                    text.length === 0 ||
                    loading ||
                    !confirmLanguage
                  "
                  @click="addSession()"
                >
                  {{ $t("gw.create") }}
                </v-btn>
              </div>
            </template>
            <span>{{ $t("general.confirm_checkbox") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import GwTextExtractor from "@/components/ghostwriter/GwTextExtractor";

export default {
  name: "GwAddSessionBulkDialog",
  components: {
    LanguageChooserInline,
    GwTextExtractor,
  },
  props: {
    inputText: {
      type: String,
      required: false,
    },
    inputLanguage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      name: "",
      text: "",
      options: {
        language: this.$i18n.locale,
      },
      defaults: {
        category_id: null,
        time: 30,
      },
      loading: true,
      rules: {
        name: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
        input: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 1000000) ||
            this.$t("ng.too_many_chars_hint", {
              count: v.length,
              max: 1000000,
            }),
        ],
      },
      confirmLanguage: false,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.loadCategories();
        this.resetName();
        if (this.inputText) this.text = this.inputText;
        if (this.inputLanguage) this.options.language = this.inputLanguage;
      }
      if (!val) this.reset();
    },
  },
  methods: {
    ...mapActions("categories", ["fetch_categories"]),
    ...mapActions("ghostwriter", ["add_session"]),
    ...mapMutations("app", ["set_loading"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
    },
    async addSession() {
      this.loading = true;
      this.set_loading(true);
      let payload = {
        name: this.name,
        defaults: this.defaults,
        options: this.options,
        text: this.text,
        type: 2,
      };
      let res = await this.add_session({
        payload: payload,
      });
      this.loading = false;
      this.showDialog = false;
      this.set_loading(false);

      // if worked:
      if (res && res.session_id) {
        this.closeDialog();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.$router.push({
          name: "GwSessionBulkMain",
          params: { id: res.session_id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async loadCategories() {
      this.loading = true;
      await this.fetch_categories();
      this.loading = false;
    },
    reset() {
      this.resetName();
      this.text = "";
      this.defaults = {
        //time: 30,
        category_id: null,
      };
    },
    resetName() {
      let date = new Date().toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      this.name = this.$t("gw.addSession.defaultName", { date: date });
    },
    changeLanguage(val) {
      this.options.language = val;
    },
    setText(val) {
      this.text = val;
    },
  },
};
</script>
