<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">
          Ghostwriter Lizenz hinzufügen
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="input.name"
                  :label="$t('resources.categories.name')"
                  dense
                  outlined
                  background-color="white"
                  hint="Bezeichnung der Lizenz"
                  persistent-hint
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.credits_count"
                  :label="'credits_count'"
                  dense
                  outlined
                  background-color="white"
                  type="number"
                  hint="Standard-Wert der zugewiesenen Credits"
                  persistent-hint
                  min="0"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.credits_keep"
                  :label="input.credits_keep ? 'Ja' : 'Nein'"
                  hint="Credits werden in den Folgezeitraum übernommen"
                  persistent-hint
                  color="primary"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="input.credits_period"
                  label="credits_period"
                  dense
                  outlined
                  background-color="white"
                  :items="credit_periods"
                  hint="Abrechnungszeitraum für Credits"
                  persistent-hint
                  item-text="label"
                  item-value="value"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.is_superadmin"
                  :label="input.is_superadmin ? 'Ja' : 'Nein'"
                  hint="Superadmin-Lizenz (demo)"
                  persistent-hint
                  color="primary"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="input.licences_total"
                  :label="'licences_total'"
                  dense
                  outlined
                  background-color="white"
                  type="number"
                  hint="Anzahl verfügbarer Lizenzen"
                  persistent-hint
                  min="0"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.can_overload"
                  :label="input.can_overload ? 'Ja' : 'Nein'"
                  hint="Zugriff auf Credit Pool (falls vorhanden)"
                  persistent-hint
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-dialog
                  ref="startDatePicker"
                  v-model="startDatePicker"
                  :return-value.sync="input.date_start"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.date_start"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      hint="Start der Lizenz"
                      persistent-hint
                      background-color="white"
                      :disabled="input.is_superadmin"
                      v-on="on"
                      :rules="rules.required"
                    >
                      <template #append>
                        <v-btn icon small @click="input.date_start = ''">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.date_start"
                    scrollable
                    type="month"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDatePicker = false"
                    >
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDatePicker.save(input.date_start)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-dialog
                  ref="endDatePicker"
                  v-model="endDatePicker"
                  :return-value.sync="input.date_end"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.date_end"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      hint="Ende der Lizenz"
                      persistent-hint
                      :disabled="input.is_superadmin"
                      background-color="white"
                      v-on="on"
                      :rules="rules.required"
                    >
                      <template #append>
                        <v-btn icon small @click="input.date_end = ''">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.date_end"
                    scrollable
                    type="month"
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="endDatePicker = false">
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDatePicker.save(input.date_end)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="!valid || loading"
            @click="addLicence()"
          >
            {{ $t("general.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GwLicence } from "@/models/ghostwriter/GwLicence";

export default {
  name: "AddGhostwriterLicenceDialog",
  data() {
    return {
      showDialog: false,
      loading: false,
      valid: false,
      input: null,
      credit_periods: [
        { value: 1, label: "Jährlich" },
        { value: 2, label: "Monatlich" },
      ],
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
        ],
      },
      startDatePicker: false,
      endDatePicker: false,
    };
  },
  watch: {
    "input.is_superadmin": {
      handler: function (v) {
        if (v) {
          this.input.date_start = null;
          this.input.date_end = null;
        }
      },
    },
  },
  methods: {
    ...mapActions("administration", ["add_gw_licence"]),
    closeDialog() {
      this.showDialog = false;
      this.setInput();
    },
    openDialog() {
      this.setInput();
      this.showDialog = true;
    },
    setInput() {
      this.input = new GwLicence();
    },
    async addLicence() {
      this.loading = true;
      let payload = { ...this.input };

      delete payload.id;
      delete payload.users;
      delete payload.licences_used;
      delete payload.licences_blocked;
      delete payload.credits_overload;
      if (!payload.is_superadmin) {
        if (payload.date_start.length === 7)
          payload.date_start = payload.date_start.concat("-01");
        if (payload.date_end.length === 7)
          payload.date_end = payload.date_end.concat("-01");
      }

      let res = await this.add_gw_licence({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Lizenz erfolgreich angelegt.",
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Anlegen der Lizenz.",
        });
      }
    },
    /* reset() {
      this.input = {
        name: "",
        credits_count: 0,
        credits_keep: false,
        credits_period: 2,
      };
    }, */
  },
};
</script>
