<template>
  <div>
    <v-select
      v-if="field.type == 'country_de'"
      :ref="'customField-' + field.name"
      v-model="customFieldInput"
      :items="sortedCountriesDE"
      item-text="name"
      item-value="value"
      :rules="[rules.notEmpty]"
      :disabled="disabled"
      outlined
      background-color="white"
      hide-details
      :label="field.label"
      dense
      @change="setInput()"
    />
    <v-text-field
      v-if="field.type == 'string'"
      :ref="'customField-' + field.name"
      v-model="customFieldInput"
      outlined
      background-color="white"
      hide-details
      :label="field.label"
      dense
      :disabled="disabled"
      :rules="[rules.notEmpty]"
      @change="setInput()"
    />
    <v-autocomplete
      v-if="field.type == 'city_de'"
      :ref="'customField-' + field.name"
      v-model="customFieldInput"
      :items="citiesDE"
      outlined
      background-color="white"
      hide-details
      :label="field.label"
      dense
      :disabled="disabled"
      :rules="[rules.notEmpty]"
      @change="setInput()"
    />
    <v-select
      v-if="field.type == 'grade_de'"
      :ref="'customField-' + field.name"
      v-model="customFieldInput"
      :items="grades"
      item-value="value"
      item-text="label"
      :rules="[rules.notEmpty]"
      outlined
      background-color="white"
      hide-details
      :label="field.label"
      dense
      :disabled="disabled"
      @change="setInput()"
    />
    <v-select
      v-if="field.type == 'schools_de'"
      :ref="'customField-' + field.name"
      v-model="customFieldInput"
      :items="schools"
      item-value="value"
      item-text="label"
      :rules="[rules.notEmpty]"
      outlined
      background-color="white"
      hide-details
      :label="field.label"
      dense
      :disabled="disabled"
      @change="setInput()"
    />
  </div>
</template>

<script>
import { CITIES, COUNTRIES } from "@/plugins/cities.js";
import { GRADES, SCHOOLS } from "@/plugins/schools.js";

export default {
  name: "CustomField",
  props: {
    field: {
      type: Object,
      required: true,
    },
    currentValue: {
      type: [Array, Number, String],
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      customFieldInput: null,
      countriesDE: COUNTRIES.sort((a, b) => a.name.localeCompare(b.name)),
      citiesDE: CITIES,
      rules: {
        notEmpty: (value) =>
          !!value ||
          value === 0 ||
          this.$t("ng.add_chars_hint", this.$i18n.locale),
      },
    };
  },
  computed: {
    sortedCountriesDE() {
      let countries = [...this.countriesDE].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return countries;
    },
    grades() {
      var grades = GRADES.sort((a, b) => a.label.localeCompare(b.label));
      if (process.env.VUE_APP_META_GRADES.length) {
        var active_grades = process.env.VUE_APP_META_GRADES.split(",").map(
          (g) => (g = Number(g))
        );
        grades = grades.filter((g) => active_grades.includes(g.value));
      }
      return grades;
    },
    schools() {
      var schools = SCHOOLS.sort((a, b) => a.label.localeCompare(b.label));
      if (process.env.VUE_APP_META_SCHOOLS.length) {
        var active_schools = process.env.VUE_APP_META_SCHOOLS.split(",").map(
          (s) => (s = Number(s))
        );
        schools = schools.filter((s) => active_schools.includes(s.value));
      }
      return schools;
    },
  },
  mounted() {
    this.setCurrentValue();
  },
  methods: {
    setCurrentValue() {
      let customFieldInput = null;
      if (this.field.type === "city_de")
        customFieldInput = this.citiesDE.filter(
          (c) => c === this.currentValue
        )[0];
      if (this.field.type === "country_de")
        customFieldInput = this.countriesDE.filter(
          (c) => c.value === this.currentValue
        )[0];
      if (this.field.type === "schools_de")
        customFieldInput = this.schools.filter(
          (c) => c.value === this.currentValue
        )[0];
      if (["grade_de", "string"].includes(this.field.type))
        customFieldInput = this.grades.filter(
          (c) => c.value === this.currentValue
        )[0];
      this.customFieldInput = customFieldInput;
    },
    setInput() {
      this.$emit("change", {
        field: this.field,
        value: JSON.parse(JSON.stringify(this.customFieldInput)),
      });
    },
  },
};
</script>
