<template>
  <div>
    <v-btn color="error" small depressed @click="openDialog()">
      <span>{{ $t("resources.nuggets.delete_title") }}</span>
      <v-icon color="white" small class="ml-2"> mdi-delete </v-icon>
    </v-btn>

    <v-dialog
      :value="showDialog"
      width="500"
      @click:outside="closeDialog(false)"
    >
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-title class="text-overline pb-0">
          <span>{{ $t("resources.nuggets.delete_title") }}</span>
        </v-card-title>

        <v-card-text class="pt-2">
          <p class="primary white--text font-weight-bold text-center pa-2">
            {{ nugget.title }}
          </p>
          <p>
            {{ $t("resources.nuggets.delete_description") }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text small @click="closeDialog(false)">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn color="error" text small @click="deleteNugget">
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NuggetDelete",
  components: {},
  props: {
    nugget: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
    };
  },
  methods: {
    ...mapActions("nuggets", ["delete_nugget"]),
    closeDialog(wasDeleted) {
      this.showDialog = false;
      this.$emit("close", wasDeleted);
    },
    openDialog() {
      this.showDialog = true;
    },
    async deleteNugget() {
      this.loading = true;
      let res = await this.delete_nugget({
        payload: this.nugget,
      });
      this.loading = false;
      this.$notify({
        type: res ? "success" : "error",
        title: res ? this.$t("general.success") : this.$t("general.error"),
        text: res
          ? this.$t("resources.nuggets.delete_success")
          : this.$t("resources.nuggets.delete_error"),
      });
      if (res) {
        this.closeDialog(true);
      }
    },
  },
};
</script>

<style></style>
