export const get_journeys = (state) => state.journeys;

export const get_journey = (state) => state.journey;
export const get_user_journey = (state) => state.user_journey;
export const get_journey_bundles = (state) => state.journeyBundles;
export const get_journey_statistics = (state) => state.journeyStats;

export const get_journey_nuggets = (state) => state.journeyNuggets;
export const get_journey_questions = (state) => state.journeyQuestions;

export const get_bundle = (state) => state.bundle;
export const get_bundle_nuggets = (state) => state.bundleNuggets;
export const get_bundle_quizzes = (state) => state.bundleQuizzes;

export const get_certificate = (state) => state.certificate;
export const get_certificates = (state) => state.certificates;

export const get_journey_translations = (state) => state.journey_translations;
export const get_journey_bundle_translations = (state) =>
  state.journey_bundle_translations;
export const get_selected_language = (state) => state.selected_language;
export const get_certificate_translations = (state) =>
  state.journey_translations;
