<template>
  <v-container v-if="emails.length > 0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-chip-group v-if="errors" column>
          <v-chip small label>
            <span class="font-weight-bold mr-2">
              {{ emails.length }}
            </span>
            {{ $t("resources.invitations.total") }}
          </v-chip>
          <v-chip small label color="success">
            <span class="font-weight-bold mr-2">
              {{ errors.valid }}
            </span>
            {{ $t("resources.invitations.valid") }}
          </v-chip>
          <v-chip small label color="error">
            <span class="font-weight-bold mr-2">
              {{ errors.invalid }}
            </span>
            {{ $t("resources.invitations.invalid") }}
          </v-chip>
        </v-chip-group>
        <span
          v-if="errors && errors.duplicates > 0"
          class="caption ml-1"
          v-html="
            $t('resources.invitations.duplicatesText', {
              amount: errors.duplicates,
            })
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="px-0">
        <v-alert
          v-for="(email, index) in mails"
          :key="index"
          outlined
          border="left"
          :type="email.is_valid ? 'success' : 'error'"
          dense
        >
          <div class="body-2 font-weight-bold">
            {{ email.email }}
          </div>
          <div class="caption">
            {{ getErrorText(email) }}
          </div>
          <v-divider class="my-1" />
          <div class="text-right">
            <v-btn text x-small @click="removeMail(email)">
              {{ $t("resources.invitations.removeMail") }}
            </v-btn>
          </div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InvitationMailList",
  props: {
    emails: {
      type: Array,
      required: true,
      default: () => [],
    },
    errorCounter: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    errors: null,
    mails: [],
  }),
  watch: {
    emails(val) {
      if (val) {
        this.mails = { ...this.emails };
        this.errors = { ...this.errorCounter };
      }
    },
  },
  created() {
    this.mails = { ...this.emails };
    this.errors = { ...this.errorCounter };
  },
  methods: {
    getErrorText(email) {
      if (!email.is_valid) {
        if (email.invalid_string)
          return this.$t("resources.invitations.mailError.invalid_string");
        if (email.invitation_exists)
          return this.$t("resources.invitations.mailError.invitation_exists");
        if (email.user_exists)
          return this.$t("resources.invitations.mailError.user_exists");
        return "unknown error";
      }
      return "";
    },
    removeMail(mail) {
      this.$emit("remove-mail", mail);
    },
  },
};
</script>

<style></style>
