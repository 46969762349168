<template>
  <div>
    <v-card :color="color" flat :to="link">
      <v-card-text>
        <v-avatar
          :color="iconBackgroundColor"
          tile
          width="56"
          class="v-avatar--offset ml-2"
        >
          <v-icon :color="iconColor">
            {{ icon }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-light mb-2">
          <b v-if="titleValue" class="mr-1">{{ titleValue }}</b>
          <span>{{ title }}</span>
        </div>
        <div class="subheading font-weight-light">
          <b v-if="subtitleValue" class="mr-1">{{ subtitleValue }}</b>
          <span>{{ subtitle }}</span>
        </div>
        <v-divider class="my-2" />
        <v-icon class="mr-2" small>
          {{ hintIcon }}
        </v-icon>
        <span class="text-caption font-weight-light">{{
          hintText ? hintText : $t("ng.data_last_month")
        }}</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "StatisticsCardLarge",
  props: {
    title: {
      type: [Number, String],
      required: true,
    },
    titleValue: {
      type: [Number, String],
      required: false,
    },
    subtitle: {
      type: [Number, String],
      required: false,
    },
    subtitleValue: {
      type: [Number, String],
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-help",
    },
    iconColor: {
      type: String,
      required: false,
      default: "white",
    },
    iconBackgroundColor: {
      type: String,
      required: false,
      default: "primary",
    },
    link: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    hintText: {
      type: String,
      required: false,
    },
    hintIcon: {
      type: String,
      required: false,
      default: "mdi-clock-outline",
    },
  },
  data() {
    return {
      date: new Date(),
    };
  },
};
</script>
