import { GwPart } from "@/models/ghostwriter/GwPart";
/* import { GwNugget } from '@/models/ghostwriter/content/GwNugget.js';
import { GwQuestion } from '@/models/ghostwriter/content/GwQuestion.js';
 */
export class GwSession {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;

    this.defaults = obj.defaults;
    this.options = obj.options ? obj.options : null;

    this.parts = obj.parts ? this.generateParts(obj.parts) : null;
    this.stats = obj.stats ? obj.stats : null;

    this.is_deleted = obj.is_deleted;
    this.type = obj.type ? obj.type : 1;
    // type:
    // 1 = default
    // 2 = bulk
    this.status = obj.status
      ? obj.status === 1
        ? obj.type === 1
          ? 2
          : 1 //6
        : obj.status
      : 0;
    // status:
    // 0 = can be edited
    // 1 = will be imported, will be set to 2 in frontend
    // 2 = import is running
    // 3 = import successfull
    // 4 = error - not fully imported
    // 5 = error - unexpected error
    // 6 = bulk generating
    // 7 = bulk generated
    this.disabled = this.options.language ? false : true; // no lang temp fix

    this.job = obj.job ? obj.job : null;

    this.user_id = obj.user_id;
    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);
  }

  get nugget_count() {
    let count = 0;
    if (!this.parts && !this.stats) return count;
    if (this.stats) return this.stats.nuggets;
    this.parts.forEach(
      (p) => (count += p.nuggets.filter((n) => !n.is_deleted).length)
    );
    return count;
  }

  get question_count() {
    let count = 0;
    if (!this.parts && !this.stats) return count;
    if (this.stats) return this.stats.questions;
    this.parts.forEach(
      (p) => (count += p.questions.filter((q) => !q.is_deleted).length)
    );
    return count;
  }

  get nugget_count_accepted() {
    let count = 0;
    if (!this.parts) return count;
    this.parts.forEach((p) => {
      let nuggets = [...p.nuggets];
      nuggets.forEach((n) => {
        if (n.is_accepted && !n.is_deleted) count++;
      });
    });
    return count;
  }

  get question_count_accepted() {
    let count = 0;
    if (!this.parts) return count;
    this.parts.forEach((p) => {
      let questions = [...p.questions];
      questions.forEach((q) => {
        if (q.is_accepted && !q.is_deleted) count++;
      });
    });
    return count;
  }

  get whole_content() {
    let tmp = [];
    let content = [];
    if (!this.parts) return content;
    this.parts.forEach((p, i) => {
      tmp[i] = p.nuggets.concat(p.questions);
    });
    tmp.forEach((t) => (content = content.concat(t)));
    if (this.type !== "bulk") content = content.filter((c) => !c.is_deleted);
    return content;
  }
  get unedited_content() {
    let tmp = [];
    let content = [];
    if (!this.parts) return content;
    this.parts.forEach((p, i) => {
      tmp[i] = p.nuggets.concat(p.questions);
    });
    tmp.forEach((t) => (content = content.concat(t)));
    return content.filter(
      (c) => [0, 3].includes(c.status) && !c.is_accepted && !c.is_deleted
    );
  }

  get status_icon() {
    if (this.disabled) return "mdi-close-circle"; // no lang temp fix
    const icon = [
      "mdi-playlist-edit",
      "mdi-database-import",
      "mdi-database-import",
      "mdi-check-circle",
      "mdi-close-circle",
      "mdi-close-circle",
      "mdi-checkbox-marked-circle-auto-outline",
      "mdi-checkbox-multiple-marked-circle",
    ];
    return icon[this.status];
  }
  get status_color() {
    if (this.disabled || [4, 5].includes(this.status)) return "error"; // no lang temp fix
    if ([3, 7].includes(this.status)) return "success";
    return "secondary";
  }

  generateParts(parts) {
    return parts
      .filter((p) => !p.is_deleted)
      .map((p) => new GwPart(p))
      .sort((a, b) => b.created_at - a.created_at);
  }

  get questions() {
    var questions = [];
    this.parts.forEach((part) => questions.push(...part.questions));
    return questions;
  }
  get unedited_questions() {
    var questions = [];
    this.parts.forEach((part) => questions.push(...part.questions));
    return questions.filter((q) => q.status === 0 && !q.is_deleted);
  }

  get nuggets() {
    var nuggets = [];
    this.parts.forEach((part) => nuggets.push(...part.nuggets));
    return nuggets;
  }
  get unedited_nuggets() {
    var nuggets = [];
    this.parts.forEach((part) => nuggets.push(...part.nuggets));
    return nuggets.filter((n) => n.status === 0 && !n.is_deleted);
  }

  get unedited_question_count() {
    let count = 0;
    if (!this.parts) return count;
    this.parts.forEach((p) => {
      let questions = [...p.questions];
      questions.forEach((q) => {
        if (q.status === 0 && !q.is_deleted) count++;
      });
    });
    return count;
  }

  get nugget_bulk_count() {
    let count = 0;
    if (!this.parts && !this.stats) return count;
    if (this.stats) return this.stats.nuggets;
    this.parts.forEach((p) => (count += p.nuggets.length));
    return count;
  }

  get question_bulk_count() {
    let count = 0;
    if (!this.parts && !this.stats) return count;
    if (this.stats) return this.stats.questions;
    this.parts.forEach((p) => (count += p.questions.length));
    return count;
  }

  get unedited_nugget_count() {
    let count = 0;
    if (!this.parts) return count;
    this.parts.forEach((p) => {
      let nuggets = [...p.nuggets];
      nuggets.forEach((n) => {
        if (n.status === 0 && !n.is_deleted) count++;
      });
    });
    return count;
  }

  get cost_prediction() {
    var obj = {
      credits: 0,
      nuggets: 0,
      questions: 0,
    };
    this.parts.forEach((part) => {
      if (!part.options) return false;
      let options = { ...part.options };
      obj.credits += options.credits;
      obj.nuggets += options.nuggets;
      obj.questions += options.questions;
    });
    return obj;
  }

  get importing_items_count() {
    var count = 0;
    this.whole_content.forEach((item) => {
      if (!!item.job && !!item.job.id) count++;
    });
    return count;
  }

  get has_only_empty_parts() {
    var has_parts_with_content;
    this.parts.forEach((part) => {
      if (part.content) has_parts_with_content = true;
    });
    return !has_parts_with_content;
  }
}
