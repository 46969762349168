<template>
  <div>
    <v-tooltip right color="primary">
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="activator" :open-dialog="openDialog" />
        </div>
      </template>
      <span> {{ $t("gw.sessionDetail.detailView") }}</span>
    </v-tooltip>

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      scrollable
      max-width="800"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-3" :loading="loading">
        <template #progress>
          <v-progress-linear indeterminate color="primary" height="2" />
        </template>

        <v-toolbar color="secondary" dark extension-height="40" height="40">
          <v-toolbar-title class="overline">{{
            resource
              ? `${$t("resources.substructures.singular")}: ${resource.name}`
              : $t("general.loading")
          }}</v-toolbar-title>

          <v-spacer />

          <KpiVersionSelector
            v-show="false"
            class="mr-2"
            color="secondary lighten-1"
          />

          <v-btn icon small @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs v-model="tab">
              <v-tab class="caption" :disabled="loading">{{
                $t("general.overview")
              }}</v-tab>
              <v-tab class="caption" :disabled="loading">{{
                $t("kpi.performance")
              }}</v-tab>
              <v-tab class="caption" :disabled="loading">{{
                $t("kpi.activity")
              }}</v-tab>
              <!-- <v-tab class="caption" :disabled="loading">Substrukturen</v-tab> -->
              <!-- <v-tab class="caption" :disabled="loading">Vergleichen</v-tab> -->
            </v-tabs>
          </template>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <KpiSubstructureMain
                :id="id"
                :stats="stats"
                @change-tab="changeTab"
              />
            </v-tab-item>
            <v-tab-item>
              <KpiSubstructurePerformance :id="id">
                <template #filter>
                  <KpiDatePicker
                    :start="date_start"
                    :end="date_end"
                    @date-change="setDates"
                  />
                </template>
              </KpiSubstructurePerformance>
            </v-tab-item>
            <v-tab-item>
              <KpiSubstructureActivity :id="id">
                <template #filter>
                  <KpiDatePicker
                    :start="date_start"
                    :end="date_end"
                    @date-change="setDates"
                  />
                </template>
              </KpiSubstructureActivity>
            </v-tab-item>
            <!-- <v-tab-item>
              <KpiSubstructureSubstructures :id="id" />
            </v-tab-item> -->
            <!-- <v-tab-item>
              <KpiSubstructureCompare :id="id" />
            </v-tab-item> -->
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import KpiSubstructureMain from "@/components/kpi/dialogs/substructure/KpiSubstructureMain";
import KpiSubstructurePerformance from "@/components/kpi/dialogs/substructure/KpiSubstructurePerformance";
import KpiSubstructureActivity from "@/components/kpi/dialogs/substructure/KpiSubstructureActivity";
import KpiDatePicker from "@/components/kpi/KpiDatePicker";
import KpiVersionSelector from "@/components/kpi/KpiVersionSelector";

export default {
  name: "StatisticsDialogSubstructure",
  components: {
    KpiSubstructureMain,
    KpiSubstructurePerformance,
    KpiSubstructureActivity,
    KpiDatePicker,
    KpiVersionSelector,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: true,
      tab: 0,
      date_start: "",
      date_end: "",
    };
  },
  computed: {
    ...mapGetters("kpi", {
      resource: "get_kpi_resource_detail",
      dates: "get_kpi_dates",
      version: "get_kpi_version",
    }),
  },
  watch: {
    showDialog: {
      handler: function (v) {
        if (v) this.fetchStatistics();
      },
      version: {
        handler: function () {
          this.fetchStatistics();
        },
      },
    },
  },
  beforeMount() {
    this.initDates();
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_statistics_detail"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("kpi", [
      "set_kpi_current_detail",
      "set_kpi_current_detail_stats",
      "set_kpi_timed_detail",
      "set_kpi_resource_detail",
    ]),
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.set_kpi_current_detail(null);
      this.set_kpi_current_detail_stats(null);
      this.set_kpi_timed_detail(null);
      this.set_kpi_resource_detail(null);
    },
    async fetchStatistics() {
      if (!this.id || !this.showDialog) return false;
      this.loading = true;
      var payload = {
        id: this.id,
        date_start:
          this.date_start.length === 7
            ? this.date_start.concat("-01")
            : this.date_start,
        date_end:
          this.date_end.length === 7
            ? this.date_end.concat("-01")
            : this.date_end,
      };
      await this.fetch_structures();
      await this.fetch_kpi_statistics_detail({
        payload: payload,
        type: "substructures",
      });

      this.loading = false;
    },
    initDates() {
      this.date_start = this.dates.date_start;
      this.date_end = this.dates.date_end;
    },
    setDates(v) {
      this.date_start = v.date_start;
      this.date_end = v.date_end;
      this.fetchStatistics();
    },
    changeTab(v) {
      this.tab = v;
    },
  },
};
</script>
