import { Question } from "@/models/content/questions/Question";
import i18n from "@/i18n.js";

export class QuestionEstimation extends Question {
  init(obj) {
    super.init(obj);
    this.question_type = "estimation";
    this.type_image = "/img/question_type_5.png";

    this.answer = {
      id: obj && obj.answers ? obj.answers[0].id : this.getRandomId(),
      body: obj && obj.answers ? Number(obj.answers[0].body) : 0,
      body_format: obj && obj.answers ? obj.answers[0].body_format : "text",
      is_correct: obj && obj.answers ? obj.answers[0].is_correct : true,
      order: 0,
      options: null,
    };
    //options? order?

    this.options = {
      border: {
        min: obj && Number(obj.options.border.min) ? obj.options.border.min : 0,
        max: obj && Number(obj.options.border.max) ? obj.options.border.max : 0,
      },
      tolerance: {
        min:
          obj && Number(obj.options.tolerance.min)
            ? obj.options.tolerance.min
            : 0,
        max:
          obj && Number(obj.options.tolerance.max)
            ? obj.options.tolerance.max
            : 0,
      },
    };
  }

  get formRules() {
    return {
      body: [
        this.body.length > 0 || i18n.t("ng.add_chars_hint"),
        this.body.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.body.length,
            max: 255,
          }),
      ],
      hint: [
        this.hint.length <= 255 ||
          i18n.t("ng.too_many_chars_hint", {
            count: this.hint.length,
            max: 255,
          }),
      ],
      answer: [
        typeof this.answer.body === "number" || i18n.t("ng.numeric_needed"),
      ],
      borderMin: [
        this.options.border.min < this.answer.body ||
          i18n.t("resources.questions.estimation_border_left_rule"),
      ],
      borderMax: [
        this.options.border.max > this.answer.body ||
          i18n.t("resources.questions.estimation_border_right_rule"),
      ],
      toleranceMin: [
        (this.options.tolerance.min < this.answer.body &&
          this.options.tolerance.min >= this.options.border.min) ||
          i18n.t("resources.questions.estimation_tolerance_left_rule"),
      ],
      toleranceMax: [
        (this.options.tolerance.max > this.answer.body &&
          this.options.tolerance.max <= this.options.border.max) ||
          i18n.t("resources.questions.estimation_tolerance_right_rule"),
      ],
      categories: [
        this.category_ids.length > 0 ||
          i18n.t("resources.questions.select_category"),
      ],
      explanation: [
        (v) => {
          if (v)
            return (
              v.length <= 512 ||
              i18n.t("ng.too_many_chars_hint", { count: v.length, max: 512 })
            );
          else return true;
        },
      ],
      time: [this.time >= 0 || ">= 0 !", this.time <= 180 || "<= 180 !"],
    };
  }

  getPostProperties() {
    delete this.answer.id;
    this.answer.body = this.answer.body.toString();
    this.answers = [this.answer];
    return super.getPostProperties();
  }

  canBeActivated() {
    //if(!this.category_ids || this.category_ids.length === 0) return false;
    if (this.status !== 1) return false;
    return this.can.activate;
  }
}
