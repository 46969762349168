<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="grey lighten-3" class="pa-4 text-left" flat>
          <div
            v-if="['text', 'html'].includes(question.body_format)"
            class="body"
            v-html="question.body"
          />

          <MathJaxRenderer
            v-if="['math'].includes(question.body_format)"
            :formula="question.body"
            :safe="false"
            class="body"
          />

          <div v-if="question.hint" class="caption mt-4">
            {{ question.hint }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-list
      v-if="
        ['gap_text', 'single_choice', 'multi_choice'].includes(
          question.question_type
        )
      "
      dense
      color="transparent"
      two-line
      class="my-4"
    >
      <v-list-item
        v-for="(answer, i) in question.answers"
        :key="i"
        class="mb-2 grey lighten-3"
        three-line
      >
        <v-list-item-action
          class="justify-center align-center"
          style="align-self: center"
        >
          <v-list-item-action-text class="secondary--text font-weight-medium">
            {{
              question.question_type !== "gap_text"
                ? $t("resources.questions.answer")
                : $t("resources.questions.gap")
            }}
            {{ i + 1 }}
          </v-list-item-action-text>
          <v-tooltip
            v-if="question.question_type !== 'gap_text'"
            right
            color="primary"
          >
            <template #activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                :color="answer.is_correct ? 'success' : 'error'"
                class="mt-1"
                >{{
                  answer.is_correct ? "mdi-check-circle" : "mdi-close-circle"
                }}</v-icon
              >
            </template>
            <span>{{
              answer.is_correct
                ? $t("resources.questions.correct")
                : $t("resources.questions.wrong")
            }}</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-content>
          <div
            v-if="['text', 'html'].includes(answer.body_format)"
            v-html="answer.body"
            class="body-2"
          />
          <MathJaxRenderer
            v-if="['math'].includes(answer.body_format)"
            :formula="answer.body"
            :safe="false"
            class="body-2"
          />
        </v-list-item-content>
        <v-list-item-action
          v-if="
            question.question_type === 'gap_text' &&
            answer.body_format === 'math'
          "
        >
          <v-list-item-action-text>{{
            fields[i].error
          }}</v-list-item-action-text>
          <v-text-field
            v-model="fields[i].value"
            outlined
            dense
            hide-details
            :background-color="fields[i].color"
          >
            <template #append-outer>
              <v-btn
                small
                depressed
                color="primary"
                :loading="fields[i].loading"
                :disabled="!fields[i].value || fields[i].value.length === 0"
                @click="checkAnswer(answer, i)"
              >
                {{ $t("views.dashboard.check") }}
              </v-btn>
            </template>
          </v-text-field>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-row v-if="['estimation'].includes(question.question_type)">
      <v-col cols="12">
        <StatisticsCardSmall
          :title="question.answer.body"
          :subtitle="$t('resources.questions.correct')"
          icon="mdi-check"
        />
      </v-col>
      <v-col cols="6">
        <StatisticsCardSmall
          :title="question.options.border.min"
          :subtitle="$t('resources.questions.border_left')"
          icon="mdi-arrow-collapse-left"
          color="grey lighten-3"
          text-color="secondary"
          icon-color="secondary"
        />
      </v-col>
      <v-col cols="6">
        <StatisticsCardSmall
          :title="question.options.border.max"
          :subtitle="$t('resources.questions.border_right')"
          icon="mdi-arrow-collapse-right"
          color="grey lighten-3"
          text-color="secondary"
          icon-color="secondary"
        />
      </v-col>
      <v-col cols="6">
        <StatisticsCardSmall
          :title="question.options.tolerance.min"
          :subtitle="$t('resources.questions.tolerance_left')"
          icon="mdi-gate-arrow-right"
          color="grey lighten-3"
          text-color="secondary"
          icon-color="secondary"
        />
      </v-col>
      <v-col cols="6">
        <StatisticsCardSmall
          :title="question.options.tolerance.max"
          :subtitle="$t('resources.questions.tolerance_right')"
          icon="mdi-gate-arrow-left"
          color="grey lighten-3"
          text-color="secondary"
          icon-color="secondary"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          small
          depressed
          color="secondary"
          :to="{ name: 'QuestionSettings', params: { id: question.id } }"
        >
          {{ $t("resources.invitations.settings") }}
        </v-btn>
        <!-- <v-btn
          small
          color="secondary"
          class="ml-2"
          depressed
          disabled
        >
          App-Vorschau
        </v-btn> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import { QuestionMultiChoice } from "@/models/content/questions/types/QuestionMultiChoice";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";
import MathJaxRenderer from "@/components/global/MathJaxRenderer";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";

export default {
  name: "QuestionPreview",
  components: {
    MathJaxRenderer,
    StatisticsCardSmall,
  },
  props: {
    question: {
      type: [
        QuestionSingleChoice,
        QuestionMultiChoice,
        QuestionEstimation,
        QuestionGap,
      ],
    },
  },
  data() {
    return {
      fields: [],
    };
  },
  beforeMount() {
    this.setInputVars();
  },
  methods: {
    ...mapActions("questions", ["check_math"]),
    setInputVars() {
      this.question.answers.forEach(() => {
        this.fields.push({
          value: "",
          color: "white",
          error: "",
          loading: false,
        });
      });
    },
    async checkAnswer(answer, i) {
      this.fields[i].loading = true;
      let payload = {
        solution: answer.body.trimStart().trimEnd(),
        answer: this.fields[i].value.trimStart().trimEnd(),
        language: this.$i18n.locale,
      };

      let res = await this.check_math({
        payload: payload,
      });

      if (res.is_valid === null) {
        this.fields[i].color = "red";
        this.fields[i].error = "Formel ungültig";
      }
      if (res.is_valid === false) {
        this.fields[i].color = "red";
        this.fields[i].error = "Ungleiches Ergebnis";
      }
      if (res.is_valid === true) {
        this.fields[i].color = "success";
        this.fields[i].error = "Das passt!";
      }
      /* TODO translate above */
      this.fields[i].loading = false;
    },
  },
};
</script>
