<template>
  <div>
    <ViewLoader v-if="loading" height="200" />

    <section v-if="!loading">
      <v-container class="pt-0">
        <v-row>
          <v-col cols="12" md="3">
            <h5 class="overline mb-0 pb-0">
              {{ $t("views.statistics.categories_popular_title") }}
            </h5>
            <span class="caption grey--text mt-0">{{
              $t("views.statistics.categories_popular_descr")
            }}</span>
          </v-col>
          <v-col cols="12" md="8" offset-md="1">
            <StatisticsChartBarHorizontal
              :chart-data="categoriesMostPlayed"
              :visible-rows="
                categoriesMostPlayed.datasets.length > 5
                  ? 5
                  : categoriesMostPlayed.datasets.length
              "
              color="primary"
            />
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-row>
          <v-col cols="12" md="3">
            <h5 class="overline mb-0 pb-0">
              {{ $t("views.statistics.categories_difficulty_title") }}
            </h5>
            <span class="caption grey--text mt-0">{{
              $t("views.statistics.categories_difficulty_descr")
            }}</span>
          </v-col>
          <v-col cols="12" md="8" offset-md="1">
            <StatisticsChartBarHorizontal
              :chart-data="categoriesMostCorrectAnswers"
              :display-min-max-only="
                categoriesMostCorrectAnswers.datasets.length > 4
              "
              colored
            />
          </v-col>
        </v-row>
        <v-divider class="my-5" />
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <h5 class="overline">
              {{ $t("general.details") }}
            </h5>
            <span class="caption grey--text mt-0">{{
              $t("ng.display_by_category")
            }}</span>
          </v-col>
          <v-col cols="12" sm="6" md="8" offset-md="1">
            <v-autocomplete
              v-model="currentCategory"
              :items="categories"
              item-text="name"
              clearable
              solo
              dense
              flat
              background-color="grey lighten-3"
              return-object
              :placeholder="$t('general.search')"
            />
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12">
            <div v-if="!currentCategory">
              {{ $t("resources.questions.select_category") }}
            </div>
            <div v-if="currentCategory">
              <SectionHeader
                :title="currentCategory.name"
                :subtitle="currentCategoryGroupNames"
                class="px-0 mb-2"
              >
                <template #action>
                  <v-btn
                    depressed
                    color="primary"
                    x-small
                    :to="{
                      name: 'CategoryDetail',
                      params: { id: currentCategory.id },
                    }"
                  >
                    {{ $t("general.details") }}
                  </v-btn>
                </template>
              </SectionHeader>
              <v-row dense>
                <v-col col="6" sm="3">
                  <StatisticsCardSimple
                    :value="currentCategory.stats.questions.count"
                    :text="$t('resources.questions.plural')"
                  />
                </v-col>
                <v-col col="6" sm="3">
                  <StatisticsCardSimple
                    :value="currentCategory.stats.nuggets.count"
                    :text="$t('resources.nuggets.plural')"
                  />
                </v-col>
                <v-col col="6" sm="3">
                  <StatisticsCardSimple
                    :value="
                      currentCategory.is_active
                        ? $t('general.yes')
                        : $t('general.no')
                    "
                    :text="$t('resources.categories.isactive')"
                  />
                </v-col>
                <v-col col="6" sm="3">
                  <StatisticsCardSimple
                    :value="
                      currentCategory.is_active_nugget
                        ? $t('general.yes')
                        : $t('general.no')
                    "
                    :text="$t('resources.categories.isactive_nugget')"
                  />
                </v-col>
              </v-row>
              <v-row dense class="mt-4">
                <v-col cols="6" md="3">
                  <StatisticsCardSimple
                    :value="currentCategory.stats.rounds.played"
                    :text="$t('resources.categories.roundsPlayed')"
                  />
                </v-col>
                <v-col cols="6" md="3">
                  <StatisticsCardSimple
                    :value="currentCategory.stats.rounds.perfect"
                    :text="
                      $t('resources.categories.statistics_list.rounds_perfect')
                    "
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <StatisticsCardSimple
                    :value="`${currentCategory.stats.questions.percent} %`"
                    :text="
                      $t('resources.users.statistics_list.correctQuestionsSub')
                    "
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader.vue";
import StatisticsChartBarHorizontal from "@/components/statistics/StatisticsChartBarHorizontal.vue";
import StatisticsCardSimple from "@/components/statistics/StatisticsCardSimple";

export default {
  name: "StatisticsCategories",
  components: {
    ViewLoader,
    StatisticsChartBarHorizontal,
    SectionHeader,
    StatisticsCardSimple,
  },
  data() {
    return {
      loading: true,
      categoriesMostPlayed: {
        labels: [],
        datasets: [],
      },
      categoriesMostCorrectAnswers: {
        labels: [],
        datasets: [],
      },
      accumulated_values: {
        categories: 0,
        questions: 0,
        rounds: 0,
      },
      currentCategory: null,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_categories" }),
    currentCategoryGroupNames() {
      if (!this.currentCategory) return "";
      if (this.currentCategory.groups.length === 0)
        return this.$t("ng.no_category_group");
      let names = "";
      this.currentCategory.groups.forEach((group, i) => {
        if (i <= this.currentCategory.groups.length && i > 0)
          names = names.concat(", ");
        names = names.concat(group.name);
      });
      return names;
    },
  },
  destroyed() {
    this.set_categories(null);
  },
  methods: {
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories();
      this.generateData();
      this.loading = false;
    },
    generateCategoriesMostPlayed(categories) {
      categories.sort((a, b) => {
        return b.stats.rounds.played - a.stats.rounds.played;
      });
      categories.forEach((category) => {
        this.categoriesMostPlayed.labels.push(category.name);
        this.categoriesMostPlayed.datasets.push(
          Math.round(
            (category.stats.rounds.played / this.accumulated_values.rounds) *
              100
          )
        );
      });
    },
    generateMostCorrectAnswers(categories) {
      categories.sort((a, b) => {
        return b.stats.questions.percent - a.stats.questions.percent;
      });
      categories.forEach((category) => {
        this.categoriesMostCorrectAnswers.labels.push(category.name);
        this.categoriesMostCorrectAnswers.datasets.push(
          category.stats.questions.percent
        );
      });
    },
    generateData() {
      let categories = [...this.categories];
      this.accumulated_values.categories = categories.length;

      this.accumulated_values.questions = categories.reduce((sum, obj) => {
        return (sum += obj.stats.questions.count);
      }, 0);
      this.accumulated_values.rounds = categories.reduce((sum, obj) => {
        return (sum += obj.stats.rounds.played);
      }, 0);

      this.generateCategoriesMostPlayed(categories);
      this.generateMostCorrectAnswers(categories);
    },
  },
};
</script>
