var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('ViewLoader'):_vm._e(),_c('v-expand-transition',[(!_vm.loading)?_c('section',[_c('SectionHeader',{attrs:{"title":_vm.$t('kpi.overview_title'),"subtitle":_vm.$t('kpi.overview_subtitle')}}),(_vm.general.current.length === 0)?_c('v-container',[_c('v-alert',{attrs:{"type":"error","color":"error","text":"","prominent":""}},[_vm._v(_vm._s(_vm.$t("kpi.data_error")))])],1):_vm._e(),(_vm.general.current.length > 0 || !!_vm.general.current.completion)?_c('v-container',[_c('v-row',[(_vm.general.current.completion)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSheetCard',{attrs:{"value":_vm.general.current.completion[0],"delta":_vm.general.current.completion[0] - _vm.general.current.completion[1],"text-color":"accent","description":`<b class='accent--text'>${_vm.$t(
                'kpi.discovery'
              )}</b> ${_vm.$t('kpi.discovery_description')}`,"icon":"mdi-progress-check","explanation-title":_vm.$t('kpi.discovery'),"explanation-text":_vm.$t('kpi.discovery_description_long'),"to":{ name: 'KpiPerformanceDiscovery' }}})],1):_vm._e(),(_vm.general.current.competency)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSheetCard',{attrs:{"value":_vm.general.current.competency[0],"delta":_vm.general.current.competency[0] - _vm.general.current.competency[1],"text-color":"accent","description":`<b class='accent--text'>${_vm.$t(
                'kpi.competency'
              )}</b> ${_vm.$t('kpi.competency_description')}`,"icon":"mdi-progress-star","explanation-title":_vm.$t('kpi.competency'),"explanation-text":_vm.$t('kpi.competency_description_long'),"to":{ name: 'KpiPerformanceCompetency' }}})],1):_vm._e(),(_vm.general.current.retention)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSheetCard',{attrs:{"value":_vm.general.current.retention[0],"delta":_vm.general.current.retention[0] - _vm.general.current.retention[1],"text-color":"accent","description":`<b class='accent--text'>${_vm.$t(
                'kpi.retention'
              )}</b> ${_vm.$t('kpi.retention_description')}`,"icon":"mdi-progress-star-four-points","explanation-title":_vm.$t('kpi.retention'),"explanation-text":_vm.$t('kpi.retention_description_long'),"to":{ name: 'KpiPerformanceRetention' }}})],1):_vm._e(),(_vm.general.current.currentSpeed)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSheetCard',{attrs:{"value":_vm.general.current.currentSpeed[0],"delta":_vm.general.current.currentSpeed[0] -
                _vm.general.current.currentSpeed[1],"text-color":"info","description":`<b class='info--text'>${_vm.$t(
                'kpi.speed'
              )}</b> ${_vm.$t('kpi.speed_description')}`,"icon":"mdi-play-speed","explanation-title":_vm.$t('kpi.speed'),"explanation-text":_vm.$t('kpi.speed_description_long'),"to":{ name: 'KpiActivitySpeed' }}})],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()]),_c('v-expand-transition',[(!_vm.loading)?_c('section',{staticClass:"mt-4"},[_c('SectionHeader',{attrs:{"title":_vm.$t('kpi.data_overview_title'),"subtitle":_vm.$t('kpi.data_overview_subtitle')}}),(!!_vm.general.stats)?_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.general.stats.structures)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSimpleCard',{attrs:{"value":_vm.general.stats.structures[0],"delta":_vm.general.stats.structures[1],"title":_vm.$t('resources.structures.plural')}})],1):_vm._e(),(_vm.general.stats.substructures)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSimpleCard',{attrs:{"value":_vm.general.stats.substructures[0],"delta":_vm.general.stats.substructures[1],"title":_vm.$t('resources.substructures.plural')}})],1):_vm._e(),(_vm.general.stats.users)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSimpleCard',{attrs:{"value":_vm.general.stats.users[0],"delta":_vm.general.stats.users[1],"title":_vm.$t('resources.users.plural')}})],1):_vm._e(),(_vm.general.stats.categories)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSimpleCard',{attrs:{"value":_vm.general.stats.categories[0],"delta":_vm.general.stats.categories[1],"title":_vm.$t('resources.categories.plural')}})],1):_vm._e(),(_vm.general.stats.questions)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('KpiStatsSimpleCard',{attrs:{"value":_vm.general.stats.questions[0],"delta":_vm.general.stats.questions[1],"title":_vm.$t('resources.questions.plural')}})],1):_vm._e()],1)],1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }