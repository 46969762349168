<template>
  <div>
    <section
      v-if="question && [0, 2, 3].includes(question.status)"
      class="mb-8"
    >
      <QuestionSubmission :question="question" />
    </section>

    <v-divider
      v-if="question && [0, 2, 3].includes(question.status)"
      class="mx-2 my-8"
    />
    <section v-if="question">
      <SectionHeader
        :title="$t('resources.categories.plural')"
        :subtitle="$t('ng.question_categories_hint')"
      >
        <template #action>
          <QuestionActivator
            :question="question"
            :disabled="[-1, 2, 3].includes(question.status)"
          />
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col class="d-flex">
            <v-chip
              v-if="!question.categories || question.categories.length === 0"
              small
              color="secondary"
              label
              class="mr-2"
            >
              {{ $t("ng.question_no_category") }}
            </v-chip>

            <v-chip
              v-for="(category, i) in question.categories"
              :key="i"
              small
              color="primary"
              label
              class="mr-2"
            >
              {{ category.name }}
            </v-chip>
            <QuestionCategoryChanger
              :question="question"
              :disabled="!question.can_edit || question.is_active"
            />
          </v-col>
          <v-expand-transition>
            <v-col
              v-if="!question.categories || question.categories.length === 0"
              cols="12"
            >
              <v-alert type="info" dense text>
                <span class="caption">{{
                  $t("ng.question_no_category_hint")
                }}</span>
              </v-alert>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-container>
    </section>

    <!-- <v-divider class="mx-2 my-8" /> -->

    <section v-if="question" class="my-4">
      <SectionHeader
        :title="$t('resources.invitations.preview')"
        :subtitle="$t('ng.question_preview_hint')"
      >
        <template #action>
          <LanguageChooserInline
            hidedetails
            hide-border
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>
      <QuestionPreview :question="question" />
    </section>

    <section v-if="question" class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.generalInfo.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      />

      <v-container>
        <v-row dense>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="question.type_name"
              :subtitle="$t('resources.questions.type')"
              icon="mdi-comment-question"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="question.image ? $t('general.yes') : $t('general.no')"
              :subtitle="$t('resources.questions.img_url')"
              icon="mdi-image-album"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
              :link="{ name: 'QuestionMedia' }"
            />
          </v-col>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="
                question.explanation.id === -1
                  ? $t('general.no')
                  : $t('general.yes')
              "
              :subtitle="$t('resources.questions.explanation')"
              icon="mdi-file-document-alert"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
              :link="{ name: 'QuestionExplanation' }"
            />
          </v-col>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="question.created_at.toLocaleDateString()"
              :subtitle="$t('general.created_at')"
              icon="mdi-calendar-range"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="question.updated_at.toLocaleDateString()"
              :subtitle="$t('general.updated_at')"
              icon="mdi-calendar-range"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col cols="6" md="4">
            <StatisticsCardSmall
              :title="question.status_name"
              subtitle="Status"
              icon="mdi-alert-box"
              color="grey lighten-3"
              text-color="secondary"
              :icon-color="question.status_color"
            />
          </v-col>
          <v-col
            v-if="question.reports && question.reports.length > 0"
            cols="6"
            md="4"
          >
            <StatisticsCardSmall
              :title="question.reports.length"
              :subtitle="$t('resources.questions.has_reports')"
              icon="mdi-alert"
              color="warning"
              text-color="black"
              icon-color="error"
              :link="{ name: 'QuestionReports', params: { id: question.id } }"
            />
          </v-col>
          <v-col v-if="question.import_question" cols="6" md="4">
            <StatisticsCardSmall
              :title="$t('ng.question_import')"
              subtitle="Originaldaten anzeigen"
              icon="mdi-import"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
              :link="{
                name: 'ImportDetailQuestion',
                params: {
                  id: question.import_question.import_id,
                  question_id: question.import_question.id,
                },
              }"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="question" class="mt-4">
      <SectionHeader
        :title="$t('ng.further_actions')"
        :subtitle="$t('ng.further_actions_descr')"
      />
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-tooltip top color="primary" :disabled="!question.is_active">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <QuestionDelete
                    :question="question"
                    @close="checkForRedirect"
                  />
                  <!-- :disabled="[-1,3].includes(question.status)" -->
                </div>
              </template>
              <span>{{ $t("ng.question_deactivate_first") }}</span>
            </v-tooltip>
            <!-- <v-btn
              color="primary"
              small
              depressed
              disabled
              class="ml-2"
            >
              <span>Frage duplizieren</span>
              <v-icon
                color="white"
                small
                class="ml-2"
              >
                mdi-content-copy
              </v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import QuestionActivator from "@/components/questions/QuestionActivator";
import QuestionCategoryChanger from "@/components/questions/QuestionCategoryChanger";
import QuestionDelete from "@/components/questions/QuestionDelete";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import QuestionPreview from "@/components/questions/QuestionPreview";
import QuestionSubmission from "@/components/questions/QuestionSubmission";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";

export default {
  name: "QuestionSummary",
  components: {
    SectionHeader,
    QuestionActivator,
    QuestionCategoryChanger,
    QuestionDelete,
    LanguageChooserInline,
    QuestionPreview,
    QuestionSubmission,
    StatisticsCardSmall,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      language: "get_selected_language",
    }),
  },
  methods: {
    ...mapActions("questions", ["patch_question", "fetch_question"]),
    ...mapMutations("questions", ["set_selected_language"]),
    checkForRedirect(wasDeleted) {
      if (wasDeleted) {
        this.$router.push({ name: "QuestionOverview" });
      }
    },
    async changeLanguage(lang) {
      this.set_selected_language(lang);
      this.loading = true;
      await this.fetch_question({
        id: this.question.id,
        language: this.language,
      });
      this.loading = false;
    },
  },
};
</script>
