<template>
  <v-container class="statistics-list-user">
    <v-row v-if="typeof stats.stats.content[0] != 'object'">
      <v-col cols="12" class="pl-0">
        {{ $t("resources.users.no_stats") }}
      </v-col>
    </v-row>
    <v-row v-if="typeof stats.stats.content[0] == 'object'">
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :size="48"
          class="mb-3"
          :width="4"
          :value="duelWinsPercent"
          color="teal"
        >
          {{ stats.overallStats.duelWins }} </v-progress-circular
        ><br />
        <span class="text-body">{{
          $t("resources.users.statistics_list.duelWins")
        }}</span
        ><br />
        <span class="text-caption">{{
          $t("resources.users.statistics_list.duelWinsSub")
        }}</span>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :size="48"
          class="mb-3"
          :width="4"
          :value="questionCorrectPercent"
          color="light-green"
        >
          {{ stats.overallStats.questionCorrect }} </v-progress-circular
        ><br />
        <span class="text-body">{{
          $t("resources.users.statistics_list.correctQuestions")
        }}</span
        ><br />
        <span class="text-caption">{{
          $t("resources.users.statistics_list.correctQuestionsSub")
        }}</span>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :size="48"
          class="mb-3"
          :width="4"
          :value="questionsAcceptedPercent"
          color="lime"
        >
          {{ stats.overallStats.questionsAccepted }} </v-progress-circular
        ><br />
        <span class="text-body">{{
          $t("resources.users.statistics_list.acceptedQuestions")
        }}</span
        ><br />
        <span class="text-caption">{{
          $t("resources.users.statistics_list.acceptedQuestionsSub")
        }}</span>
      </v-col>
    </v-row>
    <v-row v-if="typeof stats.stats.content[0] == 'object'" class="mt-2">
      <v-col
        v-if="years"
        class="grey lighten-4 d-flex align-center justify-center py-0"
        cols="12"
      >
        <v-btn
          color="primary"
          :disabled="year_index == 0"
          icon
          @click="year_index--"
        >
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        <v-spacer />
        <span>
          <b>{{ years[year_index] }}</b>
        </span>
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="year_index >= years.length - 1"
          icon
          @click="year_index++"
        >
          <v-icon> mdi-arrow-right </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-list class="py-0">
          <v-list-item>
            <span>{{
              $t(
                "resources.users.statistics_list." +
                  stats.stats.content[1].name.replace(" ", "")
              )
            }}</span>
            <v-spacer />
            <span
              >{{ stats.stats.content[1].data[year_index] }} /
              {{ stats.stats.content[1].totalCount[year_index] }}</span
            >
          </v-list-item>
          <v-list-item>
            <span>{{
              $t(
                "resources.users.statistics_list." +
                  stats.stats.content[2].name.replace(" ", "")
              )
            }}</span>
            <v-spacer />
            <span
              >{{ stats.stats.content[2].data[year_index] }} /
              {{ stats.stats.content[2].totalCount[year_index] }}</span
            >
          </v-list-item>
          <v-list-item>
            <span>{{
              $t(
                "resources.users.statistics_list." +
                  stats.stats.content[3].name.replace(" ", "")
              )
            }}</span>
            <v-spacer />
            <span
              >{{ stats.stats.content[3].data[year_index] }} /
              {{ stats.stats.content[3].totalCount[year_index] }}</span
            >
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StatisticsListUser",
  props: ["stats"],
  data() {
    return {
      years: null,
      year: null,
      year_index: 0,
    };
  },
  computed: {
    duelWinsPercent() {
      if (this.stats.overallStats.duelsTotal) {
        return Math.round(
          (100 / this.stats.overallStats.duelsTotal) *
            this.stats.overallStats.duelWins,
          0
        );
      }
      return 0;
    },
    questionCorrectPercent() {
      if (this.stats.overallStats.questionsTotal) {
        return Math.round(
          (100 / this.stats.overallStats.questionsTotal) *
            this.stats.overallStats.questionCorrect,
          0
        );
      }
      return 0;
    },
    questionsAcceptedPercent() {
      if (this.stats.overallStats.questionsSubmitted) {
        return Math.round(
          (100 / this.stats.overallStats.questionsSubmitted) *
            this.stats.overallStats.questionsAccepted,
          0
        );
      }
      return 0;
    },
  },
  mounted() {
    this.years = this.stats.stats.timeFrame;
    this.year_index = this.years.length - 1;
    this.year = this.years[this.year_index];
  },
  methods: {},
};
</script>

<style lang="scss"></style>
