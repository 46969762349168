export class Kpiuser {
  constructor(user, categories_ori, user_categories_ori) {
    this.id = user.id;
    this.user_name = user.user_name;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.email = user.email;
    this.type = user.user_type;
    this.info = user.info;

    this.categories = this.setCategories(categories_ori, user_categories_ori);
  }

  setCategories(cat, user_cat) {
    var categories = [];
    var user_categories = user_cat[this.id] ? { ...user_cat[this.id] } : null;

    cat.forEach((c) => {
      var category = { ...c };
      category.values =
        !user_categories || !user_categories[c.id] ? {} : user_categories[c.id];
      categories.push(category);
    });
    return categories;
  }
}
