import api from "@/services/api";
import { Supportrequest } from "@/models/Supportrequest";

export const fetch_support_requests = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/forms/support", config);
    if (res._status === 200) {
      let requests = res.forms.map((request) => {
        return new Supportrequest(request);
      });
      commit("set_support_requests", requests);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_support_request = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/forms/support/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_support_requests");
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};
