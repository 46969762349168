import i18n from "@/i18n.js";

export const state = {
  questions: null,
  question: null,
  question_reports: null,
  question_translations: null,
  answer_translations: null,
  selected_language: i18n.locale,
  reviewmode: false,
  reportmode: false,
  explanation_translations: null,
};
