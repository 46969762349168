import api from "@/services/api";
import { Invitation } from "@/models/Invitation";

export const fetch_invitations = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/invitations/", config);
    if (res._status === 200) {
      let invitations = res.invitations.map((invitation) => {
        return new Invitation(invitation);
      });
      commit("set_invitations", invitations);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_invitation = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get("admin/invitations/" + id, config);
    if (res._status === 200) {
      commit("set_invitation", new Invitation(res.invitation));
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const add_invitations = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/invitations/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_invitation = async (_, { payload, config = {} }) => {
  try {
    let res = await api.put(
      `admin/invitations/${payload.invitation_id}`,
      payload,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_invitation = async (_, { payload, config = {} }) => {
  try {
    let res = await api.delete(
      `admin/invitations/${payload.invitation_id}`,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const resend_invitation = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post(
      `admin/invitations/${payload.invitation_id}/resend`,
      null,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const upload_list = async (_, { payload, config }) => {
  try {
    let res = await api.post("admin/invitations/upload", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const validate_mails = async (_, { payload, config }) => {
  try {
    let res = await api.post("admin/invitations/validate", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
