export const set_session = (state, session) => (state.session = session);
export const set_sessions = (state, sessions) => (state.sessions = sessions);
export const set_bulk_session = (state, session) =>
  (state.bulk_session = session);
export const set_bulk_sessions = (state, sessions) =>
  (state.bulk_sessions = sessions);
export const set_licences = (state, licences) => (state.licences = licences);
export const set_transcriptions = (state, transcriptions) =>
  (state.transcriptions = transcriptions);
export const set_transcription = (state, transcription) =>
  (state.transcription = transcription);
export const set_credit_pool = (state, obj) => (state.credit_pool = obj);

export const set_sources = (state, sources) => (state.sources = sources);
export const set_sources_generating = (state, sources) =>
  (state.sources_generating = sources);
export const set_source = (state, source) => (state.source = source);
export const set_job = (state, source_job) => (state.source_job = source_job);
export const set_source_filter = (state, source_filter) =>
  (state.source_filter = source_filter);

export const set_gw_journeys = (state, journeys) =>
  (state.gw_journeys = journeys);
export const set_gw_journey = (state, journey) => (state.gw_journey = journey);
