import i18n from "@/i18n.js";

export class GwNugget {
  constructor(obj) {
    this.id = obj.id;
    this.part_id = obj.part_id;
    this.nugget_id = obj.nugget_id;
    this.type = "nugget";

    this.content = obj.content;
    this.content.category_id =
      obj.content && obj.content.category_id ? obj.content.category_id : null;
    this.content.activate =
      obj.content && obj.content.activate ? obj.content.activate : null;

    this.is_deleted = obj.is_deleted;
    this.is_accepted = obj.is_accepted;
    this.is_imported = this.nugget_id ? true : false;

    this.status = obj.status ? obj.status : 0;

    this.part_info = obj.part_info ? obj.part_info : null;
    // 0 editing
    // 1 ready
    // 2 importing
    // 3 imported
    // 4 import error

    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);
  }

  get formRules() {
    return {
      title: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          (!!v && v.length <= 255) ||
          i18n.t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
      ],
      description: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        (v) =>
          (!!v && v.length <= 255) ||
          i18n.t("ng.too_many_chars_hint", { count: v && v.length, max: 255 }),
      ],
      content: [
        (v) => (!!v && v.length > 0) || i18n.t("ng.add_chars_hint"),
        //(v) => !!v && v.length <= 64 || i18n.t('ng.too_many_chars_hint', { count: v.length, max: 64 })
      ],
      category: [
        this.category_id !== -1 ||
          i18n.t("resources.questions.select_category"),
      ],
    };
  }
}
