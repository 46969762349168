import i18n from "@/i18n.js";

export const state = {
  journeys: null,
  journey: null,
  user_journey: null,
  journeyBundles: null,
  journeyStats: null,
  journeyNuggets: null,
  journeyQuestions: null,
  bundle: null,
  bundleNuggets: [],
  bundleQuizzes: [],
  certificates: [],
  certificate: null,
  journey_translations: null,
  journey_bundle_translations: null,
  selected_language: i18n.locale,
  certificate_translations: null,
};
