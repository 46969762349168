import api from "@/services/api";
import { Kpicurrent } from "@/models/kpi/Kpicurrent.js";
import { Kpitimed } from "@/models/kpi/Kpitimed.js";
import { Kpiuser } from "@/models/kpi/Kpiuser.js";

export const fetch_kpi_statistics = async (
  { commit, getters },
  { payload, type, config = {} }
) => {
  try {
    commit("set_kpi_loading", true);
    var mappers = { ...getters.get_kpi_mappers };
    var api_version = getters.get_kpi_version;
    let res = await api.post(
      `admin/kpis/${api_version}/${type}`,
      //`admin/kpis/${type}`,
      payload,
      config
    );
    if (res._status === 200) {
      var current_items = [...res.current.items];
      var timed_items = [...res.timed.items];
      current_items = current_items.map((item) => {
        return new Kpicurrent(item, mappers);
      });
      timed_items = timed_items.map((item) => {
        return new Kpitimed(item, mappers);
      });

      commit("set_kpi_current", current_items);
      commit("set_kpi_current_stats", res.current.stats);
      commit("set_kpi_timed", timed_items);
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};

export const fetch_kpi_statistics_detail = async (
  { commit, getters },
  { payload, type, config = {} }
) => {
  try {
    commit("set_kpi_loading", true);

    var api_version = getters.get_kpi_version;

    let res = await api.post(
      `admin/kpis/${api_version}/${type}/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      commit("set_kpi_current_detail", res.current.items);
      commit("set_kpi_current_detail_stats", res.current.stats);
      commit("set_kpi_timed_detail", res.timed);
      commit("set_kpi_resource_detail", res.resource);
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};
// remove following fn ?
export const fetch_kpi_statistics_detail_compare = async (
  _,
  { payload, type, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/kpis/${type}/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const fetch_kpi_journey = async (
  { commit, getters },
  { payload, config = {} }
) => {
  try {
    commit("set_kpi_loading", true);

    var api_version = getters.get_kpi_version;

    let res = await api.post(
      `admin/kpis/${api_version}/journeys/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      commit("set_kpi_journey", {
        journey: res.journey,
        stats: res.stats,
        substructures: res.substructures,
        users: res.users,
      });
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};

export const fetch_kpi_journeys = async (
  { commit, getters },
  { payload = {}, config = {} }
) => {
  try {
    commit("set_kpi_loading", true);

    var api_version = getters.get_kpi_version;

    let res = await api.post(
      `admin/kpis/${api_version}/journeys`,
      payload,
      config
    );
    if (res._status === 200) {
      commit("set_kpi_journeys", res.journeys);
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};

export const fetch_kpi_categories = async (
  { commit, getters },
  config = {}
) => {
  try {
    commit("set_kpi_loading", true);

    var api_version = getters.get_kpi_version;

    let res = await api.get(
      `admin/kpis/${api_version}/users/categories/`,
      config
    );
    if (res._status === 200) {
      var users = res.users.map(
        (u) => new Kpiuser(u, res.categories, res.user_categories)
      );
      commit("set_kpi_categories", res.categories);
      commit("set_kpi_users", users);
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};

export const fetch_kpi_general = async ({ commit, getters }, config = {}) => {
  try {
    commit("set_kpi_loading", true);

    var api_version = getters.get_kpi_version;

    let res = await api.get(`admin/kpis/${api_version}/`, config);
    if (res._status === 200) {
      commit("set_kpi_general", {
        current: res.current,
        stats: res.stats,
      });
      commit("set_kpi_loading", false);
      return res;
    } else {
      commit("set_kpi_loading", false);
      return null;
    }
  } catch (err) {
    commit("set_kpi_loading", false);
    console.error(err);
    return null;
  }
};

export const fetch_kpi_mappers = async ({ commit, getters }, config = {}) => {
  try {
    var api_version = getters.get_kpi_version;
    let res = await api.get(`admin/kpis/${api_version}/mappers`, config);
    if (res._status === 200) {
      commit("set_kpi_mappers", {
        categories: res.categories,
        structures: res.structures,
        substructures: res.substructures,
        users: res.users,
      });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};
