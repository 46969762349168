import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const loadStores = () => {
  const context = require.context("./modules", true, /module.js$/i);
  const stores = {};
  context
    .keys()
    .map(context)
    .map((m) => m.default)
    .forEach((store) => {
      store.namespaced = true;
      stores[store.name] = store;
    });
  return stores;
};

export default new Vuex.Store({
  modules: loadStores(),
});
