export const set_nuggets = (state, value) => {
  state.nuggets = value;
};

export const set_nugget = (state, value) => {
  state.nugget = value;
};

export const set_nugget_translations = (state, translations) =>
  (state.nugget_translations = translations);
