<template>
  <div>
    <v-list-item
      :class="[`${color} elevation-${elevation}`]"
      dense
      @click="$emit('click')"
    >
      <v-list-item-icon class="mt-3">
        <v-badge
          left
          color="transparent"
          class="mb-2"
          offset-x="3"
          offset-y="7"
        >
          <template #badge>
            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="differsFromDefault"
                  v-bind="attrs"
                  color="primary"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span>{{ $t("gw.differsFromDefault") }}</span>
            </v-tooltip>
          </template>
          <v-icon color="secondary">
            {{ item.type === "nugget" ? "mdi-school" : "mdi-comment-question" }}
          </v-icon>
        </v-badge>
      </v-list-item-icon>
      <v-list-item-content class="secondary--text">
        <v-list-item-title class="overline">
          {{ item.type === "nugget" ? item.content.title : item.content.body }}
        </v-list-item-title>
        <v-list-item-subtitle>{{
          item.type === "nugget"
            ? item.content.body
            : $t("resources.questions.types.6")
        }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div class="d-flex mr-2 ml-4">
          <v-icon small :color="item.is_accepted ? 'success' : 'error'">
            {{ item.is_accepted ? "mdi-check-circle" : "mdi-radiobox-blank" }}
          </v-icon>
        </div>
      </v-list-item-action>
      <v-overlay
        v-if="item.is_imported"
        absolute
        opacity="0"
        class="justify-start align-start"
      >
        <v-sheet class="px-2 py-0" color="secondary">
          <span
            class="caption text-uppercase font-weight-bold grey--text text--lighten-2"
            >{{ $t("gw.imported") }}</span
          >
          <!-- <v-icon
            small
            color="white"
          >
            mdi-check
          </v-icon> -->
        </v-sheet>
      </v-overlay>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GwContentListItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
    elevation: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    differsFromDefault() {
      let differs = false;
      /* if(this.item.type === 'question') {
        if(!this.item.content.defaultTime && this.session.defaults.time !== this.item.content.time) differs = true;
      }
      if(this.item.content.category_id && this.session.defaults.category_id !== this.item.content.category_id) differs = true; */
      if (
        this.item.content.time ||
        this.item.content.category_id ||
        !!this.item.content.activate
      )
        differs = true;
      return differs;
    },
  },
};
</script>

<style lang="scss">
.success-border {
  border: 1px solid red !important;
}
</style>
