<template>
  <div>
    <v-list-item
      class="my-0 py-0"
      :class="[`${color} elevation-${elevation}`, { 'disabled-btn': disabled }]"
      :disabled="disabled"
      :to="link ? link : null"
      :style="!link && !clickType ? 'cursor: default !important' : ''"
      @click="click"
    >
      <v-list-item-avatar tile class="my-0">
        <country-flag
          v-if="languageIcon"
          :country="languageIcon.slice(0, 2)"
          :class="$attrs['hide-label'] != '' ? 'mr-1' : ''"
        />
        <v-icon v-if="!languageIcon && !numberIcon" :color="iconColor">
          {{ icon }}
        </v-icon>
        <span v-if="numberIcon" :class="`overline ${textColor}--text`">{{
          numberIcon
        }}</span>
      </v-list-item-avatar>
      <v-list-item-content class="my-0 py-0">
        <v-list-item-title
          :class="`overline font-weight-bold ${textColor}--text ${textOptions}`"
        >
          {{ title }}
        </v-list-item-title>
        <!-- <v-list-item-subtitle :class="`caption ${textColor}--text ${textOptions}`">
          {{ subtitle }}
        </v-list-item-subtitle> -->
      </v-list-item-content>
      <v-list-item-action v-if="!disabled">
        <v-chip x-small label :color="chipColor">
          <v-icon x-small class="mr-2" color="#ffd700">
            mdi-circle-multiple
          </v-icon>
          <span>{{ subtitle }}</span>
        </v-chip>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "ListButton",
  components: {
    CountryFlag,
  },
  props: {
    title: {
      type: [Number, String],
      required: true,
    },
    subtitle: {
      type: [Number, String],
      required: true,
    },
    textColor: {
      type: String,
      required: false,
      default: "white",
    },
    textOptions: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-help",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    iconColor: {
      type: String,
      required: false,
      default: "white",
    },
    link: {
      type: Object,
      required: false,
      default: null,
    },
    elevation: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickType: {
      type: String, // approvals || reports || dialog
      required: false,
      default: null,
    },
    languageIcon: {
      type: String,
      required: false,
    },
    numberIcon: {
      type: [String, Number],
      required: false,
    },
    chipColor: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  methods: {
    click() {
      this.clickType ? this.$emit("clicked", this.clickType) : null;
    },
  },
};
</script>

<style>
/* .disabled-btn::deep {
    border: 1px solid #000 !important
} */
</style>
