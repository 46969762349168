<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? null : closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text pb-0">
          Struktur löschen
        </v-card-title>
        <v-card-text>
          <div class="caption mb-4">
            Bist Du sicher, dass Du die Struktur
            <b>{{ structure.name }}</b> löschen möchtest? Dies kann nicht
            rückgängig gemacht werden!
          </div>
          <small
            >Gelöscht werden können lediglich Strukturen, die keinen
            Substrukturen zugeordnet sind.</small
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            :disabled="loading"
            color="secondary"
            @click="closeDialog()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="error"
            small
            :loading="loading"
            :disabled="loading || structure.substructures.length > 0"
            @click="deleteStructure()"
          >
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteStructureDialog",
  props: {
    structure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      rules: {
        name: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
    };
  },
  methods: {
    ...mapActions("structures", ["delete_structure"]),
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
    async deleteStructure() {
      this.loading = true;
      let payload = {
        id: this.structure.id,
      };
      let res = await this.delete_structure({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Struktur erfolgreich angelegt.",
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Anlegen der Struktur.",
        });
      }
    },
  },
};
</script>
