<template>
  <v-app v-if="loaded">
    <AppSideBar
      v-if="!!auth"
      :menu="menu"
      :auth="auth"
      @menu-status="setMenuStatus"
      @go-to="goTo"
      @logout="logout"
    />
    <AppTopBar
      v-if="!!auth"
      @menu-toggle="setMenuStatus(!menu)"
      @go-to="goTo"
    />

    <v-main v-if="!loading" class="grey lighten-4">
      <router-view />
    </v-main>

    <LoaderPopup />
    <notifications position="bottom left" />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import AppSideBar from "@/components/app/AppSideBar.vue";
import AppTopBar from "@/components/app/AppTopBar.vue";
import LoaderPopup from "@/components/app/LoaderPopup.vue";

export default {
  name: "App",
  components: {
    AppSideBar,
    AppTopBar,
    LoaderPopup,
  },
  data: () => ({
    menu: false,
    loading: true,
  }),
  computed: {
    loaded() {
      return !!this.community;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.setTitle();
      },
    },
    /* "$i18n.locale": {
      handler: function () {
        this.checkAppSettings();
      },
    }, */
  },
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
    this.setTitle();
  },
  async beforeMount() {
    if (!this.auth) {
      if (localStorage.getItem("auth_id")) {
        await this.get_auth(localStorage.getItem("auth_id"));
      } else {
        this.logout();
      }
    }
    await this.checkAppSettings();
  },
  methods: {
    ...mapActions("auth", ["logout_user", "init_auth"]),
    ...mapActions("community", ["init_community"]),
    async logout() {
      this.menu = false;
      let success = await this.logout_user();
      if (success) {
        if (this.$route.name != "Login") {
          this.$router.push({ name: "Login" });
        }
      } else {
        this.$notify({
          type: "error",
          text: this.$t("general.errors.logout_failed"),
        });
      }
    },
    async get_auth(id) {
      let res = await this.init_auth({ id: id });
      if (res == null) {
        await this.logout();
      }
      return res;
    },
    setMenuStatus(value) {
      this.menu = value;
    },
    goTo(name) {
      if (this.$route.name != name) {
        this.$router.push({ name: name });
      }
    },
    setTitle() {
      let route_name = this.$route.name;
      let client_name = process.env.VUE_APP_CLIENT_NAME;
      document.title = `${this.$t("ng.app_title", {
        client: client_name,
      })} - ${route_name}`;
    },
    async checkAppSettings() {
      this.loading = true;
      var locale = localStorage.getItem("locale")
        ? localStorage.getItem("locale")
        : this.$i18n.locale;
      var res = await this.init_community({
        lang: locale,
        domain: document.location.host,
      });
      if (res && res._status === 200) {
        this.$i18n.locale = locale;
        localStorage.setItem("locale", locale);
        document.documentElement.setAttribute("lang", this.$i18n.locale);
      } else {
        console.error("saved language not found. will use default");
        var fallback_locale = this.$i18n.locale;
        var res_check = await this.init_community({
          lang: fallback_locale,
          domain: document.location.host,
        });
        if (res_check && res_check._status === 200) {
          this.$i18n.locale = locale;
          localStorage.setItem("locale", fallback_locale);
          document.documentElement.setAttribute("lang", this.$i18n.locale);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.v-navigation-drawer--fixed {
  z-index: 12 !important;
}
.v-data-table__mobile-row {
  height: initial !important;
}
.v-tabs-items,
.v-tabs-bar {
  background-color: transparent !important;
}
.v-main__wrap {
  > .container {
    min-height: 100%;
    padding: 0;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  overflow: auto;
}

thead.v-data-table-header {
  vertical-align: top;
  tr {
    th {
      padding-top: 0.5em !important;
    }
  }
}

.pulse {
  animation: pulse 0.8s infinite ease-in-out alternate;
}
@keyframes pulse {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.2);
  }
}
@import "~vuetify/src/styles/settings/variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .container {
    max-width: 1280px !important;
  }
}
</style>
