<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="showDialog" max-width="640" persistent>
      <v-form v-model="valid">
        <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
          <v-card-title class="overline secondary--text">
            <span>add media source</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-file-input
                  v-model="file"
                  prepend-inner-icon="mdi-multimedia"
                  prepend-icon=""
                  :append-icon="!file ? 'mdi-upload' : ''"
                  background-color="white"
                  dense
                  show-size
                  outlined
                  accept="audio/mp3,video/mp4,audio/mpeg,audio/x-m4a,audio/wav,video/webm,video/mpg"
                  :hint="$t('gw.transcription.accepted_file_types')"
                  persistent-hint
                  :disabled="loading"
                  :rules="rules.file"
                  :label="$t('resources.invitations.fileInput')"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="$t('general.language')"
                  :value="lang"
                  background-color="white"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-btn
              text
              color="success"
              small
              :loading="loading"
              :disabled="!file || loading || !valid"
              @click="addSource()"
            >
              {{ $t("general.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddMediaSourceDialog",
  data() {
    return {
      showDialog: false,
      valid: false,
      file: null,
      lang: "de-DE",
      loading: false,
      rules: {
        file: [
          (value) =>
            !value ||
            value.size < 500000000 ||
            this.$t("resources.media.validation", { size: "500" }),
        ],
      },
    };
  },
  methods: {
    /* remove start */
    ...mapActions("tmp", ["add_source"]),
    /* remove end */
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    async addSource() {
      this.loading = true;
      console.warn("not implemented");
      /* var payload = {
        txt: this.txt,
      };
      var res = await this.add_source({ payload: payload, type: 'video?' });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.$router.push({
          name: "SourceDetail",
          params: { id: res.source.id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      } */
    },
    reset() {
      this.file = null;
    },
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>
