<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog v-model="dialog" max-width="640" persistent scrollable>
      <v-card :loading="loading" color="grey lighten-3">
        <v-card-title class="overline">
          <span>{{ $t("resources.journeys.quizCreator.createQuiz") }}</span>
        </v-card-title>
        <v-card-text>
          <div>
            <h5 class="caption">
              {{ $t("resources.journeys.quizCreator.selectedQuestions") }}
            </h5>
            <v-list v-if="selected.length > 0" dense class="transparent">
              <v-list-item
                v-for="(question, i) in selected"
                :key="i"
                class="white my-1"
                style="border: 1px solid rgba(0, 0, 0, 0.12) !important"
              >
                <v-list-item-action class="flex-row">
                  <v-btn :disabled="i === 0" icon small @click="moveUp(i)">
                    <v-icon color="secondary" small> mdi-chevron-up </v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="i === selected.length - 1"
                    icon
                    small
                    @click="moveDown(i)"
                  >
                    <v-icon color="secondary" small> mdi-chevron-down </v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ question.body }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-for="(cat, i) in question.categories" :key="i">{{
                      cat.name
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon small @click="removeQuestion(question)">
                    <v-icon color="error" small> mdi-minus-circle </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <p v-else class="font-weight-bold">
              {{ $t("resources.journeys.quizCreator.noQuestionsYet") }}
            </p>
          </div>

          <div class="pt-4">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  v-model="search"
                  clearable
                  outlined
                  background-color="white"
                  :label="$t('general.search')"
                  hide-details
                  dense
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="category"
                  :items="visibleCategories"
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                  outlined
                  background-color="white"
                  :label="$t('resources.categories.singular')"
                  chips
                  small-chips
                  deletable-chips
                >
                  <template #item="{ item }">
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="d-flex align-center">
                <v-btn
                  color="primary"
                  small
                  depressed
                  width="100%"
                  :disabled="loading"
                  @click="searchQuestions()"
                >
                  {{ $t("general.search") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card v-if="questions" outlined>
                  <v-data-table
                    :headers="tableHeaders"
                    :items="visibleQuestions"
                    :items-per-page="5"
                    dense
                  >
                    <template #item="question">
                      <tr>
                        <td>
                          <v-tooltip
                            v-if="!!isUsedInJourney(question.item)"
                            right
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="secondary"
                                v-bind="attrs"
                                small
                                v-on="on"
                              >
                                mdi-alert
                              </v-icon>
                            </template>
                            <span>{{ $t("ng.question_used_in_journey") }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-btn
                            v-if="!!questionIsSelected(question.item)"
                            icon
                            small
                            color="error"
                            @click="removeQuestion(question.item)"
                          >
                            <v-icon small> mdi-minus-circle </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="!questionIsSelected(question.item)"
                            icon
                            small
                            color="success"
                            @click="addQuestion(question.item)"
                          >
                            <v-icon small> mdi-plus-circle </v-icon>
                          </v-btn>
                        </td>
                        <td>
                          <span class="caption">{{ question.item.body }}</span>
                        </td>
                        <td>
                          <span
                            v-for="(q, i) in question.item.categories"
                            :key="i"
                            class="caption"
                            >{{ q.name }}</span
                          >
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

            <p
              v-if="!questions || questions.length == 0"
              class="caption text-center"
            >
              <span v-if="!questions">{{
                $t("resources.journeys.noSearch")
              }}</span>
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" small text class="mr-2" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            small
            text
            :disabled="selected.length == 0 || (quiz && !selectionChanged)"
            @click="addQuiz()"
          >
            {{ quiz ? $t("general.save") : $t("general.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "QuizCreator",
  props: {
    quiz: {
      type: Object,
      required: false,
      default: () => {},
    },
    bundle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      category: 0,
      selected: [],
      tableHeaders: [
        { text: "", width: "10px" },
        { text: "", width: "10px" },
        { text: this.$t("resources.questions.singular"), value: "question" },
        {
          text: this.$t("resources.categories.plural"),
          value: "category.name",
        },
      ],
      loading: false,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      questions: "get_journey_questions",
      journey: "get_journey",
      bundles: "get_journey_bundles",
      language: "get_selected_language",
    }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    visibleQuestions() {
      let questions = [...this.questions];
      return questions;
    },
    selectionChanged() {
      if (this.quiz) {
        let changed = false;
        let oriIds = [...this.quiz.quiz.question_ids];
        let selectedIds = [];
        this.selected.forEach((selected) => selectedIds.push(selected.id));

        if (oriIds.length !== selectedIds.length) {
          changed = true;
        } else {
          selectedIds.forEach((id, i) => {
            if (id !== oriIds[i]) changed = true;
          });
        }
        return changed;
      } else return false;
    },
    visibleCategories() {
      if (!this.categories) return [];
      return this.categories
        .filter((category) => {
          return category.stats.questions.count > 0;
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    if (this.quiz) {
      this.fetchQuiz();
    }
  },
  destroyed() {
    this.selected = [];
  },
  methods: {
    ...mapActions("journeys", [
      "fetch_journey_questions",
      "fetch_journey_quiz",
    ]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("categories", ["set_categories"]),
    ...mapMutations("journeys", ["set_journey_questions"]),
    openDialog() {
      this.dialog = true;
    },
    async fetchData() {
      await this.fetch_categories();
    },
    async fetchQuiz() {
      this.loading = true;
      let res = await this.fetch_journey_quiz({
        payload: { id: this.quiz.id },
      });

      if (res) {
        this.selected = res.questions;
      }
      this.loading = false;
    },
    async searchQuestions() {
      this.loading = true;
      let payload = {
        search: this.search,
        category_id: this.category,
        language: this.language,
      };
      await this.fetch_journey_questions({
        payload: payload,
      });
      this.loading = false;
    },
    closeDialog(setData) {
      this.set_categories(null);
      this.set_journey_questions(null);
      this.$emit("close", [
        setData ? this.selected : null,
        this.bundle,
        this.quiz,
      ]);
      this.dialog = false;
    },
    addQuestion(question) {
      if (this.selected.indexOf(question) === -1) this.selected.push(question);
    },
    removeQuestion(question) {
      let selected = this.selected;
      this.selected = selected.filter(function (selectedQuestion) {
        return selectedQuestion.id !== question.id;
      });
    },
    questionIsSelected(question) {
      return (
        this.selected.filter((selectedQuestion) => {
          return selectedQuestion.id === question.id;
        }).length > 0
      );
    },
    async addQuiz() {
      this.closeDialog(true);
    },
    isUsedInJourney(question) {
      var isUsed = false;
      var bundles = [...this.bundles];
      bundles.forEach((bundle) => {
        bundle.steps.forEach((step) => {
          if (
            step.type === "quiz" &&
            step.quiz &&
            step.quiz.question_ids.includes(question.id)
          )
            isUsed = true;
        });
      });
      return isUsed;
    },
    moveUp(index) {
      if (index === 0) {
        this.selected.push(this.selected.shift());
      } else {
        this.selected = this.selected.map((element, i) => {
          if (i === index - 1) {
            return this.selected[index];
          } else if (i === index) {
            return this.selected[index - 1];
          } else {
            return element;
          }
        });
      }
    },
    moveDown(index) {
      if (index === this.selected.length - 1) {
        this.selected.unshift(this.selected.pop());
      } else {
        this.selected = this.selected.map((element, i) => {
          if (i === index) {
            return this.selected[index + 1];
          } else if (i === index + 1) {
            return this.selected[index];
          } else {
            return element;
          }
        });
      }
    },
  },
};
</script>

<style></style>
