var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("action",null,{"openDialog":_vm.openDialog}),_c('v-dialog',{attrs:{"max-width":"640"},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.showDialog)?_c('v-card',{attrs:{"color":"grey lighten-3","max-width":"640"}},[_c('v-card-title',{staticClass:"overline secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("gw.licences.options"))+" ")]),_c('v-card-text',[(!_vm.user.licence)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(
                  _vm.$t('gw.licences.no_user_licence', {
                    user: `${_vm.user.first_name} ${_vm.user.last_name} (${_vm.user.user_name})`,
                  })
                )}}),_c('v-autocomplete',{attrs:{"label":"Lizenz wählen","items":_vm.visibleLicences,"item-text":"name","item-value":"id","dense":"","outlined":"","clearable":"","hide-details":"","background-color":"white"},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"two-line":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(`${_vm.$t("gw.xCredits", { number: item.credits_count, })}`))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.can_overload
                                ? 'secondary'
                                : 'grey lighten-3'}},on),[_vm._v(_vm._s(item.can_overload ? "mdi-bank" : "mdi-bank-off"))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.can_overload ? _vm.$t("gw.licences.credits_overload_access") : _vm.$t("gw.licences.credits_overload_no_access")))])])],1)],1)]}}],null,false,1327925633),model:{value:(_vm.selectedLicence),callback:function ($$v) {_vm.selectedLicence=$$v},expression:"selectedLicence"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":!_vm.selectedLicence || _vm.loading},on:{"click":function($event){return _vm.addUserLicence()}}},[_vm._v(_vm._s(_vm.$t("gw.licences.add_user_licence")))])],1)],1):_vm._e(),(_vm.user.licence)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('StatisticsCardSmall',{attrs:{"title":_vm.user.licence.licence.name,"subtitle":_vm.$t('resources.categories.name'),"icon":"mdi-file-document","color":"grey lighten-2","text-color":"secondary","icon-color":"secondary"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('StatisticsCardSmall',{attrs:{"title":new Date(_vm.user.licence.date_end).toLocaleDateString(),"subtitle":_vm.$t('gw.licences.date_end'),"icon":"mdi-calendar","color":"grey lighten-2","text-color":"secondary","icon-color":"secondary"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('StatisticsCardSmall',{attrs:{"title":_vm.user.licence.licence.credits_count,"subtitle":_vm.$t('gw.licences.credits'),"icon":"mdi-circle-multiple","color":"grey lighten-2","text-color":"secondary","icon-color":"secondary"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('StatisticsCardSmall',{attrs:{"title":_vm.user.licence.licence.can_overload
                    ? _vm.$t('general.yes')
                    : _vm.$t('general.no'),"subtitle":_vm.$t('gw.licences.can_overload'),"icon":_vm.user.licence.licence.can_overload
                    ? 'mdi-bank'
                    : 'mdi-bank-off',"color":"grey lighten-2","text-color":"secondary","icon-color":"secondary"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"secondary","to":_vm.role === 'superadmin'
                ? { name: 'GhostwriterLicenceAdministration' }
                : { name: 'GwManagement' }}},[_vm._v(" "+_vm._s(_vm.$t("gw.licences.licence_administration_goto"))+" ")])],1)],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }