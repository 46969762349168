<template>
  <div>
    <section>
      <SectionHeader :title="$t('kpi.current_chart_title')">
        <template #action>
          <v-tooltip left color="info" max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="info" v-bind="attrs" v-on="on"
                >mdi-help-circle</v-icon
              >
            </template>
            <span>{{ $t("kpi.current_chart_tooltip") }}</span>
          </v-tooltip>
        </template>
      </SectionHeader>
      <v-container>
        <v-row dense align="center">
          <v-col cols="12" sm="6" md="4">
            <KpiTypePicker :disabled="!stats" />
          </v-col>
          <v-col cols="12" sm="6" md="4" offset-md="4">
            <v-text-field
              v-model="search"
              :label="$t('general.search')"
              flat
              solo
              background-color="grey lighten-2"
              hide-details
              dense
              :disabled="!stats"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card outlined color="grey lighten-1" tile>
              <v-data-table
                :headers="tableHeaders"
                :items="items"
                hide-default-header
                :expanded.sync="expanded"
                item-key="info.name"
                show-expand
                :search="search"
                class="grey lighten-3"
                :loading="loading"
              >
                <template #item="{ item, expand, isExpanded }">
                  <tr @click="expand(!isExpanded)" class="cursor-pointer">
                    <td class="d-flex align-center">
                      <div class="d-flex align-center">
                        <v-btn
                          small
                          icon
                          class="mr-2"
                          @click.stop="expand(!isExpanded)"
                        >
                          <v-icon>{{
                            isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}</v-icon>
                        </v-btn>
                        <StatisticsDialogUser
                          v-if="item.resource === 'user'"
                          :id="item.id"
                          :stats="item.stats"
                        >
                          <template #activator="{ openDialog }">
                            <v-btn
                              x-small
                              fab
                              elevation="0"
                              color="primary"
                              @click.stop="openDialog()"
                              ><v-icon small>mdi-magnify</v-icon></v-btn
                            >
                          </template>
                        </StatisticsDialogUser>
                        <StatisticsDialogCategory
                          v-if="item.resource === 'category'"
                          :id="item.id"
                          :stats="item.stats"
                        >
                          <template #activator="{ openDialog }">
                            <v-btn
                              x-small
                              fab
                              elevation="0"
                              color="primary"
                              @click.stop="openDialog()"
                              ><v-icon small>mdi-magnify</v-icon></v-btn
                            >
                          </template>
                        </StatisticsDialogCategory>
                        <StatisticsDialogStructure
                          v-if="item.resource === 'structure'"
                          :id="item.id"
                          :stats="item.stats"
                        >
                          <template #activator="{ openDialog }">
                            <v-btn
                              x-small
                              fab
                              elevation="0"
                              color="primary"
                              @click.stop="openDialog()"
                              ><v-icon small>mdi-magnify</v-icon></v-btn
                            >
                          </template>
                        </StatisticsDialogStructure>
                        <StatisticsDialogSubstructure
                          v-if="item.resource === 'substructure'"
                          :id="item.id"
                          :stats="item.stats"
                        >
                          <template #activator="{ openDialog }">
                            <v-btn
                              x-small
                              fab
                              elevation="0"
                              color="primary"
                              @click.stop="openDialog()"
                              ><v-icon small>mdi-magnify</v-icon></v-btn
                            >
                          </template>
                        </StatisticsDialogSubstructure>
                      </div>
                      <div class="d-flex flex-column align-start ml-2">
                        <span>{{ item.info.name }}</span>
                        <small
                          v-if="item.resource === 'substructure'"
                          class="secondary--text"
                          >{{
                            `${$t("resources.structures.singular")}: ${
                              item.info.structure.name
                            }`
                          }}</small
                        >
                        <small
                          v-if="item.resource === 'user'"
                          class="secondary--text"
                          >{{
                            `${item.info.first_name} ${item.info.last_name} (${item.info.email})`
                          }}</small
                        >
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-end">
                        <StatisticsTableChip
                          :value="item.stats.currentSpeed[0]"
                          :delta="
                            item.stats.currentSpeed[0] -
                            item.stats.currentSpeed[1]
                          "
                          :description="$t('kpi.speed')"
                          icon="mdi-play-speed"
                        />
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="px-0">
                    <v-simple-table
                      tile
                      class="sub-table white"
                      max-height="400"
                    >
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="child in item.children"
                            :key="`child-${child.id}`"
                          >
                            <td class="d-flex align-center">
                              <StatisticsDialogUser
                                v-if="child.resource === 'user'"
                                :id="child.id"
                                :stats="item.stats"
                              >
                                <template #activator="{ openDialog }">
                                  <v-btn
                                    x-small
                                    fab
                                    elevation="0"
                                    color="primary"
                                    class="ml-9 mr-2"
                                    @click="openDialog()"
                                    ><v-icon small>mdi-magnify</v-icon></v-btn
                                  >
                                </template>
                              </StatisticsDialogUser>
                              <StatisticsDialogCategory
                                v-if="child.resource === 'category'"
                                :id="child.id"
                                :stats="item.stats"
                              >
                                <template #activator="{ openDialog }">
                                  <v-btn
                                    x-small
                                    fab
                                    elevation="0"
                                    color="primary"
                                    class="ml-9 mr-2"
                                    @click="openDialog()"
                                    ><v-icon small>mdi-magnify</v-icon></v-btn
                                  >
                                </template>
                              </StatisticsDialogCategory>
                              <StatisticsDialogStructure
                                v-if="child.resource === 'structure'"
                                :id="child.id"
                                :stats="item.stats"
                              >
                                <template #activator="{ openDialog }">
                                  <v-btn
                                    x-small
                                    fab
                                    elevation="0"
                                    color="primary"
                                    class="ml-9 mr-2"
                                    @click="openDialog()"
                                    ><v-icon small>mdi-magnify</v-icon></v-btn
                                  >
                                </template>
                              </StatisticsDialogStructure>
                              <StatisticsDialogSubstructure
                                v-if="child.resource === 'substructure'"
                                :id="child.id"
                                :stats="item.stats"
                              >
                                <template #activator="{ openDialog }">
                                  <v-btn
                                    x-small
                                    fab
                                    elevation="0"
                                    color="primary"
                                    class="ml-9 mr-2"
                                    @click="openDialog()"
                                    ><v-icon small>mdi-magnify</v-icon></v-btn
                                  >
                                </template>
                              </StatisticsDialogSubstructure>

                              <div class="d-flex flex-column align-start ml-2">
                                <span>{{ child.info.name }}</span>
                                <small
                                  v-if="child.resource === 'substructure'"
                                  class="secondary--text"
                                  >{{
                                    `${$t("resources.structures.singular")}: ${
                                      child.info.structure.name
                                    }`
                                  }}</small
                                >
                                <small
                                  v-if="child.resource === 'user'"
                                  class="secondary--text"
                                  >{{
                                    `${child.info.first_name} ${child.info.last_name} (${child.info.email})`
                                  }}</small
                                >
                              </div>
                              <v-spacer />
                              <StatisticsTableChip
                                :value="child.stats.currentSpeed[0]"
                                :delta="
                                  child.stats.currentSpeed[0] -
                                  child.stats.currentSpeed[1]
                                "
                                :description="$t('kpi.speed')"
                                icon="mdi-play-speed"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="role === 'superadmin' && !!stats" class="my-8" />

    <section v-if="role === 'superadmin' && !!stats">
      <v-container>
        <v-tooltip top color="info">
          <template #activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="text-center">
              <v-btn
                small
                depressed
                color="info"
                @click="showTmp = !showTmp"
                class="mb-2"
                >Toggle for superadmin</v-btn
              >
            </div>
          </template>
          <span>Nach öffnen bitte runter scrollen :-)</span>
        </v-tooltip>
        <v-expand-transition>
          <v-row v-show="showTmp" dense>
            <v-col cols="6" md="4">
              <KpiStatsProgressCard
                :title="$t('kpi.speed')"
                :to="{ name: 'KpiActivitySpeed' }"
                color="white"
                highlight-color="info"
                icon="mdi-play-speed"
                :value="stats ? stats.currentSpeed[0] : 0"
                :delta="
                  stats ? stats.currentSpeed[0] - stats.currentSpeed[1] : 0
                "
                :loading="loading"
                rounded-xl
              />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import KpiStatsProgressCard from "@/components/kpi/KpiStatsProgressCard";
import StatisticsDialogUser from "@/components/kpi/dialogs/StatisticsDialogUser";
import StatisticsDialogCategory from "@/components/kpi/dialogs/StatisticsDialogCategory";
import StatisticsDialogStructure from "@/components/kpi/dialogs/StatisticsDialogStructure";
import StatisticsDialogSubstructure from "@/components/kpi/dialogs/StatisticsDialogSubstructure";
import StatisticsTableChip from "@/components/kpi/StatisticsTableChip";
import KpiTypePicker from "@/components/kpi/KpiTypePicker";

export default {
  name: "KpiActivityOverview",
  components: {
    SectionHeader,
    KpiStatsProgressCard,
    StatisticsDialogUser,
    StatisticsDialogCategory,
    StatisticsDialogStructure,
    StatisticsDialogSubstructure,
    StatisticsTableChip,
    KpiTypePicker,
  },
  data() {
    return {
      expanded: [],
      search: "",
      tableHeaders: [
        { text: "", value: "data-table-expand", width: "5%" },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          width: "auto",
        },
        {
          text: this.$t("kpi.competency"),
          value: "stats.currentSpeed",
          width: "10%",
        },
        {
          text: this.$t("kpi.retention"),
          value: "stats.overallSpeed",
          width: "10%",
        },
      ],
      showTmp: false, // temporary val to toggle additional cards for superadmin users
    };
  },
  computed: {
    ...mapGetters("kpi", {
      items: "get_kpi_current",
      stats: "get_kpi_current_stats",
      type: "get_kpi_type",
      loading: "get_kpi_loading",
    }),
  },
  beforeMount() {
    if (!this.type) this.set_kpi_type(this.typeOptions[0]);
  },
  methods: {
    ...mapMutations("kpi", ["set_kpi_type"]),
  },
};
</script>

<style lang="scss">
.sub-table tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.card-progress .v-progress-circular__underlay {
  stroke: rgba(255, 255, 255, 0.25);
  z-index: 1;
}
</style>
