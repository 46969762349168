<template>
  <div
    class="v-html-field"
    :class="disabled ? 'grey lighten-3' : 'active white'"
  >
    <span class="text-caption">{{ label }}</span>
    <vue-trumbowyg
      :disabled="disabled"
      :value="value"
      :config="config"
      @tbw-change="$emit('change'), $emit('input', $event.target.value)"
    />
    <v-textarea v-if="!htmlRules" :value="value" :rules="htmlRules" />
  </div>
</template>

<script>
import VueTrumbowyg from "vue-trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.css";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.js";
import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";

export default {
  name: "HtmlField",
  components: {
    VueTrumbowyg,
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      config: {
        defaultLinkTarget: "_blank",
        minimalLinks: true,
        btns: [
          ["viewHTML"],
          ["undo", "redo"], // Only supported in Blink browsers
          ["formatting"],
          ["strong", "em", "del"],
          ["foreColor", "backColor"],
          ["superscript", "subscript"],
          ["link"],
          ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
          ["unorderedList", "orderedList"],
          ["removeformat"],
          ["fullscreen"],
        ],
      },
      htmlRules: [
        (v) =>
          v == "" ||
          this.validateHtml(v) ||
          this.$t("general.errors.invalid_html"),
      ],
    };
  },
  methods: {
    validateHtml(html) {
      if (html == null) html = "";
      if (html.includes("<script")) {
        return false;
      }
      html.replace("<p><ul>", "<ul>");
      html.replace("</p></ul>", "</ul>");
      html.replace("<p><ol>", "<ol>");
      html.replace("</p></ol>", "</ol>");
      var doc = document.createElement("div");
      doc.innerHTML = html;
      return doc.innerHTML === html;
    },
  },
};
</script>

<style lang="scss">
.v-html-field {
  .trumbowyg-box,
  .trumbowyg-editor {
    //margin: 0 auto;
    z-index: 0;
  }
  .v-input__slot {
    display: none;
  }
  .trumbowyg-fullscreen {
    z-index: 99;
  }
}
.v-html-field.active {
  .trumbowyg-editor {
    background: white;
  }
}
</style>
