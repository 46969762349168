//import {store} from '@/store/index.js';
import i18n from "@/i18n.js";

const BODY_FORMATS = ["text", "html", "math"];

export class Question {
  constructor(obj) {
    this.init(obj);
  }

  init(obj) {
    this.id = obj ? obj.id : null;

    this.body = obj ? obj.body : "";
    this.body_format = obj ? obj.body_format : "text";
    this.hint = obj && obj.hint ? obj.hint : "";

    this.answers = obj ? obj.answers : []; //

    this.time = obj ? obj.time : 0; //

    this.categories = obj ? obj.categories : [];
    this.category_ids = obj && obj.categories ? this.setCategories() : [];

    this.image = obj ? obj.image : null;
    this.image_hash = obj ? obj.image_hash : null;

    this.explanation = {
      id:
        obj && obj.explanation && obj.explanation.id ? obj.explanation.id : -1,
      body:
        obj && obj.explanation && obj.explanation.body
          ? obj.explanation.body
          : "",
      body_format:
        obj && obj.explanation && obj.explanation.body_format
          ? obj.explanation.body_format
          : "text",
      image:
        obj && obj.explanation && obj.explanation.image
          ? obj.explanation.image
          : "",
      image_hash:
        obj && obj.explanation && obj.explanation.image_hash
          ? obj.explanation.image_hash
          : "",
    };

    //this.options = obj.options;

    this.is_accepted = obj ? obj.is_accepted : false;
    this.is_active = obj ? obj.is_active : false;
    this.is_approved = obj ? obj.is_approved : false;
    this.is_deleted = obj ? obj.is_deleted : false;

    this.metas = obj ? obj.metas : null;
    this.user_id = obj ? obj.user_id : null;
    this.created_at = obj ? new Date(obj.created_at) : null;
    this.updated_at = obj ? new Date(obj.updated_at) : null;

    this.language = i18n.locale;

    this.reports = obj && obj.reports ? obj.reports : [];
    this.comments = obj && obj.comments ? obj.comments : [];

    this.status = obj ? obj.status : 1;

    this.import_question =
      obj && obj.import_question ? obj.import_question : null;

    this.user = obj && obj.user ? obj.user : null;

    this.nuggets = obj && obj.nuggets ? obj.nuggets : [];
    // -1 : rejected, 0 : new, 1 : accepted, 2 : review, 3 : editing

    this.can = {
      activate: obj && obj.can ? obj.can.activate : false,
      deactivate: obj && obj.can ? obj.can.deactivate : false,
      delete: obj && obj.can ? obj.can.delete : false,
    };
  }

  get status_name() {
    let name = "new";
    switch (this.status) {
      case -1:
        name = i18n.t("ng.rejected");
        break;
      //case 0 : name = 'new'; break;
      case 1:
        name = i18n.t("resources.questions.is_accepted");
        break;
      case 2:
        name = i18n.t("ng.in_review");
        break;
      case 3:
        name = i18n.t("resources.questions.needs_edition");
        break;
    }
    return name;
  }

  get status_color() {
    let color = "grey lighten-3";
    switch (this.status) {
      case -1:
        color = "error";
        break;
      case 0:
        color = "primary";
        break;
      case 1:
        color = "success";
        break;
      case 2:
        color = "warning";
        break;
      case 3:
        color = "secondary";
        break;
    }
    return color;
  }

  get type_name() {
    let name = "";
    switch (this.question_type) {
      case "gap_text":
        name = i18n.t("resources.questions.types.4");
        break;
      case "single_choice":
        name = i18n.t("resources.questions.types.6");
        break;
      case "multi_choice":
        name = i18n.t("resources.questions.types.3");
        break;
      case "estimation":
        name = i18n.t("resources.questions.types.5");
        break;
    }
    return name;
  }

  addAnswer() {
    this.answers.push({
      body: "",
      body_format: "text",
      id: this.getRandomId(),
      is_correct: false,
      options: null,
      order: this.answers.length,
    });
  }

  removeAnswer(answer) {
    let answers = JSON.parse(JSON.stringify(this.answers));
    this.answers = answers.filter((a) => a.id !== answer.id);
  }

  changeBodyFormat() {
    let idx = BODY_FORMATS.indexOf(this.body_format);
    idx === -1 || idx === 2 ? (idx = 0) : idx++;
    this.body_format = BODY_FORMATS[idx];
  }

  changeAnswerBodyFormat(answerIdx) {
    let current_format = this.answers[answerIdx].body_format;
    let idx = BODY_FORMATS.indexOf(current_format);
    idx === -1 || idx === 2 ? (idx = 0) : idx++;
    this.answers[answerIdx].body_format = BODY_FORMATS[idx];
  }

  hasCorrectAnswer() {
    let has_correct = false;
    this.answers.forEach((a) => {
      if (a.is_correct) has_correct = true;
    });
    return has_correct;
  }

  correctAnswerCount() {
    let count = 0;
    this.answers.forEach((a) => {
      if (a.is_correct) count++;
    });
    return count;
  }

  getPatchProperties(props) {
    let new_props = [];
    props.forEach((prop) => {
      new_props.push({ name: prop, value: this[prop] });
    });
    return new_props;
  }

  getPatchAnswerProperties(props, answer) {
    let new_props = [];
    props.forEach((prop) => {
      let value = prop === "body" ? answer[prop].toString() : answer[prop];
      new_props.push({ name: prop, value: value });
    });
    return new_props;
  }

  getPostProperties() {
    let payload = JSON.parse(JSON.stringify(this));
    delete payload.explanation;
    delete payload.reports;
    payload.answers.forEach((answer, i) => {
      delete answer.id;
      return (answer.order = i);
    });
    return payload;
  }

  getRandomId() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  setCategories() {
    let arr = [];
    this.categories.forEach((category) => arr.push(category.id));
    return arr;
  }

  get can_edit() {
    return [1].includes(this.status);
  }
}
