<template>
  <v-row dense>
    <v-col v-if="false" cols="12">
      <v-subheader>{{ $t("kpi.filter") }}</v-subheader>
      <v-list
        class="py-0"
        :color="showBundles ? 'grey lighten-3' : 'transparent'"
        style="transition: 0.8s"
        dense
      >
        <v-list-item dense>
          <v-list-item-icon>
            <v-avatar color="grey lighten-3" size="32">
              <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              !visibleBundles || visibleBundles.length === 0
                ? "-"
                : visibleBundles.length
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              $t("gw.journey.bundles")
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              small
              :disabled="!visibleBundles || visibleBundles.length === 0"
              @click="showBundles = !showBundles"
            >
              <v-icon>{{
                showBundles ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>

    <v-expand-transition>
      <v-col v-if="showBundles" cols="12">
        <v-list class="py-0" dense>
          <v-list-item
            v-for="t in visibleBundles"
            :key="`bundle-${t.id}`"
            class="pl-5"
            dense
          >
            <v-list-item-action>
              <v-checkbox dense disabled>
                <template #label>
                  <span class="caption ml-2">{{ t.title }}</span>
                </template>
              </v-checkbox>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-expand-transition>

    <v-col cols="12">
      <v-list class="py-0" dense>
        <v-list-item>
          <v-list-item-action>
            <v-avatar size="32" color="grey lighten-3">
              <v-icon small>mdi-filter</v-icon>
            </v-avatar>
          </v-list-item-action>
          <v-list-item-content
            ><span class="grey--text text--darken-1">{{
              $t("kpi.filter")
            }}</span></v-list-item-content
          >
          <v-list-item-action>
            <v-btn small icon color="primary" @click="showFilter = !showFilter">
              <v-icon>{{
                showFilter ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>

    <v-expand-transition>
      <v-col v-show="showFilter" cols="12">
        <v-row dense>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item dense>
                <v-list-item-action>
                  <v-switch
                    v-model="filter.hide_bundles"
                    dense
                    :disabled="!visibleBundles || visibleBundles.length === 0"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <span class="caption">{{
                    $t("gw.journey.filter.hide_bundles")
                  }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item dense>
                <v-list-item-action>
                  <v-switch v-model="filter.show_nuggets" dense />
                </v-list-item-action>
                <v-list-item-content>
                  <span class="caption">{{
                    $t("gw.journey.filter.show_nuggets")
                  }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item dense>
                <v-list-item-action>
                  <v-switch v-model="filter.show_questions" dense />
                </v-list-item-action>
                <v-list-item-content>
                  <span class="caption">{{
                    $t("gw.journey.filter.shows_questions")
                  }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item dense>
                <v-list-item-action>
                  <v-switch v-model="filter.hide_accepted" dense />
                </v-list-item-action>
                <v-list-item-content>
                  <span class="caption">{{
                    $t("gw.journey.filter.hide_accepted")
                  }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item dense>
                <v-list-item-action>
                  <v-switch v-model="filter.hide_deleted" dense />
                </v-list-item-action>
                <v-list-item-content>
                  <span class="caption">{{
                    $t("gw.journey.filter.hide_deleted")
                  }}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-expand-transition>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "GwJourneyFilter",
  data() {
    return {
      showBundles: false,
      filter: {
        visible_bundles: [],
        hide_bundles: false,
        expand_all_bundles: false,
        show_nuggets: true,
        show_questions: true,
        hide_accepted: true,
        hide_deleted: true,
      },
      showFilter: false,
    };
  },
  watch: {
    filter: {
      handler: function () {
        this.updateFilter();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journey: "get_gw_journey",
      source_filter: "get_source_filter",
    }),
    visibleBundles() {
      var bundles = { ...this.journey }.bundles;
      return bundles;
    },
  },
  methods: {
    ...mapMutations("ghostwriter", ["set_source_filter"]),
    updateFilter() {
      this.set_source_filter(this.filter);
    },
  },
};
</script>
