import Vue from "vue";
import { mapGetters } from "vuex";
import HtmlField from "@/components/global/HtmlField.vue";
import CommaSeperatedField from "@/components/global/CommaSeperatedField.vue";
import InputTextfield from "@/components/ngforms/InputTextfield.vue";
import InputTextarea from "@/components/ngforms/InputTextarea.vue";

Vue.mixin({
  components: {
    "v-html-field": HtmlField,
    "v-csv-field": CommaSeperatedField,
    InputTextfield: InputTextfield,
    InputTextarea: InputTextarea,
  },
  data() {
    return {
      media_server_url: process.env.VUE_APP_MEDIA_SERVER_URL,
    };
  },
  computed: {
    ...mapGetters("auth", { auth: "get_auth", role: "get_role" }),
    ...mapGetters("community", { community: "get_community" }),
  },
  mounted() {
    if (typeof this.fetchData === "function") {
      this.fetchData();
    }
  },
  methods: {
    allow_roles(roles) {
      if (!roles.includes(this.role)) {
        this.$router.push({ name: "ErrorPage", params: { code: 403 } });
      }
    },
    getLocaleDateString(date) {
      return new Date(date).toLocaleDateString();
    },
    randomRGBA() {
      var o = Math.round,
        r = Math.random,
        s = 255;
      return (
        "rgba(" +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        r().toFixed(1) +
        ")"
      );
    },
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    },
  },
});
