<template>
  <div>
    <v-container class="pt-0">
      <v-row>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.users
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.users.plural")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.users }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.users.plural") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.activity.started
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.journeys.started")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.activity.started }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.journeys.started") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.activity.finished
            }}</v-card-title>
            <v-card-subtitle>{{
              $t("resources.journeys.finished")
            }}</v-card-subtitle>
            <!-- <h3>
              {{ journey.stats.activity.finished }}
            </h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("resources.journeys.finished") }}</span
            > -->
          </v-card>
        </v-col>
        <v-col cols="6" md="3">
          <v-card
            class="d-flex flex-column align-center justify-center"
            outlined
            height="100"
          >
            <v-card-title class="secondary--text">{{
              journey.stats.activity.average
            }}</v-card-title>
            <v-card-subtitle>{{ $t("general.average") }}</v-card-subtitle>
            <!-- <h3>{{ journey.stats.activity.average }}%</h3>
            <span
              class="caption text-uppercase font-weight-bold secondary--text"
              >{{ $t("general.average") }}</span
            > -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JourneyStatsActivity",
  computed: {
    ...mapGetters("journeys", { journey: "get_journey" }),
  },
};
</script>

<style></style>
