import api from "@/services/api";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";
import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import { QuestionMultiChoice } from "@/models/content/questions/types/QuestionMultiChoice";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";

export const fetch_questions = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/ng_questions/?is_deleted=0", config);
    if (res._status === 200) {
      let questions = [];
      res.questions.forEach((question) => {
        if (question.question_type === "estimation") {
          questions.push(new QuestionEstimation(question));
          return false;
        }
        if (question.question_type === "single_choice") {
          questions.push(new QuestionSingleChoice(question));
          return false;
        }
        if (question.question_type === "multi_choice") {
          questions.push(new QuestionMultiChoice(question));
          return false;
        }
        if (question.question_type === "gap_text") {
          questions.push(new QuestionGap(question));
          return false;
        }
        console.error("unknown question type");
      });
      commit("set_questions", questions);
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetch_question = async (
  { commit },
  { id, language, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/ng_questions/${id}?language=${language}`,
      config
    );
    if (res._status === 200) {
      if (res.question.question_type === "estimation") {
        commit("set_question", new QuestionEstimation(res.question));
        return false;
      }
      if (res.question.question_type === "single_choice") {
        commit("set_question", new QuestionSingleChoice(res.question));
        return false;
      }
      if (res.question.question_type === "multi_choice") {
        commit("set_question", new QuestionMultiChoice(res.question));
        return false;
      }
      if (res.question.question_type === "gap_text") {
        commit("set_question", new QuestionGap(res.question));
        return false;
      }
      console.error("unknown question type");
    } else {
      commit("set_question", null);
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const add_question = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/ng_questions/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_answer = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/question_answers/", payload, config);
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_question = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/ng_questions/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.id,
        language: payload.language,
      });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const patch_answer = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.patch(
      `admin/question_answers/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.questionId,
        language: payload.language,
      });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const delete_question = async (_, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/ng_questions/${id}`, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const delete_answer = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.delete(`admin/question_answers/${payload.id}`, config);
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_question_reports = async (
  { commit },
  { question_id, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/ng_questions/${question_id}/reports`,
      config
    );
    if (res._status === 200) {
      commit("set_question_reports", res.reports);
    } else {
      return null;
    }
  } catch (err) {
    console.error("fetch_reports err:", err);
    return null;
  }
};

export const patch_report = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.patch(
      `admin/ng_question_reports/${payload.report_id}/status`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question_reports", { question_id: payload.question_id });
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return { success: false, msg: err };
  }
};

export const fetch_question_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/ng_questions/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_question_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/ng_questions/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question_translations", { payload: { id: payload.id } });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_answer_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/question_answers/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_answer_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_answer_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/question_answers/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_answer_translations", { payload: { id: payload.id } });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const check_math = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post(
      "admin/question_answers/checkmath",
      payload,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_question_explanation = async (
  { commit },
  { id, config = {} }
) => {
  try {
    let res = await api.get(`admin/question_explanations/${id}`, config);
    if (res._status === 200) {
      commit("set_explanation", res.explanation);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const patch_question_explanation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/question_explanations/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return false;
  }
};

export const add_question_explanation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/question_explanations/", payload, config);
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_question_explanation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      `admin/question_explanations/${payload.id}`,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return false;
  }
};

export const fetch_explanation_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/question_explanations/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_explanation_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_explanation_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/question_explanations/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_explanation_translations", {
        payload: { id: payload.id },
      });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const add_question_comment = async (
  { dispatch },
  payload,
  config = {}
) => {
  try {
    let res = await api.post(
      `admin/ng_questions/${payload.question_id}/comments`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const link_question_nugget = async (
  { dispatch },
  payload,
  config = {}
) => {
  try {
    let res = await api.post(
      `admin/ng_questions/${payload.question_id}/nuggets/${payload.nugget_id}`,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const unlink_question_nugget = async (
  { dispatch },
  payload,
  config = {}
) => {
  try {
    let res = await api.delete(
      `admin/ng_questions/${payload.question_id}/nuggets/${payload.nugget_id}`,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_question", {
        id: payload.question_id,
        language: payload.language,
      });
    }
    return res;
  } catch (err) {
    return false;
  }
};
