<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-expand-transition>
          <v-alert
            v-if="sourceIsGenerating"
            color="info"
            prominent
            dark
            dense
            icon="mdi-information"
          >
            <h5 class="overline">{{ $t("gw.journey.generate.generating") }}</h5>
            <p>
              {{ $t("gw.journey.generate.generating_msg") }}
            </p>
            <div class="d-flex justify-end">
              <v-btn text small @click="refresh()">
                <v-icon small class="mr-2">mdi-refresh</v-icon>
                <span>{{ $t("general.reload") }}</span>
              </v-btn>
            </div>
          </v-alert>
        </v-expand-transition>
        <v-alert
          v-if="!licences.ghostwriter || licenceExpired"
          text
          type="error"
          dense
          border="left"
        >
          <span class="caption">{{ $t("gw.no_licence") }}</span>
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-data-table
              id="gw-sessions"
              class="grey lighten-3"
              :headers="headers"
              :items="sortedJourneys"
              :items-per-page="10"
            >
              <template #item="{ item }">
                <tr class="hover" @click="goToDetails(item)">
                  <td>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            :color="item.is_imported ? 'success' : 'secondary'"
                            small
                          >
                            {{
                              item.is_imported
                                ? "mdi-database"
                                : "mdi-pencil-circle"
                            }}
                          </v-icon>
                        </div>
                      </template>
                      <span>{{
                        item.is_imported
                          ? $t("gw.journey.is_imported")
                          : $t("gw.journey.is_not_imported")
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip v-if="!item.disabled" right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar tile size="24" v-bind="attrs" v-on="on">
                          <country-flag
                            :country="flagName(item.lang)"
                            size="small"
                          />
                        </v-avatar>
                      </template>
                      <span>{{
                        $t("gw.selectedLanguage", {
                          lang: $t(`languages.${item.lang}.display`),
                        })
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td class="py-4">
                    <div class="font-weight-bold secondary--text">
                      {{ item.title }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwJourneys",
  components: {
    ViewLoader,
    CountryFlag,
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: this.$t("resources.users.status"),
          value: "status",
          width: "80px",
        },
        {
          text: this.$t("general.language"),
          value: "options.language",
          width: "80px",
        },
        {
          text: this.$t("resources.categories.name"),
          value: "name",
          width: "auto",
        },
        /* {
          text: this.$t("resources.nuggets.content"),
          value: "stats",
          width: "260px",
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      journeys: "get_gw_journeys",
      sources_generating: "get_sources_generating",
    }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    sortedJourneys() {
      var journeys = [...this.journeys];
      return journeys.sort((a, b) => b.id - a.id).filter((s) => !s.is_deleted);
    },
    sourceIsGenerating() {
      if (!this.sources_generating || this.sources_generating.length === 0)
        return false;
      var sources = [...this.sources_generating].filter((s) => !s.job_error);
      return sources.length > 0;
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  destroyed() {
    this.set_gw_journeys([]);
  },
  methods: {
    ...mapActions("ghostwriter", [
      "fetch_gw_journeys",
      "fetch_sources_generating",
    ]),
    ...mapActions("auth", ["init_auth"]),
    ...mapMutations("ghostwriter", ["set_gw_journeys"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_gw_journeys();
      await this.init_auth();
      await this.fetch_sources_generating();
      this.loading = false;
    },
    goToDetails(item) {
      this.$router.push({
        name: "GwJourneyContent",
        params: { id: item.id },
      });
    },
    refresh() {
      this.$router.go();
    },
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      if (lang === "uk-UK") return "ua"; // ukrainian flag fallback
      if (lang === "ko-KO") return "kr"; // korean flag fallback
      return lang.slice(-2);
    },
  },
};
</script>

<style></style>
