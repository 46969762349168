export const set_kpi_stats = (state, stats) => (state.kpi_stats = stats); // todo delete
export const set_kpi_current = (state, obj) => (state.current = obj);
export const set_kpi_current_stats = (state, obj) =>
  (state.current_stats = obj);
export const set_kpi_timed = (state, obj) => (state.timed = obj);
export const set_kpi_dates = (state, obj) => (state.dates = obj);
export const set_kpi_type = (state, obj) => (state.type = obj);
export const set_kpi_current_detail = (state, obj) =>
  (state.current_detail = obj);
export const set_kpi_current_detail_stats = (state, obj) =>
  (state.current_detail_stats = obj);
export const set_kpi_timed_detail = (state, obj) => (state.timed_detail = obj);
export const set_kpi_dates_detail = (state, obj) => (state.dates_detail = obj);
export const set_kpi_resource_detail = (state, obj) =>
  (state.resource_detail = obj);
export const set_kpi_journeys = (state, arr) => (state.journeys = arr);
export const set_kpi_journey = (state, obj) => (state.journey = obj);
export const set_kpi_categories = (state, arr) => (state.categories = arr);
export const set_kpi_users = (state, arr) => (state.users = arr);
export const set_kpi_general = (state, obj) => (state.general = obj);
export const set_kpi_mappers = (state, mappers) => (state.mappers = mappers);
export const set_kpi_loading = (state, loading) => (state.loading = loading);
export const set_kpi_version = (state, version) => (state.version = version);
