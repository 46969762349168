<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card>
        <v-card-title class="overline secondary--text">
          <v-icon color="secondary" class="mr-2"> mdi-certificate </v-icon>
          <span>{{ $t("certificate.activation.title") }}</span>
        </v-card-title>
        <v-card-text>
          <!-- <p>{{ certificate.is_active ? $t('certificate.activation.hint2') : $t('certificate.activation.hint') }}</p> -->
          <p>{{ $t("certificate.activation.hint") }}</p>
          <small>{{ $t("certificate.activation.delete") }}</small>
          <!-- <p>{{ $t('certificate.activation.confirmation') }}</p>
       
          <v-checkbox
            v-model="confirmed"
            dense
            :disabled="certificate.is_active"
          >
            <template #label>
              <span class="caption">{{ $t('certificate.activation.confirmation_answer') }}</span>
            </template>
          </v-checkbox> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-btn
            text
            small
            color="primary"
            :disabled="!confirmed && !certificate.is_active"
            @click="activateCertificate()"
          >
            {{ $t("certificate.activation.activate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Certificate } from "@/models/Certificate.js";

export default {
  name: "CertificateActivator",
  props: {
    certificate: {
      type: Certificate,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      confirmed: true, // false
      loading: false,
    };
  },
  methods: {
    ...mapActions("journeys", ["update_certificate"]),
    openDialog() {
      this.showDialog = true;
      //this.confirmed = this.certificate.is_active;
      this.confirmed = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.loading = false;
      this.confirmed = false;
    },
    async activateCertificate() {
      this.loading = true;
      var cert = { ...this.certificate };
      cert.is_active = !cert.is_active;
      var res = await this.update_certificate({ certificate: cert });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: this.$t("certificate.activation.change_success"),
          type: "success",
        });
        if (!cert.is_active) this.confirmed = false;
        this.closeDialog();
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: this.$t("certificate.activation.change_error"),
          type: "error",
        });
      }
    },
  },
};
</script>
