<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('kpi.label')"
      icon="mdi-chart-areaspline"
      :margin-bottom="0"
    >
      <template #extension>
        <ViewNavigation
          :nav-items="navItems"
          only-tabs
          class="flex-grow-1"
          :disabled="loading"
        />
      </template>

      <template #titleButton>
        <KpiVersionSelector v-show="false" />
      </template>
    </ViewHeader>

    <section>
      <router-view :key="$route.fullPath" />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import KpiVersionSelector from "@/components/kpi/KpiVersionSelector";

export default {
  name: "AdministrationOverview",
  components: {
    ViewHeader,
    ViewNavigation,
    KpiVersionSelector,
  },
  computed: {
    ...mapGetters("kpi", { loading: "get_kpi_loading" }),
    navItems() {
      return [
        {
          title: this.$t("kpi.overview"),
          link: { name: "KpiOverview" },
          disabled: false,
        },
        {
          title: this.$t("kpi.performance"),
          link: { name: "KpiPerformanceOverview" },
          disabled: false,
          color: "accent",
        },
        {
          title: this.$t("kpi.activity"),
          link: { name: "KpiActivityOverview" },
          disabled: false,
          color: "info",
        },
        {
          title: this.$t("resources.journeys.journey.plural"),
          link: { name: "KpiJourneyOverview" },
          disabled: false,
        },
        {
          title: this.$t("resources.categories.plural"),
          link: { name: "KpiCategoryDiscovery" },
          disabled: false,
        },
      ];
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("kpi.label"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
    this.initDates();
  },
  methods: {
    ...mapMutations("kpi", ["set_kpi_dates"]),
    initDates() {
      var end = new Date();
      var endDate = end.toISOString().slice(0, 8) + "01";
      var startDate =
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 3,
          new Date().getDate()
        )
          .toISOString()
          .slice(0, 8) + "01";
      this.set_kpi_dates({
        date_start: startDate,
        date_end: endDate,
      });
    },
  },
};
</script>

<style></style>
