<template>
  <div>
    <v-form v-model="valid" class="community-form">
      <SectionHeader
        :title="$t('general.edit')"
        :subtitle="$t('resources.community.join_additional')"
      />
      <v-container v-if="community && inputs">
        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="inputs.name"
              :label="$t('resources.community.name')"
              listen-to-input
              disabled
            />
          </v-col>
          <v-col cols="12" sm="6">
            <InputTextfield
              v-model="inputs.welcome_title"
              :label="$t('resources.community.welcome_title')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="welcome_title"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.welcome_title.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12" sm="6">
            <InputTextfield
              v-model="inputs.join_title"
              :label="$t('resources.community.join_title')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="join_title"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.join_title.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12" sm="6">
            <InputTextfield
              v-model="inputs.welcome_text"
              :label="$t('resources.community.welcome_text')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="welcome_text"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.welcome_text.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12" sm="6">
            <InputTextfield
              v-model="inputs.join_text"
              :label="$t('resources.community.join_text')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="join_text"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.join_text.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12">
            <InputTextfield
              v-model="inputs.join_additional"
              :label="$t('resources.community.join_additional')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="join_additional"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.join_additional.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12">
            <InputTextfield
              v-model="inputs.join_notice"
              :label="$t('resources.community.join_notice')"
              listen-to-input
            >
              <template #actions>
                <CommunityTranslationDialog
                  prop-name="join_notice"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.join_notice.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12">
            <InputTextarea
              v-model="inputs.info_html"
              :label="$t('resources.community.info_html')"
              listen-to-input
              preview
              body-format="html"
            >
              <template #actions="{ togglePreview }">
                <CommunityTranslationDialog
                  prop-name="info_html"
                  :community-id="community.id"
                >
                  <template #button="{ onClick }">
                    <v-btn
                      icon
                      :disabled="inputs.info_html.length === 0"
                      @click="onClick"
                    >
                      <v-icon small> mdi-translate </v-icon>
                    </v-btn>
                  </template>
                </CommunityTranslationDialog>
                <v-btn
                  icon
                  :disabled="inputs.info_html.length === 0"
                  @click="togglePreview()"
                >
                  <v-icon small> mdi-file-find </v-icon>
                </v-btn>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              color="error"
              text
              small
              :disabled="loading || !edited"
              @click="resetForm"
            >
              {{ $t("general.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              small
              :loading="loading"
              :disabled="!valid || loading || !edited"
              @click="submitForm"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import CommunityTranslationDialog from "@/components/community/CommunityTranslationDialog";

export default {
  name: "CommunityForm",
  components: {
    SectionHeader,
    CommunityTranslationDialog,
  },
  /* props: {
    community: {
      type: Object
    }
  }, */
  data() {
    return {
      inputs: null,
      loading: false,
      valid: true,
    };
  },
  computed: {
    ...mapGetters("community", { communtiy: "get_community" }),
    edited() {
      if (!this.community || !this.inputs) {
        return false;
      }
      return (
        this.inputs.name != this.community.name ||
        this.inputs.welcome_title != this.community.welcome_title ||
        this.inputs.welcome_text != this.community.welcome_text ||
        this.inputs.join_title != this.community.join_title ||
        this.inputs.join_text != this.community.join_text ||
        this.inputs.join_additional != this.community.join_additional ||
        this.inputs.join_notice != this.community.join_notice ||
        this.inputs.info_html != this.community.info_html
      );
    },
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    ...mapActions("community", ["patch_community", "fetch_community"]),
    resetForm() {
      this.inputs = { ...this.community };
    },
    async fetchData() {
      await this.fetch_community({
        id: 1,
      });
      this.inputs = { ...this.community };
    },
    async submitForm() {
      this.loading = true;
      let properties = [
        //{ name: 'name', value: this.inputs.name },
        { name: "welcome_title", value: this.inputs.welcome_title },
        { name: "welcome_text", value: this.inputs.welcome_text },
        { name: "join_title", value: this.inputs.join_title },
        { name: "join_text", value: this.inputs.join_text },
        { name: "join_additional", value: this.inputs.join_additional },
        { name: "join_notice", value: this.inputs.join_notice },
        { name: "info_html", value: this.inputs.info_html },
      ];
      /* this.inputs.logo_img = this.inputs.logo_img.replace(/(\/cdn\/)[a-z]+\//gm, '');
      this.inputs.welcome_logo_img = this.inputs.welcome_logo_img.replace(/(\/cdn\/)[a-z]+\//gm, '');
      this.inputs.info_img = this.inputs.info_img.replace(/(\/cdn\/)[a-z]+\//gm, ''); */
      this.inputs.language = this.$i18n.locale;
      let res = await this.patch_community({
        payload: {
          id: this.community.id,
          language: this.$i18n.locale,
          override: true,
          properties: properties,
        },
      });
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          text:
            this.$t("resources.community.singular") +
            " " +
            this.$t("general.updateSuccess"),
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss"></style>
