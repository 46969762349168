<template>
  <v-app-bar app color="primary" dark dense>
    <v-app-bar-nav-icon tile @click="$emit('menu-toggle')" />
    <v-toolbar-title class="text-uppercase subtitle-2">
      {{ applicationTitle() }}
    </v-toolbar-title>
    <v-spacer />
    <!-- <span v-if="$vuetify.breakpoint.smAndUp" class="subtitle-2">{{
      community.name
    }}</span> -->
    <v-btn
      text
      class="ml-1"
      :disabled="['editor', 'manager'].includes(role)"
      @click="$emit('go-to', 'Community')"
    >
      <v-avatar color="transparent" size="32" tile>
        <v-img :src="community.logo_small_img_url" contain />
      </v-avatar>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppTopBar",
  methods: {
    applicationTitle() {
      if (this.$te(`customize.title.${process.env.VUE_APP_INSTANCE_NAME}`)) {
        return (document.title = `${this.$t(
          `customize.title.${process.env.VUE_APP_INSTANCE_NAME}`,
          {
            name: process.env.VUE_APP_CLIENT_NAME,
          }
        )}`);
      }
      return (document.title = `${this.$t("customize.title.default", {
        name: process.env.VUE_APP_CLIENT_NAME,
      })}`);
    },
  },
};
</script>

<style></style>
