<template>
  <div>
    <v-container>
      <v-form ref="form-body" v-model="valid.body">
        <v-row>
          <v-col cols="12">
            <InputTextarea
              v-model="input.body"
              :label="$t('resources.questions.body')"
              counter="255"
              :body-format="input.body_format"
              disabled
              translatable
              :rules="input.formRules.body"
              preview
              @is-editing="setEditing"
              @save="updateBody"
              @cancel="setQuestion"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  changeFormat,
                  togglePreview,
                }"
              >
                <div v-if="!editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="
                            isEditing || disabled || editModes.time || loading
                          "
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <QuestionTranslationDialog
                          prop-name="body"
                          :disabled="!question.body || disabled || loading"
                          :question="question"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                ['math'].includes(question.body_format) ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </QuestionTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!input.body || input.body.length < 1"
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                  <v-tooltip class="align-self-end" left dense color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          icon
                          :disabled="!editing"
                          @click="changeFormat()"
                        >
                          <v-icon small> mdi-format-letter-case-upper </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("ng.change_format") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon @click="togglePreview()">
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("resources.invitations.preview") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-container>
      <v-form ref="form-hint" v-model="valid.hint">
        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="input.hint"
              :label="$t('ng.question_hint')"
              :rules="input.formRules.hint"
              disabled
              counter="255"
              translatable
              @is-editing="setEditing"
              @save="updateHint"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  inputChanged,
                }"
              >
                <div v-if="!editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="isEditing || disabled || editModes.time"
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <QuestionTranslationDialog
                          prop-name="hint"
                          :question="question"
                          :disabled="!question.hint || disabled || loading"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                !question.hint ||
                                question.hint.length === 0 ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </QuestionTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!inputChanged"
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextfield>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <v-slider
              v-model="input.time"
              thumb-label="always"
              min="0"
              max="180"
              color="secondary"
              track-color="secondary lighten-3"
              hide-details
              dense
              :rules="question.formRules.time"
              :disabled="!editModes.time"
            >
              <template #prepend>
                <div>
                  <v-text-field
                    v-model="input.time"
                    type="number"
                    min="0"
                    max="180"
                    outlined
                    style="width: 160px"
                    dense
                    :label="$t('ng.question_time')"
                    :background-color="editModes.time ? 'white' : 'background'"
                    hide-details
                    :rules="question.formRules.time"
                    :disabled="!editModes.time"
                    suffix="s"
                  />
                </div>
              </template>
            </v-slider>

            <div v-if="editModes.time" class="mb-3 d-flex">
              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="ml-2"
                      small
                      color="error"
                      :disabled="disabled"
                      @click="editModes.time = false"
                    >
                      <v-icon small> mdi-close </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.cancel") }}</span>
              </v-tooltip>
              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="ml-2"
                      small
                      color="success"
                      :disabled="disabled || input.time === question.time"
                      @click="updateTime()"
                    >
                      <v-icon small> mdi-check </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.save") }}</span>
              </v-tooltip>
            </div>
            <div v-if="!editModes.time" class="mb-3">
              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="mx-1"
                      small
                      :disabled="isEditing || disabled || loading"
                      @click="editModes.time = true"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.edit") }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-container>
      <v-form ref="form-answer" v-model="valid.answer">
        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="input.answer.body"
              number
              :label="$t('resources.questions.answer')"
              :rules="input.formRules.answer"
              listen-to-input
              disabled
              @is-editing="setAnswerEditing"
            >
              <template #actions="{ startEditing, cancelEditing }">
                <div v-if="!isAnswerEditing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="
                            editModes.body ||
                            editModes.hint ||
                            disabled ||
                            editModes.time ||
                            isEditing ||
                            loading
                          "
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="isAnswerEditing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!valid.answer"
                          @click="updateAnswer()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextfield>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="input.options.border.min"
              number
              :label="$t('resources.questions.border_left')"
              :rules="input.formRules.borderMin"
              :disabled="!isAnswerEditing"
              listen-to-input
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="input.options.border.max"
              number
              :label="$t('resources.questions.border_right')"
              :rules="input.formRules.borderMax"
              :disabled="!isAnswerEditing"
              listen-to-input
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="input.options.tolerance.min"
              number
              :label="$t('resources.questions.tolerance_left')"
              :rules="input.formRules.toleranceMin"
              :disabled="!isAnswerEditing"
              listen-to-input
            />
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <InputTextfield
              v-model="input.options.tolerance.max"
              number
              :label="$t('resources.questions.tolerance_right')"
              :rules="input.formRules.toleranceMax"
              :disabled="!isAnswerEditing"
              listen-to-input
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";
import QuestionTranslationDialog from "@/components/questions/QuestionTranslationDialog";

export default {
  name: "QuestionEstimationEdit",
  components: {
    QuestionTranslationDialog,
  },
  props: {
    question: {
      type: [QuestionEstimation],
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input: null,
      valid: {
        body: false,
        hint: false,
        answer: false,
      },
      loading: false,
      isEditing: false,
      isAnswerEditing: false,
      editModes: {
        time: false,
      },
    };
  },
  watch: {
    question() {
      this.setQuestion();
    },
    valid(val) {
      this.$emit("is-valid", val);
    },
    "editModes.time": {
      handler: function (v) {
        this.setEditing(v);
      },
    },
  },
  beforeMount() {
    this.setQuestion();
  },
  methods: {
    ...mapActions("questions", ["patch_question", "patch_answer"]),
    setQuestion() {
      this.input = new QuestionEstimation(
        JSON.parse(JSON.stringify(this.question))
      );
    },
    setEditing(val) {
      this.isEditing = val;
      this.isAnswerEditing = false;
    },
    setAnswerEditing(val) {
      this.isEditing = val;
      this.isAnswerEditing = val;
    },
    async updateHint(val, translate) {
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: translate,
        properties: { name: "hint", value: val },
      };

      let res = await this.patch_question({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateBody(val, translate, format) {
      let properties = [{ name: "body", value: val }];
      if (format) properties.push({ name: "body_format", value: format });
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: translate,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateTime() {
      let properties = [{ name: "time", value: this.input.time }];
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: false,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.editModes.time = false;
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateAnswer() {
      this.loading = true;
      let payload = {
        id: this.input.answer.id,
        questionId: this.question.id,
        language: this.language,
        override: false,
        properties: this.input.getPatchAnswerProperties(
          ["body"],
          this.input.answer
        ),
      };

      let res = await this.patch_answer({
        payload: payload,
      });
      if (res && res._status === 200) {
        let payload = {
          id: this.input.id,
          language: this.language,
          override: false,
          properties: this.input.getPatchProperties(["options"]),
        };

        let res2 = await this.patch_question({
          payload: payload,
        });
        this.loading = false;
        if (res2._status === 200) {
          this.$notify({
            type: "success",
            title: this.$t("general.success"),
            text: this.$t("general.updateSuccess"),
          });
          this.setAnswerEditing(false);
          this.$emit("editing", false);
        } else {
          this.$notify({
            type: "error",
            title: this.$t("general.error"),
            text: this.$t("general.updateError"),
          });
        }
      } else {
        this.loading = false;
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
