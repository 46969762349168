import axios from "axios";
const API_PREFIX = process.env.VUE_APP_API_PREFIX;

axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.validateStatus = function (status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      localStorage.removeItem("token");
      window.location = "/login/";
    }
    return Promise.reject(error);
  }
);

export default {
  async get(url, config = {}) {
    try {
      let res = await axios.get(API_PREFIX + url, config);
      res.data._status = res.status; // TODO: remove after changing to error code
      res.data._headers = res.headers;
      return res.data;
    } catch (e) {
      console.log("Error in @/services/api.js -> default.get()", e);
      return null;
    }
  },
  async post(url, payload, config = {}) {
    try {
      let res = await axios.post(API_PREFIX + url, payload, config);
      res.data._status = res.status; // TODO: remove after changing to error code
      return res.data;
    } catch (e) {
      console.log("Error in @/services/api.js -> default.post()", e);
      return null;
    }
  },
  async put(url, payload, config = {}) {
    try {
      let res = await axios.put(API_PREFIX + url, payload, config);
      res.data._status = res.status; // TODO: remove after changing to error code
      return res.data;
    } catch (e) {
      console.log("Error in @/services/api.js -> default.put()", e);
      return null;
    }
  },
  async patch(url, payload, config = {}) {
    try {
      let res = await axios.patch(API_PREFIX + url, payload, config);
      res.data._status = res.status; // TODO: remove after changing to error code
      return res.data;
    } catch (e) {
      console.log("Error in @/services/api.js -> default.patch()", e);
      return null;
    }
  },
  async delete(url, config = {}) {
    try {
      let res = await axios.delete(API_PREFIX + url, config);
      res.data._status = res.status; // TODO: remove after changing to error code
      return res.data;
    } catch (e) {
      console.log("Error in @/services/api.js -> default.delete()", e);
      return null;
    }
  },
};
