var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list-item',{staticClass:"mb-2 white",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.12) !important"},attrs:{"dense":"","three-line":""},on:{"click":function($event){_vm.item.type === 'nugget'
        ? (_vm.showNuggetDialog = true)
        : (_vm.showQuestionDialog = true)}}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"24"}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.item.type === "nugget" ? "mdi-school" : "mdi-comment-question")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.item.type === "nugget" ? _vm.item.content.title : _vm.$t("resources.questions.types.6"))+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.content.body))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"left":"","color":"secondars"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(![1, 2].includes(_vm.item.status)),expression:"![1, 2].includes(item.status)"}],attrs:{"small":"","color":_vm.getStatusColor}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getStatusIcon))]),_c('v-progress-circular',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:([1, 2].includes(_vm.item.status)),expression:"[1, 2].includes(item.status)"}],attrs:{"indeterminate":"","color":"primary","size":"16","width":"2"}},'v-progress-circular',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.getStatusText))])])],1),(_vm.item.status === 3)?_c('v-overlay',{staticClass:"justify-start align-start",attrs:{"absolute":"","opacity":".1"}},[_c('v-sheet',{staticClass:"px-2 py-0 mt-1",attrs:{"color":_vm.item.status === 3 && (!!_vm.item.question_id || !!_vm.item.nugget_id)
            ? 'success'
            : _vm.item.status === 3 && !_vm.item.question_id && !_vm.item.nugget_id
            ? 'error'
            : 'grey lighten-3'}},[_c('span',{staticClass:"caption text-uppercase font-weight-bold"},[_vm._v(_vm._s(!!_vm.item.question_id || !!_vm.item.nugget_id ? _vm.$t("gw.imported") : _vm.$t("general.error")))])])],1):_vm._e(),(_vm.item.is_deleted)?_c('v-overlay',{staticClass:"justify-start align-start",attrs:{"absolute":"","opacity":"0"}},[_c('v-sheet',{staticClass:"px-2 py-0",attrs:{"color":"error"}},[_c('span',{staticClass:"caption text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.$t("general.rejected")))])])],1):_vm._e()],1),(_vm.item.type === 'question' && _vm.session)?_c('GwBulkQuestionDialog',{attrs:{"question":_vm.item,"visible":_vm.showQuestionDialog,"session-id":_vm.session.id,"disabled":_vm.item.status !== 0,"default-category":_vm.session.defaults && _vm.session.defaults.category_id
        ? _vm.session.defaults.category_id
        : null},on:{"close":function($event){_vm.showQuestionDialog = false}}}):_vm._e(),(_vm.item.type === 'nugget' && _vm.session)?_c('GwBulkNuggetDialog',{attrs:{"nugget":_vm.item,"visible":_vm.showNuggetDialog,"session-id":_vm.session.id,"disabled":_vm.item.status !== 0,"default-category":_vm.session.defaults && _vm.session.defaults.category_id
        ? _vm.session.defaults.category_id
        : null},on:{"close":function($event){_vm.showNuggetDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }