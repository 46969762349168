export const get_questions = (state) => state.questions;
export const get_question = (state) => state.question;
export const get_question_reports = (state) => state.question_reports;
export const get_question_translations = (state) => state.question_translations;
export const get_answer_translations = (state) => state.answer_translations;
export const get_selected_language = (state) => state.selected_language;
export const get_reviewmode = (state) => state.reviewmode;
export const get_reportmode = (state) => state.reportmode;
export const get_explanation_translations = (state) =>
  state.explanation_translations;
