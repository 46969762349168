export const state = {
  kpi_stats: null, // todo delete
  current: [],
  current_stats: null,
  timed: null,
  dates: {
    date_start: "2023-01-01",
    date_end: "2023-06-01",
  },
  type: null,
  current_detail: null,
  current_detail_stats: null,
  timed_detail: null,
  dates_detail: {
    date_start: "2023-06-01",
    date_end: "2023-06-30",
  },
  resource_detail: null,
  journeys: null,
  journey: null,
  categories: null,
  users: null,
  general: null,
  endpoint: "x",
  mappers: {
    categories: null,
    structures: null,
    substructures: null,
    users: null,
  },
  loading: true,
  version: "v2",
};
