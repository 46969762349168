<template>
  <v-card
    :color="cardColor"
    :elevation="elevation"
    class="mt-4 fill-height"
    outlined
  >
    <v-sheet
      flat
      :color="sheetColor"
      outlined
      class="d-flex align-center pa-2 v-sheet--offset mx-auto rounded-lg"
      max-width="calc(100% - 16px)"
      elevation="0"
    >
      <v-progress-circular
        :value="value * 100"
        size="60"
        :color="textColor"
        :rotate="180"
      >
        <v-icon size="48" :color="'secondary'">{{ icon }}</v-icon>
      </v-progress-circular>
      <div
        class="text-h4 font-weight-bold ml-4 grow"
        :class="`${textColor}--text`"
      >
        {{ value.toFixed(2) }}
      </div>
      <div class="align-self-start justify-self-end d-flex align-center">
        <span :class="`${deltaColor}--text caption font-weight-bold`">{{
          delta > 0 ? `+${delta.toFixed(2)}` : delta.toFixed(2)
        }}</span>
        <v-icon :color="deltaColor">{{ deltaIcon }}</v-icon>
      </div>
    </v-sheet>
    <v-card-text v-if="description" class="pt-0">
      <div v-html="description" />
    </v-card-text>
    <v-card-actions class="px-4">
      <v-btn
        :disabled="!to"
        :to="to"
        depressed
        small
        :color="textColor"
        class="mr-2"
      >
        {{ $t("general.details") }}
      </v-btn>
      <KpiInfoDialog
        v-if="explanationTitle && explanationText"
        :title="explanationTitle"
        :text="explanationText"
      >
        <template #button="{ openDialog }">
          <v-btn depressed small color="secondary" @click="openDialog()">{{
            $t("ng.info")
          }}</v-btn>
        </template>
      </KpiInfoDialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import KpiInfoDialog from "@/components/kpi/KpiInfoDialog";

export default {
  name: "KpiStatsSheetCard",
  components: {
    KpiInfoDialog,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    delta: {
      type: Number,
      required: true,
    },
    cardColor: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
    sheetColor: {
      type: String,
      required: false,
      default: "white",
    },
    textColor: {
      type: String,
      required: false,
      default: "secondary",
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-help-circle",
    },
    elevation: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    description: {
      type: String,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    explanationTitle: {
      type: String,
      required: false,
    },
    explanationText: {
      type: String,
      required: false,
    },
  },
  computed: {
    deltaColor() {
      if (this.delta < 0) return "error";
      if (this.delta > 0) return "success";
      return "secondary";
    },
    deltaIcon() {
      if (this.delta < 0) return "mdi-menu-down";
      if (this.delta > 0) return "mdi-menu-up";
      return "mdi-square-small";
    },
  },
};
</script>

<style lang="scss">
.v-sheet--offset {
  top: -12px;
  position: relative;
}
</style>
