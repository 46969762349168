<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text pb-0">
          Substruktur hinzufügen
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="grow">
              <p class="caption">
                Bitte trage den gewünschten Namen ein. Dieser kann im Anschluss
                wieder geändert werden.
              </p>
              <p class="caption mb-0">
                Die Substruktur wird der Struktur
                <b>{{ structure.name }}</b> zugewiesen.
              </p>
              <small class="error--text"
                >Achtung: Substrukturen können nicht gelöscht werden.</small
              >
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="12">
              <v-autocomplete
                v-model="structure_id"
                :items="structures"
                item-text="name"
                item-value="id"
                solo
                flat
                dense
                hide-details
                background-color="white"
                :disabled="!!structure"
              />
            </v-col> -->
            <v-col cols="12">
              <InputTextfield
                v-model="name"
                :label="$t('resources.categories.name')"
                dense
                hide-details
                :rules="rules.name"
                listen-to-input
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="name.length === 0 || loading"
            @click="addSubstructure()"
          >
            {{ $t("general.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddStructureDialog",
  props: {
    structure: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      name: "",
      structure_id: null,
      loading: false,
      rules: {
        name: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("structures", { structures: "get_structures" }),
  },
  methods: {
    ...mapActions("structures", ["add_substructure"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
      if (this.structure) this.structure_id = this.structure.id;
    },
    async addSubstructure() {
      this.loading = true;
      let payload = {
        structure_id: this.structure.id,
        name: this.name,
        isactive: true,
      };

      let res = await this.add_substructure({
        payload: payload,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Struktur erfolgreich angelegt.",
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Anlegen der Struktur.",
        });
      }
    },
    reset() {
      this.name = "";
    },
  },
};
</script>
