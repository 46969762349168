<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Line</v-card-title>
          <v-card-text>
            <ChartLine
              :chart-data="{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                ],
                datasets: [
                  {
                    label: 'Data One',
                    backgroundColor: '#f87979',
                    data: [40, 39, 10, 40, 39, 80, 40],
                  },
                  {
                    label: 'Data Tow',
                    backgroundColor: '#00adf7',
                    data: [10, 12, 30, 80, 60, 70, 20],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Pie</v-card-title>
          <v-card-text>
            <ChartPie
              :chart-data="{
                labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                datasets: [
                  {
                    backgroundColor: [
                      '#41B883',
                      '#E46651',
                      '#00D8FF',
                      '#DD1B16',
                    ],
                    data: [40, 20, 80, 10],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Bubble</v-card-title>
          <v-card-text>
            <ChartBubble
              :chart-data="{
                datasets: [
                  {
                    label: 'Data One',
                    backgroundColor: '#f87979',
                    data: [
                      {
                        x: 20,
                        y: 25,
                        r: 5,
                      },
                      {
                        x: 40,
                        y: 10,
                        r: 10,
                      },
                      {
                        x: 30,
                        y: 22,
                        r: 30,
                      },
                    ],
                  },
                  {
                    label: 'Data Two',
                    backgroundColor: '#7C8CF8',
                    data: [
                      {
                        x: 10,
                        y: 30,
                        r: 15,
                      },
                      {
                        x: 20,
                        y: 20,
                        r: 10,
                      },
                      {
                        x: 15,
                        y: 8,
                        r: 30,
                      },
                    ],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Scatter</v-card-title>
          <v-card-text>
            <ChartScatter
              :chart-data="{
                datasets: [
                  {
                    label: 'Scatter Dataset 1',
                    fill: false,
                    borderColor: '#f87979',
                    backgroundColor: '#f87979',
                    data: [
                      {
                        x: -2,
                        y: 4,
                      },
                      {
                        x: -1,
                        y: 1,
                      },
                      {
                        x: 0,
                        y: 0,
                      },
                      {
                        x: 1,
                        y: 1,
                      },
                      {
                        x: 2,
                        y: 4,
                      },
                    ],
                  },
                  {
                    label: 'Scatter Dataset 2',
                    fill: false,
                    borderColor: '#7acbf9',
                    backgroundColor: '#7acbf9',
                    data: [
                      {
                        x: -2,
                        y: -4,
                      },
                      {
                        x: -1,
                        y: -1,
                      },
                      {
                        x: 0,
                        y: 1,
                      },
                      {
                        x: 1,
                        y: -1,
                      },
                      {
                        x: 2,
                        y: -4,
                      },
                    ],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Doughnut</v-card-title>
          <v-card-text>
            <ChartDoughnut
              :chart-data="{
                labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                datasets: [
                  {
                    backgroundColor: [
                      '#41B883',
                      '#E46651',
                      '#00D8FF',
                      '#DD1B16',
                    ],
                    data: [40, 20, 80, 10],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Polar</v-card-title>
          <v-card-text>
            <ChartPolar
              :chart-data="{
                labels: [
                  'Eating',
                  'Drinking',
                  'Sleeping',
                  'Designing',
                  'Coding',
                  'Cycling',
                  'Running',
                ],
                datasets: [
                  {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(179,181,198,0.2)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [65, 59, 90, 81, 56, 55, 40],
                  },
                  {
                    label: 'My Second dataset',
                    backgroundColor: 'rgba(255,99,132,.20)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: [28, 48, 40, 19, 96, 27, 100],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Radar</v-card-title>
          <v-card-text>
            <ChartRadar
              :chart-data="{
                labels: [
                  'Eating',
                  'Drinking',
                  'Sleeping',
                  'Designing',
                  'Coding',
                  'Cycling',
                  'Running',
                ],
                datasets: [
                  {
                    label: 'My First dataset',
                    backgroundColor: 'rgba(179,181,198,0.2)',
                    borderColor: 'rgba(179,181,198,1)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: [65, 59, 90, 81, 56, 55, 40],
                  },
                  {
                    label: 'My Second dataset',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: [28, 48, 40, 19, 96, 27, 100],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline">Bar</v-card-title>
          <v-card-text>
            <ChartBar
              :chart-data="{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ],
                datasets: [
                  {
                    label: 'Data One',
                    backgroundColor: '#f87979',
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                  },
                  {
                    label: 'Data Two',
                    backgroundColor: '#00afd7',
                    data: [60, 30, 7, 44, 23, 50, 39, 70, 60, 12, 24, 38],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card color="grey lighten-3" flat class="fill-height">
          <v-card-title class="secondary--text overline"
            >Bar stacked</v-card-title
          >
          <v-card-text>
            <ChartBar
              :chart-data="{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ],
                datasets: [
                  {
                    label: 'Data One',
                    backgroundColor: '#f87979',
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
                  },
                  {
                    label: 'Data Two',
                    backgroundColor: '#00afd7',
                    data: [60, 30, 7, 44, 23, 50, 39, 70, 60, 12, 24, 38],
                  },
                ],
              }"
              :chart-options="{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                  },
                },
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChartLine from "@/components/charts/ChartLine.vue";
import ChartPie from "@/components/charts/ChartPie.vue";
import ChartBubble from "@/components/charts/ChartBubble.vue";
import ChartScatter from "@/components/charts/ChartScatter.vue";
import ChartDoughnut from "@/components/charts/ChartDoughnut.vue";
import ChartPolar from "@/components/charts/ChartPolar.vue";
import ChartBar from "@/components/charts/ChartBar.vue";
import ChartRadar from "@/components/charts/ChartRadar.vue";

export default {
  name: "KpiCharts",
  components: {
    ChartLine,
    ChartPie,
    ChartBubble,
    ChartScatter,
    ChartDoughnut,
    ChartPolar,
    ChartBar,
    ChartRadar,
  },
};
</script>
